import { State, hasFeature } from '@dsf/data-access-store';
import { extractRoleFromToken } from '@dsf/util-tools';

import { PumpDetail } from '@dsf/feature-dsfe';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { withTenantLayout } from '@dsf/ui-layout';
import {
  Box,
  Button,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
} from '@mui/material';
import { UiContentBox } from '@dsf/ui-content-box';
import AddCommentIcon from '@mui/icons-material/AddComment';
import ChatIcon from '@mui/icons-material/Chat';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useWrapApi } from '@dsf/data-access-api';
import { useHistory } from 'react-router-dom';
import { strEnum, useQueries, USER_ROLES } from '@dsf/util-tools';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 'none',
  },
});

export type ConversationItem = {
  created_at: string;
  created_by: string;
  conversation_id: string;
  recepients?: string[];
};

const ChatPage = () => {
  const { isMobile } = useQueries();
  const { accessToken } = useSelector((state: State) => state.userTokens);
  const extractUserRole = extractRoleFromToken(accessToken);
  const classes = useStyles();
  const [conversations, setConversations] = useState<ConversationItem[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { api } = useWrapApi();
  const history = useHistory();

  const navigateToConversation = (conversationId: string) => {
    const conversationDetail = `/conversation/${conversationId}`;
    history.push(conversationDetail);
  };

  const navigateToCreateConversation = () => {
    const conversationDetail = `/create-conversation`;
    history.push(conversationDetail);
  };

  useEffect(() => {
    //setLoading(true);

    const fetchConversations = async () => {
      try {
        //setThingsError(undefined);
        const { data } = await api.get('/conversation');
        //const mappedThings = data.map(mapThingToAsset);
        setConversations(data);
        //setLoading(false);
      } catch (error) {
        console.error('things error', error);
        //setThings([]);
        //setLoading(false);
        //setThingsError('Unable to load the asset list.');
      }
    };
    fetchConversations();
    // eslint-disable-next-line
  }, []);

  // eslint-disable-next-line
  function getRecepients(detail: any) {
    if (detail) {
      let header = '';
      for (const recepient of detail.recipients) {
        if (recepient.given_name && recepient.family_name) {
          header = recepient.given_name + ' ' + recepient.family_name;
        } else {
          header = recepient.username;
        }
      }

      return header;
    }

    return '';
  }

  return (
    <Box maxWidth="721px" margin="0 auto">
      <div>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div>
            <h1 id="title_messages">Messages</h1>
          </div>
        </Box>
        <Box mt="2rem">
          <UiContentBox
            icon={
              <Box>
                <ChatIcon />
              </Box>
            }
            title={
              <Box
                display="flex"
                fontWeight="bold"
                fontSize="1.125rem"
                alignItems="center"
                id="title_equipment-overview"
              >
                Conversations
              </Box>
            }
            postfix={
              <Box>
                <Button
                  color="inherit"
                  variant="outlined"
                  startIcon={<AddCommentIcon />}
                  id="button_register-asset"
                  onClick={() => {
                    navigateToCreateConversation();
                  }}
                >
                  New conversation
                </Button>
              </Box>
            }
          >
            <Paper className={classes.root}>
              <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table" size="small">
                  <TableBody>
                    {conversations
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.conversation_id}
                            style={{
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              navigateToConversation(row.conversation_id);
                            }}
                          >
                            <TableCell align="left">
                              {getRecepients(row)}
                            </TableCell>
                            <TableCell align="center">
                              <IconButton
                                onClick={() => {
                                  navigateToConversation(row.conversation_id);
                                }}
                              >
                                <ZoomInIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box
                sx={
                  isMobile
                    ? {
                        mt: '1rem',
                        '& .MuiTablePagination-toolbar': {
                          display: 'block',
                          textAlign: 'center',
                        },
                        '& .MuiTablePagination-actions': {
                          ml: 0,
                        },
                        '& .MuiTablePagination-selectRoot': {
                          mr: 0,
                          ml: 0,
                        },
                      }
                    : undefined
                }
              ></Box>
            </Paper>
          </UiContentBox>
        </Box>
      </div>
    </Box>
  );
};

export default withTenantLayout(ChatPage)({
  pageName: 'DSFE-ChatPage',
});
