import { AccordionList } from '../components';
import {
  createStyles,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { FlashOn } from '@material-ui/icons';
import BuildIcon from '@material-ui/icons/Build';
import RemoveShoppingCartIcon from '@material-ui/icons/RemoveShoppingCart';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import WarningIcon from '@material-ui/icons/Warning';
import { FaultItem, FaultItemStatus, FAULT_PRIORITY } from '@dsf/util-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    error: {
      paddingLeft: theme.spacing(4),
    },
    warning: {
      paddingLeft: theme.spacing(4),
    },
  })
);

type Props = {
  faultItems: FaultItem[];
};

const FaultAccordionList: React.FC<Props> = ({ faultItems }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const items = useMemo(() => {
    return faultItems.map((item) => {
      return () => {
        const colorStyleName =
          item.priority === FAULT_PRIORITY.high ? 'error' : 'warning';
        return (
          <>
            <ListItem button className={classes[colorStyleName]} key={item.id}>
              <>
                <div className={'left_icons'}>
                  {item.typeOfFault === 'mechanical' ? (
                    <ListItemIcon className="error">
                      <BuildIcon />
                    </ListItemIcon>
                  ) : null}
                  {item.typeOfFault === 'electrical' ? (
                    <ListItemIcon className="error">
                      <FlashOn />
                    </ListItemIcon>
                  ) : null}
                  <br />
                  {item.priority ? (
                    <ListItemIcon className={item.priority}>
                      <WarningIcon />
                    </ListItemIcon>
                  ) : null}
                </div>

                <ListItemText
                  primary={`${item.partName}`}
                  secondary={`${item.faultName}`}
                />

                {item.status === FaultItemStatus.delivered ? (
                  <ListItemIcon className="status_icon green">
                    <ShoppingCartIcon />
                  </ListItemIcon>
                ) : null}
                {item.status === FaultItemStatus.ordered ? (
                  <ListItemIcon className="status_icon">
                    <ShoppingCartIcon />
                  </ListItemIcon>
                ) : null}
                {item.status === FaultItemStatus.notneeded ? (
                  <ListItemIcon className="status_icon">
                    <RemoveShoppingCartIcon />
                  </ListItemIcon>
                ) : null}
              </>
            </ListItem>
            <hr className={'list_hr'} />
          </>
        );
      };
    });
    // eslint-disable-next-line
  }, [faultItems]);
  return (
    <AccordionList
      title={t('faultAccordionList.knownOpenFaults').replace(
        '{faultItemCount}',
        `${faultItems.length}`
      )}
      items={items}
    />
  );
};

export default FaultAccordionList;
