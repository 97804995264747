import { API, APIResponse, useApi } from '..';
import {
  RequestInitDeleteTenant,
  RequestStartDeleteTenant,
} from '@dsf/util-types';
import { ENDPOINT_TENANT_DELETION } from '..';

const BASE = ENDPOINT_TENANT_DELETION;
export const postTenantDeletionPath = (): string => `${BASE}`;

export interface TenantDeletionAPI extends API {
  postInitDeleteTenant: (payload: RequestInitDeleteTenant) => APIResponse<void>;
  deleteTenantCluster: (payload: RequestStartDeleteTenant) => APIResponse<void>;
}

export const useTenantDeletionApi = (): TenantDeletionAPI => {
  const api = useApi();

  return {
    ...api,
    postInitDeleteTenant(payload: RequestInitDeleteTenant) {
      const cancelToken = api.prepareCancelToken('postTenant');
      return api.authRequest.post(postTenantDeletionPath(), payload, {
        cancelToken,
      });
    },
    deleteTenantCluster(payload: RequestStartDeleteTenant) {
      const cancelToken = api.prepareCancelToken('deleteTenant');
      return api.authRequest.delete(
        postTenantDeletionPath() +
          `?secret=${payload.secret}&lang=${payload.lang}`,
        { cancelToken }
      );
    },
  };
};
