import React from 'react';

export interface Props {
  className?: string;
}

export const NewsIcon: React.FC<Props> = ({ className }: Props) => {
  return (
    <svg
      className={className}
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 0V15C0 16.654 1.346 18 3 18H17C18.645 18 20 16.645 20 15V4H18V15C18 15.565 17.565 16 17 16C16.448 16 16 15.551 16 15V0H0ZM4 3H12V6H4V3ZM4 9H12V11H4V9ZM4 13H12V15H4V13Z"
        fill="white"
      />
    </svg>
  );
};
