import './ui-scatter-chart.module.scss';

import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  ZAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';

/* eslint-disable-next-line */
export interface UiScatterChartProps {
  data: any[];
  xAxisKey: string;
  xAxisLabel: string;
  yAxisKey: string;
  yAxisLabel: string;
  maxXDomain?: number;
  maxYDomain?: number;
  showLegend?: boolean;
  showDirections?: boolean;
}

export function UiScatterChart(props: UiScatterChartProps) {
  const {
    data,
    xAxisKey,
    xAxisLabel,
    yAxisKey,
    yAxisLabel,
    maxXDomain,
    maxYDomain,
    showLegend = true,
    showDirections = false,
  } = props;

  const CustomizedDot = (props: any) => {
    const { cx, cy, value } = props;

    const thetaPosition = props?.payload?.theta_position; // (Math.PI / 2) * -1;

    return (
      <svg
        x={cx - 12}
        y={cy - 12}
        width="24"
        height="24"
        xmlns="http://www.w3.org/2000/svg"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="red"
        stroke="#ff0000"
        strokeWidth={'1px'}
      >
        <path
          d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"
          style={
            thetaPosition
              ? {
                  transformBox: 'fill-box',
                  transformOrigin: 'center',
                  transform: `rotate(${thetaPosition}rad) scale(0.8)`,
                }
              : undefined
          }
        />
      </svg>
    );
  };
  return (
    <ScatterChart
      width={500}
      height={300}
      margin={{
        top: 20,
        right: 20,
        bottom: 20,
        left: 20,
      }}
    >
      <CartesianGrid />
      <XAxis
        type="number"
        dataKey={xAxisKey}
        name={xAxisLabel}
        domain={maxXDomain ? [0, maxXDomain] : undefined}
      />
      <YAxis
        type="number"
        dataKey={yAxisKey}
        name={yAxisLabel}
        domain={maxYDomain ? [0, maxYDomain] : undefined}
      />
      <Tooltip cursor={{ strokeDasharray: '3 3' }} />
      {showLegend ? <Legend /> : null}
      <Scatter
        data={data}
        fill="#4666d0"
        line
        shape={<CustomizedDot />}
        lineJointType="linear" // natural also looks good
      />
    </ScatterChart>
  );
}

export default UiScatterChart;
