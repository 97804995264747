import './feature-dsfe.module.scss';
import { Box } from '@material-ui/core';
import { DSFE_ASSET_DETAIL } from '@dsf/util-router';

import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import { AssetList } from './components';
import { UiContentBox } from '@dsf/ui-content-box';
import PostAddIcon from '@material-ui/icons/PostAdd';
import { Button } from '@mui/material';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import { UiDialogScanner } from '@dsf/ui-dialog-scanner';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import { OnResultFunction } from 'react-qr-reader';
import { containsUrl, useQueries } from '@dsf/util-tools';
import { useSelector } from 'react-redux';
import { State } from '@dsf/data-access-store';

export function FeatureDsfe() {
  const { isMobile } = useQueries();
  const { t } = useTranslation();
  const [showScanner, setShowScanner] = useState(false);
  const { tenantConfig } = useSelector((state: State) => state.appSettings);
  const [scanError, setScanError] = useState(false);

  const history = useHistory();
  const onScan: OnResultFunction = (result, error) => {
    if (!result) {
      return;
    }

    const textData = result.getText();

    if (
      textData &&
      containsUrl(textData) &&
      tenantConfig &&
      tenantConfig.tenant_id &&
      textData.indexOf(tenantConfig.tenant_id) !== -1
    ) {
      window.location.replace(textData);
      return;
    }

    const splitData = textData?.split('DSF-PA-');
    const thingName = splitData[1];
    if (thingName) {
      history.push(DSFE_ASSET_DETAIL.replace(':assetId', thingName));
      return;
    }
    setScanError(true);
  };

  return (
    <Box maxWidth="721px" margin="0 auto">
      <div>
        <Box
          sx={{
            display: isMobile ? 'block' : 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div>
            <h1 id="title_asset-management">{t('assetManagement.title')}</h1>
          </div>
          <Button
            variant="outlined"
            startIcon={<PlaylistRemoveIcon />}
            color="error"
            id="button_decommission"
            onClick={() => {
              history.push('/dsfe/decommissioned');
            }}
          >
            {t('assetManagement.decommissionedAssets')}
          </Button>
        </Box>
        <Box mt="2rem">
          <UiContentBox
            icon={
              <Box>
                <PostAddIcon />
              </Box>
            }
            title={
              <Box
                display="flex"
                fontWeight="bold"
                fontSize="1.125rem"
                alignItems="center"
                id="title_equipment-overview"
              >
                {t('assetManagement.equipmentOverview')}
              </Box>
            }
            postfix={
              <Box>
                <Button
                  color="inherit"
                  variant="outlined"
                  id="button_scan-existing-asset"
                  onClick={() => {
                    setShowScanner(true);
                  }}
                  startIcon={<QrCodeScannerIcon htmlColor="#000" />}
                  sx={{
                    mr: '1rem',
                    mb: isMobile ? '1rem' : undefined,
                  }}
                >
                  {t('assetManagement.scanExistingAsset')}
                </Button>
                <Button
                  color="inherit"
                  variant="outlined"
                  startIcon={<CreateNewFolderIcon />}
                  id="button_register-asset"
                  disabled
                  onClick={() => {
                    history.push('/dsfe/add-asset/');
                  }}
                >
                  {t('assetManagement.registerNewAsset')}
                </Button>
              </Box>
            }
          >
            <AssetList />
          </UiContentBox>
        </Box>
      </div>
      {showScanner ? (
        <UiDialogScanner
          onScan={onScan}
          onClose={() => {
            setShowScanner(false);
            setScanError(false);
          }}
          title={t('assetManagement.scanQrCode')}
          hasError={scanError}
          onCloseError={() => {
            setScanError(false);
          }}
        />
      ) : null}
    </Box>
  );
}

export default FeatureDsfe;
