import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { strEnum } from '@dsf/util-tools';
import { DefaultState } from './types/defaultState';

export const DEFAULT_DOMAIN = 'digital-platform.io';
// export const DEFAULT_DOMAIN = 'dsfq.io';

export const PERMISSIONS = strEnum([
  'decommission_assets',
  'grundfos_asset_detail',
  'manage_features',
  'manage_users',
  'operate_assets',
  'register_assets',
  'view_asset_details',
  'view_assets',
  'view_field_overview',
  'view_invoices',
  'view_locations',
  'view_orders',
]);
export type PermissionType = keyof typeof PERMISSIONS;

export type TenantConfig = {
  tenant_id: string; // example: alpha
  api_url: string;
  cognito: string;
  cognito_client: string;
  region: string;
  settings: {
    allowed_gui_by_role: {
      superadmin: PermissionType[];
      dsfe_admin: PermissionType[];
      dsfe_worker: PermissionType[];
      dsfe_accountant: PermissionType[];
      admin: PermissionType[]; // TODO: jm - to be removed
      grundfos_data_analyst: PermissionType[];
    };
    background_image: string;
    default_language: string; // example: en-us
    logo: string; // absolute url
    title: string; // Alpha Industries for example
    description: string; // Alpha Industries for example
  };
};

export type UserRole = keyof TenantConfig['settings']['allowed_gui_by_role'];

type Secrets = {
  gmap_api_key: string;
};

export interface AppSettingsState extends DefaultState {
  tenantConfig?: TenantConfig;
  accountDomain?: string; // example: alpha.digital-platform.io
  secrets?: Secrets;
}

const INITIAL_STATE: AppSettingsState = {
  tenantConfig: undefined,
  accountDomain: DEFAULT_DOMAIN,
  persist: true,
};

export const appSettingsSlice = createSlice({
  name: 'appSettings',
  initialState: INITIAL_STATE,
  reducers: {
    setSettings: (state, action: PayloadAction<TenantConfig>) => {
      state.tenantConfig = action.payload;
      const tenantId = action.payload.tenant_id;
      if (tenantId) {
        state.accountDomain = `${tenantId}.${DEFAULT_DOMAIN}`;
      } else {
        state.accountDomain = DEFAULT_DOMAIN;
      }
    },
    setSecrets: (state, action: PayloadAction<Secrets>) => {
      state.secrets = { ...state.secrets, ...action.payload };
    },
  },
});
