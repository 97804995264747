import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Snackbar,
  TextField,
  Typography,
} from '@material-ui/core';

import { State } from '@dsf/data-access-store';
import {
  useUserApi,
  useFaultItemApi,
  useFaultPartsApi,
  useProductionApi as useFaultTypeApiProductionTeam,
  useFaultTypeApi,
  useLocationApi,
  usePhysicalAssetApi,
} from '@dsf/data-access-api';
import { FAULT_ITEM_DETAIL } from '@dsf/util-router';

import EditIcon from '@material-ui/icons/Edit';
import PinDropIcon from '@material-ui/icons/PinDrop';
import Replay10Icon from '@material-ui/icons/Replay10';
import { Alert, Autocomplete } from '@material-ui/lab';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { MenuBrickIcon } from '@dsf/ui-assets';
import {
  ContentBox,
  QrScanner,
  ScreenTitle,
  TextLabel,
} from '../../../components';
import OrBlock from '../../../components/or-block';
import { VALUE_PATTERNS, VALUE_PREFIXES } from '../../../components/qr-scanner';
import { withTenantLayout } from '@dsf/ui-layout';
import {
  FaultItemCreateRequest,
  FaultItemListResponse,
  FaultType,
  FaultyPart,
  LocationType,
  OverlayLocation,
  PictureDefinition,
  TeamResponse,
} from '@dsf/util-types';
import Top10 from './components/Top10';

type Params = {
  assetId: string;
};

const CreateFaultItemPageNew = () => {
  const history = useHistory();
  const { assetId: assetIdParam } = useParams<Params>();

  const { t } = useTranslation();
  const imageRef = useRef<HTMLImageElement>(null);
  const { profile } = useSelector((state: State) => state.identity);

  const userApi = useUserApi();
  const locationApi = useLocationApi();
  const faultItemApi = useFaultItemApi();
  const faultTypeApi = useFaultTypeApi();
  const faultPartsApi = useFaultPartsApi();
  const physicalAssetApi = usePhysicalAssetApi();
  const faultTypeApiProductionTeam = useFaultTypeApiProductionTeam();

  const [warningValue, setWarningValue] = useState('');
  const [openWarning, setOpenWarning] = useState(false);

  const [loading, setLoading] = useState(true);
  const [locationOptions, setLocationOptions] = useState<LocationType[]>([]);
  const [assetOptions, setAssetOptions] = useState<FaultItemListResponse[]>([]);
  const [selectedAsset, setSelectedAsset] = useState<FaultItemListResponse>();
  const [selectedLocation, setSelectedLocation] = useState<LocationType>();
  const [assetPicture, setAssetPicture] = useState<string>();
  const [faultyPart, setFaultyPart] = useState<FaultyPart>();
  const [faultType, setFaultType] = useState<FaultType>();
  const [faultTypes, setFaultTypes] = useState<FaultType[]>();
  const [faultParts, setFaultParts] = useState<FaultyPart[]>([]);
  const [faultPartDetailImage, setFaultPartDetailImage] = useState<string>();
  const [r, setRatio] = useState<number>(1); // ratio for mapping image overlay definition to actual size of image
  const [pictureDefinition, setPictureDefinition] =
    useState<PictureDefinition>();
  const [causers, setCausers] = useState<TeamResponse[]>([]);
  const [faultCauser, setFaultCauser] = useState<TeamResponse | undefined>();
  const [openCreateFaultButtonError, setOpenCreateFaultButtonError] =
    useState<boolean>(false);
  const [showLocationScanner, setShowLocationScanner] = useState(false);
  const [showAssetScanner, setShowAssetScanner] = useState(false);
  const [isProfileLoading, setIsProfileLoading] = useState(true);

  const postFaultItem = async () => {
    try {
      if (selectedLocation && selectedAsset && faultType && faultyPart) {
        const createFaultItemRequest: FaultItemCreateRequest = {
          faultyPartId: faultyPart.id,
          faultTypeId: faultType.id,
          reporterId: profile?.id as number,
          physicalAssetId: selectedAsset.assetId,
          reportLocationId: selectedLocation.id,
          causerId: faultCauser?.teamId as number,
        };

        if (faultyPart?.pictureId) {
          createFaultItemRequest.faultPictureIds = [
            faultyPart?.pictureId as string,
          ];
        }

        try {
          const createFaultItemResponse = (
            await faultItemApi.createFaultItem(createFaultItemRequest)
          ).data;
          history.push(
            FAULT_ITEM_DETAIL.replace(
              ':id',
              createFaultItemResponse.id.toString()
            )
          );
          setSelectedAsset(undefined);
          setSelectedLocation(undefined);
          setFaultyPart(undefined);
          setFaultType(undefined);
          setFaultCauser(undefined);
        } catch (error) {
          console.log(error);
          console.error('Error');
          setOpenCreateFaultButtonError(true);
        }
      } else {
        /* This should never show to the user. selected items should be always selected at this condition */
        setOpenCreateFaultButtonError(true);
      }
    } catch (e) {
      setOpenCreateFaultButtonError(true);
    }
  };

  // eslint-disable-next-line
  const getFaultTypes = async (fp?: any) => {
    setLoading(true);
    const part = fp ? fp : faultyPart;
    if (part) {
      try {
        const faultyTypeCall = await faultTypeApi.getFaultTypes(
          part.id as number
        );
        setFaultTypes(faultyTypeCall.data);
        setFaultType(undefined);
        setLoading(false);
      } catch (error) {
        console.error('getFaultTypes error', error);
        setLoading(false);
      }
    }
  };

  // eslint-disable-next-line
  const getFaultTypeList = async (fp?: any) => {
    setLoading(true);
    const part = fp ? fp : faultyPart;
    if (part) {
      try {
        const faultyTypeCall = await faultTypeApi.getFaultTypes(
          part.id as number
        );
        setFaultTypes(faultyTypeCall.data);
        setFaultType(undefined);
        setLoading(false);
      } catch (error) {
        console.error('getFaultTypeList error', error);
        setLoading(false);
      }
    }
  };

  const getFaultPartDetailImage = (
    imageId: string,
    selectedFaultyPart?: FaultyPart
  ) => {
    const getImage = async () => {
      setLoading(true);
      try {
        const faultItemImageResponse = (
          await faultItemApi.getImageBase64(imageId)
        ).data;
        setFaultPartDetailImage(
          `data:image/png;base64,${faultItemImageResponse}`
        );
        if (selectedFaultyPart) {
          setFaultyPart(selectedFaultyPart);
          getFaultTypeList(selectedFaultyPart);
        }
        setLoading(false);
      } catch (error) {
        console.error('getFaultPartDetailImage error', error);
        setLoading(false);
      }
    };
    setFaultPartDetailImage(undefined);
    getImage();
  };

  const updateRatio = () => {
    let ratio = r;
    if (pictureDefinition) {
      ratio =
        (imageRef.current &&
          imageRef.current.width / pictureDefinition?.width) ||
        r;
    }
    if (r !== ratio) {
      setRatio(ratio);
    }
  };

  const closeWarningHandle = (
    event?: React.SyntheticEvent,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenWarning(false);
  };

  const closeErrorHandle = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenCreateFaultButtonError(false);
  };

  const getCurrentLocation = async () => {
    setIsProfileLoading(true);
    try {
      const response = await userApi.getUserProfileLocation();
      setSelectedLocation(response.data);
      setIsProfileLoading(false);
    } catch (error) {
      console.error('getCurrentLocation error', error);
      setLoading(false);
      setIsProfileLoading(false);
    }
  };

  const getLocationOptions = async () => {
    try {
      const getLocationsResponse = await locationApi.getLocations();
      setLocationOptions(getLocationsResponse.data);
      return getLocationsResponse.data;
    } catch (error) {
      console.error('getLocationOptions error', error);
      setLoading(false);
    }
  };

  const getAssetOptions = async () => {
    try {
      const response = (await physicalAssetApi.getAllFinished()).data;
      setAssetOptions(response);
    } catch (error) {
      console.error('getAssetOptions error', error);
      setLoading(false);
    }
  };

  const getAssetPicture = async () => {
    if (!selectedAsset) {
      return;
    }
    const assetPictureId = selectedAsset.pictureId;
    if (!assetPictureId) {
      return;
    }
    try {
      const assetPictureResponse = (
        await faultItemApi.getImageBase64(assetPictureId)
      ).data;
      setAssetPicture(`data:image/png;base64,${assetPictureResponse}`);
    } catch (error) {
      console.error('getAssetPicture error', error);
      setLoading(false);
    }
  };

  const getFaultParts = async () => {
    if (!selectedAsset) {
      return;
    }
    try {
      const response = (
        await faultPartsApi.getFaultParts(selectedAsset.assetId)
      ).data;
      setFaultParts(response);
    } catch (error) {
      console.error('getFaultParts error', error);
      setLoading(false);
    }
  };

  const getCauserList = async () => {
    try {
      const causersListResponse = (
        await faultTypeApiProductionTeam.getCausers()
      ).data;
      setCausers(causersListResponse);
    } catch (error) {
      console.error('getCauserList error', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const init = async () => {
      const locationOptionsResponse = await getLocationOptions();
      if (locationOptionsResponse && locationOptionsResponse.length > 0) {
        await getCurrentLocation();
      }
      await getAssetOptions();
      setLoading(false);
    };
    init();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (assetIdParam && assetOptions) {
      const assetFromParam = assetOptions.find(
        (item) => `${item.assetId}` === `${assetIdParam}`
      );
      if (assetFromParam) {
        setSelectedAsset(assetFromParam);
      }
    }
  }, [assetIdParam, assetOptions]);

  useEffect(() => {
    const asyncWrap = async () => {
      setLoading(true);
      await getAssetPicture();
      await getFaultParts();
      await getCauserList();
      setLoading(false);
    };
    if (selectedAsset) {
      asyncWrap();
    }
    // eslint-disable-next-line
  }, [selectedAsset]);

  //   These two effects are hacks that prevent the camera from starting when not needed between rerenders
  useEffect(() => {
    if (isProfileLoading) {
      setShowLocationScanner(false);
    } else {
      setShowLocationScanner(true);
    }
  }, [isProfileLoading]);
  useEffect(() => {
    if (selectedLocation && !selectedAsset) {
      setShowAssetScanner(true);
    } else {
      setShowAssetScanner(false);
    }
  }, [selectedLocation, selectedAsset]);

  if (loading) {
    return (
      <Box textAlign="center" mt="2rem">
        <div>
          <CircularProgress color="secondary" />
          <Typography variant="body1">{t('global.loading')}</Typography>
        </div>
      </Box>
    );
  }

  return (
    <>
      <ScreenTitle id="createFaultItem_title">
        {t('createFaultItem.title')}
      </ScreenTitle>

      <ContentBox
        icon={<PinDropIcon />}
        title={
          <Box display="flex" justifyContent="space-between">
            <Box
              fontSize="1.125rem"
              fontWeight="bold"
              id="faultDetailPage_assetNumber"
            >
              {t('createFaultItem.location.title')}
            </Box>
            <Box color="#707483" fontSize="0.75rem" mt="0.25rem">
              {!selectedLocation && t('createFaultItem.location.selectBelow')}
            </Box>
          </Box>
        }
      >
        {selectedLocation ? (
          <Box mb="2rem">{t('createFaultItem.location.saved')}</Box>
        ) : (
          <>
            <Box mb="2rem">{t('createFaultItem.location.infoLabel')}</Box>
            {showLocationScanner ? (
              <QrScanner
                successCallback={(value) => {
                  const locId = value.replace(VALUE_PREFIXES.LOCATION, '');
                  const scannedLocation = locationOptions.find(
                    (location) => `${location.id}` === locId
                  );
                  if (scannedLocation) {
                    setSelectedLocation(scannedLocation);
                  } else {
                    setWarningValue('locationNotFound');
                    setOpenWarning(true);
                  }
                }}
                valuePattern={VALUE_PATTERNS.LOCATION}
              />
            ) : null}
            <OrBlock />
          </>
        )}
        <Box mb="2rem">
          <Autocomplete
            id="asset-location-combobox"
            className="full_width"
            options={locationOptions}
            value={selectedLocation || null}
            // eslint-disable-next-line
            onChange={(event: any, value: LocationType | null) => {
              if (value) {
                setSelectedLocation(value);
              } else {
                setSelectedLocation(undefined);
              }
            }}
            getOptionLabel={(option) => option.segmentName}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={t('createFaultItem.location.select')}
              />
            )}
          />
        </Box>
      </ContentBox>

      {selectedLocation ? (
        <div>
          {selectedAsset ? (
            <>
              <Box mb="2rem">
                {t('createFaultItem.asset.savedCheckKnownFaults')}
              </Box>

              <ContentBox
                icon={
                  <Box sx={{ marginTop: '4px' }}>
                    <MenuBrickIcon />
                  </Box>
                }
                title={
                  <Box
                    display="flex"
                    fontWeight="bold"
                    fontSize="1.125rem"
                    alignItems="center"
                  >
                    {selectedAsset.assetNumber}
                    <Box ml="0.5rem">
                      <IconButton
                        onClick={() => {
                          setSelectedAsset(undefined);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Box>
                  </Box>
                }
              >
                {assetPicture ? (
                  <Box mb="1rem">
                    <img
                      id="faultItem-picture"
                      alt={selectedAsset.assetNumber}
                      src={assetPicture}
                    />
                  </Box>
                ) : null}

                <TextLabel
                  label={t('createFaultItem.asset.version').toUpperCase()}
                  content={selectedAsset.assetVersion}
                />
                <TextLabel
                  label={t('createFaultItem.asset.colourCode').toUpperCase()}
                  content="195 - light yellow"
                />
                <Box display="flex" alignItems="center">
                  <TextLabel
                    noBorder
                    label={t(
                      'createFaultItem.asset.lastLocation'
                    ).toUpperCase()}
                    content={selectedLocation.segmentName}
                  />
                  <IconButton
                    onClick={() => {
                      setSelectedAsset(undefined);
                      setSelectedLocation(undefined);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Box>
              </ContentBox>

              <Box mb="1rem">
                <ContentBox
                  icon={
                    <Box sx={{ position: 'relative', top: '3px' }}>
                      <Replay10Icon />
                    </Box>
                  }
                  title={
                    <Box
                      display="flex"
                      fontWeight="bold"
                      fontSize="1.125rem"
                      alignItems="center"
                    >
                      Top 10 Faults
                    </Box>
                  }
                >
                  <Top10
                    physicalAssetId={selectedAsset.assetId}
                    handleItemClick={(selectedCombination) => {
                      const faultyPart: FaultyPart = {
                        id: selectedCombination.faultyPartId,
                        name: selectedCombination.faultyPartName,
                        pictureId: selectedCombination.faultyPartPictureId,
                      };
                      const faultType = {
                        id: selectedCombination.faultTypeId,
                        name: selectedCombination.faultTypeName,
                      };
                      //   TODO: jm - refactor and do not use redux
                      getFaultTypes(faultyPart).then(() => {
                        setFaultyPart(faultyPart);
                        setFaultType(faultType);
                      });
                    }}
                  />
                </ContentBox>
              </Box>

              <Box mb="1rem">{t('createFaultItem.faultyPart.info')}</Box>

              <Box mb="1rem">
                <Autocomplete
                  id="Faulty_part_combobox"
                  options={faultParts}
                  value={faultyPart || null}
                  // eslint-disable-next-line
                  onChange={(event: any, value: FaultyPart | null) => {
                    if (value) {
                      setFaultyPart(value);
                      getFaultPartDetailImage(value.pictureId as string);
                      getFaultTypeList(value);
                    } else {
                      setFaultyPart(undefined);
                      getFaultTypeList(value);
                    }
                  }}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={t('createFaultItem.faultyPart.select')}
                    />
                  )}
                />
              </Box>

              <Box mb="1rem">
                {!faultyPart ? (
                  <div className="image">
                    <img
                      id="clickableImage"
                      onLoad={updateRatio}
                      ref={imageRef}
                      src={assetPicture}
                      alt="areamap"
                      useMap="#areamap"
                    />
                    <map name="areamap">
                      {pictureDefinition?.overlays.map(
                        (overlay: OverlayLocation) => {
                          const topLeftPoint = overlay.points[0];
                          const bottomRightPoint = overlay.points[2];
                          const coords = `${topLeftPoint.x * r},${
                            topLeftPoint.y * r
                          },${bottomRightPoint.x * r},${
                            bottomRightPoint.y * r
                          }`;

                          return (
                            <area
                              key={overlay.id}
                              shape="rect"
                              coords={coords}
                              alt={overlay.physicalAssetTypeName}
                              // eslint-disable-next-line
                              href="javascript:void(0);"
                              onClick={() => {
                                const selectedFaultyPart = {
                                  id: overlay.physicalAssetTypeId,
                                  name: overlay.physicalAssetTypeName,
                                  pictureId: overlay.physicalAssetTypePictureId,
                                };
                                getFaultPartDetailImage(
                                  overlay.physicalAssetTypePictureId,
                                  selectedFaultyPart
                                );

                                setFaultyPart(selectedFaultyPart);
                                getFaultTypeList(selectedFaultyPart);
                              }}
                            />
                          );
                        }
                      )}
                    </map>
                  </div>
                ) : (
                  <img src={faultPartDetailImage} alt={faultyPart.name} />
                )}
              </Box>

              {faultyPart ? (
                <>
                  <Box mb="1rem">
                    {t('createFaultItem.faultyPart.searchAndSelect')}
                  </Box>
                  <Box mb="1rem">
                    <Autocomplete
                      id="Fault_type_combobox"
                      options={faultTypes as FaultType[]}
                      value={faultType || null}
                      // eslint-disable-next-line
                      onChange={(event: any, value: FaultType | null) => {
                        if (value != null) {
                          setFaultType(value);
                        } else {
                          setFaultType(undefined);
                        }
                      }}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t('createFaultItem.faultType.select')}
                          variant="outlined"
                        />
                      )}
                    />
                  </Box>
                  <Box mb="2rem">
                    <Autocomplete
                      id="causer-combobox"
                      options={causers}
                      value={faultCauser}
                      // eslint-disable-next-line
                      onChange={(event: any, value: TeamResponse | null) => {
                        if (value) {
                          setFaultCauser(value);
                        }
                      }}
                      getOptionLabel={(option) => option?.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t('createFaultItem.causer.select')}
                          variant="outlined"
                        />
                      )}
                    />
                  </Box>
                  <Box>
                    <Button
                      id="createFaultItem_clearAllFaultData_button"
                      onClick={() => {
                        setSelectedAsset(undefined);
                        setSelectedLocation(undefined);
                        setFaultyPart(undefined);
                        setFaultType(undefined);
                        setFaultCauser(undefined);
                      }}
                    >
                      {t('createFaultItem.resetButton')}
                    </Button>
                  </Box>
                  {faultType && (
                    <Box>
                      <Button
                        id="createFaultItem_createFaultItem_button"
                        variant="contained"
                        color="primary"
                        onClick={postFaultItem}
                      >
                        {t('createFaultItem.createButton')}
                      </Button>
                    </Box>
                  )}
                </>
              ) : null}
            </>
          ) : (
            <ContentBox
              icon={
                <Box mt="0.25rem">
                  <MenuBrickIcon />
                </Box>
              }
              title={
                <Box display="flex" justifyContent="space-between">
                  <Box
                    fontSize="1.125rem"
                    fontWeight="bold"
                    id="faultDetailPage_assetNumber"
                  >
                    {t('createFaultItem.asset.title')}
                  </Box>
                  <Box color="#707483" fontSize="0.75rem" mt="0.25rem">
                    {!selectedLocation &&
                      t('createFaultItem.asset.scanOrSelect')}
                  </Box>
                </Box>
              }
            >
              <Box mb="2rem">{t('createFaultItem.asset.description')}</Box>

              {showAssetScanner ? (
                <QrScanner
                  successCallback={(value) => {
                    const assetId = value.replace(
                      VALUE_PREFIXES.PHYSICAL_ASSET,
                      ''
                    );
                    const scannedAsset = assetOptions.find(
                      (item) => `${item.digitalIdentity}` === assetId
                    );
                    if (scannedAsset) {
                      setSelectedAsset(scannedAsset);
                    } else {
                      setWarningValue('assetNotFound');
                    }
                  }}
                  valuePattern={VALUE_PATTERNS.PHYSICAL_ASSET}
                />
              ) : null}
              <OrBlock />

              <Autocomplete
                id="asset-combobox"
                className="full_width"
                options={assetOptions}
                value={selectedAsset || null}
                // eslint-disable-next-line
                onChange={(event: any, value: FaultItemListResponse | null) => {
                  if (value) {
                    setSelectedAsset(value);
                  }
                }}
                getOptionLabel={(option) => option.assetNumber}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('createFaultItem.asset.select')}
                    variant="outlined"
                  />
                )}
              />
            </ContentBox>
          )}
        </div>
      ) : null}

      <Snackbar
        open={openCreateFaultButtonError}
        autoHideDuration={5000}
        onClose={closeErrorHandle}
      >
        <Alert onClose={closeErrorHandle} severity="error">
          {t('createFaultItem.notificationMessages.createError')}
        </Alert>
      </Snackbar>

      <Snackbar
        open={openWarning}
        autoHideDuration={5000}
        onClose={closeWarningHandle}
      >
        <Alert onClose={closeWarningHandle} severity="warning">
          {t('createFaultItem.notificationMessages.' + warningValue)}
        </Alert>
      </Snackbar>
    </>
  );
};

export default withTenantLayout(CreateFaultItemPageNew)({
  pageName: 'SCR-Create-fault',
});
