import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { State } from '@dsf/data-access-store';
import {
  useCommentApi,
  useUserApi,
  useFaultItemApi,
} from '@dsf/data-access-api';

import { ItemCommentResponse } from '@dsf/util-types';
import { Box, Button, Grid, TextField } from '@material-ui/core';
import moment from 'moment';
import DiscussionCommentGroup, {
  Comment,
} from '../../../../pages/private/FaultItemDetailPage_DEPRECATED/discussion/DiscussionCommentGroup';
import { useTranslation } from 'react-i18next';
import SendIcon from '@material-ui/icons/Send';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import useFormatters from '../../../../hooks/formatter';
import { useSelector } from 'react-redux';
import { createImageFromBlob } from '@dsf/util-tools';

import InfiniteScroll from 'react-infinite-scroll-component';
import { Loader } from '../../../../components';
import Pusher from 'pusher-js';

export interface Props {
  id: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      width: '100%',
      backgroundColor: 'white',
    },
    sendButton: {
      marginLeft: theme.spacing(1),
    },
  })
);

const APP_KEY = 'c8084f512f8d12954f36';
const APP_CLUSTER = 'eu';

const FaultItemDiscussion = ({ id }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { formatTime } = useFormatters();
  const { profile } = useSelector((state: State) => state.identity);

  const faultItemApi = useFaultItemApi();
  const commentApi = useCommentApi();
  const userApi = useUserApi();

  const [newComment, setNewComment] = useState<string | undefined>(undefined);
  const [fetch, setFetch] = useState(false);
  const [fetchTranslations, setFetchTranslations] = useState(false);

  const [comments, setComments] = useState<ItemCommentResponse[]>([]);
  const [translatedComments, setTranslatedComments] = useState<
    ItemCommentResponse[]
  >([]);
  const [translatingComments, setTranslatingComments] = useState<number[]>([]);
  const translateFirstCount = 5;
  const LIMIT = 10;
  const [hasMoreComments, setHasMoreComments] = useState(true);
  const [newestCommentsFetching, setNewestCommentsFetching] = useState(false);
  const [profileImages, setProfileImages] = useState<
    { id: number; image: string | undefined }[]
  >([]);
  const [showTranslatedCommentIds, setShowTranslatedCommentIds] = useState<
    number[]
  >([]);

  const loadProfileImages = async (comments: ItemCommentResponse[]) => {
    const promises: Promise<{ id: number; image: string | undefined }>[] = [];
    const inPromises: number[] = [];

    comments.forEach((comment) => {
      const id = comment.user?.id || -1;

      if (
        !profileImages.find((v) => v.id === id) &&
        !inPromises.find((pId) => pId === id)
      ) {
        inPromises.push(id);

        promises.push(
          new Promise((resolve, reject) => {
            userApi
              .getUserProfileImage(id)
              .then((res) => {
                createImageFromBlob(res.data).then((img) => {
                  return resolve({ id, image: img as string });
                });
              })
              .catch((res) => {
                resolve({ id, image: undefined });
                reject(res);
                console.log(
                  `cannot get image :: ${id} reason :: ${JSON.stringify(res)}`
                );
              });
          })
        );
      }
    });

    if (promises.length !== 0) {
      Promise.all(promises)
        .then((values) => {
          setProfileImages((profileImages) => {
            const profileImagesCopy = [...profileImages];
            values.forEach((v) => {
              profileImagesCopy.push(v);
            });

            return profileImagesCopy;
          });
        })
        .catch((rejected) => {
          console.log(`rejected :: ${JSON.stringify(rejected)}`);
        });
    }
  };

  const markAsTranslatingComment = (id: number) => {
    //mark as translating
    setTranslatingComments((translatingComments) => {
      const translatingCopy = [...translatingComments];
      translatingCopy.push(id);
      return translatingCopy;
    });
  };

  const unmarkAsTranslatingComment = (id: number) => {
    setTranslatingComments((translatingComments) => {
      const translatingCopy = [...translatingComments];
      const index = translatingCopy.indexOf(id);
      if (index !== -1) {
        translatingCopy.splice(index, 1);
      }
      return translatingCopy;
    });
  };

  const translateComment = async (id: number) => {
    const isTranslated =
      translatedComments.find((c) => c.id === id) !== undefined;
    if (!isTranslated) {
      const isTranslating =
        translatingComments.find((tId) => tId === id) !== undefined;
      if (!isTranslating) {
        markAsTranslatingComment(id);
        try {
          const res = await commentApi.translate(id);

          setTranslatedComments((translatedComments) => {
            const translatedCommentsCopy = [...translatedComments];
            translatedCommentsCopy.push(res.data);
            return translatedCommentsCopy;
          });
        } catch (e) {
          console.error(`cannot translate comment :: ${e}`);
          setTranslatedComments((translatedComments) => {
            const translatedCommentsCopy = [...translatedComments];
            translatedCommentsCopy.push({
              id: id,
              translatedComment: t(
                'faultItemDiscussion.translation.cannotTranslate'
              ),
            });
            return translatedCommentsCopy;
          });
        } finally {
          unmarkAsTranslatingComment(id);
        }
      }
    }
  };

  const handleShowTranslation = (id: number) => {
    setShowTranslatedCommentIds((showTranslatedCommentIds) => {
      const showTranslatedCommentIdsCopy = [...showTranslatedCommentIds];
      const index = showTranslatedCommentIdsCopy.indexOf(id);
      if (index === -1) {
        showTranslatedCommentIdsCopy.push(id);
      } else {
        showTranslatedCommentIdsCopy.splice(index, 1);
      }
      return showTranslatedCommentIdsCopy;
    });
  };

  const forceTranslations = useCallback(
    (comments: ItemCommentResponse[]) => {
      // translate first top 5
      let translatedCount = 0;
      comments.forEach((comment) => {
        const translated = translatedComments.find((c) => c.id === comment.id);
        const translating = translatingComments.find(
          (tId) => tId === comment.id
        );

        const tryTranslate = () => {
          return comment.commentLanguage !== profile?.preferredUILanguage;
        };

        if (translatedCount < translateFirstCount) {
          if (tryTranslate()) {
            translatedCount++;
            if (!translated && !translating) {
              translateComment(comment.id);
              handleShowTranslation(comment.id);
            }
          }
        }
      });
    },
    // eslint-disable-next-line
    [translatedComments, translatingComments, setShowTranslatedCommentIds]
  );

  const fetchNewest = async () => {
    if (!newestCommentsFetching) {
      setNewestCommentsFetching(true);
      try {
        const firstReadCommentId: number | undefined =
          comments.length === 0 ? undefined : comments[0].id;

        const commentsResponse = await faultItemApi.getComments(
          id,
          LIMIT,
          undefined,
          firstReadCommentId
        );
        const resComments = commentsResponse.data;
        loadProfileImages(resComments);
        const commentsCopy: ItemCommentResponse[] = [];
        setComments((comments) => {
          commentsCopy.push(...resComments);
          commentsCopy.push(...comments);
          return commentsCopy;
        });
        forceTranslations(commentsCopy);
      } catch (error) {
        console.log(`cannot get comments :: ${error}`);
      } finally {
        setNewestCommentsFetching(false);
      }
    }
  };

  const fetchNext = async () => {
    try {
      const lastReadCommentId: number | undefined =
        comments.length === 0 ? undefined : comments[comments.length - 1].id;

      const commentsResponse = await faultItemApi.getComments(
        id,
        LIMIT,
        lastReadCommentId,
        undefined
      );

      const resComments = commentsResponse.data;

      setHasMoreComments(resComments.length >= LIMIT);

      loadProfileImages(resComments);

      const commentsCopy: ItemCommentResponse[] = [];
      setComments((comments) => {
        commentsCopy.push(...comments);
        commentsCopy.push(...resComments);
        return commentsCopy;
      });

      forceTranslations(commentsCopy);
    } catch (error) {
      console.log(`cannot get comments :: ${error}`);
    }
  };

  const addComment = async (comment: string) => {
    try {
      await commentApi.postComments({
        comment,
        faultItemId: parseInt(id),
      });
      await fetchNewest();
    } catch (e) {
      console.error(`cannot send comment :: ${e}`);
    }
  };

  const createMessageGroups = () => {
    const commentGroupMap = new Map<string, ItemCommentResponse[]>();
    comments.forEach((comment) => {
      const key = moment(comment.timestamp).format('YYYY-MM-DD-hh:mm');

      let group = commentGroupMap.get(key);
      if (!group) {
        group = [];
      }
      group.push(comment);
      commentGroupMap.set(key, group);
    });

    return commentGroupMap;
  };

  const commentGroupItems = useMemo(() => {
    const groupsMap = createMessageGroups();

    const items: JSX.Element[] = [];
    let firstGroup = true;

    groupsMap.forEach((value, key) => {
      const comments: Comment[] = value.map((v) => {
        const translated = translatedComments.find((c) => c.id === v.id);
        const translating = translatingComments.find((tId) => tId === v.id);
        const showTranslation = showTranslatedCommentIds.find(
          (tId) => tId === v.id
        );

        return {
          id: v.id,
          title: `${v.user?.lastName}, ${v.user?.firstName}: ${formatTime(
            v.timestamp || ''
          )}`,
          text: v.comment || '',
          textLanguage: v.commentLanguage || '',
          translatedText: translated?.translatedComment || '',
          translatedTextLanguage: profile?.preferredUILanguage || '',
          isTranslated: translated !== undefined,
          isTranslating: translating !== undefined,
          showTranslation: showTranslation !== undefined,
          userId: v.user?.id || -1,
        };
      });

      // resolve date or today, yesterday
      const nowDayM = moment(moment().format('YYYY-MM-DD'));
      const commentDayM = moment(
        moment(value[0].timestamp).format('YYYY-MM-DD')
      );
      const isToday = nowDayM.isSame(commentDayM);
      const isYesterday = nowDayM.subtract(1, 'day').isSame(commentDayM);

      let groupTitle = formatTime(value[0].timestamp || '');
      if (isToday) {
        groupTitle = `${t('faultItemDiscussion.commentGroup.today')} ${moment(
          value[0].timestamp
        ).format('HH:mm')}`;
      } else if (isYesterday) {
        groupTitle = `${t(
          'faultItemDiscussion.commentGroup.yesterday'
        )} ${moment(value[0].timestamp).format('HH:mm')}`;
      }

      items.push(
        <DiscussionCommentGroup
          key={key}
          title={groupTitle}
          comments={comments}
          isFirst={firstGroup}
          profileImages={profileImages}
          translateComment={(id) => translateComment(id)}
          showTranslation={handleShowTranslation}
        />
      );
      firstGroup = false;
    });

    return items;
    // eslint-disable-next-line
  }, [
    comments,
    translatedComments,
    translatingComments,
    showTranslatedCommentIds,
  ]);

  const commentButtonDisabled = () => {
    return (newComment || '').length === 0;
  };

  useEffect(() => {
    const pusher = new Pusher(APP_KEY, {
      cluster: APP_CLUSTER,
    });
    pusher.subscribe('comment');
    // eslint-disable-next-line
    pusher.bind('new-comment', (data: any) => {
      try {
        const messageData = JSON.parse(data);
        if (
          messageData.id === parseInt(id) &&
          messageData.userId !== profile?.id
        ) {
          setFetch(true);
        }
      } catch (e) {
        console.error(`cannot parse json :: ${e}`);
      }
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchNewest();
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (comments.length !== 0) {
      setShowTranslatedCommentIds([]);
      setTranslatedComments([]);
      setTranslatingComments([]);
      setFetchTranslations(true);
    }
    // eslint-disable-next-line
  }, [profile?.preferredUILanguage]);

  useEffect(() => {
    if (fetchTranslations) {
      setFetchTranslations(false);
      forceTranslations(comments);
    }
    // eslint-disable-next-line
  }, [fetchTranslations]);

  useEffect(() => {
    if (fetch) {
      setFetch(false);
      fetchNewest();
    }
    // eslint-disable-next-line
  }, [fetch]);

  return (
    <Grid container className="fault-item-discussion">
      <Grid item xs={12}>
        <Box display="flex">
          <TextField
            value={newComment || ''}
            onChange={(e) => setNewComment(e.target.value)}
            className={classes.input}
            variant="outlined"
            placeholder={t('faultItemDiscussion.inputText.placeholder')}
            onKeyUp={(event) => {
              if (event.key === 'Enter' && !commentButtonDisabled()) {
                event.preventDefault();
                event.stopPropagation();
                addComment(newComment || '');
                setNewComment(undefined);
              }
            }}
          />
          <Button
            disabled={commentButtonDisabled()}
            key={`${commentButtonDisabled()}`}
            className={classes.sendButton}
            variant="contained"
            color="secondary"
            onClick={() => {
              addComment(newComment || '');
              setNewComment(undefined);
            }}
          >
            <SendIcon fontSize="large" />
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <InfiniteScroll
          next={() => fetchNext()}
          hasMore={hasMoreComments}
          loader={<Loader />}
          dataLength={comments.length}
          style={{ overflow: 'hidden' }}
        >
          {commentGroupItems}
        </InfiniteScroll>
      </Grid>
    </Grid>
  );
};

export default FaultItemDiscussion;
