import React from 'react';

export interface Props {
  className?: string;
}

export const UsersIcon: React.FC<Props> = ({ className }: Props) => {
  return (
    <svg
      className={className}
      width="22"
      height="14"
      viewBox="0 0 22 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 0C9.35499 0 8 1.35499 8 3C8 4.64501 9.35499 6 11 6C12.645 6 14 4.64501 14 3C14 1.35499 12.645 0 11 0ZM4 2C3.46957 2 2.96086 2.21071 2.58579 2.58579C2.21071 2.96086 2 3.46957 2 4C2 4.53043 2.21071 5.03914 2.58579 5.41421C2.96086 5.78929 3.46957 6 4 6C4.53043 6 5.03914 5.78929 5.41421 5.41421C5.78929 5.03914 6 4.53043 6 4C6 3.46957 5.78929 2.96086 5.41421 2.58579C5.03914 2.21071 4.53043 2 4 2ZM18 2C17.4696 2 16.9609 2.21071 16.5858 2.58579C16.2107 2.96086 16 3.46957 16 4C16 4.53043 16.2107 5.03914 16.5858 5.41421C16.9609 5.78929 17.4696 6 18 6C18.5304 6 19.0391 5.78929 19.4142 5.41421C19.7893 5.03914 20 4.53043 20 4C20 3.46957 19.7893 2.96086 19.4142 2.58579C19.0391 2.21071 18.5304 2 18 2ZM4 8C1.815 8 0 8.9088 0 10.2168V12H3V11.2168C3 9.9618 3.52698 8.87744 4.45898 8.02344C4.30698 8.01444 4.157 8 4 8ZM11 8C7.468 8 5 9.3228 5 11.2168V14H17V11.2168C17 9.3228 14.532 8 11 8ZM18 8C17.843 8 17.693 8.01444 17.541 8.02344C18.473 8.87744 19 9.9628 19 11.2168V12H22V10.2168C22 8.9088 20.185 8 18 8Z"
        fill="white"
      />
    </svg>
  );
};
