import React from 'react';

export interface Props {
  className?: string;
}

export const UserIcon: React.FC<Props> = ({ className }: Props) => {
  return (
    <svg
      className={className}
      width="42"
      height="44"
      viewBox="0 0 42 44"
      fill="none"
      xmlns="http://www.w3.org/…svg"
    >
      <path
        d="M22 0.25C16.6899 0.25 12.3333 4.60654 12.3333 9.91667C12.3333 15.2268 16.6899 19.5833 22 19.5833C27.3101
      19.5833 31.6667 15.2268 31.6667 9.91667C31.6667 4.60654 27.3101 0.25 22 0.25ZM22 5.08333C24.698 5.08333 26.8333
      7.21867 26.8333 9.91667C26.8333 12.6147 24.698 14.75 22 14.75C19.302 14.75 17.1667 12.6147 17.1667 9.91667C17.1667
      7.21867 19.302 5.08333 22 5.08333ZM22 26.8333C17.783 26.8333 12.7856 27.8236 8.58561 29.4813C6.4856 30.3101 4.58718
      31.2946 3.06787 32.5588C1.54856 33.8229 0.25 35.5318 0.25 37.7083V43.75H43.75V41.3333V37.7083C43.75 35.5318 42.4514
      33.8229 40.9321 32.5588C39.4128 31.2946 37.5144 30.3101 35.4144 29.4813C31.2144 27.8236 26.217 26.8333 22 26.8333ZM22
      31.6667C25.4173 31.6667 30.0863 32.5705 33.6444 33.9748C35.4234 34.6769 36.9277 35.5139 37.8405 36.2734C38.7533
      37.033 38.9167 37.5503 38.9167 37.7083V38.9167H5.08333V37.7083C5.08333 37.5503 5.24667 37.033 6.15951 36.2734C7.07234
      35.5139 8.5766 34.6769 10.3556 33.9748C13.9137 32.5705 18.5827 31.6667 22 31.6667Z"
        fill="black"
      />
    </svg>
  );
};
