import { State, hasFeature } from '@dsf/data-access-store';
import { extractRoleFromToken } from '@dsf/util-tools';

import { PumpDetail } from '@dsf/feature-dsfe';
import React from 'react';
import { useSelector } from 'react-redux';
import { withTenantLayout } from '@dsf/ui-layout';
import { Box } from '@mui/material';

const PumpDetailPage = () => {
  const { accessToken } = useSelector((state: State) => state.userTokens);
  const extractUserRole = extractRoleFromToken(accessToken);

  return extractUserRole.includes('grundfos_data_analyst') ? (
    <PumpDetail />
  ) : (
    <Box sx={{ textAlign: 'center', m: '3rem' }}>
      You don't have permission to enter this page.
    </Box>
  );
};

export default withTenantLayout(PumpDetailPage)({
  pageName: 'DSFE-PumpDetailPage',
});
