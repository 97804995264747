import React from 'react';

export interface Props {
  className?: string;
}

export const ApprovalIcon: React.FC<Props> = ({ className }: Props) => {
  return (
    <svg
      className={className}
      width="46"
      height="44"
      viewBox="0 0 46 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M45.0373 20.4365L40.912 16.1725L41.7418 10.2987C41.9108 9.10268 41.1005 7.98901 39.911 7.77885L34.0675 6.75401L31.2876 1.51501C30.7243 0.45118 29.4135 0.0243464 28.3301 0.55518L23.0001 3.15951L17.6701 0.55518C16.5868 0.0243464 15.276 0.45118 14.7105 1.51718L11.9306 6.75835L6.08713 7.78318C4.89763 7.99118 4.0873 9.10485 4.2563 10.3008L5.08613 16.1747L0.960796 20.4387C0.120129 21.3075 0.120129 22.6833 0.960796 23.5522L5.08613 27.8162L4.2563 33.69C4.0873 34.886 4.89763 35.9997 6.08713 36.2098L11.9306 37.2347L14.7105 42.4737C15.276 43.5397 16.5868 43.9665 17.6723 43.4357L23.0001 40.8292L28.3301 43.4335C29.4156 43.9643 30.7243 43.5375 31.292 42.4715L34.0718 37.2325L39.9153 36.2077C41.1048 35.9997 41.9151 34.8838 41.7461 33.6878L40.9163 27.814L45.0416 23.55C45.8758 22.6833 45.8758 21.3053 45.0373 20.4365ZM20.8335 31.558L12.8016 23.5262L15.8653 20.4625L20.8335 25.4307L32.3016 13.9625L35.3653 17.0262L20.8335 31.558Z"
        fill="#CCCCCC"
        fillOpacity="0.5"
      />
    </svg>
  );
};
