import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DefaultState } from './types/defaultState';

export interface SessionTokenState extends DefaultState {
  sessionToken: string;
}

const initialSessionTokenState: SessionTokenState = {
  sessionToken: '',
  persist: true,
};

export const sessionTokenSlice = createSlice({
  name: 'sessionToken',
  initialState: initialSessionTokenState,
  reducers: {
    setSessionToken: (state, action: PayloadAction<string>) => {
      state.sessionToken = action.payload;
    },
  },
});
