import React from 'react';

export interface Props {
  className?: string;
}

export const WrenchIcon: React.FC<Props> = ({ className }: Props) => {
  return (
    <svg
      className={className}
      width="43"
      height="50"
      viewBox="0 0 43 50"
      fill="none"
      xmlns="http://www.w3.org/…svg"
    >
      <path
        d="M46.0492 3.95086C43.5358 1.43752 40.0075 0.205024 36.0925 0.446691C32.4675 0.688357 28.9633 2.23502 26.4742
      4.72419C23.9125 7.28586 22.8733 10.4034 23.4533 13.7142C23.55 14.2942 23.695 14.85 23.8883 15.43L15.43 23.8884C14.85
      23.695 14.2942 23.55 13.7142 23.4534C10.4033 22.8734 7.28584 23.9125 4.72418 26.4742C2.23501 28.9634 0.688343 32.4675
      0.446676 36.0925C0.20501 40.0075 1.43751 43.5359 3.95084 46.0492C6.24668 48.345 9.41251 49.5775 12.9167 49.5775C13.2308
      49.5775 13.5692 49.5775 13.9075 49.5534C17.5325 49.3117 21.0367 47.765 23.5258 45.2759C26.0875 42.7142 27.1267 39.5967
      26.5467 36.2859C26.45 35.7059 26.305 35.15 26.1117 34.57L34.57 26.1117C35.15 26.305 35.7058 26.45 36.2858 26.5467C36.89
      26.6675 37.47 26.7159 38.05 26.7159C40.7083 26.7159 43.1733 25.6284 45.2758 23.5259C47.765 21.0367 49.3117 17.5325 49.5533
      13.9075C49.795 9.99252 48.5625 6.46419 46.0492 3.95086ZM41.8683 20.0942C40.9258 21.0367 39.9592 21.6167 38.8958
      21.7859C38.3158 21.9067 37.7358 21.9067 37.1075 21.7859C35.9958 21.5925 34.8117 21.0609 33.6033 20.215L30.1475
      23.6709L23.6708 30.1475L20.215 33.6034C21.0608 34.8117 21.5925 35.9959 21.7858 37.1075C21.8342 37.4217 21.8825 37.7359
      21.8825 38.05C21.8825 38.2917 21.8583 38.5334 21.8342 38.7509C21.665 39.8625 21.085 40.8775 20.1183 41.8442C18.4508
      43.5117 16.0583 44.5509 13.5933 44.72C12.0612 44.8239 10.6571 44.5412 9.43426 43.9854L14.6253 38.7919C15.5702 37.847
      15.5702 36.3197 14.6253 35.3748C13.6803 34.4299 12.153 34.4299 11.2081 35.3748L6.01709 40.5682C5.45884 39.3454 5.17609
      37.9389 5.28001 36.4067C5.44918 33.9417 6.48834 31.5492 8.13168 29.9059C9.12251 28.915 10.1617 28.335 11.2733 28.19C11.4908
      28.1417 11.7325 28.1175 11.9742 28.1175C12.2642 28.1175 12.5783 28.1417 12.8925 28.2142C14.0042 28.4075 15.1883 28.9392
      16.3967 29.785L19.8525 26.3292L26.3292 19.8525L29.785 16.3967C28.9392 15.1884 28.4075 14.0042 28.2142 12.8925C28.1175
      12.3125 28.0933 11.7809 28.1658 11.2492C28.335 10.1375 28.915 9.12252 29.8817 8.15586C31.5492 6.48836 33.9417 5.44919
      36.4067 5.28002C36.6483 5.25586 36.8658 5.25586 37.0833 5.25586C38.3642 5.25586 39.5242 5.49752 40.5633 6.00502H40.5754L35.3723
      11.2081C34.4274 12.153 34.4274 13.6804 35.3723 14.6253C35.846 15.0965 36.4647 15.3334 37.0833 15.3334C37.702 15.3334 38.3207
      15.0965 38.7919 14.6253L44.0192 9.39802V9.41252C44.575 10.6209 44.8167 12.0709 44.72 13.5934C44.5508 16.0584 43.5117
      18.4509 41.8683 20.0942Z"
        fill="black"
      />
    </svg>
  );
};
