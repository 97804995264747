import { identitySlice, State } from '@dsf/data-access-store';
import { useUserApi } from '@dsf/data-access-api';

import { useDispatch, useSelector } from 'react-redux';
import { Language, switchLanguage } from '@dsf/util-translate';

const useChangeLanguage = () => {
  const { profile } = useSelector((state: State) => state.identity);
  const dispatch = useDispatch();
  const userApi = useUserApi();

  const changeLanguage = async (lang: Language) => {
    await switchLanguage(lang);

    if (profile?.id) {
      try {
        await userApi.updateUserProfile({
          acceptedTermsAndCondition: profile.acceptedTermsAndCondition,
          email: profile.email,
          firstName: profile.firstName,
          lastName: profile.lastName,
          preferredUILanguage: lang,
        });

        dispatch(
          identitySlice.actions.setProfile({
            ...profile,
            preferredUILanguage: lang,
          })
        );
      } catch (e) {
        console.error('cannot update ui language');
      }
    }
  };

  return changeLanguage;
};

export default useChangeLanguage;
