import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { State, identitySlice, store } from '@dsf/data-access-store';
import { useUserApi } from '@dsf/data-access-api';
import { TenantResponse, UserAccountResponse } from '@dsf/util-types';
import { useHistory, useLocation } from 'react-router';
import { Language, switchLanguage } from '@dsf/util-translate';
import { LOGOUT } from '@dsf/util-router';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: any;
  path: string;
}
export const GuardedRoute: React.FC<Props> = ({
  component: Component,
  ...rest
}) => {
  const userApi = useUserApi();
  const dispatch = useDispatch();
  const history = useHistory();
  const { tenant } = useSelector((state: State) => state.tenant);
  const { profile } = useSelector((state: State) => state.identity);
  const location = useLocation();

  const redirectToLogin = (
    profile: UserAccountResponse | null,
    tenant: TenantResponse | null
  ): void => {
    if (tenant) {
      window.location.href = `${window.location.protocol}//${tenant?.appUri}/login`;
    } else {
      history.push('/');
    }
  };

  const fetchProfile = async () => {
    try {
      const profileRes = await userApi.getUserProfile();
      const profile = profileRes.data;
      dispatch(identitySlice.actions.setProfile(profile));

      if (profile.preferredUILanguage !== localStorage.getItem('lang')) {
        const preferredLanguage =
          localStorage.getItem('lang') || profile.preferredUILanguage;
        await switchLanguage(preferredLanguage as Language);
      }
    } catch (e) {
      // console.error(`cannot load profile :: ${e}``);
    }
  };

  useEffect(() => {
    if (!profile) {
      const accessToken = store.getState().userTokens.accessToken;
      if (accessToken) {
        dispatch(identitySlice.actions.setLoadingProfile(true));
        fetchProfile().then(() =>
          dispatch(identitySlice.actions.setLoadingProfile(false))
        );
      }
    }
    // eslint-disable-next-line
  }, [profile]);

  return (
    <Route
      {...rest}
      exact={true}
      render={(props) => {
        // check access token
        const accessToken = store.getState().userTokens.accessToken;
        if (!accessToken) {
          dispatch(identitySlice.actions.userNotFound());
          if (location.pathname !== LOGOUT) {
            dispatch(identitySlice.actions.setRedirectUrl(location.pathname));
          }
          redirectToLogin(profile, tenant);
          return undefined;
        }
        return <Component {...props} />;
      }}
    />
  );
};
