import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FalseIcon } from '@dsf/ui-assets';
import { TrueIcon } from '@dsf/ui-assets';
import { ValidatorValues } from '@dsf/util-tools';

interface Props {
  values: ValidatorValues;
}

const ValidatorStatuses: FC<Props> = ({ values }) => {
  const { t } = useTranslation();

  return (
    <List dense={true}>
      <ListItem>
        <ListItemAvatar className={'match_icon'}>
          <Avatar src={values.match_length ? TrueIcon : FalseIcon} />
        </ListItemAvatar>
        <ListItemText primary={t('validatorStatusesComponent.8characters')} />
      </ListItem>
      <ListItem>
        <ListItemAvatar className={'match_icon'}>
          <Avatar src={values.match_upper ? TrueIcon : FalseIcon} />
        </ListItemAvatar>
        <ListItemText primary={t('validatorStatusesComponent.oneCapital')} />
      </ListItem>
      <ListItem>
        <ListItemAvatar className={'match_icon'}>
          <Avatar src={values.match_lower ? TrueIcon : FalseIcon} />
        </ListItemAvatar>
        <ListItemText primary={t('validatorStatusesComponent.oneLowerCase')} />
      </ListItem>
      <ListItem>
        <ListItemAvatar className={'match_icon'}>
          <Avatar src={values.match_special ? TrueIcon : FalseIcon} />
        </ListItemAvatar>
        <ListItemText primary={t('validatorStatusesComponent.specialChar')} />
      </ListItem>
      <ListItem>
        <ListItemAvatar className={'match_icon'}>
          <Avatar src={values.match_number ? TrueIcon : FalseIcon} />
        </ListItemAvatar>
        <ListItemText primary={t('validatorStatusesComponent.number')} />
      </ListItem>
    </List>
  );
};

export default ValidatorStatuses;
