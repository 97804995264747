import React from 'react';

export interface Props {
  className?: string;
}

export const WrenchSmallIcon: React.FC<Props> = ({ className }: Props) => {
  return (
    <svg
      className={className}
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5 0.5C8.74862 0.5 6.49998 2.74865 6.49998 5.5C6.49998 5.93833 6.63121 6.32847 6.73761 6.72721L2.86228
      10.6025C1.60785 10.9282 0.666641 12.0658 0.666641 13.4167C0.666641 15.0176 1.98235 16.3333 3.58331 16.3333C4.93416
      16.3333 6.07179 15.3921 6.39744 14.1377L10.2728 10.2624C10.6715 10.3688 11.0616 10.5 11.5 10.5C14.2513 10.5 16.5
      8.25135 16.5 5.5C16.5 4.85012 16.3658 4.23615 16.1484 3.68034L15.6845 2.49382L13.7558 4.42253H13.7542C13.5889
      4.58825 13.3817 4.66667 13.1666 4.66667C12.9516 4.66667 12.7444 4.58825 12.5791 4.42253H12.5774C12.245 4.09009
      12.245 3.57658 12.5774 3.24414L14.5062 1.31543L13.3196 0.851563C12.7638 0.634158 12.1499 0.5 11.5 0.5ZM11.3454
      2.19759C10.4817 3.17404 10.4659 4.66778 11.3991 5.60091C11.8838 6.08685 12.5284 6.33333 13.1666 6.33333C13.7572
       6.33333 14.3326 6.07282 14.8024 5.65625C14.7171 7.42873 13.2948 8.83333 11.5 8.83333C11.0782 8.83333 10.6733
        8.74663 10.2874 8.5957L9.7796 8.39713L6.13539 12.0413C5.8642 11.5431 5.4569 11.1358 4.95863 10.8646L8.60284
        7.22038L8.40427 6.71257C8.25334 6.32671 8.16664 5.92178 8.16664 5.5C8.16664 3.70462 9.57209 2.28208 11.3454
        2.19759ZM3.58331 12.1667C4.28353 12.1667 4.83331 12.7164 4.83331 13.4167C4.83331 14.1169 4.28353 14.6667 3.58331
        14.6667C2.88308 14.6667 2.33331 14.1169 2.33331 13.4167C2.33331 12.7164 2.88308 12.1667 3.58331 12.1667Z"
        fill="black"
      />
    </svg>
  );
};
