import React from 'react';
import { Switch, Route as PublicRoute } from 'react-router-dom';

import {
  FAULT_ITEM_DETAIL,
  MARK_PRODUCT_AS_FINISHED,
  FAULT_ITEM_CREATE,
  USER_OVERVIEW,
  SERIAL_NUMBER_NEW,
  QR_CODE_GENERATION,
  QR_CODE_GENERATION_SPECIFIC,
  PRODUCT_OVERVIEW,
  DELETE_TENANT,
  ADMIN,
  ADMIN_USERS,
  ADMIN_USERS_DETAIL,
  ADMIN_CREATE_USER,
  IDENTIFY_PRODUCT,
  PRODUCT_IDENTIFIED,
  DELETE_TENANT_START,
  DELETE_TENANT_IN_PROGRESS,
  PRODUCTION_SEGMENT_CHECK_IN,
  KPI_DASHBOARD_PAGE,
  FEATURES_PAGE,
  DSFE_ASSET_LIST,
  DSFE_ASSET_DETAIL,
  LOCATIONS,
  FIELD_OVERVIEW,
  DSFE_ADD_ASSET,
  DECOMMISSIONED_ASSETS,
  DSFE_PUMP_DETAIL,
  HALL_MAP,
  LOGOUT,
  SIGNUP,
  TENANT,
  TENANT_REQUEST_CHANGE_PASSWORD,
  TENANT_CHANGE_PASSWORD,
  TENANT_PROFILE,
  UNLOCK_ACCOUNT_REQUEST,
  UNLOCK_ACCOUNT_SUCCESS,
  FAULT_LIST,
  CHANGE_TEMPORARY_PASSWORD,
  REQUEST_RESET_PASSWORD,
  LOGIN_PAGE,
  NEWS,
  MFA_SETUP,
  MFA_VERIFY,
  SERVICES,
  PRICE,
  ABOUT,
  RESET_PASSWORD,
  TAC,
  NEW_TENANT_SUMMARY,
  NEW_TENANT_PROCESS,
  NEW_TENANT,
  SUPPORT,
  USER_LIST_PAGE,
  CHAT_PAGE,
  CONVERSATION_PAGE,
  CREATE_CONVERSATION_PAGE,
  HOW_TO_USE_DSF,
} from '@dsf/util-router';

import NewsPage from '../pages/private/NewsPage/news-page';
import HallMapPage from '../pages/private/HallMapPage/HallMapPage';
import SignUpPage from '../pages/public/SignUpPage/SignUpPage';
import LogoutPage from '../pages/private/LogoutPage/LogoutPage';
import ChangeTemporaryPasswordPage from '../pages/public/ChangeTemporaryPasswordPage/ChangeTemporaryPasswordPage';
import MFASetupPage from '../pages/public/MFASetupPage/MFASetupPage';
import MFAVerifyPage from '../pages/public/MFAVerifyPage/MFAVerifyPage';
import RequestResetPasswordPage from '../pages/public/RequestResetPasswordPage/RequestResetPasswordPage';
import ResetPasswordPage from '../pages/public/ResetPasswordPage/ResetPasswordPage';
import HomePage from '../pages/private/HomePage/HomePage';
import EditProfilePage from '../pages/private/EditProfilePage/EditProfilePage';
import FaultListPageNew from '../pages/private/FaultListPage/fault-list-page';
import CreateFaultItemPage from '../pages/private/CreateFaultItemPage/create-fault-item-page';
import FaultDetailPage from '../pages/private/fault-detail-page/fault-detail-page';
import KpiDashboardPage from '../pages/private/kpi-dashboard-page/kpi-dashboard-page';
import UnlockAccountSuccessPage from '../pages/public/UnlockAccountSuccessPage/UnlockAccountSuccessPage';
import UnlockAccountRequestPage from '../pages/public/UnlockAccountRequestPage/UnlockAccountRequestPage';
import ServicesPage from '../pages/public/ServicesPage/ServicesPage';
import PricePage from '../pages/public/PricePage/PricePage';
import AboutPage from '../pages/public/AboutPage/AboutPage';
import HowToPage from '../pages/public/HowToPage/HowToPage';
import RequestChangePasswordPage from '../pages/private/RequestChangePasswordPage/RequestChangePasswordPage';
import ChangePasswordPage from '../pages/private/ChangePasswordPage/ChangePasswordPage';
import TenantCreationProcessPage from '../pages/public/CreateNewTenantPage/TenantCreationProcessPage/TenantCreationProcessPage';
import TenantPageSummaryPage from '../pages/public/CreateNewTenantPage/TenantCreationSummaryPage/TenantPageSummaryPage';
import CreateNewTenantPage from '../pages/public/CreateNewTenantPage/CreateNewTenantPage';
import DeleteTenantPage from '../pages/private/DeleteTenantPage/DeleteTenantPage';
import TermsAndConditionsPage from '../pages/public/TermsAndConditionsPage/TermsAndConditionsPage';
import UserOverviewPage from '../pages/private/UserOverviewPage/UserOverviewPage';
import SerialNumberNewPage from '../pages/private/SerialNumberNew/SerialNumberNewPage';
import ProductOverviewPage from '../pages/private/product-overview-page/product-overview-page';
import MarkProductAsFinishedPage from '../pages/private/mark-product-as-finished-page/mark-product-as-finished-page';
import QrCodeGenerationPage from '../pages/private/QrCodeGenerationPage/QrCodeGenerationPage';
import ProductionSegmentCheckInPage from '../pages/private/production-segment-check-in-page/production-segment-check-in-page';

import AdminIndex from '../pages/admin/AdminIndex';
import Users from '../pages/admin/Users';
import UserAccountDetailPage from '../pages/private/UserAccountDetailPage/UserAccountDetailPage';
import UserAccountCreationPage from '../pages/private/UserAccountCreationPage/UserAccountCreationPage';
import IdentifyProductPage from '../pages/private/identify-product-page/identify-product-page';
import ProductIdentifiedPage from '../pages/private/product-identified-page/product-identified-page';
import StartDeleteTenantPage from '../pages/private/DeleteTenantPage/start/StartDeleteTenantPage';
import GoodByeDeleteTenantPage from '../pages/private/DeleteTenantPage/goodbye/GoodByeDeleteTenantPage';
import SupportPage from '../pages/public/SupportPage/SupportPage';
import DsfePage from '../pages/private/dsfe-pages/dsfe-page';
import LocationsPage from '../pages/private/locations-page/locations-page';
import FieldOverviewPage from '../pages/private/field-overview-page/field-overview-page';
import AssetDetail from '../pages/private/dsfe-pages/asset-detail-page';
import PumpDetail from '../pages/private/dsfe-pages/pump-detail-page';

import featuresPage from '../pages/private/features-page/features-page';
import AddAssetPage from '../pages/private/dsfe-pages/add-asset-page';
import { DecommissionedPage } from '../pages/private/dsfe-pages/decommissioned-page';
import { RootPage } from '../pages/root-page';
import { UserListPage } from '../pages/user-list-page';
import { MainLoginPage } from '../pages/main-login-page';
import ChatPage from '../pages/private/chat-page/chat-page';
import ConversationPage from '../pages/private/chat-page/conversation-page';
import CreateConversationPage from '../pages/private/chat-page/create-conversation-page';
import { GuardedRoute } from '@dsf/ui-components';

const Route: React.FC = () => {
  return (
    <Switch>
      {/* ADMIN PAGES */}
      {/*<GuardedRoute
        path={ADMIN_USERS_DETAIL}
        component={UserAccountDetailPage}
      />
      <GuardedRoute path={ADMIN_USERS} component={Users} />
      <GuardedRoute path={ADMIN} component={AdminIndex} />
      <GuardedRoute
        path={ADMIN_CREATE_USER}
        component={UserAccountCreationPage}
      />*/}

      {/* DSFQ PAGES */}
      {/*<PublicRoute path={SUPPORT} component={SupportPage} />
      <PublicRoute path={SERVICES} component={ServicesPage} />
      <PublicRoute path={PRICE} component={PricePage} />
      <PublicRoute
        path={NEW_TENANT_SUMMARY}
        exact={true}
        component={TenantPageSummaryPage}
      />
      <PublicRoute
        path={NEW_TENANT_PROCESS}
        exact={true}
        component={TenantCreationProcessPage}
      />
      <PublicRoute path={ABOUT} component={AboutPage} />
      <PublicRoute path={TAC} component={TermsAndConditionsPage} />
      <GuardedRoute path={NEW_TENANT} component={CreateNewTenantPage} />*/}
      <GuardedRoute path={FIELD_OVERVIEW} component={FieldOverviewPage} />
      <GuardedRoute path={DSFE_PUMP_DETAIL} component={PumpDetail} />
      <GuardedRoute path={DSFE_ASSET_DETAIL} component={AssetDetail} />
      <GuardedRoute path={DSFE_ADD_ASSET} component={AddAssetPage} />
      <GuardedRoute
        path={DECOMMISSIONED_ASSETS}
        component={DecommissionedPage}
      />
      <GuardedRoute path={DSFE_ASSET_LIST} component={DsfePage} />
      <GuardedRoute path={LOCATIONS} component={LocationsPage} />
      <GuardedRoute path={DELETE_TENANT} component={DeleteTenantPage} />
      {/*<GuardedRoute
        path={DELETE_TENANT_START}
        component={StartDeleteTenantPage}
      />
      <GuardedRoute
        path={DELETE_TENANT_IN_PROGRESS}
        component={GoodByeDeleteTenantPage}
      />
      <PublicRoute path={SIGNUP} component={SignUpPage} />
      <GuardedRoute path={HALL_MAP} component={HallMapPage} />
      <GuardedRoute path={NEWS} component={NewsPage} />*/}
      <PublicRoute path={LOGOUT} component={LogoutPage} />
      <PublicRoute
        path={CHANGE_TEMPORARY_PASSWORD}
        component={ChangeTemporaryPasswordPage}
      />
      <PublicRoute path={MFA_SETUP} component={MFASetupPage} />
      <PublicRoute path={MFA_VERIFY} component={MFAVerifyPage} />
      <PublicRoute
        path={REQUEST_RESET_PASSWORD}
        component={RequestResetPasswordPage}
      />
      <PublicRoute path={RESET_PASSWORD} component={ResetPasswordPage} />
      {/*<GuardedRoute path={TENANT} component={HomePage} />*/}
      <GuardedRoute path={TENANT_PROFILE} component={EditProfilePage} />
      {/*<GuardedRoute
        path={TENANT_REQUEST_CHANGE_PASSWORD}
        component={RequestChangePasswordPage}
      />
      <GuardedRoute
        path={TENANT_CHANGE_PASSWORD}
        component={ChangePasswordPage}
      />
      <PublicRoute
        path={UNLOCK_ACCOUNT_REQUEST}
        component={UnlockAccountRequestPage}
      />
      <PublicRoute
        path={UNLOCK_ACCOUNT_SUCCESS}
        component={UnlockAccountSuccessPage}
      />*/}
      <GuardedRoute path={FAULT_ITEM_DETAIL} component={FaultDetailPage} />
      {/*<GuardedRoute path={KPI_DASHBOARD_PAGE} component={KpiDashboardPage} />
      <GuardedRoute path={FEATURES_PAGE} component={featuresPage} />*/}
      <GuardedRoute
        path={MARK_PRODUCT_AS_FINISHED}
        component={MarkProductAsFinishedPage}
      />
      <GuardedRoute path={IDENTIFY_PRODUCT} component={IdentifyProductPage} />
      <GuardedRoute
        path={PRODUCT_IDENTIFIED}
        component={ProductIdentifiedPage}
      />
      <GuardedRoute path={FAULT_ITEM_CREATE} component={CreateFaultItemPage} />
      <GuardedRoute path={FAULT_LIST} component={FaultListPageNew} />
      {/*<GuardedRoute path={USER_OVERVIEW} component={UserOverviewPage} />*/}
      <GuardedRoute path={PRODUCT_OVERVIEW} component={ProductOverviewPage} />
      <GuardedRoute path={SERIAL_NUMBER_NEW} component={SerialNumberNewPage} />
      <GuardedRoute
        path={QR_CODE_GENERATION_SPECIFIC}
        component={QrCodeGenerationPage}
      />
      <GuardedRoute
        path={QR_CODE_GENERATION}
        component={QrCodeGenerationPage}
      />
      {/*<GuardedRoute
        path={PRODUCTION_SEGMENT_CHECK_IN}
        component={ProductionSegmentCheckInPage}
    />*/}
      <GuardedRoute path={USER_LIST_PAGE} component={UserListPage} />
      <GuardedRoute path={CHAT_PAGE} component={ChatPage} />
      <GuardedRoute path={CONVERSATION_PAGE} component={ConversationPage} />
      <GuardedRoute
        path={CREATE_CONVERSATION_PAGE}
        component={CreateConversationPage}
      />

      <PublicRoute
        path={FAULT_ITEM_CREATE}
        exact={true}
        component={CreateFaultItemPage}
      />
      <PublicRoute path={HOW_TO_USE_DSF} exact={true} component={HowToPage} />
      <PublicRoute path={LOGIN_PAGE} exact={true} component={MainLoginPage} />
      <PublicRoute path="/" exact={true} component={RootPage} />
    </Switch>
  );
};

export default Route;
