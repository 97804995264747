import { usePhysicalAssetApi } from '@dsf/data-access-api';
import { PhysicalAsset } from '@dsf/util-types';

import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { withTenantLayout } from '@dsf/ui-layout';
import {
  Button,
  CircularProgress,
  Grid,
  Snackbar,
  TextField,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Alert, Autocomplete } from '@material-ui/lab';
import QRCode from 'react-qr-code';
import './QrCodeGenerationPage.scss';
import { VALUE_PREFIXES } from '../../../components/qr-scanner';

interface ParamsType {
  serialNumber: string;
}

interface PageState {
  assetsList: PhysicalAsset[];
  selected: PhysicalAsset | null;
}

const QrCodeGenerationPage: React.FC = () => {
  const physicalAssetApi = usePhysicalAssetApi();
  const { t } = useTranslation();
  const { serialNumber } = useParams<ParamsType>();
  const [pageState, setPageState] = useState<PageState>({
    assetsList: [],
    selected: null,
  });
  const [loaded, setLoaded] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [qrCodeValue, setQrCodeValue] = useState<string>('');

  const loadPhysicalAssets = (): Promise<PhysicalAsset[]> => {
    return new Promise((resolve, reject) => {
      physicalAssetApi
        .getPhysicalAssets(null, null, null)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  };

  const onClickPrint = async (event: SyntheticEvent) => {
    event.preventDefault();
    window.print();
  };

  const onCloseAlert = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowAlert(false);
  };

  useEffect(() => {
    loadPhysicalAssets().then((assets) => {
      setPageState({
        ...pageState,
        assetsList: assets,
      });
      setLoaded(true);

      if (serialNumber) {
        selectSerialNumber(serialNumber, assets);
      }
    });
    // eslint-disable-next-line
  }, []);

  const selectSerialNumber = (
    serialNumber: string,
    assetsList: PhysicalAsset[]
  ) => {
    const serialNumberMatches = assetsList.filter(
      (a) => a.serialNumber === serialNumber
    );

    if (serialNumberMatches.length > 0) {
      setPageState({
        ...pageState,
        selected: serialNumberMatches[0],
      });
      const qrValue = `${VALUE_PREFIXES.PHYSICAL_ASSET}${serialNumberMatches[0].digitalIdentity}`;
      setQrCodeValue(qrValue);
    } else {
      setPageState({
        ...pageState,
        selected: null,
      });
      setQrCodeValue('');

      const errorMsg = `${t(
        'qrCodeGenerator.errors.notFound.beforeSN'
      )} ${serialNumber} ${t('qrCodeGenerator.errors.notFound.afterSN')}`;
      setAlertMessage(errorMsg);
      setShowAlert(true);
    }
  };

  // eslint-disable-next-line
  const onChangeSerialNumber = (event: any, value: PhysicalAsset | null) => {
    if (value?.serialNumber) {
      selectSerialNumber(value?.serialNumber, pageState.assetsList);
    }
  };

  if (!loaded) {
    return (
      <div className={'qr-code-page'}>
        <div className="loader">
          <CircularProgress color="secondary" />
          <Typography variant="body1">
            {t('faultItemDetailPage.loading')}
          </Typography>
        </div>
      </div>
    );
  }

  return (
    <div className={'qr-code-page'}>
      <h1 id="qrCodeGenerator_title">{t('qrCodeGenerator.title')}</h1>
      <p>{t('qrCodeGenerator.description')}</p>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        spacing={3}
      >
        <Grid container item xs={12} justify="center" alignItems="center">
          <Autocomplete
            id="combo-box-demo"
            options={pageState.assetsList}
            getOptionLabel={(option) => option.serialNumber}
            style={{ flex: 1 }}
            onChange={onChangeSerialNumber}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('qrCodeGenerator.form.searchField')}
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid container item xs={12} justify="center" alignItems="center">
          {pageState.selected && (
            <TextField
              value={pageState.selected?.serialNumber}
              label={t('qrCodeGenerator.form.serialNumber')}
              placeholder={t('qrCodeGenerator.form.placeholders.serialNumber')}
              disabled={true}
              variant="outlined"
              style={{ flex: 1 }}
            />
          )}
        </Grid>
        <Grid container item xs={12} justify="center" alignItems="center">
          {pageState.selected && (
            <TextField
              multiline
              rows={4}
              value={pageState.selected?.productDescription}
              label={t('qrCodeGenerator.form.productDescription')}
              disabled={true}
              variant="outlined"
              style={{ flex: 1 }}
            />
          )}
        </Grid>
        <Grid container item xs={12} justify="center" alignItems="center">
          {pageState.selected && (
            <TextField
              label={t('qrCodeGenerator.form.partNumber')}
              disabled={true}
              value={pageState.selected?.productNumber}
              variant="outlined"
              style={{ flex: 1 }}
            />
          )}
        </Grid>
        <Grid
          style={{ flex: 1 }}
          container
          item
          xs={12}
          justify="center"
          alignItems="center"
        >
          {pageState.selected && (
            <div className="qr-code" id="qrCodeGenerator_qrcode">
              <QRCode value={qrCodeValue} />
            </div>
          )}
        </Grid>
        <Grid
          style={{ flex: 1 }}
          container
          item
          xs={12}
          justify="flex-end"
          alignItems="flex-end"
        >
          {pageState.selected && (
            <Button
              id="qrCodeGenerator_printButton"
              onClick={onClickPrint}
              disabled={!pageState.selected}
              variant="contained"
              color="primary"
              key={`${!pageState.selected}`} // Fix for Safari
            >
              {t('qrCodeGenerator.form.printButton')}
            </Button>
          )}
        </Grid>
      </Grid>

      <Snackbar
        open={showAlert}
        autoHideDuration={5000}
        onClose={onCloseAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={onCloseAlert} severity="error">
          {alertMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default withTenantLayout(QrCodeGenerationPage)({ pageName: 'SRC-103' });
