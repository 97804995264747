import { hasFeature } from '@dsf/data-access-store';

import React, { useState } from 'react';

import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';

import { withTenantLayout } from '@dsf/ui-layout';
import { WizardContainer } from '@dsf/ui-asset-wizard';
import { Box, Button } from '@mui/material';
import { UiContentBox } from '@dsf/ui-content-box';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useHistory } from 'react-router-dom';
import { DSFE_ASSET_LIST } from '@dsf/util-router';
import { useTranslation } from 'react-i18next';

const AddAssetPage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  return hasFeature('DSF/E') ? (
    <div>
      <Box maxWidth="721px" margin="0 auto 3rem auto">
        <UiContentBox
          icon={
            <Box>
              <CreateNewFolderIcon />
            </Box>
          }
          title={
            <Box
              display="flex"
              fontWeight="bold"
              fontSize="1.125rem"
              alignItems="center"
              id="title_box"
            >
              {t('assetWizard.addNewAsset')}
            </Box>
          }
          postfix={
            <Button
              variant="outlined"
              color="inherit"
              id="button_back-to-asset-list"
              onClick={() => {
                history.push(DSFE_ASSET_LIST);
              }}
              startIcon={<ArrowBackIosNewIcon />}
            >
              {t('assetManagement.backToAssets')}
            </Button>
          }
        >
          <WizardContainer />
        </UiContentBox>
      </Box>
    </div>
  ) : (
    <div>Your DSF/E licence is not active.</div>
  );
};

export default withTenantLayout(AddAssetPage)({
  pageName: 'DSFE-AddAssetPage',
});
