import React, { useEffect, useState } from 'react';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import { CircularProgress } from '@mui/material';

import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  AssetColumn,
  AssetItem,
  formatAssetType,
  mapThingToAsset,
} from './asset-list';
import { useWrapApi } from '@dsf/data-access-api';

const useStyles = makeStyles({
  root: {
    width: '100%',
    position: 'relative',
  },
  container: {
    maxHeight: 'none',
  },
});

type AssetItemDecom = AssetItem & {
  loading?: boolean;
};

export const DecommissionedList = () => {
  const { api } = useWrapApi();
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loading, setLoading] = useState(false);
  const [things, setThings] = useState<AssetItemDecom[]>([]);

  const columns: AssetColumn[] = [
    {
      id: 'assetName',
      label: t('assetList.assetName'),
      minWidth: 100,
    },
    {
      id: 'assetAttributes',
      label: t('assetList.assetType'),
      minWidth: 100,
      format: formatAssetType,
      align: 'center',
    },
    {
      id: 'assetAttributes',
      label: t('assetList.location'),
      minWidth: 100,
      format: (value) => {
        return <div>{value?.location}</div>;
      },
    },
  ];

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const fetchDecommissioned = () => {
    setLoading(true);
    api
      .get('/things/decommissioned')
      .then(({ data }) => {
        const mappedThings = data.map(mapThingToAsset);
        setThings(mappedThings);
        setLoading(false);
      })
      .catch((error) => {
        console.error('things error', error);
        setThings([]);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchDecommissioned();
  }, []);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" p="2rem">
        <CircularProgress />
      </Box>
    );
  }

  if (things.length === 0) {
    return (
      <Box sx={{ textAlign: 'center' }}>
        {t('assetManagement.noDecommissionedAssets')}
      </Box>
    );
  }

  return (
    <>
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table" size="small">
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell
                    key={`${column.id}-${index}`}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
                <TableCell
                  key="asset-detail-header"
                  style={{ minWidth: '80px' }}
                  align="center"
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {things
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.assetId}
                    >
                      {columns.map((column, index) => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            key={`${column.id}-${index}`}
                            align={column.align}
                          >
                            {column.format && value
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                      <TableCell key="asset-detail-row" align="center">
                        <Button
                          variant="contained"
                          size="small"
                          disabled={row.loading}
                          onClick={() => {
                            setThings((prevThings) => {
                              prevThings[index] = {
                                ...prevThings[index],
                                loading: true,
                              };
                              return [...prevThings];
                            });
                            api
                              .patch(`/things/${row.assetId}/recommission`)
                              .then(() => {
                                fetchDecommissioned();
                                setThings((prevThings) => {
                                  prevThings[index] = {
                                    ...prevThings[index],
                                    loading: false,
                                  };
                                  return [...prevThings];
                                });
                              })
                              .catch(() => {
                                setThings((prevThings) => {
                                  prevThings[index] = {
                                    ...prevThings[index],
                                    loading: false,
                                  };
                                  return [...prevThings];
                                });
                              });
                          }}
                          startIcon={
                            row.loading ? (
                              <CircularProgress size="20px" color="inherit" />
                            ) : (
                              <ModelTrainingIcon />
                            )
                          }
                        >
                          Recommision
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={things.length}
          rowsPerPage={rowsPerPage}
          labelDisplayedRows={function defaultLabelDisplayedRows({
            from,
            to,
            count,
          }) {
            return `${from}–${to} ${t('global.of')} ${
              count !== -1 ? count : `more than ${to}`
            }`;
          }}
          labelRowsPerPage={t('global.rowsPerPage')}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {/* <ConfirmDecommission /> */}
    </>
  );
};
