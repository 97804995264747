import {
  State,
  privateSnackbarMessagesSlice,
  SnackbarMessage,
} from '@dsf/data-access-store';

import { AppBar, Drawer } from '@dsf/feature-dsfe';
import { Box, IconButton, Snackbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { isMobileSafari } from 'react-device-detect';

const useStyles = makeStyles({
  root: {},
});

export type PageProps = {
  pageName?: string;
  publicPage?: boolean;
};

export const withTenantLayout =
  (WrappedComponent: React.ComponentType) =>
  (pageProps: PageProps): React.FC => {
    const publicPage =
      pageProps.publicPage === undefined ? false : pageProps.publicPage;
    const LayoutComponent: React.FC = (props?: Record<string, unknown>) => {
      const classes = useStyles();
      const dispatch = useDispatch();
      const { t } = useTranslation();

      const [openDrawer, setOpenDrawer] = React.useState<boolean>(false);
      const { messages, openSnack } = useSelector(
        (state: State) => state.privateSnackbarMessages
      );
      const [currentMessage, setCurrentMessage] =
        React.useState<SnackbarMessage | null>(null);

      const handleOpenDrawer = () => {
        setOpenDrawer(true);
      };

      useEffect(() => {
        let _currentMessage;
        messages.forEach((message) => {
          _currentMessage = message;
        });

        if (_currentMessage) {
          // remove massage from slice
          dispatch(
            privateSnackbarMessagesSlice.actions.removeMessage(_currentMessage)
          );
          setCurrentMessage(_currentMessage);
        }
        // eslint-disable-next-line
      }, [messages]);

      useEffect(() => {
        if (isMobileSafari) {
          setTimeout(() => {
            // This fixes some random empty spaces in rendering on iPad Safari.
            // Not sure if it's a mobile Safari bug - see bugs 460756 and 462705 for details.
            window.scrollTo(0, 1);
          }, 300);
        }
        // eslint-disable-next-line
      }, [isMobileSafari]);

      const handleCloseSnack = (
        event: React.SyntheticEvent | MouseEvent,
        reason?: string
      ) => {
        if (reason === 'clickaway') {
          return;
        }
        setCurrentMessage(null);
        dispatch(privateSnackbarMessagesSlice.actions.setOpen(false));
      };

      return (
        <div className="with-tenant-layout">
          <Snackbar
            key={currentMessage ? currentMessage.key : undefined}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={openSnack && !!currentMessage}
            autoHideDuration={8000}
            onClose={handleCloseSnack}
            message={currentMessage ? t(currentMessage.message) : undefined}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                onClick={handleCloseSnack}
              >
                <CloseIcon />
              </IconButton>
            }
          />

          <div className="container">
            {publicPage ? null : <AppBar handleOpenDrawer={handleOpenDrawer} />}

            <Box p="1rem">
              <WrappedComponent {...props} />
            </Box>

            {publicPage ? null : (
              <Drawer
                id="Side-menu"
                anchor="left"
                open={openDrawer}
                onClose={() => {
                  setOpenDrawer(false);
                }}
                closeDrawer={() => setOpenDrawer(false)}
                pageProps={pageProps}
              />
            )}
          </div>
        </div>
      );
    };

    return LayoutComponent;
  };
