import React from 'react';

export interface Props {
  className?: string;
}

export const TwoFactorIcon: React.FC<Props> = ({ className }: Props) => {
  return (
    <svg
      className={className}
      width="36"
      height="32"
      viewBox="0 0 36 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.00006 0C2.06549 0 0.500061 1.56543 0.500061 3.5V17.5C0.500061 19.4346 2.06549 21 4.00006 21H16.4141C17.1797
        16.4268 20.9053 12.8994 25.5469 12.3594C25.2051 11.8193 25.0001 11.1836 25.0001 10.5C25.0001 8.56543 26.5655 7
        28.5001 7C30.4346 7 32.0001 8.56543 32.0001 10.5C32.0001 11.5322 31.5626 12.4277 30.8516 13.0703C32.7589 13.877
        34.3653 15.2441 35.5001 16.9531V3.5C35.5001 1.56543 33.9346 0 32.0001 0H4.00006ZM7.50006 7C9.43463 7 11.0001
        8.56543 11.0001 10.5C11.0001 12.4346 9.43463 14 7.50006 14C5.56549 14 4.00006 12.4346 4.00006 10.5C4.00006
        8.56543 5.56549 7 7.50006 7ZM18.0001 7C19.9346 7 21.5001 8.56543 21.5001 10.5C21.5001 12.4346 19.9346 14 18.0001
        14C16.0655 14 14.5001 12.4346 14.5001 10.5C14.5001 8.56543 16.0655 7 18.0001 7ZM26.7501 14C21.9171 14 18.0001
        17.917 18.0001 22.75C18.0001 27.583 21.9171 31.5 26.7501 31.5C31.5831 31.5 35.5001 27.583 35.5001 22.75C35.5001
        17.917 31.5831 14 26.7501 14ZM30.7969 18.7578L32.6563 20.6172L26.2579 27.0703L21.7735 22.5859L23.6329
        20.7266L26.2579 23.3516L30.7969 18.7578Z"
        fill="#000000"
      />
    </svg>
  );
};
