import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  orBlock: {
    marginTop: '1rem',
    marginBottom: '1rem',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: '1rem',
  },
});

const OrBlock: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return <Box className={classes.orBlock}>{t('global.or')}</Box>;
};

export default OrBlock;
