import { Box } from '@mui/material';
import './ui-battery-status.module.scss';
import Battery90Icon from '@mui/icons-material/Battery90';
import Battery30Icon from '@mui/icons-material/Battery30';
import BatteryFull from '@mui/icons-material/BatteryFull';

/* eslint-disable-next-line */
export interface UiBatteryStatusProps {
  value: number | string;
  label: string;
  position: number;
  alignRight?: boolean;
}

export function UiBatteryStatus({
  value,
  label,
  position,
  alignRight,
}: UiBatteryStatusProps) {
  if (!value && value !== 0) {
    return null;
  }
  const getPosition = () => {
    if (position === 1) {
      return '0.5rem';
    }
    if (position === 2) {
      return '5.0rem';
    }
    return '0';
  };
  return (
    <Box
      sx={{
        position: 'absolute',
        top: getPosition(),
        left: alignRight ? undefined : '0.5rem',
        right: alignRight ? '0.5rem' : undefined,
        background: 'rgba(255, 255, 255, 0.8)',
        borderRadius: '5px',
        padding: '0.4rem 0.9rem',
        width: '9rem',
      }}
    >
      <Box>{label}</Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            marginRight: '0.5rem',
          }}
        >
          {value === 100 ? (
            <BatteryFull htmlColor="green" />
          ) : value < 100 && value >= 50 ? (
            <Battery90Icon htmlColor="green" />
          ) : (
            <Battery30Icon htmlColor="red" />
          )}
        </Box>
        <Box
          sx={{
            fontSize: '1rem',
          }}
        >
          {value} %
        </Box>
      </Box>
    </Box>
  );
}

export default UiBatteryStatus;
