import { useFaultItemApi } from '@dsf/data-access-api';
import {
  FaultItemDetailResponse,
  FaultItemPerson,
  FaultItemStatus,
  FAULT_PRIORITY,
} from '@dsf/util-types';

import {
  Box,
  createStyles,
  FormControl,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Theme,
} from '@material-ui/core';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import RemoveShoppingCartIcon from '@material-ui/icons/RemoveShoppingCart';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import WarningIcon from '@material-ui/icons/Warning';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AlertIcon,
  CogsMultipleIcon,
  MenuWrenchIcon,
  ProfileIcon,
  WrenchSmallIcon,
} from '@dsf/ui-assets';
import { ContentBox, FactoidList, Loader } from '../../../components';
import useFormatters from '../../../hooks/formatter';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrenchIcon: {
      '& path': {
        fill: '#707483',
      },
    },
    iconWrap: {
      width: '1.1rem',
      minWidth: '1.2rem',
    },
    detailFactoidList: {
      '& path': {
        fill: '#707483',
      },
    },
    priorityIconHigh: {
      '& path': {
        fill: '#b00020',
      },
    },
    priorityIconLow: {
      '& path': {
        fill: '#ffb800',
      },
    },
    deliveredStatus: {
      color: '#00b894',
    },
    faultImage: {
      border: '1px solid rgba(45, 47, 54, 0.2)',
      borderRadius: '4px',
    },
    factoidPriorityIcon: {
      '& svg': { transform: 'scale(1.3)' },
    },
    additionalInfo: {
      position: 'relative',
      borderTop: '1px solid rgba(45, 47, 54, 0.2)',
    },
    additionalInfoLabel: {
      position: 'absolute',
      top: '-8px',
      left: '17px',
      display: 'inline-block',
      padding: '0 0.5rem',
      background: '#fff',
      color: '#707483',
      fontSize: '0.75rem',
    },
  })
);

type Props = {
  faultData: FaultItemDetailResponse;
  updateFaultData: (data: FaultItemDetailResponse) => void;
};

const FaultDetail: React.FC<Props> = ({ faultData, updateFaultData }) => {
  const classes = useStyles();
  type ClassesType = keyof typeof classes;
  const faultItemApi = useFaultItemApi();

  const [faultImages, setFaultImages] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [assigneeOptions, setAssigneeOptions] = useState<FaultItemPerson[]>([]);

  const { t } = useTranslation();
  const { formatTime } = useFormatters();

  const handleAssigneeChange = async (
    // eslint-disable-next-line
    event: any,
    value: FaultItemPerson | null
  ) => {
    if (!value) {
      return;
    }

    try {
      await faultItemApi.changeAssignee(`${faultData.id}`, value?.id as number);
      updateFaultData({ ...faultData, assignee: value as FaultItemPerson });
    } catch (error) {
      console.log(error);
    }
  };

  const handleStatusChange = async (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    try {
      const newStatus = event.target.value as FaultItemStatus;
      await faultItemApi.changeFaultItem(`${faultData.id}`, {
        status: newStatus,
      });
      updateFaultData({ ...faultData, status: newStatus });
    } catch (error) {
      console.log(error);
    }
  };

  const handlePriorityChange = async (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    try {
      const newPriority = event.target.value as string;
      await faultItemApi.changeFaultItem(`${faultData.id}`, {
        priority: newPriority,
      });
      updateFaultData({ ...faultData, priority: newPriority });
    } catch (error) {
      console.log(error);
    }
  };

  const getPriorityIcon = () => {
    let className = '';
    if (faultData.priority === 'high') {
      className = 'High';
    } else if (faultData.priority === 'medium') {
      className = 'Medium';
    } else if (faultData.priority === 'low') {
      className = 'Low';
    }
    const attr = `priorityIcon${className}` as ClassesType;
    return <AlertIcon className={className ? classes[attr] : ''} />;
  };

  const getStatusIcon = () => {
    const status = faultData.status;
    const getIcon = (status: FaultItemStatus) => {
      switch (status) {
        case FaultItemStatus.delivered:
          return <ShoppingCartIcon />;
        case FaultItemStatus.ordered:
          return <ShoppingCartIcon />;
        case FaultItemStatus.notneeded:
          return <RemoveShoppingCartIcon />;
        default:
          return null;
      }
    };

    const className =
      status === FaultItemStatus.delivered ? classes.deliveredStatus : '';
    return <Box className={`${className}`}>{getIcon(status)}</Box>;
  };

  const factoidDetails = [
    {
      label: <>{t('faultDetailPage.faultDetail.faultyPart')}</>,
      value: <span>{faultData.partName}</span>,
      icon: <CogsMultipleIcon />,
    },
    {
      label: <>{t('faultDetailPage.faultDetail.faultType')}</>,
      value: <span>{faultData.faultName}</span>,
      icon: <MenuWrenchIcon />,
    },
    {
      label: <>{t('faultDetailPage.faultDetail.assignee')}</>,
      value: (
        <Box mt="0.5rem" maxWidth="290px">
          <FormControl variant="outlined" fullWidth>
            <Autocomplete
              id="faultDetailPage_assignee"
              options={assigneeOptions}
              value={faultData.assignee}
              getOptionSelected={(option, value) => option.id === value.id}
              getOptionLabel={(option) =>
                `${option.lastName}, ${option.firstName} (${option.id})`
              }
              onChange={handleAssigneeChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={t(
                    'faultDetailPage.faultDetail.assigneePlaceholder'
                  )}
                  variant="outlined"
                />
              )}
            />
          </FormControl>
        </Box>
      ),
      icon: <ProfileIcon />,
    },
    {
      label: <>{t('faultDetailPage.faultDetail.status')}</>,
      value: (
        <Box mt="0.5rem" maxWidth="290px">
          <Select
            labelId="fault-select-outlined-label"
            id="faultDetailPage_status"
            value={faultData.status.toLowerCase()}
            onChange={handleStatusChange}
            variant="outlined"
            fullWidth
          >
            {Object.keys(FaultItemStatus).map((status) => (
              <MenuItem value={status} key={status}>
                {t(`faultDetailPage.faultDetail.states.${status}`)}
              </MenuItem>
            ))}
          </Select>
        </Box>
      ),
      icon: <ShoppingCartIcon />,
    },
    {
      label: <>{t('faultDetailPage.faultDetail.priority')}</>,
      value: (
        <Box mt="0.5rem" maxWidth="290px">
          <Select
            labelId="priority-select-outlined-label"
            id="faultDetailPage_priority"
            value={faultData.priority.toLowerCase()}
            onChange={handlePriorityChange}
            variant="outlined"
            fullWidth
          >
            {Object.keys(FAULT_PRIORITY).map((priority) => (
              <MenuItem value={priority} key={priority}>
                {t(`faultDetailPage.faultDetail.priorities.${priority}`)}
              </MenuItem>
            ))}
          </Select>
        </Box>
      ),
      icon: (
        <span className={classes.factoidPriorityIcon}>{getPriorityIcon()}</span>
      ),
    },
    {
      label: <>{t('faultDetailPage.faultDetail.causer')}</>,
      value: <span>{faultData.origin}</span>,
      icon: <WarningIcon />,
    },
  ];

  const factoidAdditionals = [
    {
      label: <>{t('faultDetailPage.faultDetail.reportTime')}</>,
      value: <>{formatTime(faultData.reportTime)}</>,
    },
    {
      label: <>{t('faultDetailPage.faultDetail.reportedLocation')}</>,
      value: <span>{faultData.reportLocation}</span>,
    },
    {
      label: <>{t('faultDetailPage.faultDetail.reporter')}</>,
      value: (
        <>
          {faultData.reporter.lastName}, {faultData.reporter.firstName} (
          {faultData.reporter.id})
        </>
      ),
    },
    {
      label: <>{t('faultDetailPage.faultDetail.informedPersons')}</>,
      value: (
        <span>
          {faultData.informedPersons && faultData.informedPersons.length
            ? faultData.informedPersons
                .map(
                  (person) =>
                    `${person.lastName}, ${person.firstName} (${person.id})`
                )
                .join('; ')
            : 'N/A'}
        </span>
      ),
    },
  ];

  const getFaultImages = (images: string[]) => {
    const faultPictureIds = images || [];
    if (!faultPictureIds.length) {
      setLoading(false);
      return;
    }
    const picturesPromises: Promise<AxiosResponse<unknown>>[] =
      faultPictureIds.map((pictureId) => {
        return new Promise((resolve, reject) => {
          faultItemApi
            .getImageBase64(pictureId)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      });

    Promise.all(picturesPromises)
      .then((values) => {
        const _faultImages = values.map(
          (image) => `data:image/png;base64,${image?.data}`
        );
        setFaultImages(_faultImages);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getAssigneeOptions = async () => {
    try {
      const assigneeOptionsResponse = await faultItemApi.getAssigneeOptions();
      const assignees = assigneeOptionsResponse.data.sort(
        (a, b) =>
          a.lastName.localeCompare(b.lastName) ||
          a.firstName.localeCompare(b.firstName) ||
          a.id - b.id
      );

      setAssigneeOptions(assignees);
    } catch (error) {
      setAssigneeOptions([]);
    }
  };

  useEffect(() => {
    getAssigneeOptions();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (faultData.faultPictureIds) {
      getFaultImages(faultData.faultPictureIds);
    }
    // eslint-disable-next-line
  }, [faultData]);

  if (loading) {
    return <Loader />;
  }

  return (
    <ContentBox
      icon={<MenuWrenchIcon className={classes.wrenchIcon} />}
      title={
        <>
          <Box fontSize="1.125rem" fontWeight="bold">
            {faultData.assetNumber} - {faultData.id}
          </Box>
          <Box color="#707483" fontSize="0.75rem" mt="0.25rem">
            {t('faultDetailPage.faultDetail.lastUpdated')}:{' '}
            {formatTime(faultData.reportTime)}
          </Box>
        </>
      }
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Box>
          <Box display="flex" mb="0.5rem">
            <Box mr="0.5rem" className={classes.iconWrap}>
              <WrenchSmallIcon />
            </Box>
            <Box>{faultData.partName}</Box>
          </Box>
          <Box display="flex">
            <Box mr="0.5rem" className={classes.iconWrap}>
              {getPriorityIcon()}
            </Box>
            <Box>{faultData.faultName}</Box>
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <Box>{getStatusIcon()}</Box>
          <Box ml="0.5rem" alignItems="center">
            {faultData.status === FaultItemStatus.closed ? (
              <Box className={classes.deliveredStatus}>
                <DoneOutlineIcon />
              </Box>
            ) : null}
          </Box>
          <Box>
            {faultData.status === FaultItemStatus.ordered ? (
              <Box>{t('faultDetailPage.faultDetail.partsOrdered')}</Box>
            ) : null}
          </Box>
        </Box>
      </Box>
      <Box m="1rem 0" pt="1rem" borderTop="1px solid rgba(45, 47, 54, 0.2)">
        {faultImages.map((image, idx) => (
          <img
            key={idx}
            alt="Fault"
            src={image}
            height="180px"
            className={classes.faultImage}
          />
        ))}
      </Box>
      <Box mt="1rem" className={classes.detailFactoidList}>
        <FactoidList factoids={factoidDetails} />
      </Box>
      <Box mt="3rem" pt="1.5rem" className={classes.additionalInfo}>
        <div className={classes.additionalInfoLabel}>
          {t('faultDetailPage.faultDetail.additionalInformation')}
        </div>
        <FactoidList factoids={factoidAdditionals} />
      </Box>
    </ContentBox>
  );
};

export default FaultDetail;
