import {
  FAULT_ITEM_CREATE,
  IDENTIFY_PRODUCT,
  PRODUCTION_SEGMENT_CHECK_IN,
  PRODUCT_OVERVIEW,
  QR_CODE_GENERATION,
  SERIAL_NUMBER_NEW,
  FAULT_LIST,
} from '@dsf/util-router';

import { MenuWrenchIcon } from '@dsf/ui-assets';
import React from 'react';
import { MenuItemType } from './menu-item';

export const getProductionItems = (
  navigate: (route: string) => void
): MenuItemType[] => [
  {
    label: 'drawerNav.production',
    icon: () => <MenuWrenchIcon />,
    id: 'drawer_production',
    subItems: [
      {
        label: 'drawerNav.checkIn',
        onClick: () => {
          navigate(PRODUCTION_SEGMENT_CHECK_IN);
        },
        id: 'drawer_production_checkIn',
      },
      {
        label: 'drawerNav.productOverview',
        onClick: () => {
          navigate(PRODUCT_OVERVIEW);
        },
        id: 'drawer_production_productOverview',
      },
      {
        label: 'drawerNav.locateProduct',
        onClick: () => {
          navigate(IDENTIFY_PRODUCT);
        },
        id: 'drawer_production_identifyProduct',
      },
      {
        label: 'drawerNav.faultOverview',
        onClick: () => {
          navigate(FAULT_LIST);
        },
        id: 'drawer_production_faultOverview',
      },
      {
        label: 'drawerNav.createFaultItem',
        onClick: () => {
          navigate(FAULT_ITEM_CREATE.replace(':assetId?', ''));
        },
        id: 'drawer_production_createFaultItem',
      },
      {
        label: 'drawerNav.createProduct',
        onClick: () => {
          navigate(SERIAL_NUMBER_NEW);
        },
        id: 'drawer_production_administration_createProduct',
      },
      {
        label: 'drawerNav.createQrCode',
        onClick: () => {
          navigate(QR_CODE_GENERATION);
        },
        id: 'drawer_production_administration_createQrCode',
      },
    ],
  },
];
