import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

interface Props {
  text?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: '#777',
      fontSize: 14,
      marginTop: 15,
      marginBottom: 10,
    },
  })
);

const InfoLabel: React.FC<Props> = ({ text }) => {
  const classes = useStyles();

  return <div className={classes.root}>{text}</div>;
};

export default InfoLabel;
