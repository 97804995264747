import { Alert, Box, Dialog, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './ui-dialog-scanner.module.scss';
import { QrReader } from 'react-qr-reader';

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 12,
            top: 12,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

/* eslint-disable-next-line */
export interface UiDialogScannerProps {
  onClose: () => void;
  onScan: any;
  title?: string;
  hasError?: boolean;
  onCloseError?: () => void;
}

export function UiDialogScanner({
  onClose,
  onScan,
  title = 'QR Code Scanner',
  hasError = false,
  onCloseError,
}: UiDialogScannerProps) {
  return (
    <Dialog onClose={onClose} open={true} fullWidth>
      <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
        {title}
      </BootstrapDialogTitle>
      {hasError ? (
        <Box m="1rem 1rem 0 1rem">
          <Alert severity="warning" onClose={onCloseError || undefined}>
            Unknown QR code.
          </Alert>
        </Box>
      ) : null}
      <QrReader constraints={{ facingMode: 'environment' }} onResult={onScan} />
    </Dialog>
  );
}

export default UiDialogScanner;
