import { useProductApi, usePhysicalAssetApi } from '@dsf/data-access-api';
import { QR_CODE_GENERATION_SPECIFIC } from '@dsf/util-router';
import { Product } from '@dsf/util-types';

import React, { SyntheticEvent, useEffect, useState } from 'react';
import { withTenantLayout } from '@dsf/ui-layout';
import { Button, Grid, Snackbar, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Alert, Autocomplete } from '@material-ui/lab';

interface PageState {
  productNumbers: Product[];
  selected: Product | null;
  serialNumber: string;
}

const SerialNumberNewPage: React.FC = () => {
  const productApi = useProductApi();
  const physicalAssetApi = usePhysicalAssetApi();
  const { t } = useTranslation();
  const history = useHistory();
  const [pageState, setPageState] = useState<PageState>({
    productNumbers: [],
    selected: null,
    serialNumber: '',
  });
  const [isAcquired, setIsAcquired] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const acceptedSerialNumberChars = new RegExp('^[a-zA-Z0-9-_]+$');

  // eslint-disable-next-line
  const handleSerialNumberChange = (event: any) => {
    setPageState({ ...pageState, serialNumber: event.target.value });
  };

  const loadProductNumbers = (): Promise<Product[]> => {
    return new Promise((resolve, reject) => {
      productApi
        .getAllProducts()
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  };

  const onSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();

    try {
      const acquireResponse = await physicalAssetApi.createPhysicalAsset({
        serialNumber: pageState.serialNumber,
        physicalAssetTypeId: pageState.selected?.id as number,
      });
      if (acquireResponse.status === 200) {
        setIsAcquired(true);
      }
    } catch (e) {
      let errorMessage;
      if (e.response.status === 409) {
        errorMessage =
          `${t('serialNumberNew.errors.conflict.beforeSN')}` +
          ` ${pageState.serialNumber} ${t(
            'serialNumberNew.errors.conflict.afterSN'
          )}`;
      } else {
        errorMessage = t('serialNumberNew.errors.general');
      }
      setAlertMessage(errorMessage);
      setShowAlert(true);
    }
  };

  const onCloseAlert = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowAlert(false);
  };

  useEffect(() => {
    loadProductNumbers().then((pns) => {
      setPageState({
        ...pageState,
        productNumbers: pns,
      });
    });
    // eslint-disable-next-line
  }, []);

  // eslint-disable-next-line
  const onProductNumberChange = (event: any, value: Product | null) => {
    setPageState({ ...pageState, selected: value });
  };

  const onClickCreateQrCode = (event: SyntheticEvent) => {
    event.preventDefault();
    history.push(
      QR_CODE_GENERATION_SPECIFIC.replace(
        ':serialNumber',
        pageState.serialNumber
      )
    );
  };

  const handleSerialNumberInput = (e: SyntheticEvent) => {
    if (!acceptedSerialNumberChars.test((e.nativeEvent as KeyboardEvent).key)) {
      e.preventDefault();
    }
  };

  return (
    <div className={'serial-number-page'}>
      <h1 id="serialNumberNew_title">{t('serialNumberNew.title')}</h1>
      <p>{t('serialNumberNew.description')}</p>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        spacing={3}
      >
        <Grid container item xs={12} justify="center" alignItems="center">
          <Autocomplete
            id="combo-box-demo"
            options={pageState.productNumbers}
            getOptionLabel={(option) => option.productNumber}
            style={{ flex: 1 }}
            onChange={onProductNumberChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('serialNumberNew.form.partNumber')}
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid container item xs={12} justify="center" alignItems="center">
          {pageState.selected && (
            <TextField
              multiline
              rows={4}
              value={pageState.selected?.description}
              label={t('serialNumberNew.form.productDescription')}
              disabled={true}
              variant="outlined"
              style={{ flex: 1 }}
            />
          )}
        </Grid>
        <Grid container item xs={12} justify="center" alignItems="center">
          {pageState.selected && (
            <TextField
              id="homePage"
              label={t('serialNumberNew.form.serialNumber')}
              required={true}
              value={pageState.serialNumber}
              onChange={handleSerialNumberChange}
              variant="outlined"
              onKeyDown={handleSerialNumberInput}
              style={{ flex: 1 }}
            />
          )}
        </Grid>
        <Grid
          style={{ flex: 1 }}
          container
          item
          xs={12}
          justify="flex-end"
          alignItems="flex-end"
        >
          {pageState.selected && !isAcquired && (
            <Button
              id="serialNumberNew_createProduct_button"
              onClick={onSubmit}
              disabled={!pageState.serialNumber}
              variant="contained"
              color="primary"
              key={`${!pageState.serialNumber}`} // Fix for Safari
            >
              {t('serialNumberNew.form.button')}
            </Button>
          )}
          {isAcquired && (
            <div>
              <span>{t('serialNumberNew.success.message')}</span> &nbsp;
              <Button
                onClick={onClickCreateQrCode}
                variant="contained"
                color="secondary"
              >
                {t('serialNumberNew.success.button')}
              </Button>
            </div>
          )}
        </Grid>
      </Grid>

      <Snackbar
        open={showAlert}
        autoHideDuration={5000}
        onClose={onCloseAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={onCloseAlert} severity="error">
          {alertMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default withTenantLayout(SerialNumberNewPage)({ pageName: 'SRC-89' });
