import { State, TENANT_ALPHA } from '@dsf/data-access-store';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { LanguageSwitch } from '../components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerWrapper: {
      position: 'relative',
      width: '100%',
      maxWidth: '35rem',
      margin: '0 auto',
      textAlign: 'center',
    },
    headerLogo: {
      height: 64,
      margin: 55,
      [theme.breakpoints.down('xs')]: {
        marginBottom: 0,
      },
    },
    languageSwitch: {
      position: 'absolute',
      right: 0,
      top: 55,
      height: 64,
    },
  })
);

type Props = {
  logoUrl?: string;
};

const HeaderPublic: React.FC<Props> = ({ logoUrl }) => {
  const classes = useStyles();
  const appSettings = useSelector((state: State) => state.appSettings);

  return (
    <header className={classes.headerWrapper}>
      <img
        className={classes.headerLogo}
        src={logoUrl || '/logo/dsfx.png'}
        alt="Logo"
        style={
          logoUrl && appSettings.tenantConfig?.tenant_id !== TENANT_ALPHA
            ? {
                filter: 'invert(1) grayscale(1) brightness(2)',
              }
            : undefined
        }
      />

      {/* <img className={classes.headerLogo} src={'/logo/logo.png'} /> */}
      <div className={classes.languageSwitch}>
        <LanguageSwitch />
      </div>
    </header>
  );
};

export default HeaderPublic;
