import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { useParams } from 'react-router';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import TextField from '@mui/material/TextField';
import { keyframes } from '@emotion/react';
import InputAdornment from '@mui/material/InputAdornment';
import { formatAssetType, AssetType, ASSET_TYPES } from './asset-list';
import { UiContentBox } from '@dsf/ui-content-box';
import PostAddIcon from '@material-ui/icons/PostAdd';
import { useHistory } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CachedIcon from '@material-ui/icons/Cached';
import CircularProgress from '@material-ui/core/CircularProgress';
import { AWS_IOT_SHADOW } from '@dsf/data-access-api';
import { useWrapApi } from '@dsf/data-access-api';
import {
  formatDataValue,
  formatYearsValue,
  roundNumber,
  strEnum,
} from '@dsf/util-tools';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Box, Button, Grid, Skeleton } from '@mui/material';

import Alert from '@mui/material/Alert';
import { useQueries } from '@dsf/util-tools';

import { UiBatteryStatus } from '@dsf/ui-battery-status';
import AssetEvent from './asset-event';
import { useTranslation } from 'react-i18next';
import { ConfirmDecommission } from './confirm-decommission';
import { DSFE_ASSET_LIST } from '@dsf/util-router';
import { QHCurrentCurve, QHPoint } from './qh-current-curve';
import { PumpIndicator } from './pump-indicator';
import { QHTimeline, HistoryQhItem } from './qh-timeline';
import { BearingNonDriveend } from './pump-boxes/bearing-non-driveend';
import { PowerElectronics } from './pump-boxes/power-electronics';
import { Motor } from './pump-boxes/motor';
import { CavitationDamage } from './pump-boxes/cavitation-damage';
import { BearingDriveend } from './pump-boxes/bearing-driveend';
import { SecondImpeller } from './pump-boxes/second-impeller';
import { FirstImpeller } from './pump-boxes/first-impeller';
import { ThreeDots } from '@dsf/ui-components';
import { QHHistoryScatter } from './qh-history-scatter';

/**
 * Examples:
 * [
	{"timestamp":"2022-02-20T10:01:02.319081", "type":"info", "txt":"Pump restored expected output"},
	{"timestamp":"2022-02-20T09:25:32.319081", "type":"error", "txt":"Pump stopped"},
	{"timestamp":"2022-02-20T07:18:48.319081", "type":"warning", "txt":"Potential malfunction detected based on vibration"}
	]
 */
type AssetEventItem = {
  eventId: ReturnType<typeof uuid>;
  timestamp: string;
  type: string;
  txt: string;
  detail: string;
  isExpanded?: boolean;
};

const STARTING_FROM_OPTIONS = strEnum(['ALL', 'TODAY', 'THIS_CALENDAR_MONTH']);
type StartingFromType = keyof typeof STARTING_FROM_OPTIONS;

const DEFAULT_PUMP_CHART_ITEMS = ['power_consumption', 'flow_speed'];
const GRAPH_ITEMS = strEnum(DEFAULT_PUMP_CHART_ITEMS);
type GraphItemType = keyof typeof GRAPH_ITEMS;

type Consumption = {
  history_points: number;
  seconds_consumed: number;
  distance_travelled: number;
};
type ThingType = {
  battery_reach: number;
  battery_soc: number;
  battery_soh: number;
  operating_mode: number;
  singal_strength: number;
  speed: number;
  theta_position: number;
  uptime: number;
  welcome: string;
  x_position: number;
  y_position: number;
  in_operation: boolean;

  //   pump:
  extra_graphs?: GraphItemType[];
  stroke_freqency?: number;
  flow_speed?: number;
  machineTimestamp?: string;
  output_pressure?: number;
  motion_profile?: number;
  power_consumption?: number;
  liquid_temperature?: number;
  warnings?: any[]; // eslint-disable-line
  target_output_pressure?: number;
};

export type Shadow = {
  attributes: { [key: string]: string };
  location: {
    name?: string;
    max_x?: number;
    max_y?: number;
  };
  thingId: string;
  thingName: string;
  thingTypeName: string;
  metadata: any; // eslint-disable-line
  state: {
    delta: ThingType;
    desired: ThingType;
    reported: any; // eslint-disable-line
  };
  timestamp: number;
  version: number;
};

type ShadowHistoryItem = {
  state: ThingType;
  timestamp: string;
};

type ShadowHistory = ShadowHistoryItem[];

export type PumpDetailParams = {
  assetId?: string;
};

export const INPUT_STYLES: React.CSSProperties = {
  textAlign: 'right',
};

const ripple = keyframes({
  from: { transform: 'scale(1)', opacity: 1 },
  to: { transform: 'scale(1.15)', opacity: 0 },
});

export const PumpDetail = () => {
  const { api } = useWrapApi();
  const { isMobile } = useQueries();

  const pumpIndicatorTimeout = useRef<any>(); // eslint-disable-line
  const [pumpIndicatorId, setPumpIndicatorId] = useState<string | undefined>(
    undefined
  );
  const [pumpOnline, setPumpOnline] = useState(false);
  const [initialIndicatorCheck, setInitialIndicatorCheck] = useState(true);

  const { sendMessage, lastMessage, readyState } = useWebSocket(
    'wss://qtqd8gdz72.execute-api.eu-central-1.amazonaws.com/dev',
    { shouldReconnect: () => true }
  );
  const [websocketMessages, setWebsocketMessages] = useState<any[]>([]); // eslint-disable-line
  const [lastUpdatedMessages, setLastUpdatedMessages] = useState<any>(); // eslint-disable-line

  const history = useHistory();
  const { t } = useTranslation();

  const [openConfirmDecom, setOpenConfirmDecom] = useState(false);
  const [decomLoading, setDecomLoading] = useState(false);

  const { assetId } = useParams<PumpDetailParams>();
  const [shadow, setShadow] = useState<Shadow | undefined>();
  const [consumption, setConsumption] = useState<Consumption | undefined>();
  const [assetEvents, setAssetEvents] = useState<AssetEventItem[]>([]);

  const [shadowHistory, setShadowHistory] = useState<
    ShadowHistory | undefined
  >();
  const [loading, setLoading] = useState(true);
  const [loadingShadow, setLoadingShadow] = useState(true);
  const [loadingEvents, setLoadingEvents] = useState(true);
  const [loadingConsumption, setLoadingConsumption] = useState(true);
  const [loadingHistory, setLoadingHistory] = useState(true);
  const [error, setError] = useState('');

  const [showQHCurve, setShowQHCurve] = useState(false);

  const [currentBatterySoc, setCurrentBatterySoc] = useState(0);
  const [currentBatterySoh, setCurrentBatterySoh] = useState(0);

  const [startingFromOption, setStartingFromOption] =
    useState<StartingFromType>(STARTING_FROM_OPTIONS.ALL);
  const [startingFromTimestamp, setStartingFromTimestamp] = useState<
    string | undefined
  >();

  const changeStartingFrom = (event: SelectChangeEvent) => {
    setStartingFromOption(event.target.value as StartingFromType);
  };

  const [qhData, setQhData] = useState<QHPoint[]>([]);
  const [historyQhData, setHistoryQhData] = useState<HistoryQhItem[]>([]);

  const fetchShadow = useCallback(() => {
    if (assetId) {
      try {
        setLoadingShadow(true);
        setError('');
        api
          .get(`/things/${assetId}/shadows/${AWS_IOT_SHADOW}`)
          .then(({ data }) => {
            setShadow(data);
            setQhData([
              {
                q: shadow?.state?.reported?.['gfdm:REMOTE_FLOW']?.floatValue,
                h: shadow?.state?.reported?.['databricks:head']?.floatValue,
              },
            ]);
            const historyQ =
              shadow?.state?.reported?.['gfdm:REMOTE_FLOW']?.floatValue;

            const historyH =
              shadow?.state?.reported?.['databricks:head']?.floatValue;

            if (historyQ !== undefined && historyH !== undefined) {
              setHistoryQhData([
                {
                  q: parseFloat(historyQ),
                  h: parseFloat(historyH),
                  timestamp: moment(
                    shadow?.state?.reported?.['machineTimestamp']
                  ).format('YYYY-MM-DD HH:mm:ss'),
                  // timestamp: moment(shadow?.state?.reported?.['machineTimestamp']).valueOf(),
                },
              ]);
            }

            setCurrentBatterySoc(data?.state?.reported?.battery_soc);
            setCurrentBatterySoh(data?.state?.reported?.battery_soh);

            setLoadingShadow(false);
          })
          .catch((error) => {
            console.error('shadows detail error', error);
            if (error?.response?.status === 404) {
              setError(
                'Please power the device in order to finish the onboarding.'
              );
            }
            setShadow(undefined);
            setLoadingShadow(false);
          });
      } catch (error) {
        setError('Unable to load the asset.');
        setShadow(undefined);
        setLoadingShadow(false);
      }
    }
  }, [assetId]); // eslint-disable-line

  const prepareEventData = (eventData: Omit<AssetEventItem, 'eventId'>[]) => {
    return eventData?.map((item) => ({
      ...item,
      eventId: uuid(),
      isExpanded: item.isExpanded !== undefined,
    }));
  };

  const fetchEvents = useCallback(() => {
    if (assetId) {
      setLoadingEvents(true);
      api
        .get(
          `/things/${assetId}/events${
            startingFromTimestamp
              ? `?starting_from=${startingFromTimestamp}`
              : ''
          }`
        )
        .then(({ data }) => {
          if (startingFromOption === STARTING_FROM_OPTIONS.ALL) {
            const eventData = prepareEventData(data);
            setAssetEvents(eventData);
          } else {
            if (
              startingFromOption === STARTING_FROM_OPTIONS.THIS_CALENDAR_MONTH
            ) {
              const filtered = data.filter((item: AssetEventItem) => {
                const todayItem = moment(item.timestamp)
                  .startOf('month')
                  .toISOString();
                const todayActual = moment().startOf('month').toISOString();
                return todayItem === todayActual;
              });
              const eventData = prepareEventData(filtered);
              setAssetEvents(eventData);
            } else if (startingFromOption === STARTING_FROM_OPTIONS.TODAY) {
              const filtered = data.filter((item: AssetEventItem) => {
                const todayItem = moment(item.timestamp)
                  .startOf('day')
                  .toISOString();
                const todayActual = moment().startOf('day').toISOString();
                return todayItem === todayActual;
              });
              const eventData = prepareEventData(filtered);
              setAssetEvents(eventData);
            } else {
              setAssetEvents([]);
            }
          }

          setLoadingEvents(false);
        })
        .catch((error) => {
          console.error('events error', error);
          setLoadingEvents(false);
        });
    }
  }, [assetId, startingFromOption, startingFromTimestamp]); // eslint-disable-line

  const fetchConsumption = useCallback(() => {
    if (assetId) {
      setLoadingConsumption(true);
      api
        .get(
          `things/${assetId}/consumption${
            startingFromTimestamp
              ? `?starting_from=${startingFromTimestamp}`
              : ''
          }`
        )
        .then(({ data }) => {
          setConsumption(data);
          setLoadingConsumption(false);
        })
        .catch((error) => {
          console.error('consumption error', error);
          setConsumption(undefined);
          setLoadingConsumption(false);
        });
    }
  }, [assetId, startingFromTimestamp]); // eslint-disable-line

  const fetchShadowHistory = useCallback(() => {
    if (assetId) {
      setLoadingHistory(true);
      api
        .get(
          `/things/${assetId}/shadows/${AWS_IOT_SHADOW}/history${
            startingFromTimestamp
              ? `?starting_from=${startingFromTimestamp}`
              : `?starting_from=${moment().add(-24, 'hours').toISOString()}`
          }`
        )
        .then(({ data }) => {
          setShadowHistory(data);
          setLoadingHistory(false);
          setHistoryQhData(
            // eslint-disable-next-line
            data.map((value: any) => {
              return {
                q: parseFloat(value.state['gfdm:REMOTE_FLOW']?.floatValue),
                h: parseFloat(value.state['databricks:head']?.floatValue),
                timestamp: moment(value.state.machineTimestamp).format(
                  'YYYY-MM-DD HH:mm:ss'
                ),
              };
            })
          );
        })
        .catch((error) => {
          console.error('shadows history error', error);
          setShadowHistory(undefined);
          setLoadingHistory(false);
        });
    }
  }, [assetId, startingFromTimestamp]); // eslint-disable-line

  const inidicatorTimeout = useCallback(() => {
    setTimeout(() => {
      if (!pumpIndicatorId) {
        setInitialIndicatorCheck(false);
      }
    }, 9000);
  }, [pumpIndicatorId]);

  const refreshData = useCallback(() => {
    fetchShadow();
    fetchShadowHistory();
    fetchEvents();
    fetchConsumption();
    setInitialIndicatorCheck(true);
    setPumpIndicatorId(undefined);
    inidicatorTimeout();
  }, [fetchShadow, fetchShadowHistory, fetchEvents, fetchConsumption]); // eslint-disable-line

  useEffect(() => {
    if (!assetId) {
      return;
    }
    fetchShadow();
    fetchShadowHistory();
    fetchConsumption();
    fetchEvents();
  }, [
    assetId,
    startingFromTimestamp,
    fetchShadow,
    fetchShadowHistory,
    fetchConsumption,
    fetchEvents,
  ]);

  useEffect(() => {
    setTimeout(() => {
      setShowQHCurve(true);
    }, 2000);
  }, []);



  const onToggleExpandEvent = (eventId: string) => {
    setAssetEvents((prevEvents) => {
      const needleEvent = prevEvents.find((item) => item.eventId === eventId);
      if (needleEvent) {
        needleEvent.isExpanded = !needleEvent.isExpanded;
      }
      return [...prevEvents];
    });
  };

  useEffect(() => {
    const getParameterValue = () => {
      if (startingFromOption === STARTING_FROM_OPTIONS.TODAY) {
        return moment().startOf('day').toISOString();
      }
      if (startingFromOption === STARTING_FROM_OPTIONS.THIS_CALENDAR_MONTH) {
        return moment().startOf('month').toISOString();
      }
      return;
    };
    const parameterValue = getParameterValue();
    setStartingFromTimestamp(parameterValue);
  }, [startingFromOption]);

  const assetType = shadow?.attributes?.dsfqtype as AssetType;
  const assetImageSrc = '/images/vertical-pump-1111.jpeg';

  // eslint-disable-next-line
  const handleQHUpdate = (value: any) => {
    const newQHPoint = {
      q: value['gfdm:REMOTE_FLOW']?.floatValue,
      h: value['databricks:head']?.floatValue,
    };

    if (
      !qhData.find((item) => item.q === newQHPoint.q && item.h === newQHPoint.h)
    ) {
      setQhData([newQHPoint]);
    }
    if (newQHPoint.q !== undefined && newQHPoint.h !== undefined) {
      setHistoryQhData((prevHistoryQhData) => {
        if (
          !prevHistoryQhData.find(
            (item) =>
              item.q === (parseFloat(newQHPoint.q) || 0) &&
              item.h === (parseFloat(newQHPoint.h) || 0)
            // && item.timestamp ===
            //   moment(value.message.machineTimestamp).format(
            //     'YYYY-MM-DD HH:mm:ss'
            //   )
          )
        ) {
          return [
            ...prevHistoryQhData,
            {
              q: parseFloat(newQHPoint.q) || 0,
              h: parseFloat(newQHPoint.h) || 0,
              timestamp: moment(value.machineTimestamp).format(
                'YYYY-MM-DD HH:mm:ss'
              ),
              // timestamp: moment(value.message.machineTimestamp).valueOf(),
            },
          ];
        }
        return prevHistoryQhData;
      });
    }
  };

  const handleShadowUpdate = useCallback(
    (value) => {
      if (value.message) {
        if (value.message.battery_soc !== undefined) {
          setCurrentBatterySoc(value.message.battery_soc);
        }
        if (value.message.battery_soh !== undefined) {
          setCurrentBatterySoh(value.message.battery_soh);
        }
        handleQHUpdate(value.message);

        setShadow((prevShadow) => {
          if (prevShadow) {
            return {
              ...prevShadow,
              state: {
                ...prevShadow.state,
                reported: {
                  ...prevShadow?.state.reported,
                  ...value.message,
                },
              },
            };
          }
          return {
            state: {
              reported: value.message,
            },
          } as Shadow;
        });

        if (shadowHistory && value.message.machineTimestamp) {
          // eslint-disable-next-line
          const newItem: any = {
            timestamp: moment(value.message.machineTimestamp).format(
              'YYYY-MM-DD HH:mm'
            ),
          };
          const graphs =
            shadow?.state.reported.extra_graphs || DEFAULT_PUMP_CHART_ITEMS;

          if (graphs.length) {
            // eslint-disable-next-line
            graphs.forEach((graphKey: any) => {
              if (value.message[graphKey]) {
                newItem[graphKey as string] = value.message[graphKey];
              }
            });
          }
        }
      }
      if (value.message?.consumption) {
        setConsumption((prevConsumption) => {
          if (prevConsumption) {
            return {
              ...prevConsumption,
              ...value.message.consumption,
            };
          }
          return value.message.consumption;
        });
      }
    },
    [shadow?.state.reported.extra_graphs, shadowHistory] // eslint-disable-line
  );

  const flush = useCallback(() => {
    websocketMessages.forEach((value) => {
      if (value.event === 'onShadowUpdate') {
        setPumpIndicatorId(uuid());
        if (!shadowHistory?.length && !loadingHistory) {
          fetchShadowHistory();
        }
        handleShadowUpdate(value);
      } else if (value.event === 'onEventUpdate') {
        if (value.message) {
          setPumpIndicatorId(uuid());
          setAssetEvents((prevEvents) => {
            const eventData = prepareEventData([value.message]);
            return [...eventData, ...prevEvents];
          });
        }
      }
    });
    setLastUpdatedMessages(moment(new Date()));
    setWebsocketMessages([]);
  }, [
    websocketMessages,
    handleShadowUpdate,
    fetchShadowHistory,
    shadowHistory,
  ]);

  useEffect(() => {
    if (lastMessage && lastMessage.data) {
      const messageData = JSON.parse(lastMessage.data);
      if (messageData.event === 'onShadowUpdate' && messageData.message) {
        setPumpOnline(true);
        setInitialIndicatorCheck(false);
        clearTimeout(pumpIndicatorTimeout.current);
      }

      setWebsocketMessages((prev) => [...prev, messageData]);
    }
  }, [lastMessage]);

  useEffect(() => {
    if (!lastUpdatedMessages) {
      flush();
    } else {
      const now = moment(new Date());
      const duration = moment.duration(now.diff(lastUpdatedMessages));
      const seconds = duration.asSeconds();

      if (seconds > 3) {
        flush();
      }
    }
  }, [websocketMessages, lastUpdatedMessages]); // eslint-disable-line

  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      if (assetId) {
        sendMessage(
          JSON.stringify({
            action: 'getShadowStream',
            message: {
              assetName: assetId,
              eventType: 'onShadowUpdate',
            },
          })
        );
        sendMessage(
          JSON.stringify({
            action: 'getShadowStream',
            message: {
              assetName: assetId,
              eventType: 'onEventUpdate',
            },
          })
        );
      }
    }
  }, [readyState, assetId, sendMessage]);

  useEffect(() => {
    if (!pumpIndicatorId) {
      setPumpOnline(false);
    } else {
      setPumpOnline(true);
      clearTimeout(pumpIndicatorTimeout.current);
      pumpIndicatorTimeout.current = setTimeout(() => {
        setPumpOnline(false);
      }, 9000);
    }
    return () => {
      clearTimeout(pumpIndicatorTimeout.current);
    };
  }, [pumpIndicatorId]);

  useEffect(() => {
    if (!inidicatorTimeout) {
      return;
    }
    inidicatorTimeout();
  }, [pumpIndicatorId, inidicatorTimeout]);

  useEffect(() => {
    setLoading(
      loadingShadow || loadingEvents || loadingHistory || loadingConsumption
    );
  }, [loadingShadow, loadingEvents, loadingHistory, loadingConsumption]);

  const SectionWrap: React.FC<{ inline?: boolean }> = ({
    children,
    inline = false,
  }) => {
    return (
      <Box
        sx={
          inline
            ? {
                // display: 'flex',
                // flexDirection: 'column',
                p: '0.5rem',
                backgroundColor: '#eee',
                background:
                  'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(238,238,238,1) 50%, rgba(255,255,255,1) 100%)',
                borderRadius: '5px',
                mb: '0.5rem',
                border: '1px solid #eee',
                // alignItems: 'center',
              }
            : {}
        }
      >
        {children}
      </Box>
    );
  };

  const SectionTitle: React.FC<{ inline?: boolean }> = ({
    children,
    inline = false,
  }) => {
    return (
      <Box
        sx={
          inline
            ? {
                fontWeight: 'bold',
                fontSize: '0.8rem',
                // width: '50%',
                // textAlign: 'right',
                mr: '1rem',
              }
            : {
                fontWeight: 'bold',
                fontSize: '1rem',
                mt: '2rem',
                p: '1rem',
                backgroundColor: '#eee',
                backgroundImage: 'linear-gradient(to right, #eee , #fff)',
                borderRadius: '5px 5px 0 0',
                border: '1px solid #eee',
                borderBottomWidth: 0,
              }
        }
      >
        {children}
      </Box>
    );
  };

  const SectionContent: React.FC<{ inline?: boolean }> = ({
    children,
    inline = false,
  }) => {
    return (
      <Box
        sx={
          inline
            ? {}
            : {
                fontSize: '1rem',
                mb: '1rem',
                p: '1rem',
                borderRadius: '0 0 5px 5px',
                border: '1px solid #eee',
              }
        }
      >
        {children}
      </Box>
    );
  };

  const ColumnTitle: React.FC = ({ children }) => {
    return (
      <Box
        sx={{
          bgcolor: '#ccc',
          p: '0.5rem',
          fontSize: '1rem',
          fontWeight: 'bold',
          textAlign: 'center',
          border: '1px solid #ccc',
          borderRadius: '4px',
          mb: '0.5rem',
          textTransform: 'uppercase',
        }}
      >
        {children}
      </Box>
    );
  };

  const titlePartOne =
    shadow?.attributes?.label?.replace('_', ' ') ||
    shadow?.attributes?.dsfqtype;

  const cavitationDamage =
    shadow?.state?.reported['databricks:instant_cavitation_index']?.floatValue;
  const cavitationStatusColor = useMemo(() => {
    if (cavitationDamage == 0) {
      return '#90ee90'; // green
    }
    if (cavitationDamage > 0 && cavitationDamage <= 30) {
      return '#90ee90'; // light green
    }
    if (cavitationDamage > 30 && cavitationDamage <= 80) {
      return '#ffff00'; // yellow
    }
    if (cavitationDamage > 80 && cavitationDamage <= 100) {
      return '#ff0000'; // red
    }
    return '#000';
  }, [cavitationDamage]);

  // eslint-disable-next-line
  const getBoxStatusColor = useCallback((value: any) => {
    if (value > 5 && value <= 10) {
      return '#ffff00'; // yellow
    }
    if (value > 10) {
      return '#90ee90'; // light green
    }
    return '#ff0000'; // red if motorRemaining < 5
  }, []);

  return (
    <>
      <Box maxWidth="721px" margin="0 auto">
        <PumpIndicator
          open={pumpOnline}
          initialIndicatorCheck={initialIndicatorCheck}
        />
        <Box
          sx={{
            color: pumpOnline ? undefined : '#4c4c4c',
            mt: isMobile ? '5rem' : undefined,
          }}
        >
          <UiContentBox
            icon={
              <Box>
                <PostAddIcon />
              </Box>
            }
            title={
              loadingShadow ? (
                <Skeleton
                  sx={{ transform: 'none' }}
                  width={isMobile ? '200px' : '300px'}
                  height="25px"
                  animation="wave"
                  variant="text"
                />
              ) : (
                <Box
                  fontWeight="bold"
                  fontSize="1.125rem"
                  alignItems="center"
                  justifyContent="space-between"
                  lineHeight={1.5}
                >
                  {titlePartOne
                    ? titlePartOne.replaceAll('_', ' ')
                    : titlePartOne}
                  {shadow?.location?.name
                    ? `${titlePartOne ? ' - ' : ''}${
                        shadow?.attributes?.locationLabel
                          ? t(shadow?.attributes?.locationLabel).replaceAll(
                              '_',
                              ' '
                            )
                          : shadow?.location?.name
                      }`
                    : ''}
                </Box>
              )
            }
            postfix={
              <Box
                sx={{
                  display: 'flex',
                  position: isMobile ? 'absolute' : undefined,
                  top: isMobile ? '-58px' : undefined,
                  right: isMobile ? '0' : undefined,
                }}
              >
                <Button
                  variant="contained"
                  id="button_detail-back"
                  onClick={() => {
                    history.push(DSFE_ASSET_LIST);
                  }}
                  startIcon={<ArrowBackIcon />}
                  sx={{
                    mr: '0.5rem',
                  }}
                >
                  {t('assetDetail.back')}
                </Button>
                <ThreeDots
                  options={[
                    {
                      label: t('assetDetail.refresh'),
                      icon: <CachedIcon fontSize="small" />,
                      onClick: () => {
                        refreshData();
                      },
                      id: 'button_refresh',
                    },
                    {
                      label: t('assetManagement.decommission'),
                      icon: <DeleteForeverIcon fontSize="small" />,
                      onClick: () => {
                        setOpenConfirmDecom(true);
                      },
                      id: 'button_decommission',
                    },
                  ]}
                />
              </Box>
            }
          >
            <Box
              display={isMobile ? 'block' : 'flex'}
              alignItems="center"
              justifyContent="flex-end"
              mb="1.8rem"
              style={{ display: 'none' }}
            >
              <FormControl
                fullWidth
                sx={{
                  marginRight: 'auto',
                  maxWidth: isMobile ? undefined : '250px',
                  mb: isMobile ? '1rem' : undefined,
                }}
                size="small"
              >
                <InputLabel id="starting-from-select">
                  {t('assetDetail.startingFrom')}
                </InputLabel>
                <Select
                  labelId="starting-from-select"
                  id="demo-simple-select"
                  value={startingFromOption}
                  label="Starting From"
                  onChange={changeStartingFrom}
                >
                  <MenuItem value={STARTING_FROM_OPTIONS.ALL}>All</MenuItem>
                  <MenuItem value={STARTING_FROM_OPTIONS.TODAY}>
                    {t('assetDetail.today')}
                  </MenuItem>
                  <MenuItem value={STARTING_FROM_OPTIONS.THIS_CALENDAR_MONTH}>
                    {t('assetDetail.thisCalendarMonth')}
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
            {loading ? (
              <Box display="flex" justifyContent="center" p="6rem 2rem">
                <CircularProgress />
              </Box>
            ) : shadow ? (
              <Box>
                {assetImageSrc ? (
                  <Box
                    sx={{
                      position: 'relative',
                      m: '-1rem -1rem -2rem -1rem',
                      zIndex: 0,
                      '& .MuiInputAdornment-root': {
                        mr: 0,
                        ml: '0.2rem',
                      },
                    }}
                  >
                    <Box
                      sx={{
                        position: 'relative',
                        my: '3rem',
                      }}
                    >
                      <Box
                        sx={{
                          position: isMobile ? 'relative' : 'absolute',
                          top: isMobile ? 0 : '135px',
                          right: 0,
                          transform: isMobile ? undefined : 'scale(0.7)',
                          m: isMobile ? '2rem auto' : undefined,
                          width: '210px',
                          height: '210px',
                          p: '50px 20px 30px 20px',
                          border: `6px solid ${
                            pumpOnline ? '#00B050' : 'grey'
                          }`,
                          borderRadius: '50%',
                          overflow: 'visible',

                          '&::before': {
                            animation: pumpOnline
                              ? `${ripple} 1.8s infinite ease-in-out`
                              : undefined,
                            content: '""',
                            position: 'absolute',
                            zIndex: -1,
                            top: '-11px',
                            left: '-11px',
                            right: '-11px',
                            bottom: '-11px',
                            border: '2px solid #00B050',
                            borderRadius: '50%',
                          },
                          '&::after': {
                            content: '""',
                            position: 'absolute',
                            zIndex: -1,
                            top: '-11px',
                            left: '-11px',
                            right: '-11px',
                            bottom: '-11px',
                            border: `2px solid ${
                              pumpOnline ? '#00B050' : 'grey'
                            }`,
                            borderRadius: '50%',
                          },
                        }}
                      >
                        <Box
                          sx={{
                            fontWeight: 'bold',
                            textTransform: 'uppercase',
                          }}
                        >
                          {t('assetDetail.operatingTime1')} <br />
                          {t('assetDetail.operatingTime2')}
                        </Box>
                        <TextField
                          size="small"
                          type="text"
                          value={formatYearsValue(
                            shadow?.state?.reported[
                              'databricks:remaining_SystemLife'
                            ]?.floatValue
                          )}
                          fullWidth
                          InputProps={{
                            readOnly: true,
                            inputProps: {
                              style: INPUT_STYLES,
                            },
                            endAdornment: (
                              <InputAdornment position="start">
                                {t('units.years')}
                              </InputAdornment>
                            ),
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Box>
                      <img
                        src={assetImageSrc}
                        alt="Asset"
                        style={{
                          display: 'block',
                          width: '100%',
                          maxWidth: isMobile ? '150px' : '300px',
                          margin: 'auto',
                        }}
                      />
                      <PowerElectronics
                        shadow={shadow}
                        pumpOnline={pumpOnline}
                        statusColor={getBoxStatusColor(
                          shadow?.state?.reported[
                            'databricks:remaining_PowerElectronics'
                          ]?.floatValue
                        )}
                      />
                      <BearingNonDriveend
                        shadow={shadow}
                        pumpOnline={pumpOnline}
                        statusColor={getBoxStatusColor(
                          shadow?.state?.reported[
                            'databricks:remaining_BearingNonDriveend'
                          ]?.floatValue
                        )}
                      />
                      <Motor
                        shadow={shadow}
                        pumpOnline={pumpOnline}
                        statusColor={getBoxStatusColor(
                          shadow?.state?.reported[
                            'databricks:remaining_MotorWindings'
                          ]?.floatValue
                        )}
                      />
                      <BearingDriveend
                        shadow={shadow}
                        pumpOnline={pumpOnline}
                        statusColor={getBoxStatusColor(
                          shadow?.state?.reported[
                            'databricks:remaining_BearingDriveend'
                          ]?.floatValue
                        )}
                      />
                      <CavitationDamage
                        shadow={shadow}
                        pumpOnline={pumpOnline}
                        statusColor={cavitationStatusColor}
                      />
                      <Box
                        sx={{
                          maxWidth: '190px',
                          mx: isMobile ? 'auto' : undefined,
                          position: isMobile ? 'static' : 'absolute',
                          right: '50px',
                          bottom: '50px',
                        }}
                      />
                      <SecondImpeller
                        shadow={shadow}
                        pumpOnline={pumpOnline}
                        statusColor={getBoxStatusColor(
                          shadow?.state?.reported['databricks:remaining_Imp2']
                            ?.floatValue
                        )}
                      />
                      <FirstImpeller
                        shadow={shadow}
                        pumpOnline={pumpOnline}
                        statusColor={getBoxStatusColor(
                          shadow?.state?.reported['databricks:remaining_Imp1']
                            ?.floatValue
                        )}
                      />
                    </Box>
                    {assetType === ASSET_TYPES.transporter ? (
                      <>
                        <UiBatteryStatus
                          label={t('assetDetail.batteryState')}
                          value={currentBatterySoc}
                          position={1}
                          alignRight={assetType !== ASSET_TYPES.transporter}
                        />
                        <UiBatteryStatus
                          label={t('assetDetail.batteryHealth')}
                          value={currentBatterySoh}
                          position={2}
                          alignRight={assetType !== ASSET_TYPES.transporter}
                        />
                      </>
                    ) : null}
                  </Box>
                ) : null}

                <SectionWrap>
                  <SectionTitle>{t('assetDetail.current')}</SectionTitle>
                  <SectionContent>
                    <Box
                      sx={{
                        opacity: pumpOnline ? 1 : 0.6,
                        minWidth: isMobile ? '653px' : undefined,
                        transform: isMobile ? 'scale(0.4)' : undefined,
                        transformOrigin: isMobile ? 'top left' : undefined,
                        ml: isMobile ? '-1rem' : undefined,
                        height: isMobile ? '150px' : undefined,
                      }}
                    >
                      {showQHCurve ? (
                        <QHCurrentCurve
                          pumpOnline={pumpOnline}
                          qhData={qhData}
                        />
                      ) : (
                        <Box
                          sx={{
                            width: '100%',
                            height: '350px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <CircularProgress color="inherit" />
                        </Box>
                      )}
                    </Box>
                  </SectionContent>
                </SectionWrap>
                
                {/*
                <SectionWrap>
                  <SectionTitle>{t('assetDetail.history')}</SectionTitle>
                  <SectionContent>
                    <Box overflow="hidden">
                      <Box
                        sx={{
                          opacity: pumpOnline ? 1 : 0.6,
                          minWidth: isMobile ? '653px' : undefined,
                          transform: isMobile ? 'scale(0.45)' : undefined,
                          transformOrigin: isMobile ? 'top left' : undefined,
                          ml: isMobile ? '-1rem' : undefined,
                          height: isMobile ? '180px' : undefined,
                        }}
                      >
                        {showQHCurve ? (
                          <QHHistoryScatter
                            historyQhData={historyQhData}
                            latestQhPoint={
                              qhData.length
                                ? {
                                    q: parseFloat(`${qhData[0].q}`),
                                    h: parseFloat(`${qhData[0].h}`),
                                  }
                                : undefined
                            }
                          />
                        ) : (
                          <Box
                            sx={{
                              width: '100%',
                              height: '350px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <CircularProgress color="inherit" />
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </SectionContent>
                </SectionWrap>
                */}
                          
                <SectionWrap>
                  <SectionTitle>{t('assetDetail.timeline')}</SectionTitle>
                  <SectionContent>
                    <Box
                      sx={{
                        opacity: pumpOnline ? 1 : 0.6,
                        minWidth: isMobile ? '653px' : undefined,
                        transform: isMobile ? 'scale(0.47)' : undefined,
                        transformOrigin: isMobile ? 'top left' : undefined,
                        ml: isMobile ? '-1rem' : undefined,
                        height: isMobile ? '250px' : undefined,
                      }}
                    >
                      {showQHCurve ? (
                        <QHTimeline historyQhData={historyQhData} />
                      ) : (
                        <Box
                          sx={{
                            width: '100%',
                            height: '350px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <CircularProgress color="inherit" />
                        </Box>
                      )}
                    </Box>
                  </SectionContent>
                </SectionWrap>

                <SectionWrap>
                  <SectionTitle>{t('assetDetail.assetEvents')}</SectionTitle>
                  <SectionContent>
                    {assetEvents.length ? (
                      <Box
                        sx={{
                          maxHeight: '150px',
                          overflow: 'auto',
                          border: '1px solid rgba(224, 224, 224, 1)',
                          borderRadius: '5px',
                        }}
                      >
                        {assetEvents.map((item, index) => {
                          return (
                            <AssetEvent
                              key={`event-${index}`}
                              data={item}
                              onToggleExpandEvent={onToggleExpandEvent}
                            />
                          );
                        })}
                      </Box>
                    ) : (
                      <Box>N/A</Box>
                    )}
                  </SectionContent>
                </SectionWrap>

                <Box
                  sx={{
                    position: 'relative',
                    mt: isMobile ? '3rem' : undefined,
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={isMobile ? 12 : 4}>
                      <ColumnTitle>{t('assetDetail.generalData')}</ColumnTitle>

                      <SectionWrap inline>
                        <SectionTitle inline>
                          {t('assetDetail.assetType')}
                        </SectionTitle>
                        <SectionContent inline>
                          {formatAssetType(shadow.attributes)}
                        </SectionContent>
                      </SectionWrap>

                      <SectionWrap inline>
                        <SectionTitle inline>
                          {t('assetDetail.location')}
                        </SectionTitle>
                        <SectionContent inline>
                          {shadow.attributes?.locationLabel
                            ? t(shadow.attributes?.locationLabel)
                            : shadow.location?.name}
                        </SectionContent>
                      </SectionWrap>

                      <SectionWrap inline>
                        <SectionTitle inline>
                          {t('assetDetail.lastUpdated')}
                        </SectionTitle>
                        <SectionContent inline>
                          <Box>
                            {formatDataValue(
                              shadow?.metadata?.reported?.machineTimestamp
                                ?.timestamp &&
                                moment
                                  .unix(
                                    shadow?.metadata?.reported?.machineTimestamp
                                      ?.timestamp
                                  )
                                  .format('YYYY-MM-DD HH:mm')
                            )}
                          </Box>
                        </SectionContent>
                      </SectionWrap>

                      {consumption ? (
                        <>
                          <SectionWrap inline>
                            <SectionTitle inline>
                              {t('assetDetail.historyPoints')}
                            </SectionTitle>
                            <SectionContent inline>
                              <Box>
                                {formatDataValue(
                                  consumption.history_points,
                                  undefined,
                                  0
                                )}
                              </Box>
                            </SectionContent>
                          </SectionWrap>
                        </>
                      ) : null}
                    </Grid>
                    <Grid item xs={isMobile ? 12 : 4}>
                      <ColumnTitle>
                        {t('assetDetail.operatingConditions')}
                      </ColumnTitle>

                      <SectionWrap inline>
                        <SectionTitle inline>
                          {t('assetDetail.measuredFlow')}
                        </SectionTitle>
                        <SectionContent inline>
                          <Box>
                            {formatDataValue(
                              shadow?.state?.reported?.['gfdm:REMOTE_FLOW']
                                ?.floatValue,
                              'm³/h'
                            )}
                          </Box>
                        </SectionContent>
                      </SectionWrap>

                      <SectionWrap inline>
                        <SectionTitle inline>
                          {t('assetDetail.head')}
                        </SectionTitle>
                        <SectionContent inline>
                          <Box>
                            {formatDataValue(
                              shadow?.state?.reported?.['databricks:head']
                                ?.floatValue,
                              'm'
                            )}
                          </Box>
                        </SectionContent>
                      </SectionWrap>

                      <SectionWrap inline>
                        <SectionTitle inline>
                          {t('assetDetail.measuredMotorSpeed')}
                        </SectionTitle>
                        <SectionContent inline>
                          <Box>
                            {formatDataValue(
                              shadow?.state?.reported?.[
                                'mfdm:measured_motor_speed'
                              ]?.ushortValue,
                              'rpm'
                            )}
                          </Box>
                        </SectionContent>
                      </SectionWrap>

                      <SectionWrap inline>
                        <SectionTitle inline>
                          {t('assetDetail.energyConsumption')}
                        </SectionTitle>
                        <SectionContent inline>
                          <Box>
                            {formatDataValue(
                              shadow?.state?.reported?.[
                                'mfdm:energy_consumption'
                              ]?.uintValue,
                              'kwh'
                            )}
                          </Box>
                        </SectionContent>
                      </SectionWrap>

                      <SectionWrap inline>
                        <SectionTitle inline>
                          {t('assetDetail.inletPressure')}
                        </SectionTitle>
                        <SectionContent inline>
                          <Box>
                            {formatDataValue(
                              shadow?.state?.reported?.['mfdm:inlet_pressure']
                                ?.floatValue,
                              'bar'
                            )}
                          </Box>
                        </SectionContent>
                      </SectionWrap>

                      <SectionWrap inline>
                        <SectionTitle inline>
                          {t('assetDetail.outletPressure')}
                        </SectionTitle>
                        <SectionContent inline>
                          <Box>
                            {formatDataValue(
                              shadow?.state?.reported?.[
                                'mfdm:pump_outlet_presseure'
                              ]?.floatValue,
                              'bar'
                            )}
                          </Box>
                        </SectionContent>
                      </SectionWrap>

                      <SectionWrap inline>
                        <SectionTitle inline>
                          {t('assetDetail.liqtecTemperature')}
                        </SectionTitle>
                        <SectionContent inline>
                          <Box>
                            {formatDataValue(
                              shadow?.state?.reported?.['mfdm:LIQTEC_TEMP']
                                ?.floatValue,
                              '°C',
                              0
                            )}
                          </Box>
                        </SectionContent>
                      </SectionWrap>

                      <SectionWrap inline>
                        <SectionTitle inline>
                          {t('assetDetail.sensorTemperature')}
                        </SectionTitle>
                        <SectionContent inline>
                          <Box>
                            {formatDataValue(
                              shadow?.state?.reported?.[
                                'mfdm:GRUNDFOS_SENSOR_TEMP'
                              ]?.floatValue,
                              '°C',
                              0
                            )}
                          </Box>
                        </SectionContent>
                      </SectionWrap>

                      <SectionWrap inline>
                        <SectionTitle inline>
                          {t('assetDetail.electronicTemp')}
                        </SectionTitle>
                        <SectionContent inline>
                          <Box>
                            {formatDataValue(
                              shadow?.state?.reported?.[
                                'gfdm:POWER_ELECTRONIC_TEMP'
                              ]?.floatValue,
                              '°C',
                              0
                            )}
                          </Box>
                        </SectionContent>
                      </SectionWrap>


                      <SectionWrap inline>
                        <SectionTitle inline>
                          {t('assetDetail.pumpStarts')}
                        </SectionTitle>
                        <SectionContent inline>
                          <Box>
                            {
                              (formatDataValue(
                                shadow?.state?.reported?.[
                                  'mfdm:num_pump_starts'
                                ]?.uintValue
                              ),
                              undefined,
                              0)
                            }
                          </Box>
                        </SectionContent>
                      </SectionWrap>
                      <SectionWrap inline>
                        <SectionTitle inline>
                          {t('assetDetail.pumpState')}
                        </SectionTitle>
                        <SectionContent inline>
                          <Box>
                            {0 == shadow.state.reported.detectedPumpState ? "Off":
                             1 == shadow.state.reported.detectedPumpState ? "On" : 
                             2 == shadow.state.reported.detectedPumpState ? "Risk of Cavitation" : "Unknown"}
                          </Box>
                        </SectionContent>
                      </SectionWrap>
                    </Grid>
                    <Grid item xs={isMobile ? 12 : 4}>
                      <ColumnTitle>{t('assetDetail.lifetime')}</ColumnTitle>

                      <SectionWrap inline>
                        <SectionTitle inline>
                          {t('assetDetail.motorWindingsLifetime')}
                        </SectionTitle>
                        <SectionContent inline>
                          <Box>
                            {formatYearsValue(
                              shadow?.state?.reported?.[
                                'databricks:remaining_MotorWindings'
                              ]?.floatValue,
                              t('units.years')
                            )}
                          </Box>
                        </SectionContent>
                      </SectionWrap>

                      <SectionWrap inline>
                        <SectionTitle inline>
                          {t('assetDetail.shaftLifetime')}
                        </SectionTitle>
                        <SectionContent inline>
                          <Box>
                            {formatYearsValue(
                              shadow?.state?.reported?.['databricks:Shaft']
                                ?.floatValue,
                              t('units.years')
                            )}
                          </Box>
                        </SectionContent>
                      </SectionWrap>

                      <SectionWrap inline>
                        <SectionTitle inline>
                          {t('assetDetail.powerElectronics')}
                        </SectionTitle>
                        <SectionContent inline>
                          <Box>
                            {formatYearsValue(
                              shadow?.state?.reported?.[
                                'databricks:remaining_PowerElectronics'
                              ]?.floatValue,
                              t('units.years')
                            )}
                          </Box>
                        </SectionContent>
                      </SectionWrap>

                      <SectionWrap inline>
                        <SectionTitle inline>
                          {t('assetDetail.motorShaftLifetime')}
                        </SectionTitle>
                        <SectionContent inline>
                          <Box>
                            {formatYearsValue(
                              shadow?.state?.reported?.['databricks:MotorShaft']
                                ?.floatValue,
                              t('units.years')
                            )}
                          </Box>
                        </SectionContent>
                      </SectionWrap>

                      <SectionWrap inline>
                        <SectionTitle inline>
                          {t('assetDetail.driveEndBearing')}
                        </SectionTitle>
                        <SectionContent inline>
                          <Box>
                            {formatYearsValue(
                              shadow?.state?.reported?.[
                                'databricks:BearingDriveend'
                              ]?.floatValue,
                              t('units.years')
                            )}
                          </Box>
                        </SectionContent>
                      </SectionWrap>

                      <SectionWrap inline>
                        <SectionTitle inline>
                          {t('assetDetail.NonDriveEndBearing')}
                        </SectionTitle>
                        <SectionContent inline>
                          <Box>
                            {formatYearsValue(
                              shadow?.state?.reported?.[
                                'databricks:BearingNonDriveend'
                              ]?.floatValue,
                              t('units.years')
                            )}
                          </Box>
                        </SectionContent>
                      </SectionWrap>

                      <SectionWrap inline>
                        <SectionTitle inline>
                          {t('assetDetail.firstImpellerLifetime')}
                        </SectionTitle>
                        <SectionContent inline>
                          <Box>
                            {formatYearsValue(
                              shadow?.state?.reported[
                                'databricks:remaining_Imp1'
                              ]?.floatValue,
                              t('units.years')
                            )}
                          </Box>
                        </SectionContent>
                      </SectionWrap>

                      <SectionWrap inline>
                        <SectionTitle inline>
                          {t('assetDetail.secondImpellerLifetime')}
                        </SectionTitle>
                        <SectionContent inline>
                          <Box>
                            {formatYearsValue(
                              shadow?.state?.reported[
                                'databricks:remaining_Imp2'
                              ]?.floatValue,
                              t('units.years')
                            )}
                          </Box>
                        </SectionContent>
                      </SectionWrap>

                      <SectionWrap inline>
                        <SectionTitle inline>
                          {t('assetDetail.stopNut')}
                        </SectionTitle>
                        <SectionContent inline>
                          <Box>
                            {formatYearsValue(
                              shadow?.state?.reported?.['databricks:Stop_nut']
                                ?.floatValue,
                              t('units.years')
                            )}
                          </Box>
                        </SectionContent>
                      </SectionWrap>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            ) : (
              <>
                {error && (
                  <Alert
                    severity="error"
                    variant="standard"
                    sx={{ mb: '1rem' }}
                  >
                    {error}
                  </Alert>
                )}
                <Box>{t('assetDetail.noDataAvailable')}</Box>
              </>
            )}
          </UiContentBox>
        </Box>
      </Box>
      <ConfirmDecommission
        open={openConfirmDecom}
        onClose={() => {
          setOpenConfirmDecom(false);
        }}
        loading={decomLoading}
        onConfirm={() => {
          setDecomLoading(true);
          api
            .delete(`/things/${assetId}`)
            .then(() => {
              setDecomLoading(false);
              setOpenConfirmDecom(false);
              history.push('/dsfe/decommissioned');
            })
            .catch(() => {
              console.error('Decommission error');
              setDecomLoading(false);
            });
        }}
      />
    </>
  );
};
