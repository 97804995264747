import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { postSlice } from './slice/postSlice';
import { loginSlice } from './slice/loginSlice';
import { loadState, saveState } from './appStorage';
import { sessionTokenSlice } from './slice/sessionTokenSlice';
import { userTokensSlice } from './slice/userTokensSlice';
import { publicSnackbarMessagesSlice } from './slice/publicSnackbarMessagesSlice';
import { privateSnackbarMessagesSlice } from './slice/privateSnackbarMessagesSlice';
import { requestChangePasswordSlice } from './slice/requestChangePasswordSlice';
import { identitySlice } from './slice/identitySlice';
import { createFaultItemSlice } from './slice/createFaultItemSlice';
import { tenantSlice, TENANT_ALPHA } from './slice/tenantSlice';
import { featuresSlice } from './slice/featuresSlice';
import { appSettingsSlice, DEFAULT_DOMAIN } from './slice/appSettingsSlice';

export { postSlice };
export { loginSlice };
export { loadState };
export { sessionTokenSlice };
export { userTokensSlice };
export { publicSnackbarMessagesSlice };
export { privateSnackbarMessagesSlice };
export { requestChangePasswordSlice };
export { identitySlice };
export { createFaultItemSlice };
export { tenantSlice, TENANT_ALPHA };
export { appSettingsSlice, DEFAULT_DOMAIN };

const reducer = combineReducers({
  login: loginSlice.reducer,
  features: featuresSlice.reducer,
  appSettings: appSettingsSlice.reducer,
  identity: identitySlice.reducer,
  posts: postSlice.reducer,
  sessionToken: sessionTokenSlice.reducer,
  userTokens: userTokensSlice.reducer,
  publicSnackbarMessages: publicSnackbarMessagesSlice.reducer,
  privateSnackbarMessages: privateSnackbarMessagesSlice.reducer,
  requestChangePassword: requestChangePasswordSlice.reducer,
  createFaultItem: createFaultItemSlice.reducer,
  tenant: tenantSlice.reducer,
});

const middleware = [
  ...getDefaultMiddleware({
    serializableCheck: false,
  }),
  thunk,
];

export const store = configureStore({
  reducer,
  middleware,
  preloadedState: loadState(),
});

store.subscribe(() => {
  saveState(store.getState());
});
