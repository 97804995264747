import React from 'react';

export interface Props {
  className?: string;
}

export const MenuBrickIcon: React.FC<Props> = ({ className }: Props) => {
  return (
    <svg
      className={className}
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M20 16H0V3H3V0H9V3H11V0H17V3H20V16Z" fill="white" />
    </svg>
  );
};
