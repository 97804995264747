import { createStyles, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      cursor: 'pointer',
    },
    textLink: {
      color: theme.palette.secondary.main,
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    inline: {
      display: 'inline',
    },
  })
);

type Props = {
  onClick: () => void;
  disabled?: boolean;
  className?: string;
  id?: string;
  textLink?: boolean;
  inline?: boolean;
};

const ButtonDiv: React.FC<Props> = ({
  disabled,
  onClick: handleClick,
  className,
  children,
  id,
  textLink,
  inline,
}) => {
  const classes = useStyles();

  const onClick = () => {
    if (disabled) {
      return;
    }
    handleClick();
  };

  const onKeyPress: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
    const enterOrSpace =
      e.key === 'Enter' ||
      e.key === ' ' ||
      e.key === 'Spacebar' ||
      e.which === 13 ||
      e.which === 32;
    if (enterOrSpace) {
      e.preventDefault();
      onClick();
    }
  };
  return (
    <div
      className={clsx(classes.root, className, {
        [classes.inline]: inline,
        [classes.textLink]: textLink,
      })}
      role="button"
      tabIndex={disabled ? -1 : 0}
      onClick={onClick}
      onKeyPress={onKeyPress}
      id={id}
    >
      {children}
    </div>
  );
};

export default ButtonDiv;
