import { ButtonDiv } from '../../../components';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import React from 'react';

type StyleProps = {
  active: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      backgroundColor: '#fff',
      width: 98,
      height: 85,
      border: '1px solid #D3D3D3',
      borderRadius: 10,
      padding: '0.9375rem 0.75rem 0 0.6rem',
    },
    title: {
      fontSize: '2.125rem',
      fontWeight: 'bold',
      color: (p: StyleProps) => (p.active ? '#fff' : 'var(--secondary-color)'),
    },
    description: {
      fontSize: '0.75rem',
      color: (p: StyleProps) => (p.active ? '#fff' : 'var(--primary-color)'),
      marginTop: '-0.25rem',
    },
    icon: {
      position: 'absolute',
      top: 0,
      right: 0,
      opacity: 0.08,
      '& path': {
        fill: (p: StyleProps) => (p.active ? '#fff' : '#000'),
      },
    },
    active: {
      backgroundColor: theme.palette.secondary.main,
    },
  })
);

type Props = StyleProps & {
  onClick: () => void;
  title: React.FC;
  description: React.FC;
  icon: React.FC;
  id: string;
};

const FilterBox: React.FC<Props> = ({
  onClick,
  title: Title,
  description: Description,
  icon: Icon,
  id,
  active,
}) => {
  const classes = useStyles({ active });

  return (
    <ButtonDiv
      onClick={onClick}
      className={`${classes.root} ${active ? classes.active : ''}`}
      id={id}
    >
      <div className={classes.title}>
        <Title />
      </div>
      <div className={classes.description}>
        <Description />
      </div>
      <div className={classes.icon}>
        <Icon />
      </div>
    </ButtonDiv>
  );
};

export default FilterBox;
