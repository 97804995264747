import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { useParams } from 'react-router';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import { formatAssetType, AssetType, ASSET_TYPES } from './asset-list';
import { UiContentBox } from '@dsf/ui-content-box';
import PostAddIcon from '@material-ui/icons/PostAdd';
import SaveIcon from '@material-ui/icons/Save';
import { useHistory } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CachedIcon from '@material-ui/icons/Cached';
import CircularProgress from '@material-ui/core/CircularProgress';
import { AWS_IOT_SHADOW } from '@dsf/data-access-api';
import { UiSlider } from '@dsf/ui-slider';
import { UiLineChart } from '@dsf/ui-line-chart';
import { UiScatterChart } from '@dsf/ui-scatter-chart';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { LineProps } from 'recharts';
import { green, red } from '@material-ui/core/colors';
import { roundNumber, strEnum } from '@dsf/util-tools';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Box, Button } from '@mui/material';

import Alert from '@mui/material/Alert';
import { UiBatteryStatus } from '@dsf/ui-battery-status';
import AssetEvent from './asset-event';
import { useTranslation } from 'react-i18next';
import { ConfirmDecommission } from './confirm-decommission';
import { useWrapApi } from '@dsf/data-access-api';
import { DSFE_ASSET_LIST } from '@dsf/util-router';

/**
 * Examples:
 * [
	{"timestamp":"2022-02-20T10:01:02.319081", "type":"info", "txt":"Pump restored expected output"},
	{"timestamp":"2022-02-20T09:25:32.319081", "type":"error", "txt":"Pump stopped"},
	{"timestamp":"2022-02-20T07:18:48.319081", "type":"warning", "txt":"Potential malfunction detected based on vibration"}
	]
 */
export type AssetEventItem = {
  eventId: ReturnType<typeof uuid>;
  timestamp: string;
  type: string;
  txt: string;
  detail: string;
  isExpanded?: boolean;
};

const STARTING_FROM_OPTIONS = strEnum(['ALL', 'TODAY', 'THIS_CALENDAR_MONTH']);
type StartingFromType = keyof typeof STARTING_FROM_OPTIONS;

const DEFAULT_PUMP_CHART_ITEMS = ['power_consumption', 'flow_speed'];
const GRAPH_ITEMS = strEnum(DEFAULT_PUMP_CHART_ITEMS);
type GraphItemType = keyof typeof GRAPH_ITEMS;

type Consumption = {
  history_points: number;
  seconds_consumed: number;
  distance_travelled: number;
};
export type ThingType = {
  battery_reach: number;
  battery_soc: number;
  battery_soh: number;
  operating_mode: number;
  singal_strength: number;
  speed: number;
  theta_position: number;
  uptime: number;
  welcome: string;
  x_position: number;
  y_position: number;
  in_operation: boolean;

  //   pump:
  extra_graphs?: GraphItemType[];
  stroke_freqency?: number;
  flow_speed?: number;
  machineTimestamp?: string;
  output_pressure?: number;
  motion_profile?: number;
  power_consumption?: number;
  liquid_temperature?: number;
  warnings?: any[];
  target_output_pressure?: number;
};

type Shadow = {
  attributes: { [key: string]: string };
  metadata: any;
  state: {
    delta: ThingType;
    desired: ThingType;
    reported: ThingType;
  };
  timestamp: number;
  version: number;
  location: {
    name?: string;
    max_x?: number;
    max_y?: number;
  };
};

type ShadowHistoryItem = {
  state: ThingType;
  timestamp: string;
};

type ShadowHistory = ShadowHistoryItem[];

type AssetDetailParams = {
  assetId?: string;
};

export const AssetDetail = () => {
  const { api } = useWrapApi();
  const { sendMessage, lastMessage, readyState } = useWebSocket(
    'wss://qtqd8gdz72.execute-api.eu-central-1.amazonaws.com/dev'
  );
  const [websocketMessages, setWebsocketMessages] = useState<any[]>([]);
  const [lastUpdatedMessages, setLastUpdatedMessages] = useState<any>();

  const history = useHistory();
  const { t } = useTranslation();

  const [openConfirmDecom, setOpenConfirmDecom] = useState(false);
  const [decomLoading, setDecomLoading] = useState(false);

  const { assetId } = useParams<AssetDetailParams>();
  const [shadow, setShadow] = useState<Shadow | undefined>();
  const [consumption, setConsumption] = useState<Consumption | undefined>();
  const [assetEvents, setAssetEvents] = useState<AssetEventItem[]>([]);

  const [shadowHistory, setShadowHistory] = useState<
    ShadowHistory | undefined
  >();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [operatingMode, setOperatingMode] = useState<number | undefined>(
    undefined
  );

  const [pumpChartData, setPumpChartData] = useState<any[]>([]);
  const [positionChartData, setPositionChartData] = useState<any[]>([]);
  const [statusChartData, setStatusChartData] = useState<any[]>([]);

  const [currentBatterySoc, setCurrentBatterySoc] = useState(0);
  const [currentBatterySoh, setCurrentBatterySoh] = useState(0);

  const [startingFromOption, setStartingFromOption] =
    useState<StartingFromType>(STARTING_FROM_OPTIONS.ALL);
  const [startingFromTimestamp, setStartingFromTimestamp] = useState<
    string | undefined
  >();

  const changeStartingFrom = (event: SelectChangeEvent) => {
    setStartingFromOption(event.target.value as StartingFromType);
  };

  const fetchShadow = useCallback(() => {
    if (assetId) {
      setLoading(true);
      setError('');
      api
        .get(`/things/${assetId}/shadows/${AWS_IOT_SHADOW}`)
        .then(({ data }) => {
          setShadow(data);
          setCurrentBatterySoc(data?.state?.reported?.battery_soc);
          setCurrentBatterySoh(data?.state?.reported?.battery_soh);
          if (data && data.state && data.state.reported) {
            setOperatingMode(data.state.reported.operating_mode);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.error('shadows detail error', error);
          if (error?.response?.status === 404) {
            setError(
              'Please power the device in order to finish the onboarding.'
            );
          }
          setShadow(undefined);
          setLoading(false);
        });
    }
  }, [assetId]);

  const prepareEventData = (eventData: Omit<AssetEventItem, 'eventId'>[]) => {
    return eventData?.map((item) => ({
      ...item,
      eventId: uuid(),
      isExpanded: item.isExpanded !== undefined,
    }));
  };

  const fetchEvents = useCallback(() => {
    if (assetId) {
      setLoading(true);
      api
        .get(
          `/things/${assetId}/events${
            startingFromTimestamp
              ? `?starting_from=${startingFromTimestamp}`
              : ''
          }`
        )
        .then(({ data }) => {
          if (startingFromOption === STARTING_FROM_OPTIONS.ALL) {
            const eventData = prepareEventData(data);
            setAssetEvents(eventData);
          } else {
            if (
              startingFromOption === STARTING_FROM_OPTIONS.THIS_CALENDAR_MONTH
            ) {
              const filtered = data.filter((item: AssetEventItem) => {
                const todayItem = moment(item.timestamp)
                  .startOf('month')
                  .toISOString();
                const todayActual = moment().startOf('month').toISOString();
                return todayItem === todayActual;
              });
              const eventData = prepareEventData(filtered);
              setAssetEvents(eventData);
            } else if (startingFromOption === STARTING_FROM_OPTIONS.TODAY) {
              const filtered = data.filter((item: AssetEventItem) => {
                const todayItem = moment(item.timestamp)
                  .startOf('day')
                  .toISOString();
                const todayActual = moment().startOf('day').toISOString();
                return todayItem === todayActual;
              });
              const eventData = prepareEventData(filtered);
              setAssetEvents(eventData);
            } else {
              setAssetEvents([]);
            }
          }
          setLoading(false);
        })
        .catch((error) => {
          console.error('events error', error);
          setLoading(false);
        });
    }
  }, [assetId, startingFromOption, startingFromTimestamp]);

  const fetchConsumption = useCallback(() => {
    if (assetId) {
      setLoading(true);
      api
        .get(
          `things/${assetId}/consumption${
            startingFromTimestamp
              ? `?starting_from=${startingFromTimestamp}`
              : ''
          }`
        )
        .then(({ data }) => {
          setConsumption(data);
          setLoading(false);
        })
        .catch((error) => {
          console.error('consumption error', error);
          setConsumption(undefined);
          setLoading(false);
        });
    }
  }, [assetId, startingFromTimestamp]);

  const fetchShadowHistory = useCallback(() => {
    if (assetId) {
      setLoading(true);
      api
        .get(
          `/things/${assetId}/shadows/${AWS_IOT_SHADOW}/history${
            startingFromTimestamp
              ? `?starting_from=${startingFromTimestamp}`
              : ''
          }`
        )
        .then(({ data }) => {
          setShadowHistory(data);
          setLoading(false);
        })
        .catch((error) => {
          console.error('shadows history error', error);
          setShadowHistory(undefined);
          setLoading(false);
        });
    }
  }, [assetId, startingFromTimestamp]);

  const refreshData = useCallback(() => {
    fetchShadow();
    fetchShadowHistory();
    fetchEvents();
    fetchConsumption();
  }, [fetchShadow, fetchShadowHistory, fetchEvents, fetchConsumption]);

  const handleOperatingMode: any = (event: any, newValue: number) => {
    setOperatingMode(newValue);
  };

  const handleSave = () => {
    api
      .post(`/things/${assetId}/shadows/${AWS_IOT_SHADOW}`, {
        state: {
          desired: {
            operating_mode: operatingMode,
          },
        },
      })
      .then(() => {
        fetchShadow();
        fetchShadowHistory();
      })
      .catch((error) => {
        console.error('shadows detail error', error);
      });
  };

  useEffect(() => {
    if (!assetId) {
      return;
    }
    fetchShadow();
    fetchShadowHistory();
    fetchConsumption();
    fetchEvents();
  }, [
    assetId,
    startingFromTimestamp,
    fetchShadow,
    fetchShadowHistory,
    fetchConsumption,
    fetchEvents,
  ]);

  const statusLines: LineProps[] = [
    {
      type: 'monotone',
      dataKey: t('assetDetail.stateOfCharge'),
      stroke: '#FF3333',
    },
    {
      type: 'monotone',
      dataKey: t('assetDetail.stateOfHealth'),
      stroke: '#00ab4c',
    },
  ];

  const getPumpLines = () => {
    const colors = ['#FF3333', '#00ab4c'];
    const graphs =
      shadow?.state.reported.extra_graphs || DEFAULT_PUMP_CHART_ITEMS;
    if (graphs.length) {
      return graphs.map((item, index) => {
        return {
          type: 'monotone',
          dataKey: item,
          stroke: colors[index],
        };
      });
    }
    return [];
  };

  const pumpChartLines = getPumpLines();

  useEffect(() => {
    if (shadowHistory) {
      const data = shadowHistory.map((item) => {
        return {
          timestamp: moment(item.timestamp).format('YYYY-MM-DD HH:mm'),
          battery_soc: item.state?.battery_soc,
          battery_soh: item.state?.battery_soh,
        };
      });
      setStatusChartData(data);
    }
  }, [shadowHistory]);

  useEffect(() => {
    if (shadowHistory) {
      const newPumpChartData = shadowHistory?.map((item) => {
        const newItem: any = {
          timestamp: moment(item.timestamp).format('YYYY-MM-DD HH:mm'),
        };
        const graphs =
          shadow?.state.reported.extra_graphs || DEFAULT_PUMP_CHART_ITEMS;
        if (graphs.length) {
          graphs.forEach((graphKey) => {
            if (item.state) {
              newItem[graphKey as string] = item.state[graphKey];
            }
          });
        }
        return newItem;
      });

      setPumpChartData(newPumpChartData);
    }
  }, [shadowHistory]);

  useEffect(() => {
    if (shadowHistory) {
      const data = shadowHistory.map((item) => {
        const x_position = item.state?.x_position;
        const y_position = item.state?.y_position;
        const theta_position = item.state?.theta_position;
        return {
          x_position,
          y_position,
          theta_position,
        };
      });
      setPositionChartData(data);
    }
  }, [shadowHistory]);

  const getTimeConsumed = () => {
    const seconds = consumption && consumption.seconds_consumed;

    if (seconds || seconds === 0) {
      if (seconds < 60) {
        return `${roundNumber(seconds, 2)} seconds`;
      }
      if (seconds === 60) {
        return `1 minute`;
      }
      const oneHour = 60 * 60;
      if (seconds === oneHour) {
        return `1 hour`;
      }
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = roundNumber(seconds - minutes * 60);
      return `${roundNumber(minutes)} minutes ${remainingSeconds} seconds`;
    }
    return undefined;
  };
  const timeConsumed = getTimeConsumed();

  const onToggleExpandEvent = (eventId: string) => {
    setAssetEvents((prevEvents) => {
      const needleEvent = prevEvents.find((item) => item.eventId === eventId);
      if (needleEvent) {
        needleEvent.isExpanded = !needleEvent.isExpanded;
      }
      return [...prevEvents];
    });
  };

  useEffect(() => {
    const getParameterValue = () => {
      if (startingFromOption === STARTING_FROM_OPTIONS.TODAY) {
        return moment().startOf('day').toISOString();
      }
      if (startingFromOption === STARTING_FROM_OPTIONS.THIS_CALENDAR_MONTH) {
        return moment().startOf('month').toISOString();
      }
      return;
    };
    const parameterValue = getParameterValue();
    setStartingFromTimestamp(parameterValue);
  }, [startingFromOption]);

  const assetType = shadow?.attributes?.dsfqtype as AssetType;
  const getAssetImage = () => {
    if (assetType === ASSET_TYPES.transporter) {
      return '/images/asset-transporter.png';
    }
    if (assetType === ASSET_TYPES.rotational) {
      // https://img5.goodfon.com/wallpaper/nbig/2/b7/metal-metallurgical-bench-drill.jpg
      return '/images/asset-drill.jpeg';
    }
    if (assetType === ASSET_TYPES.smartpump) {
      // https://grundfos.scene7.com/is/image/grundfos/course-the-industrial-pump-solutions-course-2650x1440-wide-master:560x314?wid=1470&hei=825&dpr=off
      return '/images/asset-pump.jpg';
    }
    return undefined;
  };
  const assetImageSrc = getAssetImage();

  const handleShadowUpdate = useCallback(
    (value) => {
      if (value.message) {
        if (value.message.battery_soc !== undefined) {
          setCurrentBatterySoc(value.message.battery_soc);
        }
        if (value.message.battery_soh !== undefined) {
          setCurrentBatterySoh(value.message.battery_soh);
        }

        if (value.message.operating_mode !== undefined) {
          setOperatingMode(value.message.operating_mode);
        }
        if (
          value.message.x_position !== undefined &&
          value.message.y_position !== undefined &&
          value.message.theta_position !== undefined
        ) {
          setPositionChartData((prevPositionData) => {
            const x_position = value.message.x_position;
            const y_position = value.message.y_position;
            const theta_position = value.message.theta_position;
            return [
              ...prevPositionData,
              {
                x_position,
                y_position,
                theta_position,
              },
            ];
          });
        }
        setShadow((prevShadow) => {
          if (prevShadow) {
            return {
              ...prevShadow,
              state: {
                ...prevShadow.state,
                reported: {
                  ...prevShadow?.state.reported,
                  ...value.message,
                },
              },
            };
          }
          return {
            state: {
              reported: value.message,
            },
          } as Shadow;
        });

        if (shadowHistory && value.message.machineTimestamp) {
          if (value.message.battery_soc && value.message.battery_soh) {
            const newStatusItem = {
              timestamp: moment(value.message.machineTimestamp).format(
                'YYYY-MM-DD HH:mm'
              ),
              battery_soc: value.message.battery_soc,
              battery_soh: value.message.battery_soh,
            };
            setStatusChartData((prevStatusData) => {
              return [...prevStatusData, newStatusItem];
            });
          }

          const newItem: any = {
            timestamp: moment(value.message.machineTimestamp).format(
              'YYYY-MM-DD HH:mm'
            ),
          };
          const graphs =
            shadow?.state.reported.extra_graphs || DEFAULT_PUMP_CHART_ITEMS;

          if (graphs.length) {
            graphs.forEach((graphKey) => {
              if (value.message[graphKey]) {
                newItem[graphKey as string] = value.message[graphKey];
              }
            });
          }

          setPumpChartData((prevChartData) => {
            return [
              ...prevChartData,
              {
                ...newItem,
              },
            ];
          });
        }
      }
      if (value.message?.consumption) {
        setConsumption((prevConsumption) => {
          if (prevConsumption) {
            return {
              ...prevConsumption,
              ...value.message.consumption,
            };
          }
          return value.message.consumption;
        });
      }
    },
    [shadow?.state.reported.extra_graphs, shadowHistory]
  );

  const flush = useCallback(() => {
    websocketMessages.forEach((value) => {
      if (value.event === 'onShadowUpdate') {
        if (!shadowHistory?.length) {
          fetchShadowHistory();
        }
        handleShadowUpdate(value);
      } else if (value.event === 'onEventUpdate') {
        if (value.message) {
          setAssetEvents((prevEvents) => {
            const eventData = prepareEventData([value.message]);
            return [...eventData, ...prevEvents];
          });
        }
      }
    });
    setLastUpdatedMessages(moment(new Date()));
    setWebsocketMessages([]);
  }, [
    websocketMessages,
    handleShadowUpdate,
    fetchShadowHistory,
    shadowHistory,
  ]);

  useEffect(() => {
    if (lastMessage && lastMessage.data) {
      setWebsocketMessages((prev) => [...prev, JSON.parse(lastMessage.data)]);
    }
  }, [lastMessage]);

  useEffect(() => {
    if (!lastUpdatedMessages) {
      flush();
    } else {
      const now = moment(new Date());
      const duration = moment.duration(now.diff(lastUpdatedMessages));
      const seconds = duration.asSeconds();

      if (seconds > 3) {
        flush();
      }
    }
  }, [websocketMessages, lastUpdatedMessages]);

  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      if (assetId) {
        sendMessage(
          JSON.stringify({
            action: 'getShadowStream',
            message: {
              assetName: assetId,
              eventType: 'onShadowUpdate',
            },
          })
        );
        sendMessage(
          JSON.stringify({
            action: 'getShadowStream',
            message: {
              assetName: assetId,
              eventType: 'onEventUpdate',
            },
          })
        );
      }
    }
  }, [readyState, assetId, sendMessage]);

  const SectionWrap: React.FC<{ inline?: boolean }> = ({
    children,
    inline = false,
  }) => {
    return (
      <Box
        sx={
          inline
            ? {
                display: 'flex',
                p: '0.5rem',
                backgroundColor: '#eee',
                background:
                  'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(238,238,238,1) 50%, rgba(255,255,255,1) 100%)',
                borderRadius: '5px',
                mb: '0.5rem',
                border: '1px solid #eee',
                alignItems: 'center',
              }
            : {}
        }
      >
        {children}
      </Box>
    );
  };

  const SectionTitle: React.FC<{ inline?: boolean }> = ({
    children,
    inline = false,
  }) => {
    return (
      <Box
        sx={
          inline
            ? {
                fontWeight: 'bold',
                fontSize: '1rem',
                width: '50%',
                textAlign: 'right',
                mr: '1rem',
              }
            : {
                fontWeight: 'bold',
                fontSize: '1rem',
                mt: '2rem',
                p: '1rem',
                backgroundColor: '#eee',
                backgroundImage: 'linear-gradient(to right, #eee , #fff)',
                borderRadius: '5px 5px 0 0',
                border: '1px solid #eee',
                borderBottomWidth: 0,
              }
        }
      >
        {children}
      </Box>
    );
  };

  const SectionContent: React.FC<{ inline?: boolean }> = ({
    children,
    inline = false,
  }) => {
    return (
      <Box
        sx={
          inline
            ? {}
            : {
                fontSize: '1rem',
                mb: '1rem',
                p: '1rem',
                borderRadius: '0 0 5px 5px',
                border: '1px solid #eee',
              }
        }
      >
        {children}
      </Box>
    );
  };

  return (
    <>
      <Box maxWidth="721px" margin="0 auto">
        <div>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            id="title_asset-detail"
          >
            <h1>{t('assetDetail.title')}</h1>
            <Button
              color="primary"
              variant="contained"
              id="button_detail-back"
              onClick={() => {
                history.push(DSFE_ASSET_LIST);
              }}
              startIcon={<ArrowBackIcon />}
            >
              {t('assetDetail.back')}
            </Button>
          </Box>
          <Box mt="2rem">
            <UiContentBox
              icon={
                <Box>
                  <PostAddIcon />
                </Box>
              }
              title={
                <Box
                  display="flex"
                  fontWeight="bold"
                  fontSize="1.125rem"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  {assetId}
                </Box>
              }
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                mb="1.8rem"
              >
                <FormControl
                  fullWidth
                  sx={{
                    marginRight: 'auto',
                    maxWidth: '250px',
                  }}
                  size="small"
                >
                  <InputLabel id="starting-from-select">
                    {t('assetDetail.startingFrom')}
                  </InputLabel>
                  <Select
                    labelId="starting-from-select"
                    id="demo-simple-select"
                    value={startingFromOption}
                    label="Starting From"
                    onChange={changeStartingFrom}
                  >
                    <MenuItem value={STARTING_FROM_OPTIONS.ALL}>All</MenuItem>
                    <MenuItem value={STARTING_FROM_OPTIONS.TODAY}>
                      {t('assetDetail.today')}
                    </MenuItem>
                    <MenuItem value={STARTING_FROM_OPTIONS.THIS_CALENDAR_MONTH}>
                      {t('assetDetail.thisCalendarMonth')}
                    </MenuItem>
                  </Select>
                </FormControl>
                <Button
                  variant="outlined"
                  color="error"
                  startIcon={<DeleteForeverIcon />}
                  onClick={() => {
                    setOpenConfirmDecom(true);
                  }}
                  sx={{
                    mr: '1rem',
                  }}
                  id="button_decommission"
                >
                  Decommission
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  id="button_refresh"
                  startIcon={<CachedIcon />}
                  onClick={refreshData}
                >
                  {t('assetDetail.refresh')}
                </Button>
              </Box>
              {loading ? (
                <Box display="flex" justifyContent="center" p="2rem">
                  <CircularProgress />
                </Box>
              ) : shadow ? (
                <Box>
                  {assetImageSrc ? (
                    <Box
                      sx={{
                        position: 'relative',
                        m: '-1rem -1rem -2rem -1rem',
                        zIndex: 0,
                      }}
                    >
                      <Box
                        sx={{
                          content: '""',
                          position: 'absolute',
                          top: '0',
                          left: '0',
                          right: '0',
                          bottom: 0,
                          background:
                            'linear-gradient(0deg, rgba(255,255,255,1), transparent 100%)',
                        }}
                      ></Box>

                      <img
                        src={assetImageSrc}
                        alt="Asset"
                        style={{
                          display: 'block',
                          width: '100%',
                        }}
                      />
                      {assetType === ASSET_TYPES.transporter ? (
                        <>
                          <UiBatteryStatus
                            label={t('assetDetail.batteryState')}
                            value={currentBatterySoc}
                            position={1}
                            alignRight={assetType !== ASSET_TYPES.transporter}
                          />
                          <UiBatteryStatus
                            label={t('assetDetail.batteryHealth')}
                            value={currentBatterySoh}
                            position={2}
                            alignRight={assetType !== ASSET_TYPES.transporter}
                          />
                        </>
                      ) : null}
                    </Box>
                  ) : null}

                  <Box sx={{ position: 'relative' }}>
                    {assetType !== ASSET_TYPES.smartpump ? (
                      <SectionWrap>
                        <SectionTitle>{t('assetDetail.position')}</SectionTitle>
                        <SectionContent>
                          {shadow.location?.name ? (
                            <Box
                              fontWeight={'bold'}
                              mb="0.5rem"
                              ml={positionChartData.length ? '5rem' : undefined}
                            >
                              {shadow.location?.name}
                            </Box>
                          ) : null}

                          <div>
                            {positionChartData.length ? (
                              <UiScatterChart
                                data={positionChartData}
                                xAxisKey="x_position"
                                xAxisLabel="x_position"
                                yAxisKey="y_position"
                                yAxisLabel="y_position"
                                maxXDomain={shadow.location?.max_x}
                                maxYDomain={shadow.location?.max_y}
                                showLegend={false}
                                showDirections={true}
                              />
                            ) : (
                              <>N/A</>
                            )}
                          </div>
                        </SectionContent>
                      </SectionWrap>
                    ) : null}

                    {assetType === ASSET_TYPES.smartpump && (
                      <SectionWrap>
                        <SectionTitle>
                          {t('assetDetail.visualData')}
                        </SectionTitle>
                        <SectionContent>
                          {pumpChartData.length ? (
                            <UiLineChart
                              xAxis="timestamp"
                              data={pumpChartData}
                              lines={pumpChartLines}
                            />
                          ) : (
                            <>N/A</>
                          )}
                        </SectionContent>
                      </SectionWrap>
                    )}
                    {assetType !== ASSET_TYPES.smartpump && (
                      <SectionWrap>
                        <SectionTitle>
                          {t('assetDetail.statusHistory')}
                        </SectionTitle>
                        <SectionContent>
                          {statusChartData.length ? (
                            <UiLineChart
                              xAxis="timestamp"
                              data={statusChartData}
                              lines={statusLines}
                            />
                          ) : (
                            <>N/A</>
                          )}
                        </SectionContent>
                      </SectionWrap>
                    )}
                    <SectionWrap>
                      <SectionTitle>
                        {t('assetDetail.operatingMode')}
                      </SectionTitle>
                      <SectionContent>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          sx={{ m: '0 auto' }}
                        >
                          <UiSlider
                            sliderColor={
                              shadow?.state?.delta?.operating_mode
                                ? 'red'
                                : undefined
                            }
                            onChange={handleOperatingMode}
                            value={operatingMode}
                            id="slider_operation-mode"
                          />
                          {shadow?.state?.reported?.operating_mode ? (
                            <Button
                              variant="contained"
                              color="secondary"
                              id="button_save"
                              startIcon={<SaveIcon />}
                              onClick={handleSave}
                              style={{
                                marginLeft: '2rem',
                                backgroundColor: shadow?.state?.delta
                                  ?.operating_mode
                                  ? 'red'
                                  : undefined,
                              }}
                            >
                              {t('assetDetail.save')}
                            </Button>
                          ) : null}
                        </Box>
                      </SectionContent>
                    </SectionWrap>
                    <SectionWrap>
                      <SectionTitle>
                        {t('assetDetail.assetEvents')}
                      </SectionTitle>
                      <SectionContent>
                        {assetEvents.length ? (
                          <Box
                            sx={{
                              maxHeight: '150px',
                              overflow: 'auto',
                              border: '1px solid rgba(224, 224, 224, 1)',
                              borderRadius: '5px',
                            }}
                          >
                            {assetEvents.map((item, index) => {
                              return (
                                <AssetEvent
                                  key={`event-${index}`}
                                  data={item}
                                  onToggleExpandEvent={onToggleExpandEvent}
                                />
                              );
                            })}
                          </Box>
                        ) : (
                          <Box>N/A</Box>
                        )}
                      </SectionContent>
                    </SectionWrap>

                    <SectionWrap inline>
                      <SectionTitle inline>
                        {t('assetDetail.assetType')}
                      </SectionTitle>
                      <SectionContent inline>
                        {formatAssetType(shadow.attributes)}
                      </SectionContent>
                    </SectionWrap>

                    {assetType === ASSET_TYPES.smartpump ? (
                      <SectionWrap inline>
                        <SectionTitle inline>
                          {t('assetDetail.location')}
                        </SectionTitle>
                        <SectionContent inline>
                          {shadow.location?.name}
                        </SectionContent>
                      </SectionWrap>
                    ) : null}

                    {assetType !== ASSET_TYPES.smartpump && (
                      <SectionWrap inline>
                        <SectionTitle inline>
                          {t('assetDetail.thetaOrientation')}
                        </SectionTitle>
                        <SectionContent inline>
                          {shadow?.state?.reported?.theta_position
                            ? `${roundNumber(
                                shadow?.state?.reported?.theta_position,
                                2
                              )}°`
                            : 'N/A'}
                        </SectionContent>
                      </SectionWrap>
                    )}

                    {assetType !== ASSET_TYPES.smartpump && (
                      <SectionWrap inline>
                        <SectionTitle inline>
                          {t('assetDetail.batteryReach')}
                        </SectionTitle>
                        <SectionContent inline>
                          {shadow?.state?.reported?.battery_reach || 'N/A'}
                        </SectionContent>
                      </SectionWrap>
                    )}
                    <SectionWrap inline>
                      <SectionTitle inline>
                        {t('assetDetail.inOperation')}
                      </SectionTitle>
                      <SectionContent inline>
                        {shadow?.state?.reported?.in_operation === true ? (
                          <Box display="flex" alignItems="center">
                            <CheckCircleIcon
                              style={{
                                color: green[500],
                                marginRight: '0.5rem',
                              }}
                            />

                            {t('assetDetail.yes')}
                          </Box>
                        ) : shadow?.state?.reported?.in_operation === false ? (
                          <Box display="flex" alignItems="center">
                            <CancelIcon
                              style={{ color: red[500], marginRight: '0.5rem' }}
                            />

                            {t('assetDetail.no')}
                          </Box>
                        ) : (
                          'N/A'
                        )}
                      </SectionContent>
                    </SectionWrap>

                    {assetType !== ASSET_TYPES.smartpump && (
                      <SectionWrap inline>
                        <SectionTitle inline>
                          {t('assetDetail.maximumSpeed')}
                        </SectionTitle>
                        <SectionContent inline>
                          {operatingMode ? (
                            <strong>
                              {operatingMode < 100 ? (
                                <span style={{ color: red[500] }}>
                                  {operatingMode / 100} m/s
                                </span>
                              ) : (
                                <span style={{ color: green[800] }}>1 m/s</span>
                              )}
                            </strong>
                          ) : (
                            'N/A'
                          )}
                        </SectionContent>
                      </SectionWrap>
                    )}
                    {assetType !== ASSET_TYPES.smartpump && (
                      <SectionWrap inline>
                        <SectionTitle inline>
                          {t('assetDetail.xPosition')}
                        </SectionTitle>
                        <SectionContent inline>
                          {shadow?.state?.reported?.x_position
                            ? `${roundNumber(
                                shadow?.state?.reported?.x_position,
                                2
                              )} m`
                            : 'N/A'}
                        </SectionContent>
                      </SectionWrap>
                    )}
                    {assetType !== ASSET_TYPES.smartpump && (
                      <SectionWrap inline>
                        <SectionTitle inline>
                          {t('assetDetail.yPosition')}
                        </SectionTitle>
                        <SectionContent inline>
                          {shadow?.state?.reported?.y_position
                            ? `${roundNumber(
                                shadow?.state?.reported?.y_position,
                                2
                              )} m`
                            : 'N/A'}
                        </SectionContent>
                      </SectionWrap>
                    )}
                    <SectionWrap inline>
                      <SectionTitle inline>
                        {t('assetDetail.lastUpdated')}
                      </SectionTitle>
                      <SectionContent inline>
                        <Box>
                          {shadow?.metadata?.reported?.operating_mode?.timestamp
                            ? moment
                                .unix(
                                  shadow?.metadata?.reported?.operating_mode
                                    ?.timestamp
                                )
                                .format('YYYY-MM-DD HH:mm')
                            : 'N/A'}
                        </Box>
                      </SectionContent>
                    </SectionWrap>
                    <SectionWrap inline>
                      <SectionTitle inline>
                        {t('assetDetail.cost')}
                      </SectionTitle>
                      <SectionContent inline>
                        <Box>
                          {operatingMode !== undefined && operatingMode < 50
                            ? '0.5 €/s'
                            : '0.007 €/s'}
                        </Box>
                      </SectionContent>
                    </SectionWrap>

                    {consumption ? (
                      <>
                        <SectionWrap inline>
                          <SectionTitle inline>
                            {t('assetDetail.historyPoints')}
                          </SectionTitle>
                          <SectionContent inline>
                            <Box>{consumption.history_points}</Box>
                          </SectionContent>
                        </SectionWrap>
                        <SectionWrap inline>
                          <SectionTitle inline>
                            {t('assetDetail.timeConsumed')}
                          </SectionTitle>
                          <SectionContent inline>
                            <Box>{timeConsumed || 'N/A'}</Box>
                          </SectionContent>
                        </SectionWrap>

                        {assetType !== ASSET_TYPES.smartpump && (
                          <SectionWrap inline>
                            <SectionTitle inline>
                              {t('assetDetail.distanceTravelled')}
                            </SectionTitle>
                            <SectionContent inline>
                              <Box>
                                {consumption.distance_travelled ||
                                consumption.distance_travelled === 0 ? (
                                  <>
                                    {roundNumber(
                                      consumption.distance_travelled,
                                      2
                                    )}{' '}
                                    m
                                  </>
                                ) : (
                                  'N/A'
                                )}
                              </Box>
                            </SectionContent>
                          </SectionWrap>
                        )}
                      </>
                    ) : null}
                    <SectionWrap inline>
                      <SectionTitle inline>
                        {t('assetDetail.version')}
                      </SectionTitle>
                      <SectionContent inline>
                        <Box>{shadow?.version || 'N/A'}</Box>
                      </SectionContent>
                    </SectionWrap>
                  </Box>
                </Box>
              ) : (
                <>
                  {error && (
                    <Alert
                      severity="error"
                      variant="standard"
                      sx={{ mb: '1rem' }}
                    >
                      {error}
                    </Alert>
                  )}
                  <Box>{t('assetDetail.noDataAvailable')}</Box>
                </>
              )}
            </UiContentBox>
          </Box>
        </div>
      </Box>
      <ConfirmDecommission
        open={openConfirmDecom}
        onClose={() => {
          setOpenConfirmDecom(false);
        }}
        loading={decomLoading}
        onConfirm={() => {
          setDecomLoading(true);
          api
            .delete(`/things/${assetId}`)
            .then(() => {
              setDecomLoading(false);
              setOpenConfirmDecom(false);
              history.push('/dsfe/decommissioned');
            })
            .catch(() => {
              console.error('Decommission error');
              setDecomLoading(false);
            });
        }}
      />
    </>
  );
};
