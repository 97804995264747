import { FaultItemListResponse } from '@dsf/util-types';
import { ActionsUnion, createAction } from '@dsf/util-tools';

export const UPDATE_ITEMS_TO_DISPLAY = 'UPDATE_ITEMS_TO_DISPLAY';

export const updateItemsToDisplay = (payload: FaultItemListResponse[]) =>
  createAction(UPDATE_ITEMS_TO_DISPLAY, payload);

export const FaultListActions = {
  updateItemsToDisplay,
};

export type FaultListActionsType = ActionsUnion<typeof FaultListActions>;
