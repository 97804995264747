import { useUserApi } from '@dsf/data-access-api';
import { PRODUCTION_SEGMENT_CHECK_IN } from '@dsf/util-router';

import { Button } from '@material-ui/core';
import { Box } from '@mui/material';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import { LocationType } from '@dsf/util-types';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiSkeleton-wave::after': {
        background:
          'linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.08), transparent)',
      },
    },
    button: {
      justifyContent: 'flex-start',
      padding: '0.5rem 1rem',
      textTransform: 'none',
      '& .MuiButton-startIcon': {
        marginRight: '0.75rem',
      },
    },
    indicatorWrap: {
      position: 'relative',
      width: '13px',
      height: '13px',

      borderRadius: '50%',

      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: '$ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
    greenDot: {
      backgroundColor: '#44b700',
      color: '#44b700',
    },
    redDot: {
      backgroundColor: '#ff0000',
      color: '#ff0000',
    },
  })
);

type Props = {
  navigate: (route: string) => void;
  disabled?: boolean;
};

export const CheckinIndicator: React.FC<Props> = ({ navigate, disabled }) => {
  const classes = useStyles();
  const userApi = useUserApi();
  const [loading, setLoading] = useState(true);
  const [selectedLocation, setSelectedLocation] = useState<LocationType>();
  const { t } = useTranslation();

  useEffect(() => {
    const getCurrentLocation = async () => {
      setLoading(true);
      try {
        const response = await userApi.getUserProfileLocation();
        setSelectedLocation(response.data);
        setLoading(false);
      } catch (error) {
        console.error('getCurrentLocation error', error);
        setLoading(false);
      }
    };
    getCurrentLocation();
    // eslint-disable-next-line
  }, []);

  const handleClick = () => {
    navigate(PRODUCTION_SEGMENT_CHECK_IN);
  };

  return (
    <Box
      width="227px"
      m="0.5rem 2rem"
      className={disabled ? undefined : classes.root}
      sx={
        disabled
          ? {
              opacity: 0.5,
            }
          : undefined
      }
    >
      {loading ? (
        <Box
          style={{ background: 'rgba(255, 255, 255, 0.13)' }}
          width={227}
          height={51}
          borderRadius="4px"
        >
          <Skeleton
            variant="rect"
            animation="wave"
            width={'100%'}
            height={'100%'}
          />
        </Box>
      ) : (
        <Box
          style={
            disabled
              ? { color: 'grey' }
              : {
                  color: selectedLocation ? '#44b700' : '#ff0000',
                }
          }
        >
          <Button
            variant="outlined"
            size="small"
            color="inherit"
            onClick={handleClick}
            fullWidth
            startIcon={
              disabled ? undefined : (
                <div
                  className={clsx([
                    classes.indicatorWrap,
                    selectedLocation ? classes.greenDot : classes.redDot,
                  ])}
                ></div>
              )
            }
            className={classes.button}
          >
            <Box textAlign="left" lineHeight="1.4">
              {selectedLocation ? (
                <>
                  <Box
                    style={{
                      textTransform: 'uppercase',
                      opacity: 0.5,
                      fontSize: '0.7rem',
                    }}
                  >
                    {t('checkinIndicator.checkinLocation')}
                  </Box>
                  <Box>{selectedLocation.segmentName}</Box>
                </>
              ) : (
                <>
                  <Box
                    style={{
                      textTransform: 'uppercase',
                      opacity: 0.7,
                      fontSize: '0.7rem',
                    }}
                  >
                    {t('checkinIndicator.checkinLocation')}
                  </Box>
                  <Box>{t('checkinIndicator.notSelected')}</Box>
                </>
              )}
            </Box>
          </Button>
        </Box>
      )}
    </Box>
  );
};
