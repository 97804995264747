import { API, APIResponse, useApi } from '..';
import { ENDPOINT_PROFILE_IMAGE } from '..';
import { AxiosResponse } from 'axios';

const BASE = ENDPOINT_PROFILE_IMAGE;
export const getProfileImagePath = (): string => `${BASE}`;
export const postProfileImagePath = (): string => `${BASE}`;

export interface ProfileImageAPI extends API {
  getProfileImage: () => Promise<AxiosResponse<Blob>>;
  // eslint-disable-next-line
  postProfileImage: (data: Blob) => APIResponse<any>;
}

/*
 * Deprecated
 * */
export const useProfileImageApi = (): ProfileImageAPI => {
  const api = useApi();

  return {
    ...api,
    getProfileImage() {
      const cancelToken = api.prepareCancelToken('getImage');
      return api.authRequest.get(getProfileImagePath(), {
        cancelToken,
        responseType: 'blob',
      });
    },
    postProfileImage(data: Blob) {
      const formData = new FormData();
      formData.append('file', data);
      const cancelToken = api.prepareCancelToken('postImage');
      return api.authRequest.post(postProfileImagePath(), formData, {
        cancelToken,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },
  };
};
