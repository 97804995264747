import React from 'react';

export interface Props {
  className?: string;
}

export const ElectricalIcon: React.FC<Props> = ({ className }: Props) => {
  return (
    <svg
      className={className}
      width="11"
      height="20"
      viewBox="0 0 11 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.83333 0.833328L0 11.6667H5V19.1667L10.8333 8.33333H5.83333V0.833328Z"
        fill="#2D2F36"
      />
    </svg>
  );
};
