import { extractRoleFromToken, USER_ROLES } from '@dsf/util-tools';
import {
  State,
  hasFeature,
  identitySlice,
  store,
} from '@dsf/data-access-store';
import {
  LOGOUT,
  NEW_TENANT,
  NEWS,
  TENANT,
  DSFE_ASSET_LIST,
  LOCATIONS,
  FIELD_OVERVIEW,
  USER_LIST_PAGE,
  CHAT_PAGE,
} from '@dsf/util-router';
import { getCurrentLanguage } from '@dsf/util-translate';

import { NewsIcon, UsersIcon } from '@dsf/ui-assets';
import { PageProps } from '@dsf/ui-layout';
import {
  Box,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import PinDropIcon from '@mui/icons-material/PinDrop';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import BrushIcon from '@material-ui/icons/Brush';
import ChatIcon from '@mui/icons-material/Chat';
import Divider from '@material-ui/core/Divider';
import DrawerMat, { DrawerProps } from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import { ArrowBack, ExitToApp } from '@material-ui/icons';
import TranslateIcon from '@material-ui/icons/Translate';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
// import packageJson from '../../../package.json';
import { getAdministrationItems } from './administration-items';
import MenuItem from './menu-item';
import { getProductionItems } from './production-items';
import useChangeLanguage from './languages';
import { MenuItemType } from './menu-item';
import { CheckinIndicator } from '..';
import { DsfeLogo } from '@dsf/ui-assets';

const SHOW_CHAT = false;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiDrawer-paper': {
        color: theme.palette.common.white,
        backgroundColor: 'var(--primary-color)',
      },
      '& .MuiListItem-root': {
        borderRadius: '4px',
      },
      '& .MuiListItemIcon-root': {
        color: theme.palette.common.white,
      },
      '& .MuiListItem-button:hover': {
        backgroundColor: 'var(--secondary-color)',
      },
    },
    list: {
      padding: '0 1rem',
    },
    subheader: {
      color: theme.palette.secondary.main,
      textTransform: 'uppercase',
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    divider: {
      backgroundColor: 'rgba(255, 255, 255, 0.20)',
      marginTop: '2rem',
    },
    backButton: {
      background: 'var(--grey)',
      width: 56,
      height: 56,
      borderRadius: 0,
      color: '#fff',
      '&:hover': {
        background: 'rgba(74, 79, 94, 0.62)',
      },
    },
  })
);

type Props = DrawerProps & {
  closeDrawer: () => void;
  pageProps: PageProps;
};

export const Drawer: React.FC<Props> = ({
  closeDrawer,
  pageProps,
  ...props
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();

  const { tenantConfig: settings } = useSelector(
    (state: State) => state.appSettings
  );
  const customLogo = settings?.settings?.logo;
  const isDsfe = location.pathname.indexOf('/dsfe') !== -1;

  const { accessToken, roles } = useSelector(
    (state: State) => state.userTokens
  );
  const isAdmin = roles.includes(USER_ROLES.admin);
  const lang = getCurrentLanguage();
  const changeLanguage = useChangeLanguage();

  // eslint-disable-next-line
  const [buildInfo, setBuildInfo] = React.useState<any>({});
  // eslint-disable-next-line
  const [langSetting, setLangSetting] = React.useState<any>(lang);

  const getLanguagueItems = (
    navigate: (route: string) => void
  ): MenuItemType[] => [
    {
      label: langSetting.toUpperCase(),
      icon: () => <TranslateIcon />,
      id: 'drawer_production',
      subItems: [
        {
          label: 'drawerNav.en',
          onClick: () => {
            const lang = 'en';
            setLangSetting(lang);
            switchLang(lang);
          },
          id: 'drawer_production_checkIn',
        },
        {
          label: 'drawerNav.zh',
          onClick: () => {
            const lang = 'zh';
            setLangSetting(lang);
            switchLang(lang);
          },
          id: 'drawer_production_productOverview',
        },
        {
          label: 'drawerNav.de',
          onClick: () => {
            const lang = 'de';
            setLangSetting(lang);
            switchLang(lang);
          },
          id: 'drawer_production_identifyProduct',
        },
        {
          label: 'drawerNav.da',
          onClick: () => {
            const lang = 'da';
            setLangSetting(lang);
            switchLang(lang);
          },
          id: 'drawer_production_identifyProduct',
        },
        {
          label: 'drawerNav.pt',
          onClick: () => {
            const lang = 'pt';
            setLangSetting(lang);
            switchLang(lang);
          },
          id: 'drawer_production_identifyProduct',
        },
      ],
    },
  ];

  // eslint-disable-next-line
  const switchLang = async (lang: any) => {
    await changeLanguage(lang);
    closeDrawer();
  };

  const handleBackButton = () => {
    closeDrawer();
  };

  const navigate = (route: string) => {
    if (history && history.location && history.location.pathname === route) {
      closeDrawer();
      return;
    }
    history.push(route);
  };

  useEffect(() => {
    fetch('/info/buildinfo/buildinfo.json', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((r) => r.json())
      .then((data) => {
        setBuildInfo(data);
      });

    // TODO: Implement after we have terms and conditions
    // if (!window.sessionStorage.getItem('tac')) {
    //   setOpenDrawer(true);
    // }
  }, []);

  const topLevelItems = {
    news: {
      label: 'drawerNav.news',
      icon: () => <NewsIcon />,
      id: 'drawer_news',
      onClick: () => {
        navigate(NEWS);
      },
    },
    userManagement: {
      label: 'drawerNav.userManagement',
      icon: () => <UsersIcon />,
      id: 'drawer_userManagement',
      onClick: () => {
        navigate(USER_LIST_PAGE);
      },
    },
    customerOverview: {
      label: 'drawerNav.customerOverview',
      icon: () => <NewsIcon />,
      id: 'drawer_customerOverview',
      onClick: () => {
        navigate(TENANT);
      },
    },
    tryIt: {
      label: 'drawerNav.tryIt',
      icon: () => <NewsIcon />,
      id: 'drawer_tryIt',
      onClick: () => {
        navigate(NEW_TENANT);
      },
    },
    equipment: {
      label: 'drawerNav.equipment',
      icon: () => <BrushIcon />,
      id: 'drawer_equipment',
      onClick: () => {
        navigate(DSFE_ASSET_LIST);
      },
    },
    locations: {
      label: 'drawerNav.locations',
      icon: () => <MyLocationIcon />,
      id: 'drawer_locations',
      onClick: () => {
        navigate(LOCATIONS);
      },
    },
    fieldOverview: {
      label: 'drawerNav.fieldOverview',
      icon: () => <PinDropIcon />,
      id: 'drawer_fieldOverview',
      onClick: () => {
        navigate(FIELD_OVERVIEW);
      },
    },
    logout: {
      label: 'global.logout',
      icon: () => <ExitToApp />,
      onClick: () => {
        store.dispatch(identitySlice.actions.setRedirectUrl(location.pathname));
        navigate(LOGOUT);
      },
      id: 'drawer_legacy_logout',
    },
    chat: {
      label: 'drawerNav.chat',
      icon: () => <ChatIcon />,
      id: 'drawer_chat',
      onClick: () => {
        navigate(CHAT_PAGE);
      },
    },
  };

  return (
    <DrawerMat {...props} className={classes.root}>
      <div>
        <Box display="flex">
          <Box>
            <IconButton
              id="drawer_backButton"
              className={classes.backButton}
              onClick={handleBackButton}
            >
              <ArrowBack />
            </IconButton>
          </Box>
          <Box display="flex" justifyContent="center" margin="auto">
            {customLogo && roles.includes(USER_ROLES.grundfos_data_analyst) ? (
              <Box
                component="span"
                sx={{
                  m: '-6px 0 0px 0',
                  borderRadius: '5px',
                  display: 'inline-flex',
                  position: 'relative',
                  top: '4px',
                }}
                style={{
                  filter: 'invert(1) grayscale(1) brightness(2)',
                }}
              >
                <img
                  alt="Custom Logo"
                  src={customLogo}
                  width="auto"
                  height="64px"
                />
              </Box>
            ) : isDsfe ? (
              <img alt="DSFE Logo" src={DsfeLogo} width={83} />
            ) : (
              <img alt="DSFQ Logo" src={'/logo/logo.png'} width={83} />
            )}
          </Box>
        </Box>
        <Box
          my="2rem"
          style={
            roles.includes(USER_ROLES.grundfos_data_analyst)
              ? {
                  pointerEvents: 'none',
                }
              : undefined
          }
        >
          <CheckinIndicator
            navigate={navigate}
            disabled={roles.includes(USER_ROLES.grundfos_data_analyst)}
          />
        </Box>
        <Box
          mt="1rem"
          style={
            roles.includes(USER_ROLES.grundfos_data_analyst)
              ? { pointerEvents: 'none', opacity: 0.2 }
              : undefined
          }
        >
          <List className={classes.list}>
            <MenuItem
              key={topLevelItems.news.label}
              item={topLevelItems.news}
            />
          </List>
        </Box>
        {hasFeature('DSF/Q') ? (
          <List className={classes.list}>
            {getProductionItems(navigate).map((item) => {
              return <MenuItem key={item.label} item={item} />;
            })}
          </List>
        ) : null}
        {/* TODO: Will be added in the future */}
        {/* <List className={classes.list}>
          {getProductionAdministrationItems(history).map((item) => {
            return <MenuItem key={item.label} item={item} />;
          })}
        </List> */}
        <Box
          style={
            roles.includes(USER_ROLES.grundfos_data_analyst)
              ? { pointerEvents: 'none', opacity: 0.2 }
              : undefined
          }
        >
          <List
            className={classes.list}
            //   subheader={
            //     <ListSubheader disableSticky className={classes.subheader}>
            //       Administration
            //     </ListSubheader>
            //   }
          >
            {getAdministrationItems(navigate)
              .map((item) => {
                if (isAdmin) {
                  return item;
                }

                return {
                  ...item,
                  subItems: item.subItems?.filter(
                    (subItem) => subItem.visibility !== USER_ROLES.admin
                  ),
                };
              })
              .map((item) => {
                return <MenuItem key={item.label} item={item} />;
              })}
          </List>
        </Box>

        {isAdmin && !roles.includes(USER_ROLES.grundfos_data_analyst) ? (
          <Box mt="1rem">
            <List className={classes.list}>
              <MenuItem
                key={topLevelItems.tryIt.label}
                item={topLevelItems.tryIt}
              />
            </List>
          </Box>
        ) : null}
        {isAdmin && roles.includes(USER_ROLES.grundfos_data_analyst) ? (
          <Box mt="1rem">
            <List className={classes.list}>
              <MenuItem
                key={topLevelItems.userManagement.label}
                item={topLevelItems.userManagement}
              />
            </List>
          </Box>
        ) : null}
        {SHOW_CHAT ? (
          <Box mt="1rem">
            <List className={classes.list}>
              <MenuItem
                key={topLevelItems.chat.label}
                item={topLevelItems.chat}
              />
            </List>
          </Box>
        ) : null}
        {hasFeature('DSF/E') ? (
          <Box mt="1rem">
            <List className={classes.list}>
              <MenuItem
                key={topLevelItems.equipment.label}
                item={topLevelItems.equipment}
              />
            </List>
          </Box>
        ) : null}
        <Box mt="1rem">
          <List className={classes.list}>
            <MenuItem
              key={topLevelItems.locations.label}
              item={topLevelItems.locations}
            />
          </List>
        </Box>
        {hasFeature('DSF/E') ? (
          <Box mt="1rem">
            <List className={classes.list}>
              <MenuItem
                key={topLevelItems.fieldOverview.label}
                item={topLevelItems.fieldOverview}
              />
            </List>
          </Box>
        ) : null}
        <Divider className={classes.divider} />
        <Box paddingTop="2rem">
          <List className={classes.list}>
            <MenuItem
              key={topLevelItems.logout.label}
              item={topLevelItems.logout}
            />
          </List>
        </Box>
        <Divider className={classes.divider} />
        <Box paddingTop="2rem">
          <List className={classes.list}>
            {getLanguagueItems(navigate).map((item) => {
              return <MenuItem key={item.label} item={item} />;
            })}
          </List>
        </Box>
        <Divider className={classes.divider} />
        <Box mt="-2rem">
          <Divider className={classes.divider} />
        </Box>
        <Box mt="1rem" p="1rem" color={theme.palette.grey[500]}>
          <Box>
            {t('drawerNav.build')}: {buildInfo.buildNumber}
          </Box>
          <Box>
            {t('drawerNav.page')}: {pageProps.pageName || '---'}
          </Box>
          {/* TODO: jm - uncomment and make this work again */}
          {/* <Box>Bundle: {packageJson.version}</Box> */}
        </Box>
      </div>
    </DrawerMat>
  );
};
