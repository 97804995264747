import { State } from '@dsf/data-access-store';

import { TENANT_PROFILE } from '@dsf/util-router';

import {
  Avatar,
  Box,
  Button,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { CogIcon } from '@dsf/ui-assets';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      width: 35,
      height: 35,
    },
    userName: {
      fontSize: '0.875rem',
      fontWeight: 'bold',
    },
    userData: {
      fontSize: '0.75rem',
    },
    textLink: {
      color: theme.palette.secondary.main,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  })
);

const ProfileBadge: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { profile } = useSelector((state: State) => state.identity);

  const handleManageProfile = () => {
    history.push(TENANT_PROFILE);
  };

  if (!profile) {
    return null;
  }

  return (
    <Box padding="1rem">
      <Box display="flex">
        <Box mr="1rem">
          <Avatar alt="Profile Picture" className={classes.avatar} />
        </Box>
        <Box>
          <div className={classes.userName}>
            {profile.firstName} {profile.lastName}
          </div>
          <div className={classes.userData}>
            <div className={classes.textLink}>{profile.email}</div>
            <Box mt="1rem">
              <Button
                variant="text"
                color="inherit"
                startIcon={<CogIcon />}
                onClick={handleManageProfile}
              >
                Manage your profile
              </Button>
            </Box>
          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default ProfileBadge;
