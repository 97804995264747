import { ButtonDiv, ContentBox } from '../../../../components';
import { Box, Collapse, createStyles, makeStyles } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FaultItemDiscussion from './FaultItemDiscussion';

const PRODUCT_DETAIL_LINE_COLOR = 'rgba(0, 0, 0, 0.6)';

const ASSET_PICTURE_RADIUS = '4px';

const useStyles = makeStyles(() =>
  createStyles({
    toggleSection: {
      fontSize: '0.65rem',
      color: PRODUCT_DETAIL_LINE_COLOR,
      '& path': {
        fill: PRODUCT_DETAIL_LINE_COLOR,
      },
    },
  })
);

type Props = {
  faultId: string;
};

const ProductDetail: React.FC<Props> = ({ faultId }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [showDiscussion, setShowDiscussion] = useState(true);

  const handleToggleAssetDetails = () => {
    setShowDiscussion((prev) => !prev);
  };

  return (
    <ContentBox
      icon={<QuestionAnswerIcon />}
      title={
        <Box display="flex" justifyContent="space-between">
          <Box>{t('faultDetailPage.discussion.title')}</Box>
          <Box
            className={classes.toggleSection}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <ButtonDiv onClick={handleToggleAssetDetails}>
              <Box display="flex" alignItems="center">
                <Box mr="0.3rem">
                  {t(
                    showDiscussion
                      ? 'faultDetailPage.collapse'
                      : 'faultDetailPage.expand'
                  )}
                </Box>
                {showDiscussion ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </Box>
            </ButtonDiv>
          </Box>
        </Box>
      }
    >
      <Collapse in={showDiscussion}>
        <FaultItemDiscussion id={faultId} />
      </Collapse>
    </ContentBox>
  );
};

export default ProductDetail;
