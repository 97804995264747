import React, { useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ListIcon from '@mui/icons-material/List';
import Alert from '@mui/material/Alert';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { UiDialogScanner } from '@dsf/ui-dialog-scanner';
import { AssetType, ASSET_TYPES } from '@dsf/feature-dsfe';
import { useGeolocation } from 'react-use';
import CircularProgress from '@mui/material/CircularProgress';
import WarningIcon from '@mui/icons-material/Warning';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import { LocationType } from '@dsf/ui-location-list';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { useJsApiLoader } from '@react-google-maps/api';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Link } from '@mui/material';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import SendIcon from '@mui/icons-material/Send';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { OnResultFunction } from 'react-qr-reader';
import { useWrapApi } from '@dsf/data-access-api';
import { DSFE_ASSET_LIST } from '@dsf/util-router';
import { AppSettingsState } from '@dsf/data-access-store';

type EstimateLocationType = {
  city: string;
  country: string;
  latitude: number;
  longitude: number;
};

type CompanyType = {
  id: string;
  name: string;
};

type Props = {
  secrets: AppSettingsState['secrets'];
};

export const UiAssetWizard: React.FC<Props> = ({ secrets }) => {
  const { api } = useWrapApi();

  const { t } = useTranslation();
  const geoLocation = useGeolocation();
  const history = useHistory();

  const [locations, setLocations] = useState<LocationType[]>([]);
  const [estimateLocation, setEstimateLocation] =
    useState<EstimateLocationType | null>(null);
  const [loadingLocations, setLoadingLocations] = useState(false);
  const [locationsError, setLocationsError] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState<LocationType | null>(
    null
  );
  const [loadingCompanies, setLoadingCompanies] = useState(false);
  const [companies, setCompanies] = useState<CompanyType[]>([]);
  const [companiesError, setCompaniesError] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<CompanyType | null>(
    null
  );

  const [activeStep, setActiveStep] = useState(0);
  const [lastStep, setLastStep] = useState(0);
  const [showScanner, setShowScanner] = useState(false);
  const [assetSerial, setAssetSerial] = useState<string>();
  const [assetType, setAssetType] = useState<AssetType | null>();

  const [loadingRegister, setLoadingRegister] = useState(false);
  const [registerSuccess, setRegisterSuccess] = useState<boolean | undefined>(
    undefined
  );
  const [registerError, setRegisterError] = useState<string>();

  const fetchLocations = () => {
    setLocationsError(false);
    setLoadingLocations(true);
    api
      .get('/locations')
      .then(({ data }) => {
        setLocations(data);
        setLoadingLocations(false);
      })
      .catch((error) => {
        console.error('locations error', error);
        setLocations([]);
        setLoadingLocations(false);
        setLocationsError(true);
      });
  };

  const fetchCompanies = () => {
    setCompaniesError(false);
    setLoadingCompanies(true);
    api
      .get('/companies')
      .then(({ data }) => {
        setCompanies(data?.companies);
        setLoadingCompanies(false);
      })
      .catch((error) => {
        console.error('companies error', error);
        setCompaniesError(true);
        setCompanies([]);
        setLoadingCompanies(false);
      });
  };

  const registerAsset = useCallback(() => {
    setRegisterSuccess(false);
    if (!assetSerial) {
      setRegisterError('Missing serial number, please provide it.');
      return;
    }
    if (!selectedCompany || !selectedCompany?.id) {
      setRegisterError('Missing company, please select a company.');
      return;
    }
    if (!selectedLocation || !selectedLocation?.thingName) {
      setRegisterError('Missing location, please select a location.');
      return;
    }
    if (!assetType) {
      setRegisterError('Missing asset type, please select an asset type.');
      return;
    }
    setLoadingRegister(true);
    api
      .post(`/things/${assetSerial}/register`, {
        company: selectedCompany?.id,
        dsfqtype: assetType,
        location: selectedLocation?.thingName,
      })
      .then(() => {
        setLoadingRegister(false);
        setRegisterSuccess(true);
        setRegisterError(undefined);
      })
      .catch((error) => {
        console.error('register error', error);
        setLoadingRegister(false);
        setRegisterError(undefined);
      });
  }, [assetSerial, selectedCompany, assetType, selectedLocation]);

  const onScan: OnResultFunction = (result, error) => {
    if (!result || error) {
      return;
    }
    const splitData = result.getText()?.split('DSF-PA-');
    const assetSerial = splitData[1];
    if (assetSerial) {
      fetchCompanies();
      setAssetSerial(assetSerial);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setShowScanner(false);
    }
  };

  const handleNext = () => {
    if (activeStep === 0) {
      fetchLocations();
    }
    if (activeStep === 2) {
      setShowScanner(true);
    } else if (activeStep === 3) {
      fetchCompanies();
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      registerAsset();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setLastStep(0);
    setSelectedLocation(null);
    setAssetSerial(undefined);
    setAssetType(undefined);
    setRegisterSuccess(false);
    setRegisterError(undefined);
  };

  // helper function
  const getLocationCoordinate = (location: LocationType) => {
    const cityLatitude = +location.attributes['latitude'];
    const cityLongitude = +location.attributes['longitude'];
    const cityAxisValid = !isNaN(cityLatitude) && !isNaN(cityLongitude);

    if (cityAxisValid) {
      return {
        latitude: cityLatitude,
        longitude: cityLongitude,
      };
    } else {
      return null;
    }
  };

  // Earth is a sphere!
  const getCoordinateDistance = (
    lat1: number,
    long1: number,
    lat2: number,
    long2: number
  ) => {
    const angle1 = (lat1 * Math.PI) / 180; // φ, λ in radians
    const angle2 = (lat2 * Math.PI) / 180;
    const deltaLatitudeAngle = ((lat2 - lat1) * Math.PI) / 180;
    const deltaLongitudeAngle = ((long2 - long1) * Math.PI) / 180;

    const a =
      Math.sin(deltaLatitudeAngle / 2) * Math.sin(deltaLatitudeAngle / 2) +
      Math.cos(angle1) *
        Math.cos(angle2) *
        Math.sin(deltaLongitudeAngle / 2) *
        Math.sin(deltaLongitudeAngle / 2);
    return 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  };

  // TODO: jm - call this in a separate child component only if secrets?.gmap_api_key is set
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: secrets?.gmap_api_key || '',
  });

  useEffect(() => {
    if (activeStep !== 0 && activeStep > lastStep) {
      setLastStep(activeStep);
    }
  }, [activeStep, lastStep]);

  useEffect(() => {
    if (estimateLocation) {
      const userLatitude: number = estimateLocation.latitude;
      const userLongitude: number = estimateLocation.longitude;

      let bestMatch: LocationType | null = null;
      let bestMatchResult = 0;

      for (const location of locations) {
        const cityCoordinate = getLocationCoordinate(location);
        if (cityCoordinate == null) {
          console.log(
            'Location',
            location.thingName,
            'has no coordinate (latitude, longitude) set, which will never be auto-selected.'
          );
        } else {
          const currentCityInterval = getCoordinateDistance(
            userLatitude,
            userLongitude,
            cityCoordinate.latitude,
            cityCoordinate.longitude
          );

          if (bestMatch == null) {
            bestMatch = location;
            bestMatchResult = currentCityInterval;
          } else if (currentCityInterval < bestMatchResult) {
            bestMatch = location;
            bestMatchResult = currentCityInterval;
          }
        }
      }

      if (bestMatch) {
        setSelectedLocation(bestMatch);
      }
    }
  }, [locations, estimateLocation]);

  const getContinueLabel = (index: number) => {
    if (index === 2) {
      return { label: t('assetWizard.startScan'), startIcon: <QrCode2Icon /> };
    }
    if (index === 3) {
      return { label: t('assetWizard.registerAsset'), startIcon: <SendIcon /> };
    }
    return { label: t('global.continue'), endIcon: <ArrowForwardIosIcon /> };
  };

  useEffect(() => {
    if (
      !geoLocation ||
      !geoLocation.latitude ||
      !geoLocation.longitude ||
      !secrets?.gmap_api_key ||
      !isLoaded
    ) {
      return;
    }

    setTimeout(() => {
      const latlng = new google.maps.LatLng(
        geoLocation.latitude,
        geoLocation.longitude
      );

      new google.maps.Geocoder().geocode(
        { location: latlng },
        function (results, status) {
          if (status === google.maps.GeocoderStatus.OK) {
            if (results && results[1]) {
              let country = null,
                countryCode = null,
                city = null,
                cityAlt = null;
              let c, lc, component;
              for (let r = 0, rl = results.length; r < rl; r += 1) {
                const result = results[r];

                if (!city && result.types[0] === 'locality') {
                  for (
                    c = 0, lc = result.address_components.length;
                    c < lc;
                    c += 1
                  ) {
                    component = result.address_components[c];

                    if (component.types[0] === 'locality') {
                      city = component.long_name;
                      break;
                    }
                  }
                } else if (
                  !city &&
                  !cityAlt &&
                  result.types[0] === 'administrative_area_level_1'
                ) {
                  for (
                    c = 0, lc = result.address_components.length;
                    c < lc;
                    c += 1
                  ) {
                    component = result.address_components[c];

                    if (component.types[0] === 'administrative_area_level_1') {
                      cityAlt = component.long_name;
                      break;
                    }
                  }
                } else if (!country && result.types[0] === 'country') {
                  country = result.address_components[0].long_name;
                  countryCode = result.address_components[0].short_name;
                }

                if (city && country) {
                  break;
                }
              }

              setEstimateLocation({
                city: city ?? '',
                country: country ?? '',
                latitude: geoLocation.latitude ?? 0,
                longitude: geoLocation.longitude ?? 0,
              });
              // console.log(
              //   'City: ' +
              //     city +
              //     ', City2: ' +
              //     cityAlt +
              //     ', Country: ' +
              //     country +
              //     ', Country Code: ' +
              //     countryCode
              // );
            }
          }
        }
      );
    }, 250);
  }, [secrets, geoLocation, isLoaded]);

  const steps = [
    {
      label: t('assetWizard.allowLocation'),
      description: () => {
        return (
          <>
            <Box sx={{ mb: '1rem' }}>{t('assetWizard.allowLocation')}</Box>
            <Box sx={{ mb: '1rem' }}>
              {geoLocation.loading && (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <CircularProgress size="20px" sx={{ mr: '0.5rem' }} />
                  {t('assetWizard.loadingLocation')}
                </Box>
              )}
              {!geoLocation.loading && geoLocation.error?.message && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    fontWeight: 'bold',
                    color: 'red',
                  }}
                >
                  <WarningIcon sx={{ mr: '0.5rem' }} />
                  {t('assetWizard.locationFail')}
                </Box>
              )}
              {!geoLocation.loading &&
                geoLocation.latitude &&
                geoLocation.longitude && (
                  <Box>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        fontWeight: 'bold',
                      }}
                    >
                      <MyLocationIcon sx={{ mr: '0.5rem' }} />
                      <Box>{t('assetWizard.yourLocation')}</Box>
                    </Box>
                    <Box sx={{ ml: '2.0rem', mt: '0.5rem' }}>
                      {t('assetWizard.lat')}: {geoLocation.latitude}
                      <br />
                      {t('assetWizard.long')}: {geoLocation.longitude}
                      {estimateLocation && (
                        <>
                          <br />
                          {t('assetWizard.city')}: {estimateLocation.city}
                          <br />
                          {t('assetWizard.country')}: {estimateLocation.country}
                        </>
                      )}
                    </Box>
                  </Box>
                )}
            </Box>
          </>
        );
      },
    },
    {
      label: t('assetWizard.chooseAssetLocation'),
      description: () => {
        return (
          <div>
            <Box mb="1rem">{t('assetWizard.chooseAssetLocationText')}</Box>
            <Box mb="1rem">
              {locationsError && (
                <Alert severity="error" variant="standard" sx={{ mb: '1rem' }}>
                  Error: Unable to retrieve locations.
                </Alert>
              )}
              {loadingLocations ? (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <CircularProgress size="20px" sx={{ mr: '0.5rem' }} />
                  Loading locations...
                </Box>
              ) : (
                <Box>
                  <Autocomplete
                    id="dropdown_locations"
                    options={locations}
                    getOptionLabel={(option: LocationType) =>
                      option.shadow.name ? option.shadow.name : option.thingName
                    }
                    value={selectedLocation}
                    onChange={(event: any, newValue: LocationType | null) => {
                      setSelectedLocation(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          selectedLocation ? 'Selected Location:' : undefined
                        }
                        placeholder="Select location from the list..."
                        variant="outlined"
                      />
                    )}
                  />
                </Box>
              )}
            </Box>
          </div>
        );
      },
    },
    {
      label: 'Scan QR code',
      description: () => {
        return (
          <>
            <Box mb="1rem">
              Now you need to find the QR code of your asset and use the button
              below to scan it.
            </Box>
            <Box mb="1rem">
              If you don't have a QR code, you may{' '}
              <Link
                href="#"
                id="link_skip-this-step"
                onClick={(e) => {
                  e.preventDefault();
                  fetchCompanies();
                  setActiveStep((prevActiveStep) => prevActiveStep + 1);
                }}
              >
                skip this step
              </Link>
              .
            </Box>
          </>
        );
      },
    },
    {
      label: 'Enter asset details',
      description: () => {
        const handleAssetSerial = (
          event: React.ChangeEvent<HTMLInputElement>
        ) => {
          setAssetSerial(event.target.value);
        };

        return (
          <div>
            <Box mb="2rem">
              Before we register this asset, we need to add the following
              information to the asset:
            </Box>

            <TextField
              label="Serial Number"
              value={assetSerial}
              onChange={handleAssetSerial}
              size="small"
              sx={{ mb: '2rem' }}
              id="text_serial-number"
              fullWidth
              required
            />

            <Autocomplete
              id="autocomplete_asset-type"
              options={Object.values(ASSET_TYPES)}
              getOptionLabel={(option: AssetType) => option}
              value={assetType}
              onChange={(event: any, newValue: AssetType | null) => {
                setAssetType(newValue);
              }}
              sx={{ mb: '2rem' }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Asset Type"
                  variant="outlined"
                  size="small"
                  id="text_asset-type"
                  required
                />
              )}
            />

            <Autocomplete
              id="autocomplete_company"
              disabled={loadingCompanies}
              options={companies}
              getOptionLabel={(option: CompanyType) => option.name}
              value={selectedCompany}
              onChange={(event: any, newValue: CompanyType | null) => {
                setSelectedCompany(newValue);
              }}
              sx={{ mb: '2rem' }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Company"
                  variant="outlined"
                  size="small"
                  id="text_company"
                  required
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loadingCompanies ? (
                          <CircularProgress
                            color="inherit"
                            size={20}
                            sx={{ mr: '2rem' }}
                          />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
            {companiesError && (
              <Alert severity="error" variant="standard" sx={{ mb: '1rem' }}>
                Error: Unable to retrieve companies.
              </Alert>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <Box>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => {
          const continueButton = getContinueLabel(index);
          return (
            <Step key={step.label} sx={{ maxWidth: '400px' }}>
              <StepLabel>
                {index <= lastStep ? (
                  <Button
                    variant="text"
                    onClick={() => {
                      setActiveStep(index);
                    }}
                    color={index === activeStep ? 'inherit' : undefined}
                    sx={{ p: 0 }}
                  >
                    {step.label}
                  </Button>
                ) : (
                  <Box sx={{ textTransform: 'uppercase' }}>{step.label}</Box>
                )}
              </StepLabel>
              <StepContent>
                <Box>{step.description()}</Box>
                <Box sx={{ mb: 2 }}>
                  <div>
                    <Button
                      variant="contained"
                      onClick={handleNext}
                      sx={{ mt: 1, mr: 1 }}
                      endIcon={continueButton.endIcon}
                      startIcon={continueButton.startIcon}
                      id="button_continue"
                      disabled={
                        (index === 0 && geoLocation.loading) ||
                        (index === 1 && !selectedLocation) ||
                        (index === 3 &&
                          (!selectedCompany || !assetSerial || !assetType))
                      }
                    >
                      {continueButton.label}
                    </Button>
                    {index > 0 && (
                      <Button
                        disabled={index === 0}
                        onClick={handleBack}
                        sx={{ mt: 1, mr: 1 }}
                        id="button_back"
                      >
                        Back
                      </Button>
                    )}
                  </div>
                </Box>
              </StepContent>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length && (
        <Box sx={{ my: '1rem' }}>
          {loadingRegister ? (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <CircularProgress size="20px" sx={{ mr: '0.5rem' }} />
              Registering new asset...
            </Box>
          ) : (
            <Box>
              {registerSuccess ? (
                <>
                  <Alert severity="success" variant="standard">
                    Asset registration was successful.
                  </Alert>
                  <Box>
                    <Button
                      onClick={handleReset}
                      startIcon={<RestartAltIcon />}
                      color="success"
                      variant="outlined"
                      sx={{ mr: '1rem', mt: '1rem' }}
                      id="button_start-over"
                    >
                      Start Over
                    </Button>
                    <Button
                      onClick={() => {
                        history.push(DSFE_ASSET_LIST);
                      }}
                      startIcon={<ListIcon />}
                      color="success"
                      variant="outlined"
                      sx={{ mr: '1rem', mt: '1rem' }}
                      id="button_go_to_asset_list"
                    >
                      Go to Asset List
                    </Button>
                    <Button
                      onClick={() => {
                        const assetName = `${assetSerial}`;
                        const url = `/dsfe/asset-detail/${assetName}`;
                        history.push(url);
                      }}
                      startIcon={<ZoomInIcon />}
                      color="success"
                      variant="contained"
                      sx={{ mr: '1rem', mt: '1rem' }}
                      id="button_go-to-asset-detail"
                    >
                      Go to Asset Detail
                    </Button>
                  </Box>
                </>
              ) : (
                <>
                  <Alert severity="error" variant="standard">
                    Error: Asset registration failed. {registerError || null}
                  </Alert>
                  <Button
                    onClick={() => {
                      registerAsset();
                    }}
                    color="error"
                    variant="contained"
                    sx={{ mr: '1rem', mt: '1rem' }}
                    id="button_try-again"
                    startIcon={<RestartAltIcon />}
                  >
                    Try again
                  </Button>
                  <Button
                    onClick={() => {
                      setActiveStep((prevActiveStep) => prevActiveStep - 1);
                    }}
                    color="error"
                    variant="outlined"
                    sx={{ mr: '1rem', mt: '1rem' }}
                    id="button_back2"
                    startIcon={<ArrowBackIosNewIcon />}
                  >
                    Back
                  </Button>
                </>
              )}
            </Box>
          )}
        </Box>
      )}
      {showScanner ? (
        <UiDialogScanner
          onScan={onScan}
          onClose={() => {
            setShowScanner(false);
          }}
          title={t('assetManagement.scanQrCode')}
        />
      ) : null}
    </Box>
  );
};
