export const ENDPOINT_API = '/user/refresh';
export const ENDPOINT_FAULT_ITEM = '/fault-item';
export const ENDPOINT_FAULT_PART = '/fault-part';
export const ENDPOINT_PHYSICAL_ASSET = '/physical-asset';
export const ENDPOINT_FAULT_PART_TYPE = '/fault-part-type';
export const ENDPOINT_FAULT_TYPE = '/fault-type';
export const ENDPOINT_LOCATIONS = '/location';
export const ENDPOINT_PICTURE_DEFINITION = '/picture-definition';
export const ENDPOINT_POST = '/posts';
export const ENDPOINT_PRODUCTION_TEAM = '/team';
export const ENDPOINT_TENANT = '/tenant';
export const ENDPOINT_ROLE = '/role';
export const ENDPOINT_USER = '/user';
export const ENDPOINT_USER_ADMINISTRATION = '/admin/user';
export const ENDPOINT_NOTIFICATION = '/notification';
export const ENDPOINT_ALL_CAPABILITIES = '/tags/capabilities';
export const ENDPOINT_CAPABILITIES = '/user/management/:accountId/capabilities';
export const ENDPOINT_USER_LOCATION = '/user/management/:accountId/location';
export const ENDPOINT_PROFILE_IMAGE = '/user/profile/image';
export const ENDPOINT_COMMENT = '/comment';
export const ENDPOINT_TENANT_CREATION = '/tenant-creation';
export const ENDPOINT_TENANT_DELETION = '/tenant-deletion';
export const ENDPOINT_VAT = '/vat';
