import { withTenantLayout } from '@dsf/ui-layout';
import { Box } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { Messenger } from '@dsf/feature-conversations';

const ChatPage = () => {
  return (
    <Box maxWidth="721px" margin="0 auto">
      <div>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div>
            <h1 id="title_messages">Messages</h1>
          </div>
        </Box>
        <Messenger type="chat" />
      </div>
    </Box>
  );
};

export default withTenantLayout(ChatPage)({
  pageName: 'DSFE-ConversationPage',
});
