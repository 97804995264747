import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { App } from './App';
import { i18n } from '@dsf/util-translate';

const onResize = () => {
  // workaround for iOS Safari
  const root = window.document.documentElement;
  if (root && window && window.innerHeight) {
    root.style.setProperty('--max-height', `${window.innerHeight}px`);
  }
};

const initializeApplication = () => {
  onResize();
  window.addEventListener('resize', onResize, false);

  // bugfix(#389449) remove all serviceworkers
  if (navigator.serviceWorker) {
    navigator.serviceWorker
      .getRegistrations()
      .then(async function (registrations) {
        for (const registration of registrations) {
          await registration.unregister();
        }
      });
  }

  ReactDOM.render(
    <App />,
    document.getElementById('root') || document.createElement('div')
  );
};

initializeApplication();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
