import { formatDataValue, useQueries } from '@dsf/util-tools';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

// eslint-disable-next-line
const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <Box>
        <Box>{label}</Box>
        <Box>Q: {payload[0].value}</Box>
        <Box>H: {payload[1].value}</Box>
      </Box>
    );
  }

  return null;
};

export type HistoryQhItem = {
  q: number;
  h: number;
  timestamp: string;
  //   timestamp: number;
};

type Props = {
  historyQhData: HistoryQhItem[];
};

export const QHTimeline = ({ historyQhData }: Props) => {
  const { isMobile } = useQueries();
  const { t } = useTranslation();

  if (historyQhData.length === 0) {
    return <Box>{t('assetDetail.noDataAvailable')}</Box>;
  }

  return (
    <LineChart
      width={653}
      height={isMobile ? 550 : 550}
      data={historyQhData}
      margin={{
        top: 40,
        right: 20,
        bottom: isMobile ? 120 : 120,
        left: 0,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis
        dataKey="timestamp"
        // label={{
        //   value: 'Time',
        //   position: 'right',
        //   dy: 22,
        //   dx: -70,
        // }}
        angle={-60}
        textAnchor="end"
        // type="number"
        // scale="time"
        // interval={5}
        // tickSize={5}
        // scale="time"
        tickCount={20}
      />
      <YAxis
        yAxisId="left"
        label={{
          value: t('assetDetail.qhLabel'),
          position: 'top',
          dx: 20,
          dy: -15,
        }}
        domain={[0, 36]}
        max={36}
        tickCount={30}
        tickFormatter={(value) => {
          return formatDataValue(value, undefined, 0);
        }}
      />
      <YAxis
        yAxisId="right"
        orientation="right"
        label={{ value: 'H [m]', position: 'top', dx: 10, dy: -15 }}
        domain={[0, 45]}
        max={45}
        tickCount={30}
        tickFormatter={(value) => {
          return formatDataValue(value, undefined, 0);
        }}
      />

      <Tooltip content={<CustomTooltip />} isAnimationActive={false} />

      <Legend
        verticalAlign="top"
        layout="horizontal"
        wrapperStyle={{ top: 0 }}
      />
      <Line
        isAnimationActive={false}
        yAxisId="left"
        type="monotone"
        dataKey="q"
        stroke="#ff0000"
        activeDot={{ r: 8 }}
      />
      <Line
        yAxisId="right"
        type="monotone"
        dataKey="h"
        stroke="#3376D0"
        isAnimationActive={false}
      />
    </LineChart>
  );
};
