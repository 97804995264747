import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Post } from '@dsf/util-types';
import { DefaultState } from './types/defaultState';

export interface PostState extends DefaultState {
  loadingPosts: boolean;
  posts: Post[];
}

const initialPostState: PostState = {
  loadingPosts: false,
  posts: [],
  persist: false,
};

export const postSlice = createSlice({
  name: 'posts',
  initialState: initialPostState,
  reducers: {
    loadingPostsStarted: (state) => {
      state.loadingPosts = true;
      state.posts = [];
    },
    loadingPostsSuccess: (state, action: PayloadAction<Post[]>) => {
      state.loadingPosts = false;
      state.posts = [...action.payload];
    },
    loadingPostsFailure: (state) => {
      state.loadingPosts = false;
    },
  },
});
