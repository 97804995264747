import { State } from '@dsf/data-access-store';
import { useFaultItemApi } from '@dsf/data-access-api';
import { FAULT_ITEM_DETAIL } from '@dsf/util-router';

import { Loader } from '../../../components';
import { withTenantLayout } from '@dsf/ui-layout';
import { Box, createStyles, IconButton, makeStyles } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Alert } from '@material-ui/lab';
import { FaultItem, FaultItemDetailResponse } from '@dsf/util-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import FaultDetail from './fault-detail';
import Discussion from './discussion/discussion';
import ProductDetail from './product-detail';
import { ErrorAlert } from '@dsf/ui-components';

/**
 * assetVersion = product part number
 */

const useStyles = makeStyles(() =>
  createStyles({
    faultNavBox: {
      background: 'rgba(45, 47, 54, 0.15)',
    },
  })
);

type Params = {
  id: string; // faultId
};

const FaultDetailPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { id: faultId } = useParams<Params>();
  const { tenant } = useSelector((state: State) => state.tenant);
  const history = useHistory();

  const faultItemApi = useFaultItemApi();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [faultData, setFaultData] = useState<FaultItemDetailResponse>();
  const [faultList, setFaultList] = useState<FaultItem[]>([]);
  const [assetPicture, setAssetPicture] = useState<string>();
  const [faultsError, setFaultsError] = useState<string | false>(false);

  const updateFaultData = (data: FaultItemDetailResponse) => {
    setFaultData(data);
  };

  const fetchAssetPicture = async (assetPictureId: string) => {
    try {
      const response = await faultItemApi.getImageBase64(assetPictureId);
      setAssetPicture(`data:image/png;base64,${response.data}`);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchProductDetail = async () => {
    setError(false);
    setLoading(true);
    try {
      const response = await faultItemApi.getDetail(faultId);
      if (response.data.assetPictureId) {
        fetchAssetPicture(response.data.assetPictureId);
      }
      setFaultData(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  };

  const fetchFaultsForProduct = async () => {
    if (
      !tenant ||
      (tenant && !tenant.id) ||
      !faultData ||
      (faultData && !faultData.assetNumber)
    ) {
      return;
    }
    setFaultsError(false);
    setLoading(true);
    try {
      const response = await faultItemApi.getFaultsForProduct(
        `${tenant.id}`,
        `${faultData.assetNumber}` /* serial number  */
      );
      if (response && response.data && response.data.length) {
        setFaultList(response.data[0].faultItems);
      }
      setLoading(false);
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.status === 404 &&
        error.response.data &&
        error.response.data.message
      ) {
        setFaultsError(error.response.data.message);
      } else {
        setFaultsError(`${t('faultDetailPage.faultItemsError')}`);
      }
      setLoading(false);
    }
  };

  const goToNextFault = () => {
    const faultIdNumber = parseInt(faultId, 10);
    const currentIndex = faultList.findIndex(
      (item) => item.id === faultIdNumber
    );
    if (currentIndex !== -1 && currentIndex < faultList.length - 1) {
      history.push(
        FAULT_ITEM_DETAIL.replace(':id', `${faultList[currentIndex + 1].id}`)
      );
    }
  };

  const goToPreviousFault = () => {
    const faultIdNumber = parseInt(faultId, 10);
    const currentIndex = faultList.findIndex(
      (item) => item.id === faultIdNumber
    );

    if (currentIndex !== -1 && currentIndex > 0) {
      history.push(
        FAULT_ITEM_DETAIL.replace(':id', `${faultList[currentIndex - 1].id}`)
      );
    }
  };

  useEffect(() => {
    if (faultId) {
      fetchProductDetail();
    }
    // eslint-disable-next-line
  }, [faultId]);

  useEffect(() => {
    fetchFaultsForProduct();
    // eslint-disable-next-line
  }, [tenant, faultData]);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return (
      <Box p="1rem" textAlign="center">
        {t('global.generalError')}
      </Box>
    );
  }

  if (!faultData) {
    return (
      <Box p="1rem" textAlign="center">
        {t('faultDetailPage.noData')}
      </Box>
    );
  }

  return (
    <>
      <ProductDetail faultData={faultData} assetPicture={assetPicture} />
      {faultsError ? (
        <Box m="1rem 0">
          <ErrorAlert error={faultsError} />;
        </Box>
      ) : (
        <>
          {faultList.length === 0 ? (
            <Box m="3rem 0">
              <Alert severity="error">
                {t('faultDetailPage.noFaultItems')}
              </Alert>
            </Box>
          ) : (
            <>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                className={classes.faultNavBox}
                m="1.5rem -1rem 0 -1rem"
                p="0.25rem 1rem"
              >
                <Box>
                  <IconButton
                    onClick={goToPreviousFault}
                    disabled={`${faultList[0].id}` === faultId}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                </Box>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Box fontSize="1rem" color="#2D2F36" fontWeight="bold">
                    {t('faultDetailPage.faultId')}: {faultData.assetNumber} -{' '}
                    {faultData.id}
                  </Box>
                </Box>
                <Box>
                  <IconButton
                    onClick={goToNextFault}
                    disabled={
                      `${faultList[faultList.length - 1].id}` === faultId
                    }
                  >
                    <ArrowForwardIcon />
                  </IconButton>
                </Box>
              </Box>
              <FaultDetail
                faultData={faultData}
                updateFaultData={updateFaultData}
              />
            </>
          )}
          <Discussion faultId={faultId} />
        </>
      )}
    </>
  );
};

export default withTenantLayout(FaultDetailPage)({ pageName: 'SCR-36' });
