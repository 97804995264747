import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { TableCellProps } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import { IconButton } from '@material-ui/core';
import { strEnum, useQueries, USER_ROLES } from '@dsf/util-tools';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import CircularProgress from '@material-ui/core/CircularProgress';

import ElectricMopedIcon from '@mui/icons-material/ElectricMoped';

import { DrillMachineIcon, PumpIcon } from '@dsf/ui-assets';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useWrapApi } from '@dsf/data-access-api';
import { useSelector } from 'react-redux';
import { State } from '@dsf/data-access-store';
import { ErrorAlert } from '@dsf/ui-components';

// import { formatAssetStatus } from './asset-status';

type AssetAttribute = { [key: string]: string };

/**
	Example:
	attributes: {company: "munich-re", dsfqtype: "temperature-sensor"}
	company: "munich-re"
	dsfqtype: "temperature-sensor"
	thingArn: "arn:aws:iot:eu-central-1:115926753473:thing/DSF-Thing05"
	thingName: "DSF-Thing05"
	thingTypeName: "DSFQ-Type-2"
	version: 1
 */
type Thing = {
  attributes: AssetAttribute;
  thingArn: string;
  thingName: string;
  thingTypeName: string;
  version: number;
};

export const mapThingToAsset = (thing: Thing) => {
  return {
    assetName: thing.thingName || '',
    assetType: thing.thingTypeName || '',
    assetId: thing.thingName || '',
    assetAttributes: thing.attributes,
  };
};

/**
 * DSFQ-Type-3 is AGV (robot icon)
 * DSFQ-Type-4 is Drilling Machine (tool icon)
 */
export const ASSET_TYPES = strEnum(['rotational', 'transporter', 'smartpump']);
export type AssetType = keyof typeof ASSET_TYPES;

export const formatAssetType = (param: AssetAttribute) => {
  if (!param) {
    return 'N/A';
  }
  const value = param.dsfqtype as AssetType;
  if (value === ASSET_TYPES.transporter) {
    return <ElectricMopedIcon />;
  }
  if (value === ASSET_TYPES.rotational) {
    return <DrillMachineIcon />;
  }
  if (value === ASSET_TYPES.smartpump) {
    return (
      <Box
        sx={{
          '& svg': {
            width: '1.8rem',
          },
        }}
      >
        <PumpIcon />
      </Box>
    );
  }
  if (value) {
    return value;
  }
  return <>N/A</>;
};

export interface AssetColumn {
  id: 'assetName' | 'assetType' | 'assetId' | 'assetStatus' | 'assetAttributes';
  label: string;
  minWidth?: number;
  align?: TableCellProps['align'];
  format?: (value: any) => JSX.Element | null;
}

export type AssetItem = {
  assetName: string;
  assetType: string;
  assetId: string;
  assetStatus?: string;
  assetAttributes?: {
    [key: string]: string;
  };
};

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 'none',
  },
});

export const AssetList: React.FC = () => {
  const { isMobile } = useQueries();
  const { roles } = useSelector((state: State) => state.userTokens);

  const { api } = useWrapApi();
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loading, setLoading] = useState(false);
  const [things, setThings] = useState<AssetItem[]>([]);
  const [thingsError, setThingsError] = useState<string | undefined>();

  const columns: AssetColumn[] = [
    {
      id: 'assetName',
      label: t('assetList.assetName'),
      minWidth: 100,
    },
    {
      id: 'assetAttributes',
      label: t('assetList.assetType'),
      minWidth: 100,
      format: (value) => {
        if (value?.thingTypeLabel) {
          return <div>{value?.thingTypeLabel.replaceAll('_', ' ')}</div>;
        }
        return <div>{value?.dsfqtype}</div>;
      },
      align: 'center',
    },
    {
      id: 'assetAttributes',
      label: t('assetList.location'),
      minWidth: 100,
      format: (value) => {
        if (value?.locationLabel) {
          return <div>{t(value?.locationLabel).replaceAll('_', ' ')}</div>;
        }
        return <div>{value?.location}</div>;
      },
    },
    //   {
    //     id: 'assetStatus',
    //     label: 'Asset\u00a0Status',
    //     minWidth: 120,
    //     align: 'right',
    //     format: formatAssetStatus,
    //   },
  ];

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    setLoading(true);

    const fetchThings = async () => {
      try {
        setThingsError(undefined);

        const { data } = await api.get('/things');
        const mappedThings = data.map(mapThingToAsset);
        setThings(mappedThings);
        setLoading(false);
      } catch (error) {
        console.error('things error', error);
        setThings([]);
        setLoading(false);
        setThingsError('Unable to load the asset list.');
      }
    };
    fetchThings();
  }, []);

  const navigateToDetail = (assetId: string, type: string) => {
    const assetDetailUrl = `/dsfe/${
      type.startsWith('Grundfos')
        ? 'pump-detail'
        : 'asset-detail'
    }/${assetId}`;
    history.push(assetDetailUrl);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" p="2rem">
        <CircularProgress />
      </Box>
    );
  }

  if (thingsError) {
    return <ErrorAlert error={thingsError} />;
  }

  if (things.length === 0) {
    return <Box sx={{ textAlign: 'center' }}>There are no assets.</Box>;
  }

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  key={`${column.id}-${index}`}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
              <TableCell
                key="asset-detail-header"
                style={{ minWidth: '80px' }}
                align="center"
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {things
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.assetId}
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      navigateToDetail(row.assetId, row.assetAttributes.dsfqtype);
                    }}
                  >
                    {columns.map((column, index) => {
                      let value = row[column.id];

                      if (column.id == 'assetName') {
                        if (row?.assetAttributes?.label) {
                          value = row?.assetAttributes?.label.replaceAll(
                            '_',
                            ' '
                          );
                        }
                      }

                      return (
                        <TableCell
                          key={`${column.id}-${index}`}
                          align={column.align}
                        >
                          {column.format && value
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                    <TableCell key="asset-detail-row" align="center">
                      <IconButton
                        onClick={() => {
                          navigateToDetail(row.assetId, row.assetAttributes.dsfqtype);
                        }}
                      >
                        <ZoomInIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={
          isMobile
            ? {
                mt: '1rem',
                '& .MuiTablePagination-toolbar': {
                  display: 'block',
                  textAlign: 'center',
                },
                '& .MuiTablePagination-actions': {
                  ml: 0,
                },
                '& .MuiTablePagination-selectRoot': {
                  mr: 0,
                  ml: 0,
                },
              }
            : undefined
        }
      >
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={things.length}
          rowsPerPage={rowsPerPage}
          labelDisplayedRows={function defaultLabelDisplayedRows({
            from,
            to,
            count,
          }) {
            return `${from}–${to} ${t('global.of')} ${
              count !== -1 ? count : `more than ${to}`
            }`;
          }}
          labelRowsPerPage={t('global.rowsPerPage')}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </Paper>
  );
};
