import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DefaultState } from './types/defaultState';
import {
  FaultItemListResponse,
  FaultType,
  FaultyPart,
  LocationType,
  TeamResponse,
} from '@dsf/util-types';

export interface CreateFaultItemState extends DefaultState {
  assetLocation: LocationType | undefined; // selected data from step 1
  asset: FaultItemListResponse | undefined; // selected data from step 2
  keepAsset: boolean | undefined; // selected data from step 2
  faultItem: FaultItemListResponse | undefined; // selected data from step 2
  faultyPart: FaultyPart | undefined; // selected data from step 3
  faultType: FaultType | undefined; // selected data from step 4
  faultCauser: TeamResponse | undefined; // selected data from step 5
}

const initialCreateFaultItemState: CreateFaultItemState = {
  assetLocation: undefined,
  asset: undefined,
  keepAsset: false,
  faultItem: undefined,
  faultyPart: undefined,
  faultType: undefined,
  faultCauser: undefined,
  persist: true,
};

export const createFaultItemSlice = createSlice({
  name: 'createFaultItem',
  initialState: initialCreateFaultItemState,
  reducers: {
    setAssetLocation: (
      state,
      { payload: assetLocation }: PayloadAction<LocationType | undefined>
    ) => {
      state.assetLocation = assetLocation;
    },
    setAsset: (
      state,
      { payload: asset }: PayloadAction<FaultItemListResponse | undefined>
    ) => {
      state.asset = asset;
    },
    setKeepAsset: (
      state,
      { payload: keepAsset }: PayloadAction<boolean | undefined>
    ) => {
      state.keepAsset = keepAsset;
    },
    setFaultItem: (
      state,
      { payload: faultItem }: PayloadAction<FaultItemListResponse | undefined>
    ) => {
      state.faultItem = faultItem;
    },
    setFaultyPart: (
      state,
      { payload: faultyPart }: PayloadAction<FaultyPart | undefined>
    ) => {
      state.faultyPart = faultyPart;
    },
    setFaultType: (
      state,
      { payload: faultType }: PayloadAction<FaultType | undefined>
    ) => {
      state.faultType = faultType;
    },
    setFaultCauser: (
      state,
      { payload: faultCauser }: PayloadAction<TeamResponse | undefined>
    ) => {
      state.faultCauser = faultCauser;
    },
    resetStateKeepLocation(state) {
      state.asset = undefined;
      state.faultItem = undefined;
      state.faultyPart = undefined;
      state.faultType = undefined;
      state.faultCauser = undefined;
    },
    resetStateKeepAsset(state) {
      state.faultItem = undefined;
      state.faultyPart = undefined;
      state.faultType = undefined;
      state.faultCauser = undefined;
    },
  },
});
