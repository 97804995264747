import { FaultItemListResponse } from '@dsf/util-types';
import React, { ProviderProps } from 'react';
import {
  FaultListActionsType,
  UPDATE_ITEMS_TO_DISPLAY,
} from './fault-list-actions';

export type FaultListState = {
  itemsToDisplay: FaultItemListResponse[];
};

const initialState: FaultListState = {
  itemsToDisplay: [],
};

const FaultListContext = React.createContext<FaultListState | undefined>(
  undefined
);
const FaultListDispatch = React.createContext<
  ((action: FaultListActionsType) => void) | undefined
>(undefined);

function faultListReducer(
  state: FaultListState,
  action: FaultListActionsType
): FaultListState {
  switch (action.type) {
    case UPDATE_ITEMS_TO_DISPLAY:
      return { ...state, itemsToDisplay: action.payload };

    default: {
      return state;
    }
  }
}

function FaultListProvider({
  children,
}: Pick<ProviderProps<FaultListState>, 'children'>) {
  const [state, dispatch] = React.useReducer(faultListReducer, initialState);
  return (
    <FaultListContext.Provider value={state}>
      <FaultListDispatch.Provider value={dispatch}>
        {children}
      </FaultListDispatch.Provider>
    </FaultListContext.Provider>
  );
}

function useFaultListState() {
  const context = React.useContext(FaultListContext);
  if (context === undefined) {
    throw new Error(
      'useFaultListState must be used within a FaultListProvider'
    );
  }
  return context;
}

function useFaultListDispatch() {
  const context = React.useContext(FaultListDispatch);
  if (context === undefined) {
    throw new Error('useFaultListDispatch must be used within a Provider');
  }
  return context;
}

export type FaultListDispatchType = ReturnType<typeof useFaultListDispatch>;

export { FaultListProvider, useFaultListState, useFaultListDispatch };
