import { API, APIResponse, useApi } from '..';
import { ENDPOINT_PHYSICAL_ASSET } from '..';
import {
  PhysicalAssetCreationRequest,
  PhysicalAsset,
  FaultItemListResponse,
} from '@dsf/util-types';

const BASE = ENDPOINT_PHYSICAL_ASSET;
export const getPhysicalAssetPath = (
  physicalAssetId: number,
  serialNumber: string
): string => `${BASE}?id=${physicalAssetId}&serialNumber=${serialNumber}`;

export interface PhysicalAssetAPI extends API {
  getPhysicalAsset: (
    physicalAssetId: number,
    serialNumber: string
  ) => APIResponse<PhysicalAsset[]>;
  markProductFinished: (
    physicalAssetId: number,
    finished: boolean,
    locationId: number
  ) => APIResponse<PhysicalAsset>;
  updatePhysicalAsset: (
    physicalAssetId: number,
    finished: boolean,
    locationId: number
  ) => APIResponse<PhysicalAsset>;
  createPhysicalAsset: (
    pa: PhysicalAssetCreationRequest
  ) => APIResponse<PhysicalAsset>;
  getPhysicalAssets: (
    id: string | null,
    serialNumber: string | null,
    digitalIdentity: string | null
  ) => APIResponse<PhysicalAsset[]>;
  getAll: (
    physicalAssetId?: number,
    serialNumber?: string
  ) => APIResponse<FaultItemListResponse[]>;
  getAllFinished: (
    physicalAssetId?: number,
    serialNumber?: string
  ) => APIResponse<FaultItemListResponse[]>;
}

export const usePhysicalAssetApi = (): PhysicalAssetAPI => {
  const api = useApi();

  return {
    ...api,
    createPhysicalAsset(pa: PhysicalAssetCreationRequest) {
      const cancelToken = api.prepareCancelToken('createPhysicalAsset');
      return api.authRequest.post(BASE, pa, { cancelToken });
    },
    getPhysicalAsset(physicalAssetId, serialNumber) {
      const cancelToken = api.prepareCancelToken('getPhysicalAsset');
      return api.authRequest.get(
        getPhysicalAssetPath(physicalAssetId, serialNumber),
        { cancelToken }
      );
    },
    markProductFinished(id, finished, locationId) {
      const cancelToken = api.prepareCancelToken('markProductFinished');
      return api.authRequest.patch(
        `${BASE}/${id}`,
        { finished, locationId },
        { cancelToken }
      );
    },
    updatePhysicalAsset(id, finished, locationId) {
      const cancelToken = api.prepareCancelToken('updatePhysicalAsset');
      return api.authRequest.patch(
        `${BASE}/${id}`,
        { finished, locationId },
        { cancelToken }
      );
    },
    getPhysicalAssets(
      id: string | null,
      serialNumber: string | null,
      digitalIdentity: string | null
    ) {
      const cancelToken = api.prepareCancelToken('getPhysicalAssets');
      return api.authRequest.get(BASE, {
        params: { id, serialNumber, digitalIdentity },
        cancelToken,
      });
    },
    getAll(physicalAssetId?: number, serialNumber?: string) {
      const cancelToken = api.prepareCancelToken('getAll');
      if (!physicalAssetId && !serialNumber) {
        return api.authRequest.get(BASE + '/list', { params: {}, cancelToken });
      }
      if (physicalAssetId && !serialNumber) {
        return api.authRequest.get(`${BASE}/list?id=${physicalAssetId}`, {
          cancelToken,
        });
      }
      if (!physicalAssetId && serialNumber) {
        return api.authRequest.get(
          `${BASE}/list?serialNumber=${serialNumber}`,
          { cancelToken }
        );
      }
      return api.authRequest.get(
        `${BASE}/list?id=${physicalAssetId}&serialNumber=${serialNumber}`,
        { cancelToken }
      );
    },
    getAllFinished(physicalAssetId?: number, serialNumber?: string) {
      const cancelToken = api.prepareCancelToken('getAll');
      if (!physicalAssetId && !serialNumber) {
        return api.authRequest.get(BASE + '/list?finishedProducts=false', {
          params: {},
          cancelToken,
        });
      }
      if (physicalAssetId && !serialNumber) {
        return api.authRequest.get(
          `${BASE}/list?finishedProducts=false&id=${physicalAssetId}`,
          { cancelToken }
        );
      }
      if (!physicalAssetId && serialNumber) {
        return api.authRequest.get(
          `${BASE}/list?finishedProducts=false&serialNumber=${serialNumber}`,
          { cancelToken }
        );
      }
      return api.authRequest.get(
        `${BASE}/list?finishedProducts=false&id=${physicalAssetId}&serialNumber=${serialNumber}`,
        { cancelToken }
      );
    },
  };
};
