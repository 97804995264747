import { API, APIResponse, useApi } from '..';
import { ENDPOINT_FAULT_PART } from '..';
import { Product } from '@dsf/util-types';

const BASE = ENDPOINT_FAULT_PART;
export const PRODUCT_URL = `${BASE}?productsOnly=true`;

export interface ProductAPI extends API {
  getAllProducts: () => APIResponse<Product[]>;
}

export const useProductApi = (): ProductAPI => {
  const api = useApi();

  return {
    ...api,
    getAllProducts() {
      const cancelToken = api.prepareCancelToken('getAllProducts');
      return api.authRequest.get(PRODUCT_URL, { cancelToken });
    },
  };
};
