import { formatDataValue, formatYearsValue, useQueries } from '@dsf/util-tools';
import { Box, InputAdornment, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { INPUT_STYLES, Shadow } from '../pump-detail';

type Props = {
  pumpOnline: boolean;
  shadow?: Shadow;
  statusColor: string;
};

export const FirstImpeller: React.FC<Props> = ({
  pumpOnline,
  shadow,
  statusColor,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useQueries();

  return (
    <Box
      sx={{
        position: isMobile ? 'static' : 'absolute',
        top: '530px',
        right: '10px',
        maxWidth: '190px',
        m: isMobile ? '2rem auto' : undefined,
      }}
    >
      <Box
        sx={{
          fontWeight: 'bold',
          textTransform: 'uppercase',
          position: 'relative',
          zIndex: 1,
        }}
      >
        {t('assetDetail.firstImpeller')}
      </Box>
      <Box
        sx={{
          border: `1px solid ${pumpOnline ? statusColor : '#4c4c4c'}`,
          borderRadius: '5px',
          p: '1rem',
        }}
      >
        <TextField
          size="small"
          label={t('assetDetail.remainingLife')}
          type="text"
          value={formatYearsValue(
            shadow?.state?.reported['databricks:remaining_Imp1']?.floatValue
          )}
          fullWidth
          InputProps={{
            readOnly: true,
            inputProps: {
              style: INPUT_STYLES,
            },
            endAdornment: (
              <InputAdornment position="start">
                {t('units.years')}
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          size="small"
          label={t('assetDetail.consumedLife')}
          type="text"
          value={formatDataValue(
            shadow?.state?.reported['databricks:consumed_Imp1']?.floatValue,
            undefined,
            2
          )}
          fullWidth
          sx={{
            mt: '1rem',
          }}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            readOnly: true,
            inputProps: {
              style: INPUT_STYLES,
            },
            endAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
        />
      </Box>
      <Box
        sx={{
          display: isMobile ? 'none' : undefined,
          borderTop: `1px solid ${pumpOnline ? statusColor : '#4c4c4c'}`,
          transform: 'rotate(199deg)',
          transformOrigin: 'left',
          position: isMobile ? 'static' : 'absolute',
          width: '146px',
          left: 0,
          bottom: '50%',
        }}
      >
        <Box
          sx={{
            display: isMobile ? 'none' : undefined,
            background: `${pumpOnline ? statusColor : '#4c4c4c'}`,
            position: 'absolute',
            width: '10px',
            height: '10px',
            right: '-10px',
            top: '-5px',
            borderRadius: '50%',
          }}
        ></Box>
      </Box>
    </Box>
  );
};
