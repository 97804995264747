import React from 'react';

export interface Props {
  className?: string;
}

export const MenuWrenchIcon: React.FC<Props> = ({ className }: Props) => {
  return (
    <svg
      className={className}
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 0C9.691 0 7 2.691 7 6C7 6.518 7.07094 7.03587 7.21094 7.54688L2.63477 12.123C1.12945 12.5138 0 13.879
       0 15.5C0 17.4212 1.57885 19 3.5 19C5.12102 19 6.48618 17.8706 6.87695 16.3652L11.4531 11.7891C11.9641 11.9291
       12.482 12 13 12C16.309 12 19 9.309 19 6C19 5.266 18.8572 4.53141 18.5762 3.81641L18.418 3.41016L16.4141
       5.41406C16.0241 5.80506 15.512 6 15 6C14.488 6 13.9759 5.80506 13.5859 5.41406C12.8049 4.63306 12.8049
       3.36694 13.5859 2.58594L15.5898 0.582031L15.1836 0.423828C14.4686 0.142828 13.734 0 13 0ZM3.5 14C4.34027 14 5
       14.6597 5 15.5C5 16.3403 4.34027 17 3.5 17C2.65973 17 2 16.3403 2 15.5C2 14.6597 2.65973 14 3.5 14Z"
        fill="white"
      />
    </svg>
  );
};
