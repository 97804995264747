import { createTheme } from '@material-ui/core/styles';

//TODO - is it possible to rework this to .scss only?
export const appTheme = createTheme({
  palette: {
    primary: {
      main: '#2d2f36',
    },
    secondary: {
      main: '#2196f3',
    },
  },

  overrides: {
    MuiTypography: {
      gutterBottom: {
        marginBottom: '2rem',
      },
    },
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: '#eee',
        },
      },
    },
  },
});
