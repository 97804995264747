import React from 'react';

export interface Props {
  className?: string;
}

export const MenuIcon: React.FC<Props> = ({ className }: Props) => {
  return (
    <svg
      className={className}
      width="20"
      height="14"
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0V2H20V0H0ZM0 6V8H10V6H0ZM0 12V14H15V12H0Z" fill="white" />
    </svg>
  );
};
