import React from 'react';

export interface Props {
  className?: string;
}

export const BellIcon: React.FC<Props> = ({ className }: Props) => {
  return (
    <svg
      className={className}
      width="14"
      height="17"
      viewBox="0 0 14 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 0.554932C6.32254 0.554932 5.77273 1.07742 5.77273 1.7212V2.26485C3.66215 2.78659 2.09091 4.61125 2.09091 6.77502V10.5365L1.50124 12.2176H0.454544V13.7726H5.58416C5.44009 14.0089 5.36403 14.277 5.36363 14.5501C5.36363 14.9625 5.53604 15.3581 5.84291 15.6497C6.14979 15.9413 6.56601 16.1051 7 16.1051C7.43399 16.1051 7.8502 15.9413 8.15708 15.6497C8.46396 15.3581 8.63636 14.9625 8.63636 14.5501C8.63548 14.2769 8.55887 14.0087 8.41424 13.7726H13.5455V12.2176H12.4988L11.9091 10.5365V6.77502C11.9091 4.61125 10.3378 2.78659 8.22727 2.26485V1.7212C8.22727 1.07742 7.67745 0.554932 7 0.554932ZM7 3.66497C8.81667 3.66497 10.2727 5.04865 10.2727 6.77502V10.7886L10.7729 12.2176H3.22709L3.72727 10.7886V6.77502C3.72727 5.04865 5.18333 3.66497 7 3.66497Z"
        fill="white"
      />
    </svg>
  );
};
