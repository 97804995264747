import { usePhysicalAssetApi } from '@dsf/data-access-api';
import { IDENTIFY_PRODUCT, PRODUCT_OVERVIEW } from '@dsf/util-router';
import { FaultItemListResponse } from '@dsf/util-types';

import {
  Box,
  Button,
  Checkbox,
  createStyles,
  FormControlLabel,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { ScreenText } from '../../../components';
import { LocationOption } from '../../../components/location-scanner';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: '1rem',
    },
    checkbox: {
      '& .MuiFormControlLabel-label': {
        fontSize: '0.8rem',
      },
    },
    button: {
      minWidth: '5rem',
    },
  })
);

type Props = {
  physicalAsset: FaultItemListResponse;
  lastLocation: LocationOption;
  faultItemCount: number;
};

const FinishConfirmation: React.FC<Props> = ({
  physicalAsset,
  lastLocation,
  faultItemCount,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const physicalAssetApi = usePhysicalAssetApi();

  const [notify, setNotify] = useState(true);
  const [submitted, setSubmitted] = useState<boolean | undefined>(undefined);

  const handleNotifyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNotify(event.target.checked);
  };

  const handleYes = () => {
    physicalAssetApi.markProductFinished(
      physicalAsset.assetId,
      true,
      lastLocation.id
    );
    setSubmitted(true);
  };

  const handleNo = () => {
    setSubmitted(false);
  };

  const handleProductionOverview = () => {
    history.push(PRODUCT_OVERVIEW);
  };

  const handleIdentifyOther = () => {
    history.push(IDENTIFY_PRODUCT);
  };

  return (
    <Paper elevation={5} className={classes.root}>
      <Box p="1rem">
        {submitted !== undefined ? (
          <>
            {submitted === true && (
              <Box mb="1rem">
                <ScreenText>
                  <strong>
                    {t('markProductAsFinishedPage.confirmation.intro')}
                  </strong>
                </ScreenText>
              </Box>
            )}
            <Box mb="1rem" display="flex" justifyContent="space-between">
              <Box mr="0.25rem" width="50%">
                <Button
                  variant="outlined"
                  onClick={handleProductionOverview}
                  id="markProductAsFinishedPage_confirmation_openProductionOverview"
                >
                  {t(
                    'markProductAsFinishedPage.confirmation.openProductionOverview'
                  )}
                </Button>
              </Box>
              <Box ml="0.25rem" width="50%">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleIdentifyOther}
                  id="markProductAsFinishedPage_confirmation_identifyOtherProduct"
                >
                  {t(
                    'markProductAsFinishedPage.confirmation.identifyOtherProduct'
                  )}
                </Button>
              </Box>
            </Box>
          </>
        ) : (
          <>
            {faultItemCount === 0 ? (
              <Box mb="1rem">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={notify}
                      onChange={handleNotifyChange}
                      name="notifyCustomer"
                    />
                  }
                  label="Notify customer about the finished product"
                  className={classes.checkbox}
                />
              </Box>
            ) : (
              <Box mb="1rem">
                <ScreenText>
                  <Typography color="error">
                    <strong>
                      {t(
                        'markProductAsFinishedPage.confirmation.productHasFaults'
                      ).replace('{faultItemCount}', `${faultItemCount}`)}
                    </strong>
                  </Typography>
                </ScreenText>
              </Box>
            )}
            <Box mb="1rem">
              <ScreenText>
                {t('markProductAsFinishedPage.confirmation.areYouSure')}
              </ScreenText>
            </Box>
            <Box mb="1rem" display="flex" justifyContent="space-between">
              <Button
                className={classes.button}
                variant="outlined"
                onClick={handleNo}
                id="markProductAsFinishedPage_confirmation_no"
              >
                {t('markProductAsFinishedPage.confirmation.no')}
              </Button>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={handleYes}
                id="markProductAsFinishedPage_confirmation_yes"
              >
                {t('markProductAsFinishedPage.confirmation.yes')}
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Paper>
  );
};

export default FinishConfirmation;
