import {
  ADMIN_CREATE_USER,
  FEATURES_PAGE,
  KPI_DASHBOARD_PAGE,
  USER_LIST_PAGE,
  USER_OVERVIEW,
} from '@dsf/util-router';

import { UsersIcon } from '@dsf/ui-assets';
import React from 'react';
import { MenuItemType } from './menu-item';
import { USER_ROLES } from '@dsf/util-tools';

export const getAdministrationItems = (
  navigate: (route: string) => void
): MenuItemType[] => [
  {
    label: 'drawerNav.administration',
    icon: () => <UsersIcon />,
    id: 'drawer_userOverview',
    subItems: [
      {
        label: 'drawerNav.userOverview',
        id: 'drawer_administration_userOverview',
        onClick: () => {
          navigate(USER_LIST_PAGE);
        },
      },
      {
        label: 'drawerNav.kpiDashboard',
        id: 'drawer_administration_kpiDashboard',
        onClick: () => {
          navigate(KPI_DASHBOARD_PAGE);
        },
      },
      {
        label: 'drawerNav.features',
        id: 'drawer_administration_features',
        onClick: () => {
          navigate(FEATURES_PAGE);
        },
        visibility: USER_ROLES.admin,
      },
    ],
  },
];
