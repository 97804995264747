import { makeStyles, createStyles, Box } from '@material-ui/core';

import { Theme } from 'pretty-format';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      color: 'rgba(0, 0, 0, 0.6)',
      fontSize: '0.75rem',
    },
    value: {},
  })
);

export type Factoid = {
  label: JSX.Element;
  value: JSX.Element;
  icon?: JSX.Element;
};

type Props = {
  factoids: Factoid[];
};

const FactoidList: React.FC<Props> = ({ factoids }) => {
  const classes = useStyles();

  return (
    <>
      {factoids.map(({ label, value, icon }, index) => (
        <Box
          key={`factoid-${index}`}
          mb={index === factoids.length - 1 ? undefined : '1.5rem'}
        >
          <Box display="flex">
            {icon ? (
              <Box mt="0.3rem" width="2rem">
                {icon}
              </Box>
            ) : null}
            <Box flexGrow="1">
              <div className={classes.label}>{label}</div>
              <div className={classes.value}>{value}</div>
            </Box>
          </Box>
        </Box>
      ))}
    </>
  );
};

export default FactoidList;
