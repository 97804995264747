import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import {
  Checkbox,
  Collapse,
  createStyles,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Slider,
  Theme,
  withStyles,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Skeleton } from '@material-ui/lab';
import { useQueries, UseQueriesProps } from '@dsf/util-tools';

import InfoLabel from './InfoLabel';

import { State } from '@dsf/data-access-store';
import { useFaultPartTypeApi } from '@dsf/data-access-api';

import { FaultTypeAssetTypeTenantResponse } from '@dsf/util-types';

const iOSBoxShadow =
  '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

const StyledSlider = withStyles({
  root: {
    color: '#00677F',
    height: 2,
    padding: '15px 0',
    '@media screen and (max-width: 375px)': {
      'max-width': '95%',
    },
  },
  thumb: {
    height: 28,
    width: 28,
    backgroundColor: '#676767',
    boxShadow: iOSBoxShadow,
    marginTop: -14,
    marginLeft: -14,
    '&:focus, &:hover, &$active': {
      boxShadow:
        '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        boxShadow: iOSBoxShadow,
      },
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 12px)',
    top: -22,
    '& *': {
      background: 'transparent',
      color: '#000',
    },
  },
  track: {
    height: 2,
  },
  mark: {
    backgroundColor: '#bfbfbf',
    height: 10,
    width: 1,
    marginTop: -4,
  },
  rail: {
    height: 2,
    opacity: 0.5,
    backgroundColor: '#bfbfbf',
  },
  markLabel: {
    // reduce font size for labels on smaller screens (e.g. Iphone SE2)
    '@media screen and (max-width: 375px)': {
      fontSize: '12px',
      // not elegant but works
      maxWidth: '50px',
    },
  },
})(Slider);

type StyleProps = {
  isDesktop: UseQueriesProps['isDesktop'];
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    slider: {
      '&.MuiSlider-root.MuiSlider-vertical': {
        height: (props: StyleProps) => (props.isDesktop ? 'auto' : '300px'),
      },
      '&.MuiSlider-vertical .MuiSlider-thumb': {
        marginLeft: '-14px',
      },
    },
  })
);

interface Props {
  physicalAssetId: number;
  handleItemClick: (
    selectedCombination: FaultTypeAssetTypeTenantResponse
  ) => void;
}

const Top10: React.FC<Props> = (props) => {
  const { isDesktop } = useQueries();
  const classes = useStyles({ isDesktop });

  const { t } = useTranslation();
  const faultPartTypeApi = useFaultPartTypeApi();

  const [open, setOpen] = useState(true);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [combinations, setCombinations] = useState<
    FaultTypeAssetTypeTenantResponse[]
  >([]);
  const [timeframeDays, setTimeframeDays] = useState<number>(7);
  const [onlyMyFaults, setOnlyMyFaults] = useState<boolean>(false);

  const { profile } = useSelector((state: State) => state.identity);

  const getTopTenCombinations = async () => {
    try {
      const reporterId = onlyMyFaults ? (profile?.id as number) : undefined;
      const topCombinationsResponse = (
        await faultPartTypeApi.getTopTenCombinations(
          timeframeDays,
          props.physicalAssetId,
          reporterId
        )
      ).data;
      setCombinations(topCombinationsResponse);
      setLoaded(true);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const timeOutId = setTimeout(() => getTopTenCombinations(), 500);
    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [timeframeDays, onlyMyFaults]);

  const marks = [
    {
      value: 1,
      label: t('createFaultItem.topTen.sliderMarks.last24hours'),
    },
    {
      value: 30,
      label: t('createFaultItem.topTen.sliderMarks.lastMonth'),
    },
    {
      value: 90,
      label: t('createFaultItem.topTen.sliderMarks.last3Months'),
    },
    {
      value: 180,
      label: t('createFaultItem.topTen.sliderMarks.last6Months'),
    },
    {
      value: 365,
      label: t('createFaultItem.topTen.sliderMarks.lastYear'),
    },
  ];

  return (
    <Grid container className="section top10">
      <Box mb="1rem" mt="-15px">
        <InfoLabel text={t('createFaultItem.topTen.sliderLabel')} />
      </Box>

      <Box mb="1rem" width="100%" sx={{ px: '4rem' }}>
        <Grid item xs={12} className="top-ten-slider">
          <StyledSlider
            aria-label="slider"
            value={timeframeDays}
            valueLabelDisplay="off"
            // eslint-disable-next-line
            onChange={(event: any, newValue: number | number[]) =>
              setTimeframeDays(newValue as number)
            }
            min={1}
            max={365}
            marks={marks}
            orientation={isDesktop ? 'horizontal' : 'vertical'}
            className={classes.slider}
          />
        </Grid>
      </Box>

      <Grid item xs={12} className="my-faults-checkbox">
        <FormControlLabel
          control={
            <Checkbox
              checked={onlyMyFaults}
              icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
              checkedIcon={<CheckBoxIcon fontSize="large" />}
              name="checkedI"
              onChange={(e) => setOnlyMyFaults(e.target.checked)}
            />
          }
          label={t('createFaultItem.myFaults.checkbox')}
        />
      </Grid>

      {loaded ? (
        <Grid item xs={12} className="top-ten">
          <ListItem
            button
            onClick={() => setOpen(!open)}
            className="list_header"
          >
            <ListItemText
              primary={t('createFaultItem.topTen.title')
                .toString()
                .replace('{timeframeDays}', timeframeDays.toString())}
              className={'item_title'}
            />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
            className="hover_menu"
          >
            <List component="div" disablePadding className="list_body">
              {combinations.map(
                (combination: FaultTypeAssetTypeTenantResponse, idx) => {
                  return (
                    <>
                      <ListItem
                        key={`${combination.faultyPartName}${idx}`}
                        button
                        onClick={() => {
                          setOpen(false);
                          props.handleItemClick(combination);
                        }}
                      >
                        <ListItemText
                          primary={`${combination.faultyPartName}`}
                          secondary={`${combination.faultTypeName}`}
                        />
                      </ListItem>
                      <hr className="list_hr" />
                    </>
                  );
                }
              )}
            </List>
          </Collapse>
        </Grid>
      ) : (
        <Skeleton
          variant="rect"
          className="section item-image"
          width={340}
          height={500}
        />
      )}
    </Grid>
  );
};

export default Top10;
