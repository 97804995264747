import { API, APIResponse, useApi } from '..';
import { FaultTypeAssetTypeTenantResponse } from '@dsf/util-types';
import { ENDPOINT_FAULT_PART_TYPE } from '..';

const BASE = ENDPOINT_FAULT_PART_TYPE;
export const getTopTenCombinationsPath = (
  timeframeDays: number,
  physicalAssetId: number,
  reporterId?: number
): string =>
  `${BASE}/top10?timeframeDays=${timeframeDays}&physicalAssetId=${physicalAssetId}${
    reporterId ? `&reporterId=${reporterId}` : ''
  }`;

export interface FaultPartTypeAPI extends API {
  getTopTenCombinations: (
    timeframeDays: number,
    physicalAssetId: number,
    reporterId?: number
  ) => APIResponse<FaultTypeAssetTypeTenantResponse[]>;
}

export const useFaultPartTypeApi = (): FaultPartTypeAPI => {
  const api = useApi();

  return {
    ...api,
    getTopTenCombinations(timeframeDays, physicalAssetId, reporterId) {
      const cancelToken = api.prepareCancelToken('getTopTenCombinations');
      return api.authRequest.get(
        getTopTenCombinationsPath(timeframeDays, physicalAssetId, reporterId),
        { cancelToken }
      );
    },
  };
};
