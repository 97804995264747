import { Theme, useMediaQuery } from '@material-ui/core';

export type UseQueriesProps = {
  isMobile: ReturnType<typeof useMediaQuery>;
  isTablet: ReturnType<typeof useMediaQuery>;
  isDesktop: ReturnType<typeof useMediaQuery>;
};

export const useQueries = (): UseQueriesProps => {
  // const isMobile = useMediaQuery<Theme>((theme) =>
  //   theme.breakpoints.down('xs')
  // );
  const isMobile = useMediaQuery('(max-width:767px)');

  const isTablet = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'));
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'));

  return { isMobile, isTablet, isDesktop };
};
