import FilterListIcon from '@mui/icons-material/FilterList';
import GroupIcon from '@mui/icons-material/Group';
import { useWrapApi } from '@dsf/data-access-api';
import { AddUserForm } from '@dsf/feature-user-management';
import { State } from '@dsf/data-access-store';

import TableContainer from '@mui/material/TableContainer';
import { UiContentBox } from '@dsf/ui-content-box';
import { formatTime, USER_ROLES } from '@dsf/util-tools';
import {
  Checkbox,
  Skeleton,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
} from '@mui/material';
import { Box, Table, TablePagination } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useEffectOnce } from 'react-use';
import { withTenantLayout } from '@dsf/ui-layout';
import { orderBy } from 'lodash';
import ChatIcon from '@mui/icons-material/Chat';
import { useHistory } from 'react-router-dom';

type UserAttribute = {
  Name: string; // "family_name"
  Value: string; // "Bickici"
};

type UserType = {
  Attributes: UserAttribute[];
  Enabled: boolean;
  UserCreateDate: string; // '2022-06-07 08:21:19.982000+00:00';
  UserLastModifiedDate: string; //'2022-06-07 12:01:51.112000+00:00';
  UserStatus: string; // 'FORCE_CHANGE_PASSWORD';
  Username: string; // 'deniz.bickici@grundfos.digital-platform.io';
};

const CreateConversationPage = () => {
  const rowsPerPage = 10;

  const { api } = useWrapApi();
  const history = useHistory();
  const { roles } = useSelector((state: State) => state.userTokens);

  const [page, setPage] = useState(0);
  const [users, setUsers] = useState<UserType[]>([]);

  const [loading, setLoading] = useState(true);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const getAttribute = useCallback(
    (key: string, attributes: UserAttribute[]) => {
      return attributes.find((attribute) => attribute.Name === key)?.Value;
    },
    []
  );

  const fetchUsers = useCallback(async () => {
    if (api) {
      try {
        const { data } = await api.get('/user/management/search');
        const sortedUsers = orderBy(data, ['UserLastModifiedDate'], 'desc');
        setUsers(sortedUsers);
        setLoading(false);
      } catch (error) {
        console.error('fetch users error', error);
        setLoading(false);
      }
    }
  }, [api]);

  useEffectOnce(() => {
    fetchUsers();
  });

  const handleCreateConversation = async (username: string) => {
    try {
      const response = await api
        .post('/conversation', { receiver: username })
        .then((an: { data: { conversation_id: string } }) => {
          console.log(an);
          navigateToConversation(an.data.conversation_id);
        });
    } catch (error) {
      console.error('form submit error', error);
    }
  };

  const navigateToConversation = (conversationId: string) => {
    const conversationDetail = `/conversation/${conversationId}`;
    history.push(conversationDetail);
  };

  return (
    <Box maxWidth="721px" margin="0 auto">
      <div>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div>
            <h1 id="title_messages">Messages</h1>
          </div>
        </Box>
        <Box mt="2rem">
          <UiContentBox
            icon={
              <Box>
                <ChatIcon />
              </Box>
            }
            title={
              <Box
                display="flex"
                fontWeight="bold"
                fontSize="1.125rem"
                alignItems="center"
                id="title_equipment-overview"
              >
                New conversation
              </Box>
            }
          >
            <Box
              sx={{
                '& .MuiTableCell-root': {
                  p: '0 4px',
                },
              }}
            >
              {loading ? (
                <Skeleton
                  sx={{ transform: 'none' }}
                  width="100%"
                  height="287px"
                  animation="wave"
                />
              ) : (
                <div>
                  {users.length === 0 ? (
                    <Box textAlign="center" p="3rem">
                      No users available.
                    </Box>
                  ) : (
                    <>
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell sx={{ minWidth: '350px' }}>
                                User
                              </TableCell>
                              <TableCell sx={{ minWidth: '100px' }}></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {users
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((user) => {
                                return (
                                  <TableRow key={user.Username}>
                                    <TableCell>
                                      {getAttribute(
                                        'given_name',
                                        user.Attributes
                                      )
                                        ? getAttribute(
                                            'given_name',
                                            user.Attributes
                                          ) +
                                          ' ' +
                                          getAttribute(
                                            'family_name',
                                            user.Attributes
                                          )
                                        : user.Username}
                                    </TableCell>
                                    <TableCell align="right">
                                      <Button
                                        color="inherit"
                                        variant="outlined"
                                        id="button_register-asset"
                                        onClick={() => {
                                          handleCreateConversation(
                                            user.Username
                                          );
                                        }}
                                      >
                                        Start
                                      </Button>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[5]}
                        component="div"
                        count={users.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                      />
                    </>
                  )}
                </div>
              )}
            </Box>
          </UiContentBox>
        </Box>
      </div>
    </Box>
  );
};

export default withTenantLayout(CreateConversationPage)({
  pageName: 'DSFE-ChatPage',
});
