import React from 'react';
import './TextLabel.scss';

interface Props {
  content: string | React.ReactElement;
  label?: string;
  noBorder?: boolean;
}

const TextLabel: React.FC<Props> = ({ content, label, noBorder }) => {
  return (
    <div
      className="text-label"
      style={{
        border: noBorder ? 'none' : undefined,
      }}
    >
      <p className="text-label__label">{label}</p>
      <div className="text-label__content">{content}</div>
    </div>
  );
};

export default TextLabel;
