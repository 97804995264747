import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DefaultState } from './types/defaultState';

export interface LoginState extends DefaultState {
  email: string;
  // eslint-disable-next-line
  cognitoUser: any;
}

const initialLoginState: LoginState = {
  cognitoUser: undefined,
  email: '',
  persist: true,
};

export const loginSlice = createSlice({
  name: 'login',
  initialState: initialLoginState,
  reducers: {
    // eslint-disable-next-line
    setLoginEmail: (state, action: PayloadAction<any>) => {
      state.cognitoUser = action.payload;
      state.email = action.payload.username;
    },
  },
});
