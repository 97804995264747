import { NEWS } from '@dsf/util-router';
import { BellIcon } from '@dsf/ui-assets';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from '@material-ui/core/styles';
import React, { useState } from 'react';
// import ws from '../services/websocket';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 36,
      height: 36,
      backgroundColor: '#46484D',
    },
  })
);

const StyledBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      right: -6,
      top: -6,
      padding: '0 4px',
    },
  })
)(Badge);

export const NotificationBell: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();

  const [notificationCount, setNotificationCount] = useState(0);
  // ws.listen((x: number) => {
  //   return (function () {
  //     if (x !== undefined && x !== notificationCount) {
  //       // prevents infinite loops
  //       setNotificationCount(x);
  //     }
  //   })();
  // });

  const handleBellClick = () => {
    history.push(NEWS);
  };

  return (
    <IconButton
      className={classes.root}
      aria-label="notifications"
      id="notificationBell_button"
      color="primary"
      onClick={handleBellClick}
    >
      <StyledBadge
        badgeContent={notificationCount}
        color="secondary"
        showZero={false}
      >
        <BellIcon />
      </StyledBadge>
    </IconButton>
  );
};
