import { EyeCrossedIcon, MenuBrickIcon } from '@dsf/ui-assets';
import {
  ButtonDiv,
  ContentBox,
  FactoidList,
  ScreenTitle,
} from '../../../components';
import useFormatters from '../../../hooks/formatter';
import { Box, Collapse, createStyles, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { FaultItemDetailResponse } from '@dsf/util-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

const PRODUCT_DETAIL_LINE_COLOR = 'rgba(0, 0, 0, 0.6)';

const ASSET_PICTURE_RADIUS = '4px';

const useStyles = makeStyles(() =>
  createStyles({
    productDetailLine: {
      fontSize: '0.65rem',
      color: PRODUCT_DETAIL_LINE_COLOR,
      '& path': {
        fill: PRODUCT_DETAIL_LINE_COLOR,
      },
    },
    assetPictureWrap: {
      borderRadius: ASSET_PICTURE_RADIUS,
      overflow: 'hidden',
    },
    assetPicture: {
      borderRadius: ASSET_PICTURE_RADIUS,
      border: '1px solid rgba(45, 47, 54, 0.2)',
    },
    assetNumber: {
      wordBreak: 'break-word',
    },
  })
);

type Props = {
  faultData: FaultItemDetailResponse;
  assetPicture?: string;
};

const ProductDetail: React.FC<Props> = ({ faultData, assetPicture }) => {
  const { formatTime } = useFormatters();
  const { t } = useTranslation();
  const classes = useStyles();
  const [showAssetDetails, setShowAssetDetails] = useState(true);

  const handleToggleAssetDetails = () => {
    setShowAssetDetails((prev) => !prev);
  };

  const factoids = [
    {
      label: <>{t('faultDetailPage.productPartNumber')}</>,
      value: <span>{faultData.assetVersion}</span>,
    },
    {
      label: <>{t('faultDetailPage.productDescription')}</>,
      value: <span>{faultData.assetColour}</span>,
    },
    {
      label: <>{t('faultDetailPage.lastLocation')}</>,
      value: <span>{faultData.assetLocation}</span>,
    },
  ];

  return (
    <ContentBox
      icon={<MenuBrickIcon />}
      title={
        <Box display="flex" justifyContent="space-between">
          <Box>
            <Box
              fontSize="1.125rem"
              fontWeight="bold"
              id="faultDetailPage_assetNumber"
              className={classes.assetNumber}
            >
              {faultData.assetNumber}
            </Box>
            <Box color="#707483" fontSize="0.75rem" mt="0.25rem">
              {t('faultDetailPage.productDetail')}
            </Box>
          </Box>
          <Box
            className={classes.productDetailLine}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <ButtonDiv onClick={handleToggleAssetDetails}>
              <Box display="flex" alignItems="center">
                <Box mr="0.3rem">
                  {t(
                    showAssetDetails
                      ? 'faultDetailPage.collapse'
                      : 'faultDetailPage.expand'
                  )}
                </Box>
                {showAssetDetails ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </Box>
            </ButtonDiv>
          </Box>
        </Box>
      }
    >
      <Collapse in={showAssetDetails}>
        <>
          <Box className={classes.assetPictureWrap} mb="1rem" maxWidth="400px">
            {assetPicture ? (
              <img
                className={classes.assetPicture}
                alt="top"
                height="180px"
                src={assetPicture}
                id="faultDetailPage_assetPicture"
              />
            ) : (
              <Skeleton variant="rect" width={400} height={400} />
            )}
          </Box>
          <FactoidList factoids={factoids} />
        </>
      </Collapse>
    </ContentBox>
  );
};

export default ProductDetail;
