import React from 'react';
import { useHistory } from 'react-router-dom';
import { PlusIcon } from '@dsf/ui-assets';
import './FloatingPlusIcon.scss';
import { FloatingPlusIconInterface } from '@dsf/util-types';

const FloatingPlusIcon: React.FC<FloatingPlusIconInterface> = ({
  newPage,
  iconID,
}) => {
  const history = useHistory();
  return (
    <div
      className={'floating-plus-icon'}
      id={iconID}
      onClick={() => {
        history.push(newPage);
      }}
    >
      <PlusIcon className={'new-icon'} />
    </div>
  );
};

export default FloatingPlusIcon;
