import {
  useFaultItemApi,
  useLocationApi,
  usePhysicalAssetApi,
  useUserApi,
} from '@dsf/data-access-api';

import {
  FaultAccordionList,
  ScreenText,
  ScreenTitle,
  TextLabel,
} from '../../../components';
import LocationScanner, {
  LocationOption,
} from '../../../components/location-scanner';
import { withTenantLayout } from '@dsf/ui-layout';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { Skeleton } from '@material-ui/lab';
import { FaultItem, FaultItemListResponse } from '@dsf/util-types';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import FinishConfirmation from './finish-confirmation';

type ParamsType = {
  id: string;
  serial: string;
  tenant: string;
};

const MarkProductAsFinishedPage: React.FC = () => {
  const { t } = useTranslation();

  const faultItemApi = useFaultItemApi();
  const physicalAssetApi = usePhysicalAssetApi();
  const locationApi = useLocationApi();
  const userApi = useUserApi();

  const { id, serial } = useParams<ParamsType>();
  const locationRef = useRef<HTMLDivElement>(null);

  const [loaded, setLoaded] = useState<boolean>(false);
  const [notFound, setNotFound] = useState<boolean>(false);
  const [assetPicture, setAssetPicture] = useState<string>();
  const [physicalAsset, setPhysicalAsset] = useState<FaultItemListResponse>();
  const [editLocation, setEditLocation] = useState(false);
  const [lastLocation, setLastLocation] = useState<LocationOption>();
  const [faultItems, setFaultItems] = useState<FaultItem[]>([]);

  const handleLocationChange = (locationOption: LocationOption) => {
    setEditLocation(false);
    setLastLocation(locationOption);
  };

  useEffect(() => {
    const getPhysicalAsset = async () => {
      setLoaded(false);
      try {
        const detailResponse = await physicalAssetApi.getAll(
          parseInt(id),
          serial
        );
        const physicalAsset = detailResponse.data[0];
        setFaultItems(physicalAsset.faultItems);
        setPhysicalAsset(physicalAsset);
      } catch (error) {
        setNotFound(true);
      }
      setLoaded(true);
    };
    getPhysicalAsset();
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    setLoaded(false);
    const fetchLastLocation = async () => {
      try {
        const response = await locationApi.getFinalLocation();
        setLastLocation({
          ...response?.data[0],
          label: response?.data[0]?.segmentName,
        });
      } catch (error) {
        setNotFound(true);
      }
      setLoaded(true);
    };

    const getCurrentLocation = async () => {
      try {
        const getLocationResponse = await userApi.getUserProfileLocation();
        setLastLocation({
          ...getLocationResponse.data,
          label: getLocationResponse.data.segmentName,
        });
        setLoaded(true);
      } catch (error) {
        if (error.status === 404) {
          await fetchLastLocation();
        } else {
          console.error(error);
        }
      }
    };
    getCurrentLocation();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const getAssetPicture = async () => {
      const assetPictureId = physicalAsset?.pictureId;
      if (!assetPictureId || assetPicture) {
        return;
      }

      try {
        const assetPictureResponse = (
          await faultItemApi.getImageBase64(assetPictureId)
        ).data;
        setAssetPicture(`data:image/png;base64,${assetPictureResponse}`);
      } catch (error) {
        console.log(error);
      }
    };
    getAssetPicture();
    // eslint-disable-next-line
  }, [physicalAsset?.pictureId]);

  useEffect(() => {
    if (locationRef && locationRef.current && editLocation) {
      locationRef.current.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }
  }, [editLocation]);

  if (!loaded && !notFound) {
    return (
      <div className="fault-item-detail">
        <div className="loader">
          <CircularProgress color="secondary" />
          <Typography variant="body1">
            {t('faultItemDetailPage.loading')}
          </Typography>
        </div>
      </div>
    );
  }

  if (loaded && notFound) {
    return (
      <Typography variant="h5">{t('faultItemDetailPage.notFound')}</Typography>
    );
  }

  return (
    <>
      <ScreenTitle id="markProductAsFinishedPage_title">
        {t('markProductAsFinishedPage.headline')}
      </ScreenTitle>
      <ScreenText gutterBottom>
        {t('markProductAsFinishedPage.intro')}
      </ScreenText>

      <Typography
        variant="h5"
        component="h1"
        id="markProductAsFinishedPage_assetNumber"
      >
        {physicalAsset?.assetNumber}
      </Typography>

      <Box mb="1rem">
        {assetPicture ? (
          <img
            alt="Asset"
            src={assetPicture}
            id="markProductAsFinishedPage_assetPicture"
          />
        ) : (
          <Box maxWidth={400}>
            <Skeleton variant="rect" height={200} />
          </Box>
        )}
      </Box>

      <Box mb="1rem">
        <TextLabel
          content={physicalAsset?.assetNumber || ''}
          label={t('markProductAsFinishedPage.productPartNumber')}
        />
        <TextLabel
          content={physicalAsset?.productDescription || ''}
          label={t('markProductAsFinishedPage.productDescription')}
        />
      </Box>

      <ScreenText>
        {t('markProductAsFinishedPage.locationDescription')}
      </ScreenText>

      <div ref={locationRef}>
        <Box display="flex" alignItems="center" mb="1rem">
          <TextLabel
            content={lastLocation?.label || ''}
            label={t('markProductAsFinishedPage.lastLocation')}
          />
          <IconButton
            aria-label="edit-location"
            onClick={() => {
              setEditLocation((previousState) => !previousState);
            }}
          >
            <EditIcon />
          </IconButton>
        </Box>
      </div>
      {editLocation && (
        <Box mb="1rem">
          <LocationScanner onChange={handleLocationChange} />
        </Box>
      )}
      <Box mb="1rem">
        <FaultAccordionList faultItems={faultItems} />
      </Box>
      {physicalAsset && lastLocation && (
        <FinishConfirmation
          physicalAsset={physicalAsset}
          lastLocation={lastLocation}
          faultItemCount={faultItems.length}
        />
      )}
    </>
  );
};

export default withTenantLayout(MarkProductAsFinishedPage)({
  pageName: 'SCR-98',
});
