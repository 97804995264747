import FilterListIcon from '@mui/icons-material/FilterList';
import GroupIcon from '@mui/icons-material/Group';
import { useWrapApi } from '@dsf/data-access-api';
import { AddUserForm } from '@dsf/feature-user-management';
import { State } from '@dsf/data-access-store';

import TableContainer from '@mui/material/TableContainer';
import { UiContentBox } from '@dsf/ui-content-box';
import { formatTime, USER_ROLES } from '@dsf/util-tools';
import {
  Checkbox,
  Skeleton,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { Box, Table, TablePagination } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useEffectOnce } from 'react-use';
import { withTenantLayout } from '@dsf/ui-layout';
import { orderBy } from 'lodash';

type UserAttribute = {
  Name: string; // "family_name"
  Value: string; // "Bickici"
};

type UserType = {
  Attributes: UserAttribute[];
  Enabled: boolean;
  UserCreateDate: string; // '2022-06-07 08:21:19.982000+00:00';
  UserLastModifiedDate: string; //'2022-06-07 12:01:51.112000+00:00';
  UserStatus: string; // 'FORCE_CHANGE_PASSWORD';
  Username: string; // 'deniz.bickici@grundfos.digital-platform.io';
};

export const UserList = () => {
  const rowsPerPage = 5;

  const { api } = useWrapApi();
  const { roles } = useSelector((state: State) => state.userTokens);

  const [page, setPage] = useState(0);
  const [users, setUsers] = useState<UserType[]>([]);

  const [loading, setLoading] = useState(true);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const getAttribute = useCallback(
    (key: string, attributes: UserAttribute[]) => {
      return attributes.find((attribute) => attribute.Name === key)?.Value;
    },
    []
  );

  const fetchUsers = useCallback(async () => {
    if (api) {
      try {
        const { data } = await api.get('/user/management/search');
        const sortedUsers = orderBy(data, ['UserLastModifiedDate'], 'desc');
        setUsers(sortedUsers);
        setLoading(false);
      } catch (error) {
        console.error('fetch users error', error);
        setLoading(false);
      }
    }
  }, [api]);

  useEffectOnce(() => {
    fetchUsers();
  });

  // eslint-disable-next-line
  if (!roles.includes(USER_ROLES.admin)) {
    // TODO: jm - remove any and use superadmin role
    return (
      <Box textAlign="center">
        You don't have permission to access this page.
      </Box>
    );
  }

  return (
    <Box maxWidth="721px" margin="0 auto">
      <h1>User Management</h1>
      <UiContentBox
        icon={
          <Box>
            <GroupIcon />
          </Box>
        }
        title={
          <Box fontWeight="bold" fontSize="1.125rem">
            Users
          </Box>
        }
        postfix={
          loading ? (
            <Skeleton width="183px" variant="text" />
          ) : (
            <Box display="flex" color="GrayText">
              <Box mt="0.15rem" mr="0.3rem">
                Sorted by <strong>Date Modified</strong>
              </Box>
              <Box>
                <FilterListIcon />
              </Box>
            </Box>
          )
        }
      >
        <Box
          sx={{
            '& .MuiTableCell-root': {
              p: '0 4px',
            },
          }}
        >
          {loading ? (
            <Skeleton
              sx={{ transform: 'none' }}
              width="100%"
              height="287px"
              animation="wave"
            />
          ) : (
            <div>
              {users.length === 0 ? (
                <Box textAlign="center" p="3rem">
                  No users available.
                </Box>
              ) : (
                <>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ minWidth: '350px' }}>
                            User Name
                          </TableCell>
                          <TableCell sx={{ minWidth: '70px' }}>
                            Enabled
                          </TableCell>
                          <TableCell sx={{ minWidth: '60px' }}>
                            Verified
                          </TableCell>
                          <TableCell sx={{ minWidth: '210px' }}>
                            Status
                          </TableCell>
                          <TableCell sx={{ minWidth: '90px' }}>
                            Tenant
                          </TableCell>
                          <TableCell sx={{ minWidth: '100px' }}>
                            First Name
                          </TableCell>
                          <TableCell sx={{ minWidth: '160px' }}>
                            Last Name
                          </TableCell>
                          <TableCell sx={{ minWidth: '220px' }}>
                            Email
                          </TableCell>
                          <TableCell sx={{ minWidth: '150px' }}>
                            Date Created
                          </TableCell>
                          <TableCell sx={{ minWidth: '150px' }}>
                            Date Modified
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {users
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((user) => {
                            return (
                              <TableRow key={user.Username}>
                                <TableCell>{user.Username}</TableCell>
                                <TableCell>
                                  <Checkbox
                                    readOnly
                                    size="small"
                                    color="success"
                                    checked={user.Enabled}
                                  />
                                </TableCell>
                                <TableCell>
                                  <Checkbox
                                    readOnly
                                    size="small"
                                    color="success"
                                    checked={
                                      getAttribute(
                                        'email_verified',
                                        user.Attributes
                                      ) === 'true'
                                    }
                                  />
                                </TableCell>
                                <TableCell>{user.UserStatus}</TableCell>

                                <TableCell>
                                  {getAttribute(
                                    'custom:tenant_id',
                                    user.Attributes
                                  )}
                                </TableCell>
                                <TableCell>
                                  {getAttribute('given_name', user.Attributes)}
                                </TableCell>
                                <TableCell>
                                  {getAttribute('family_name', user.Attributes)}
                                </TableCell>
                                <TableCell>
                                  {getAttribute('email', user.Attributes)}
                                </TableCell>
                                <TableCell>
                                  {formatTime(user.UserCreateDate)}
                                </TableCell>
                                <TableCell>
                                  {formatTime(user.UserLastModifiedDate)}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5]}
                    component="div"
                    count={users.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                  />
                </>
              )}
            </div>
          )}
        </Box>
      </UiContentBox>
      <AddUserForm />
    </Box>
  );
};

export const UserListPage = withTenantLayout(UserList)({
  pageName: 'USER-LIST',
});
