import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useTheme } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';
import { USER_ROLES } from '@dsf/util-tools';
import { useSelector } from 'react-redux';
import { State } from '@dsf/data-access-store';

type Props = {
  open: boolean;
  onConfirm: () => void;
  onClose: () => void;
  loading: boolean;
};

export const ConfirmDecommission = ({
  open,
  onConfirm,
  onClose,
  loading,
}: Props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { roles } = useSelector((state: State) => state.userTokens);
  const isAdmin = roles.includes(USER_ROLES.admin);

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        Do you really want to decommission this asset?
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          The asset will be moved to the decommissioned list.
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Button
          variant="outlined"
          color="error"
          onClick={onClose}
          sx={{ mr: '3rem' }}
          disabled={loading}
        >
          No
        </Button>

        <Button
          startIcon={
            loading ? (
              <CircularProgress size="20px" color="inherit" />
            ) : (
              <DeleteForeverIcon />
            )
          }
          variant="contained"
          color="error"
          onClick={() => {
            onConfirm();
          }}
          autoFocus
          disabled={!isAdmin}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
