import { appSettingsSlice, hasFeature, State } from '@dsf/data-access-store';

import React, { useEffect, useRef, useState } from 'react';
import PinDropIcon from '@mui/icons-material/PinDrop';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { withTenantLayout } from '@dsf/ui-layout';
import BuildIcon from '@mui/icons-material/Build';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import {
  Autocomplete,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from '@mui/material';
import { Button, CircularProgress, TableHead } from '@mui/material';
import { UiContentBox } from '@dsf/ui-content-box';
import Grid from '@mui/material/Grid';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import { useWrapApi } from '@dsf/data-access-api';
import { useDispatch, useSelector } from 'react-redux';
import { Messenger } from '@dsf/feature-conversations';
import { extractRoleFromToken, USER_ROLES } from '@dsf/util-tools';

const formatCurrency = (value: number) => {
  const result = value.toLocaleString('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0,
  });

  return result;
};

type Consumable = {
  id: number;
  thing: string;
  label?: string;
  Location: string;
  price_euro: number;
  Note: string;
  date: string;
  deviceType: string;
};

type FieldSummary = {
  devices_in_field: number;
  devices_revenue_euro_month: number;
  devices_need_attention: number;
  service_revenue_euro_month: number;
  service_orders_month: number;
  service_need_attention: number;
  consumables_revenue_euro_month: number;
  consumables_orders_month: number;
  consumables_need_attention: number;
};

type MarkerType = {
  txt: string;
  thing_id: string;
  type: string;
  lat: number;
  lng: number;
};

type FieldData = {
  name: string;
  map: {
    center_gps: { lat: number; lng: number };
    points: MarkerType[];
  };
  summary: FieldSummary;
  orders_consumables: Consumable[];
  orders_service: Consumable[];
};

type FieldType = {
  id: string;
  name: string;
};

const FieldOverviewPage = () => {
  const { api } = useWrapApi();
  const history = useHistory();
  const dispatch = useDispatch();
  const { secrets } = useSelector((state: State) => state.appSettings);
  const { t } = useTranslation();

  const [fields, setFields] = useState<FieldType[]>([]);
  const [loadingFields, setLoadingFields] = useState(false);
  const [errorFields, setErrorFields] = useState(false);
  const [selectedField, setSelectedField] = useState<FieldType | null>(null);
  const mapRef = useRef<HTMLDivElement>(null);

  const consumablesRef = useRef<HTMLDivElement>(null);
  const scrollToConsumables = () => {
    if (consumablesRef.current) {
      consumablesRef.current.scrollIntoView();
    }
  };

  const servicesRef = useRef<HTMLDivElement>(null);
  const scrollToServices = () => {
    if (servicesRef.current) {
      servicesRef.current.scrollIntoView();
    }
  };

  const [fieldData, setFieldData] = useState<FieldData | undefined>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSecrets = async () => {
      try {
        // eslint-disable-next-line
        const data: any = await api.get('/secrets');
        if (data && data.data) {
          dispatch(appSettingsSlice.actions.setSecrets(data.data));
        }
      } catch (error) {
        console.error('secrets error', error);
      }
    };
    fetchSecrets();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const SCRIPT_ID = 'google-map-api-script';
    const scriptExists = document.getElementById(SCRIPT_ID);

    if (secrets?.gmap_api_key && !scriptExists) {
      const script = document.createElement('script');
      script.setAttribute('id', SCRIPT_ID);
      script.src = `https://maps.googleapis.com/maps/api/js?key=${secrets.gmap_api_key}&v=weekly`;
      script.async = true;
      document.body.appendChild(script);
    }
  }, [secrets]);

  useEffect(() => {
    setLoadingFields(true);
    api
      .get('/fields')
      .then(({ data }) => {
        const dataFields = data as FieldType[];
        setFields(dataFields);
        setLoadingFields(false);
        if (dataFields.length) {
          setSelectedField(dataFields[0]);
        }
      })
      .catch((error) => {
        console.error('locations error', error);
        setErrorFields(false);
        setLoadingFields(false);
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedField) {
      setLoading(true);
      api
        .get(`/fields/${selectedField.id}`)
        .then(({ data }) => {
          setFieldData(data);
          setLoading(false);
        })
        .catch((error) => {
          console.error('locations error', error);
          setFieldData(undefined);
          setLoading(false);
        });
    } else {
      setFieldData(undefined);
    }
    // eslint-disable-next-line
  }, [selectedField]);

  useEffect(() => {
    if (!secrets?.gmap_api_key) {
      return;
    }
    const initMap = (mapRef: HTMLDivElement, fieldData: FieldData): void => {
      // const myLatLng = { lat: 51.198328172343444, lng: 4.404311039450503 };
      // const myLatLng = { lat: 54.24352, lng: -42.370899 };
      // const myLatLng = { lat: 51.364973, lng: 4.27035 };
      const mapstyle =[
        {
          featureType: "poi",
          elementType: "labels",
          stylers: [
                { visibility: "off" }
          ]
        },
        {
          featureType: "transit",
          elementType: "station",
          stylers: [
                { visibility: "off" }
          ]
        }
      ];
      const map = new google.maps.Map(mapRef as HTMLElement, {
        center: fieldData.map.center_gps,
        mapTypeId: "roadmap",
        styles: mapstyle,
        clickableIcons: false,
      });

      const mapMarkers = fieldData.map?.points.map((item) => {
        return {
          position: {
            lat: item.lat,
            lng: item.lng,
          },
          map,
          //   dsfLink: item.link,
          dsfLabel: item.txt,
          thingType: item.type != undefined ? item.type: 'asset',
          thingId: item.thing_id,         
        };
      });

      const bounds  = new google.maps.LatLngBounds();
      mapMarkers.forEach((item) => {
        const marker = new google.maps.Marker(item);
        const infowindow = new google.maps.InfoWindow({
          content: `<a href="/dsfe/${item.thingType}-detail/${item.thingId}">${item.dsfLabel}</a>`,
          position: item.position,
        });
        const loc = new google.maps.LatLng(item.position);
        bounds.extend(loc);
        google.maps.event.addListener(marker, 'click', function() {
          infowindow.open({
            anchor: marker,
            map,
          });
        });
        map.fitBounds(bounds);
      });         
    };
    setTimeout(() => {
      if (mapRef && mapRef.current && fieldData) {
        initMap(mapRef.current, fieldData);
      }
    }, 250);
  }, [fieldData, mapRef, secrets]);

  if (!hasFeature('DSF/E')) {
    return <div>Your DSF/E licence is not active.</div>;
  }

  if (loadingFields) {
    return (
      <Box display="flex" justifyContent="center" p="2rem">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box maxWidth="721px" margin="0 auto">
      <div>
        <UiContentBox
          icon={
            <Box>
              <PinDropIcon />
            </Box>
          }
          title={
            <Box
              display="flex"
              fontWeight="bold"
              fontSize="1.125rem"
              alignItems="center"
              id="title_field-overview"
            >
              {t('fieldOverview.title')}
            </Box>
          }
          postfix={
            <Autocomplete
              id="autocomplete_location"
              disabled={loadingFields}
              options={fields}
              getOptionLabel={(option: FieldType) => option.name}
              value={selectedField}
              //   eslint-disable-next-line
              onChange={(event: any, newValue: FieldType | null) => {
                setSelectedField(newValue);
              }}
              sx={{ minWidth: '300px' }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={errorFields ? 'Unable to fetch fields' : 'Location'}
                  disabled={errorFields}
                  variant="outlined"
                  size="small"
                  id="text_location"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loadingFields ? (
                          <CircularProgress
                            color="inherit"
                            size={20}
                            sx={{ mr: '2rem' }}
                            id="cp_location"
                          />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          }
        >
          {loading ? (
            <Box display="flex" justifyContent="center" p="2rem">
              <CircularProgress />
            </Box>
          ) : !fieldData ? (
            <Box
              display="flex"
              justifyContent="center"
              p="2rem"
              id="box_sel-location"
            >
              {t('fieldOverview.selectLocationMenu')}
            </Box>
          ) : (
            <>
              <Box
                sx={{
                  m: '1.5rem 0',
                  fontSize: '1rem',
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                  letterSpacing: '1px',
                  textAlign: 'center',
                }}
              >
                <Box
                  sx={{
                    display: 'inline',
                    fontWeight: 'normal',
                    letterSpacing: 'normal',
                    fontSize: '0.8rem',
                  }}
                >
                  {fieldData.summary.devices_in_field}{' '}
                  {fieldData.summary.devices_in_field == 1 // eslint-disable-line
                    ? t('fieldOverview.deviceInField')
                    : t('fieldOverview.devicesInField')}
                </Box>
                <br />
                {fieldData.name}{' '}
              </Box>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <ShowChartIcon sx={{ mr: '0.8rem' }} />
                    <Box>
                      <Box
                        sx={{
                          fontSize: '1rem',
                          fontWeight: 'bold',
                        }}
                      >
                        {formatCurrency(
                          fieldData.summary.devices_revenue_euro_month
                        )}
                      </Box>
                      <Box>{t('fieldOverview.revenueThisMonth')}</Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    onClick={() => scrollToServices()}
                    sx={{
                      textTransform: 'none',
                      textAlign: 'left',
                      fontWeight: 'normal',
                      lineHeight: '1.43',
                    }}
                    id="button_scroll-to-services1"
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <GppMaybeIcon sx={{ mr: '0.8rem' }} />
                      <Box>
                        <Box
                          sx={{
                            fontSize: '1rem',
                            fontWeight: 'bold',
                          }}
                        >
                          {fieldData.summary.devices_need_attention}
                        </Box>
                        <Box>
                          {fieldData.summary.devices_need_attention == 1 // eslint-disable-line
                            ? t('fieldOverview.deviceNeedAttention')
                            : t('fieldOverview.devicesNeedAttention')}
                        </Box>
                      </Box>
                    </Box>
                  </Button>
                </Grid>
              </Grid>
              <Box sx={{ m: '1.5rem 0' }}>
                {t('fieldOverview.serviceOrders')}
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <AutoGraphIcon sx={{ mr: '0.8rem' }} />
                    <Box>
                      <Box
                        sx={{
                          fontSize: '1rem',
                          fontWeight: 'bold',
                        }}
                      >
                        {formatCurrency(
                          fieldData.summary.service_revenue_euro_month
                        )}
                      </Box>
                      <Box>
                        {t('fieldOverview.serviceRevenueThisMonth')},{' '}
                        {fieldData.summary.service_orders_month}{' '}
                        {t('fieldOverview.orders')}
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    onClick={() => scrollToServices()}
                    sx={{
                      textTransform: 'none',
                      textAlign: 'left',
                      fontWeight: 'normal',
                      lineHeight: '1.43',
                    }}
                    id="button_scroll-to-services2"
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <BuildIcon sx={{ mr: '0.8rem' }} />
                      <Box>
                        <Box
                          sx={{
                            fontSize: '1rem',
                            fontWeight: 'bold',
                          }}
                        >
                          {fieldData.summary.service_need_attention}
                        </Box>
                        <Box>
                          {fieldData.summary.service_need_attention == 1 // eslint-disable-line
                            ? t('fieldOverview.newServiceOrderProcessed')
                            : t('fieldOverview.newServiceOrdersProcessed')}
                        </Box>
                      </Box>
                    </Box>
                  </Button>
                </Grid>
              </Grid>
              <Box sx={{ m: '1.5rem 0' }}>
                {t('fieldOverview.consumableOrders')}
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <SignalCellularAltIcon sx={{ mr: '0.8rem' }} />
                    <Box>
                      <Box
                        sx={{
                          fontSize: '1rem',
                          fontWeight: 'bold',
                        }}
                      >
                        {formatCurrency(
                          fieldData.summary.consumables_revenue_euro_month
                        )}
                      </Box>
                      <Box>
                        {t('fieldOverview.consumableRevenueThisMonth')},{' '}
                        {fieldData.summary.consumables_orders_month}{' '}
                        {t('fieldOverview.orders')}
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    onClick={() => scrollToConsumables()}
                    sx={{
                      textTransform: 'none',
                      textAlign: 'left',
                      fontWeight: 'normal',
                      lineHeight: '1.43',
                    }}
                    id="button_scroll-to-services3"
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <ShoppingCartCheckoutIcon sx={{ mr: '0.8rem' }} />
                      <Box>
                        <Box
                          sx={{
                            fontSize: '1rem',
                            fontWeight: 'bold',
                          }}
                        >
                          {fieldData.summary.consumables_need_attention}
                        </Box>
                        <Box>
                          {fieldData.summary.consumables_need_attention == 1 // eslint-disable-line
                            ? t('fieldOverview.newConsumableOrderProcessed')
                            : t('fieldOverview.newConsumableOrdersProcessed')}
                        </Box>
                      </Box>
                    </Box>
                  </Button>
                </Grid>
              </Grid>
              <Box sx={{ m: '2rem 0' }}>
                <div ref={mapRef} id="map" style={{ height: '500px' }}></div>
                {/* <a href="/dsfe/asset-detail/Pump-DDA120-7">
              <img src={MapPng} alt="Map" />
            </a> */}
              </Box>
              {fieldData.orders_consumables.length > 0 && (
                <Box sx={{ m: '2rem 0' }} ref={consumablesRef}>
                  <Box
                    sx={{
                      m: '1rem 0',
                    }}
                  >
                    {t('fieldOverview.consumableOrders')}
                  </Box>
                  <TableContainer component={Paper}>
                    <Table
                      sx={{
                        minWidth: 650,
                        '& .MuiTableCell-root': {
                          fontSize: '0.8rem',
                          p: '0.5rem',
                        },
                      }}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell width="100px">
                            {' '}
                            {t('fieldOverview.location')}
                          </TableCell>
                          <TableCell align="right">
                            {t('fieldOverview.note')}
                          </TableCell>
                          <TableCell align="right" width="140px">
                            {t('fieldOverview.date')}
                          </TableCell>
                          <TableCell align="right">
                            {t('fieldOverview.id')}
                          </TableCell>
                          <TableCell align="right" width="100px">
                            {t('fieldOverview.priceEuro')}
                          </TableCell>
                          <TableCell align="right" width="200px">
                            {t('fieldOverview.thing')}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {fieldData.orders_consumables.map((row) => {
                          return (
                            <TableRow
                              key={row.id}
                              sx={{
                                '&:last-child td, &:last-child th': {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {row.Location}
                              </TableCell>
                              <TableCell align="right">{row.Note}</TableCell>
                              <TableCell align="right">{row.date}</TableCell>
                              <TableCell align="right">{row.id}</TableCell>
                              <TableCell align="right">
                                {formatCurrency(row.price_euro)}
                              </TableCell>
                              <TableCell align="right">
                                <Button
                                  variant="text"
                                  onClick={() => {
                                    history.push(
                                      row.deviceType
                                        ? `/dsfe/${row.deviceType}-detail/${row.thing}`
                                        : `/dsfe/asset-detail/${row.thing}`
                                    );
                                  }}
                                  sx={{
                                    p: 0,
                                    textAlign: 'right',
                                    justifyContent: 'flex-end',
                                    width: '100%',
                                    fontSize: '0.8rem',
                                    lineHeight: 1,
                                  }}
                                  id="button_go_to_asset_detail1"
                                >
                                  {row?.label ? row?.label : row.thing}
                                </Button>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              )}
              {fieldData.orders_service.length > 0 && (
                <Box sx={{ m: '2rem 0' }} ref={servicesRef}>
                  <Box
                    sx={{
                      m: '1rem 0',
                    }}
                  >
                    {t('fieldOverview.serviceOrders')}
                  </Box>
                  <TableContainer component={Paper}>
                    <Table
                      sx={{
                        minWidth: 650,
                        '& .MuiTableCell-root': {
                          fontSize: '0.8rem',
                          p: '0.5rem',
                        },
                      }}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell width="100px">
                            {t('fieldOverview.location')}
                          </TableCell>
                          <TableCell align="right">
                            {t('fieldOverview.note')}
                          </TableCell>
                          <TableCell align="right" width="140px">
                            {t('fieldOverview.date')}
                          </TableCell>
                          <TableCell align="right">
                            {t('fieldOverview.id')}
                          </TableCell>
                          <TableCell align="right" width="100px">
                            {t('fieldOverview.priceEuro')}
                          </TableCell>
                          <TableCell align="right" width="200px">
                            {t('fieldOverview.thing')}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {fieldData.orders_service.map((row) => {
                          return (
                            <TableRow
                              key={row.id}
                              sx={{
                                '&:last-child td, &:last-child th': {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {row.Location}
                              </TableCell>
                              <TableCell align="right">{row.Note}</TableCell>
                              <TableCell align="right">{row.date}</TableCell>
                              <TableCell align="right">{row.id}</TableCell>
                              <TableCell align="right">
                                {formatCurrency(row.price_euro)}
                              </TableCell>
                              <TableCell align="right">
                                <Button
                                  variant="text"
                                  onClick={() => {
                                    history.push(
                                      row.deviceType
                                        ? `/dsfe/${row.deviceType}-detail/${row.thing}`
                                        : `/dsfe/asset-detail/${row.thing}`
                                    );
                                  }}
                                  sx={{
                                    p: 0,
                                    textAlign: 'right',
                                    justifyContent: 'flex-end',
                                    width: '100%',
                                    fontSize: '0.8rem',
                                    lineHeight: 1,
                                  }}
                                  id="button_go_to_asset_detail2"
                                >
                                  {row.label ? row.label : row.thing}
                                </Button>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              )}
              <Box sx={{ m: '2rem 0' }}>
                <Messenger type="field-overview" id={selectedField?.id} />
              </Box>
            </>
          )}
        </UiContentBox>
      </div>
    </Box>
  );
};

export default withTenantLayout(FieldOverviewPage)({
  pageName: 'FieldOverviewPage',
});
