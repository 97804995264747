import React from 'react';
import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      backgroundColor: '#fff',
      width: 'calc(50% - 5px)',
      minHeight: 92,
      border: '1px solid #D3D3D3',
      borderRadius: 10,
      padding: 10,
    },
    value: {
      fontSize: '1.75rem',
      fontWeight: 'bold',
      color: 'var(--secondary-color)',
    },
    description: {
      position: 'absolute',
      bottom: 10,
      left: 10,
      fontSize: '0.75rem',
      color: 'var(--text-color)',
      marginTop: '-0.25rem',
    },
    icon: {
      position: 'absolute',
      top: 15,
      right: 15,
      '& path': {
        fill: '#D3D3D3',
      },
    },
    active: {
      backgroundColor: theme.palette.secondary.main,
    },
  })
);

interface Props {
  description: React.FC;
  value: React.FC;
  icon: React.FC;
  id: string;
}

const KpiItem: React.FC<Props> = ({
  value: Value,
  description: Description,
  icon: Icon,
  id,
}) => {
  const classes = useStyles();

  return (
    <div className={`${classes.root}`} id={id}>
      <div className={classes.value}>
        <Value />
      </div>
      <div className={classes.description}>
        <Description />
      </div>
      <div className={classes.icon}>
        <Icon />
      </div>
    </div>
  );
};

export default KpiItem;
