import { Alert, Box, CircularProgress, Snackbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useQueries } from '@dsf/util-tools';
import { useTranslation } from 'react-i18next';

type Props = {
  open: boolean;
  initialIndicatorCheck: boolean;
};

export const PumpIndicator = ({
  open: openProps,
  initialIndicatorCheck,
}: Props) => {
  const { isMobile } = useQueries();
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [showNotRunningMessage, setShowNotRunningMessage] = useState(false);
  const pumpIndicatorTimeout = useRef<any>();

  const handleClose = (event: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    setOpen(openProps);
  }, [openProps]);

  useEffect(() => {
    if (!openProps && !initialIndicatorCheck) {
      setOpen(true);
      setShowNotRunningMessage(true);
      pumpIndicatorTimeout.current = setTimeout(() => {
        setOpen(false);
      }, 9000);
    } else {
      clearTimeout(pumpIndicatorTimeout.current);
      setShowNotRunningMessage(false);
    }
  }, [openProps, initialIndicatorCheck]);

  return (
    <Snackbar
      onClose={handleClose}
      open={initialIndicatorCheck ? true : open}
      //   autoHideDuration={!openProps && !initialIndicatorCheck ? 9000 : undefined}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      sx={{
        width: '100%',
        left: isMobile ? 0 : undefined,
        right: isMobile ? 0 : undefined,
        maxWidth: isMobile ? undefined : '200px',
        position: isMobile ? 'relative' : undefined,
        ml: isMobile ? '0' : '12rem',
        mt: isMobile ? '0' : '-1.1rem',
        zIndex: isMobile ? 0 : undefined,
      }}
    >
      {showNotRunningMessage ? (
        <Alert
          variant="standard"
          icon={false}
          severity="error"
          sx={{
            width: '100%',
            bgcolor: isMobile ? '#2d2f36' : 'transparent',
            color: '#ff0000',
          }}
        >
          <Box display="flex">
            <Box
              sx={{
                position: 'relative',
                top: '-3px',
                transform: 'scale(0.5)',
                '& path, & rect': {
                  fill: '#ff0000',
                },
              }}
            >
              <Box sx={{ position: 'absolute', transform: 'scale(2)' }}>
                <svg
                  version="1.1"
                  id="loader-1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="40px"
                  height="40px"
                  viewBox="0 0 40 40"
                  enableBackground="new 0 0 40 40"
                >
                  <path
                    opacity="1"
                    fill="#000"
                    d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
                  />
                </svg>
              </Box>
              <Box
                sx={{
                  position: 'absolute',
                  transform: 'scale(1.3)',
                  left: '8px',
                  top: '6px',
                }}
              >
                <CloseIcon />
              </Box>
            </Box>
            <Box sx={{ ml: '2.1rem', color: '#ff0000' }}>
              {t('assetDetail.pumpOffline')}
            </Box>
          </Box>
        </Alert>
      ) : initialIndicatorCheck ? (
        <Alert
          variant="standard"
          icon={false}
          severity="info"
          sx={{
            width: '100%',
            bgcolor: isMobile ? '#2d2f36' : 'transparent',
            color: '#fff',
          }}
        >
          <Box display="flex">
            <Box
              sx={{
                position: 'relative',
                top: '-3px',
                transform: 'scale(0.5)',
                '& path, & rect': {
                  fill: '#fff',
                },
              }}
            >
              <Box sx={{ position: 'absolute', transform: 'scale(2)' }}>
                <svg
                  version="1.1"
                  id="loader-1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="40px"
                  height="40px"
                  viewBox="0 0 40 40"
                  enableBackground="new 0 0 40 40"
                >
                  <path
                    opacity="0.2"
                    fill="#000"
                    d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
                  />
                  <path
                    fill="#000"
                    d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
C22.32,8.481,24.301,9.057,26.013,10.047z"
                  >
                    <animateTransform
                      attributeType="xml"
                      attributeName="transform"
                      type="rotate"
                      from="0 20 20"
                      to="360 20 20"
                      dur="0.5s"
                      repeatCount="indefinite"
                    />
                  </path>
                </svg>
              </Box>
            </Box>
            <Box sx={{ ml: '2.1rem', color: '#fff' }}>
              {t('assetDetail.checkingStatus')}
            </Box>
          </Box>
        </Alert>
      ) : (
        <Alert
          variant="standard"
          icon={false}
          severity="success"
          sx={{
            width: '100%',
            bgcolor: isMobile ? '#2d2f36' : 'transparent',
          }}
        >
          <Box display="flex">
            <Box
              sx={{
                position: 'relative',
                top: '-3px',
                transform: 'scale(0.5)',
                '& path, & rect': {
                  fill: '#00ff00',
                },
              }}
            >
              <Box sx={{ position: 'absolute', transform: 'scale(2)' }}>
                <svg
                  version="1.1"
                  id="loader-1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="40px"
                  height="40px"
                  viewBox="0 0 40 40"
                  enableBackground="new 0 0 40 40"
                >
                  <path
                    opacity="0.2"
                    fill="#000"
                    d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
                  />
                  <path
                    fill="#000"
                    d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
C22.32,8.481,24.301,9.057,26.013,10.047z"
                  >
                    <animateTransform
                      attributeType="xml"
                      attributeName="transform"
                      type="rotate"
                      from="0 20 20"
                      to="360 20 20"
                      dur="0.5s"
                      repeatCount="indefinite"
                    />
                  </path>
                </svg>
              </Box>

              <Box
                sx={{
                  position: 'absolute',
                  transform: 'scale(1.3)',
                  left: '10px',
                  top: '2px',
                }}
              >
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="24px"
                  height="30px"
                  viewBox="0 0 24 30"
                >
                  <rect
                    x="0"
                    y="10"
                    width="4"
                    height="10"
                    fill="#333"
                    opacity="0.2"
                  >
                    <animate
                      attributeName="opacity"
                      attributeType="XML"
                      values="0.2; 1; .2"
                      begin="0s"
                      dur="0.6s"
                      repeatCount="indefinite"
                    />
                    <animate
                      attributeName="height"
                      attributeType="XML"
                      values="10; 20; 10"
                      begin="0s"
                      dur="0.6s"
                      repeatCount="indefinite"
                    />
                    <animate
                      attributeName="y"
                      attributeType="XML"
                      values="10; 5; 10"
                      begin="0s"
                      dur="0.6s"
                      repeatCount="indefinite"
                    />
                  </rect>
                  <rect
                    x="8"
                    y="10"
                    width="4"
                    height="10"
                    fill="#333"
                    opacity="0.2"
                  >
                    <animate
                      attributeName="opacity"
                      attributeType="XML"
                      values="0.2; 1; .2"
                      begin="0.15s"
                      dur="0.6s"
                      repeatCount="indefinite"
                    />
                    <animate
                      attributeName="height"
                      attributeType="XML"
                      values="10; 20; 10"
                      begin="0.15s"
                      dur="0.6s"
                      repeatCount="indefinite"
                    />
                    <animate
                      attributeName="y"
                      attributeType="XML"
                      values="10; 5; 10"
                      begin="0.15s"
                      dur="0.6s"
                      repeatCount="indefinite"
                    />
                  </rect>
                  <rect
                    x="16"
                    y="10"
                    width="4"
                    height="10"
                    fill="#333"
                    opacity="0.2"
                  >
                    <animate
                      attributeName="opacity"
                      attributeType="XML"
                      values="0.2; 1; .2"
                      begin="0.3s"
                      dur="0.6s"
                      repeatCount="indefinite"
                    />
                    <animate
                      attributeName="height"
                      attributeType="XML"
                      values="10; 20; 10"
                      begin="0.3s"
                      dur="0.6s"
                      repeatCount="indefinite"
                    />
                    <animate
                      attributeName="y"
                      attributeType="XML"
                      values="10; 5; 10"
                      begin="0.3s"
                      dur="0.6s"
                      repeatCount="indefinite"
                    />
                  </rect>
                </svg>
              </Box>
            </Box>
            <Box sx={{ ml: '2.1rem', color: '#00ff00' }}>
              {t('assetDetail.pumpOnline')}
            </Box>
          </Box>
        </Alert>
      )}
    </Snackbar>
  );
};
