/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

const STORAGE_NAME = 'state';

const origLocalStorageSetItem = localStorage.setItem.bind(localStorage);

const localStorageOverrideRestrict = (key: string, value: string) => {
  if (key === STORAGE_NAME) {
    console.error(
      `Error: attempt to write into default redux storage (${STORAGE_NAME}). Use redux instead`
    );
  } else {
    origLocalStorageSetItem(key, value);
  }
};

localStorage.setItem = localStorageOverrideRestrict;
window.localStorage.setItem = localStorageOverrideRestrict;

export const loadState = (): Record<string, unknown> | undefined => {
  try {
    const serializedState = localStorage.getItem(STORAGE_NAME);

    if (serializedState === null) {
      return undefined;
    }

    return JSON.parse(serializedState);
  } catch (error) {
    return undefined;
  }
};

export const saveState = (state: any): void => {
  const _state: any = {};
  for (const property in state) {
    if (Object.prototype.hasOwnProperty.call(state, property)) {
      if (state[property].persist) {
        _state[property] = state[property];
      }
    }
  }

  try {
    const serializedState = JSON.stringify(_state);
    origLocalStorageSetItem(STORAGE_NAME, serializedState);
  } catch (error) {
    // Ignore write errors.
  }
};
