import { API, APIResponse, useApi, ENDPOINT_FAULT_ITEM, AssetItem } from '..';

import {
  FaultItemDetailResponse,
  FaultItemListResponse,
  FaultItemPatchRequest,
  FaultItemCreateRequest,
  FaultItemPerson,
  FaultItem,
  ItemCommentResponse,
} from '@dsf/util-types';

const BASE = ENDPOINT_FAULT_ITEM;
export const getFaultsForProduct = (tenantId: string, serialNumber: string) =>
  `${BASE}?tenant_id=${tenantId}&serialNumber=${serialNumber}`;
export const getDetailPath = (itemId: string): string => `${BASE}/${itemId}`;
export const getImagePath = (imageId: string): string =>
  `${BASE}/image/${imageId}`;
export const getImageBase64Path = (imageId: string): string =>
  `${BASE}/image/base64/${imageId}`;
export const getListPath = (tenantId: string): string =>
  `${BASE}?tenant_id=${tenantId}`;
export const getProductOverviewPath = (tenantId: string): string =>
  `${BASE}?tenant_id=${tenantId}&orderBy=oldestAssetFirst`;
export const changeItemPath = (itemId: string): string => `${BASE}/${itemId}`;
export const getItemPath = (itemId: string): string => `${BASE}/${itemId}`;
export const getAssigneeOptionsPath = (): string => `${BASE}/assignee`;
export const changeAssigneePath = (itemId: string): string =>
  `${BASE}/${itemId}/assignee`;
export const createFaultItemPath = () => `${BASE}`;
export const getCommentsPath = (itemId: string): string =>
  `${BASE}/${itemId}/comments`;

export interface FaultItemAPI extends API {
  getFaultsForProduct: (
    tenantId: string,
    serialNumber: string
  ) => APIResponse<FaultItemListResponse[]>;
  getDetail: (itemId: string) => APIResponse<FaultItemDetailResponse>;
  // eslint-disable-next-line
  getImage: (imageId: string) => APIResponse<any>;
  // eslint-disable-next-line
  getImageBase64: (imageId: string) => APIResponse<any>;
  getList: (tenantId: string) => APIResponse<FaultItemListResponse[]>;
  getFaultItem: (itemId: string) => APIResponse<FaultItem>;
  getProductOverview: (tenantId: string) => APIResponse<AssetItem[]>;
  changeFaultItem: (
    itemId: string,
    patch: FaultItemPatchRequest
  ) => APIResponse<void>;
  createFaultItem: (
    data: FaultItemCreateRequest
  ) => APIResponse<FaultItemDetailResponse>;
  getAssigneeOptions: () => APIResponse<FaultItemPerson[]>;
  changeAssignee: (itemId: string, assigneeId: number) => APIResponse<void>;
  getComments: (
    itemId: string,
    limit?: number,
    lastReadCommentId?: number,
    firstReadCommentId?: number
  ) => APIResponse<ItemCommentResponse[]>;
}

export const useFaultItemApi = (): FaultItemAPI => {
  const api = useApi();

  return {
    ...api,
    getFaultsForProduct(tenantId, serialNumber) {
      const cancelToken = api.prepareCancelToken('serialNumber');
      return api.authRequest.get(getFaultsForProduct(tenantId, serialNumber), {
        cancelToken,
      });
    },
    getDetail(itemId) {
      const cancelToken = api.prepareCancelToken('getDetail');
      return api.authRequest.get(getDetailPath(itemId), { cancelToken });
    },
    getImage(imageId) {
      const cancelToken = api.prepareCancelToken('getImage');
      return api.authRequest.get(getImagePath(imageId), { cancelToken });
    },
    getImageBase64(imageId) {
      const cancelToken = api.prepareCancelToken(`getImageBase64-${imageId}`);
      return api.authRequest.get(getImageBase64Path(imageId), { cancelToken });
    },
    getList(tenantId) {
      const cancelToken = api.prepareCancelToken('getList');
      return api.authRequest.get(getListPath(tenantId), { cancelToken });
    },
    getFaultItem(itemId: string) {
      const cancelToken = api.prepareCancelToken('getFaultItem');
      return api.authRequest.get(getItemPath(itemId), { cancelToken });
    },
    getProductOverview(tenantId) {
      const cancelToken = api.prepareCancelToken('getList');
      return api.authRequest.get(getProductOverviewPath(tenantId), {
        cancelToken,
      });
    },
    changeFaultItem(itemId, patch) {
      const cancelToken = api.prepareCancelToken(`changeFaultItem-${itemId}`);
      return api.authRequest.patch(changeItemPath(itemId), patch, {
        cancelToken,
      });
    },
    createFaultItem(data) {
      const cancelToken = api.prepareCancelToken('createFaultItem');
      return api.authRequest.post(createFaultItemPath(), data, { cancelToken });
    },
    getAssigneeOptions() {
      const cancelToken = api.prepareCancelToken('getAssigneeOptions');
      return api.authRequest.get(getAssigneeOptionsPath(), { cancelToken });
    },
    changeAssignee(itemId, assigneeId) {
      const cancelToken = api.prepareCancelToken(
        `changeAssignee-${assigneeId}`
      );
      return api.authRequest.put(
        changeAssigneePath(itemId),
        { assigneeId },
        { cancelToken }
      );
    },
    getComments(
      itemId: string,
      limit?: number,
      lastReadCommentId?: number,
      firstReadCommentId?: number
    ) {
      const cancelToken = api.prepareCancelToken(
        `getComments-${itemId}-${limit}-${lastReadCommentId}-${firstReadCommentId}`
      );
      return api.authRequest.get(getCommentsPath(itemId), {
        cancelToken,
        params: {
          limit: limit,
          lastReadCommentId: lastReadCommentId,
          firstReadCommentId: firstReadCommentId,
        },
      });
    },
  };
};
