import { State } from '@dsf/data-access-store';
import { DSFE_ASSET_LIST, FAULT_LIST, LOGIN_PAGE } from '@dsf/util-router';
import { USER_ROLES } from '@dsf/util-tools';

import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

export const RootPage = () => {
  const history = useHistory();
  const { accessToken, roles } = useSelector(
    (state: State) => state.userTokens
  );

  useEffect(() => {
    if (!history) {
      return;
    }
    if (accessToken) {
      history.push(
        roles.includes(USER_ROLES.grundfos_data_analyst)
          ? DSFE_ASSET_LIST
          : FAULT_LIST
      );
    } else {
      history.push(LOGIN_PAGE);
    }
  }, [accessToken, history, roles]);

  return null;
};
