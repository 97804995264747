import {
  IDENTIFY_PRODUCT,
  PRODUCT_OVERVIEW,
  FAULT_LIST,
  NEWS,
  TENANT_PROFILE,
} from '@dsf/util-router';

import {
  BoardIcon,
  CheckIcon,
  HomeIcon,
  NewsIcon,
  SendIcon,
} from '@dsf/ui-assets';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ZoomIn } from '@material-ui/icons';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

type NavItem = {
  id: string;
  label: string;
  page: string;
  icon: React.FC;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: theme.palette.primary.main,
      '& .MuiBottomNavigationAction-root': {
        color: '#fff',
      },
    },
  })
);

export default function LabelBottomNavigation() {
  const {
    push: navigate,
    location: { pathname },
  } = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState('');

  const handleChange = (
    event: React.ChangeEvent<unknown>,
    newValue: string
  ) => {
    setCurrentPage(newValue);
    navigate(newValue);
  };

  const navItems: NavItem[] = useMemo(
    () => [
      {
        id: 'bottomNav_identifyProduct',
        label: t('bottomNav.identifyProduct'),
        page: IDENTIFY_PRODUCT,
        icon: () => <ZoomIn />,
      },
      {
        id: 'bottomNav_productionOverview',
        label: t('bottomNav.productionOverview'),
        page: PRODUCT_OVERVIEW,
        icon: () => <BoardIcon />,
      },
      {
        id: 'bottomNav_news',
        label: t('bottomNav.news'),
        page: NEWS,
        icon: () => <NewsIcon />,
      },
      {
        id: 'bottomNav_faultOverview',
        label: t('bottomNav.faultOverview'),
        page: FAULT_LIST,
        icon: () => <HomeIcon />,
      },
      //   {
      //     id: 'bottomNav_profile',
      //     label: t('bottomNav.profile'),
      //     page: TENANT_PROFILE,
      //     icon: () => <SendIcon />,
      //   },
    ],
    // eslint-disable-next-line
    []
  );

  useEffect(() => {
    navItems.forEach((item) => {
      if (item.page === pathname) {
        setCurrentPage(pathname);
      }
    });
    // eslint-disable-next-line
  }, [pathname]);

  return (
    <BottomNavigation
      value={currentPage}
      onChange={handleChange}
      className={classes.root}
    >
      {navItems.map(({ id, label, page, icon: Icon }) => {
        return (
          <BottomNavigationAction
            key={id}
            id={id}
            label={label}
            value={page}
            icon={<Icon />}
          />
        );
      })}
    </BottomNavigation>
  );
}
