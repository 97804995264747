import { State } from '@dsf/data-access-store';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import Route from './routes/Route';

export const RootWrapper = () => {
  const { tenantConfig } = useSelector((state: State) => state.appSettings);

  const title = tenantConfig?.settings?.title;
  const description = tenantConfig?.settings?.description;

  useEffect(() => {
    const elem = document.querySelectorAll('meta[name=description]')[0];
    elem.remove();
  }, []);

  return (
    <div>
      {title || description ? (
        <Helmet>
          {title ? <title>{title}</title> : null}
          {description ? (
            <meta name="description" content={description} />
          ) : null}
        </Helmet>
      ) : null}
      <Route />
    </div>
  );
};
