import { ProductScanner, ScreenText, ScreenTitle } from '../../../components';
import { withTenantLayout } from '@dsf/ui-layout';
import React from 'react';
import { useTranslation } from 'react-i18next';

const FaultListPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <ScreenTitle id="identifyProductPage_title">
        {t('identifyProductPage.headline')}
      </ScreenTitle>
      <ScreenText gutterBottom>{t('identifyProductPage.intro')}</ScreenText>
      <ProductScanner id="identifyProductPage_productScanner" />
    </>
  );
};

export default withTenantLayout(FaultListPage)({ pageName: 'SRC-101' });
