import { Box } from '@mui/material';
import { ReactComponent as DrillMachineSvg } from './drill-machine-icon.svg';
import PumpPng from './pump-icon.png';

export const DrillMachineIcon: React.FC = (props) => (
  <Box
    sx={{
      '& svg': {
        width: '1.8rem',
      },
    }}
  >
    <DrillMachineSvg />
  </Box>
);
export const PumpIcon: React.FC = (props) => (
  <img src={PumpPng} alt="Pump" width="29px" {...props} />
);
