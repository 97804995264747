import LoginIcon from '@mui/icons-material/Login';
import { appSettingsSlice, State } from '@dsf/data-access-store';
import { DSFE_ASSET_LIST, FAULT_LIST } from '@dsf/util-router';
import { useLogin, useQueries, USER_ROLES } from '@dsf/util-tools';
import Alert from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Button, CircularProgress, Skeleton, Stack } from '@mui/material';

import { useHistory } from 'react-router-dom';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControl from '@mui/material/FormControl';

import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BackgroundIcon } from '@dsf/ui-assets';
import { HeaderPublic } from '../components';
import { useWrapApi } from '@dsf/data-access-api';

type LoginValues = {
  account: string;
  password: string;
  newPassword: string;
  showPassword: boolean;
  showNewPassword: boolean;
};

export const MainLoginPage = () => {
  const { api, Auth, Amplify } = useWrapApi();
  const { t } = useTranslation();
  const { handleSignIn } = useLogin();
  const { isMobile } = useQueries();
  const history = useHistory();
  const dispatch = useDispatch();
  const { tenantConfig: settings, accountDomain } = useSelector(
    (state: State) => state.appSettings
  );

  const [submittingForm, setSubmittingForm] = useState(false);
  const [loginError, setLoginError] = useState<string>();
  const [configError, setConfigError] = useState<string>();
  const [loadingConfig, setLoadingConfig] = useState(true);

  const [newPasswordRequired, setNewPasswordRequired] = useState(false);
  // eslint-disable-next-line
  const [cognitoUser, setCognitoUser] = useState<any>();

  const { accessToken, roles } = useSelector(
    (state: State) => state.userTokens
  );

  // const [subdomain, setSubdomain] = useState<string>();

  const [formValues, setFormValues] = useState<LoginValues>({
    account: '',
    password: '',
    newPassword: '',
    showPassword: false,
    showNewPassword: false,
  });

  const handleChange =
    (prop: keyof LoginValues) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFormValues({ ...formValues, [prop]: event.target.value });
    };

  const handleClickShowPassword = () => {
    setFormValues({
      ...formValues,
      showPassword: !formValues.showPassword,
    });
  };

  const handleClickShowNewPassword = () => {
    setFormValues({
      ...formValues,
      showNewPassword: !formValues.showNewPassword,
    });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  // eslint-disable-next-line
  const handleNewPassword = (response: any) => {
    setCognitoUser(response);
    setNewPasswordRequired(true);
  };

  const changePassword = async () => {
    try {
      const newPasswordResponse = await Auth.completeNewPassword(
        cognitoUser,
        formValues.newPassword,
        {}
      );
      handleSignIn(newPasswordResponse, handleNewPassword, setSubmittingForm);
    } catch (error) {
      console.error('change password error', error);
      const message =
        error && error.message
          ? `Error: ${error.message}`
          : 'Error: Unable to change password';
      setLoginError(message);
      setSubmittingForm(false);
    }

    // dispatch(loginSlice.actions.setLoginEmail(newPasswordResponse));
    // dispatch(
    //   sessionTokenSlice.actions.setSessionToken(newPasswordResponse.Session)
    // );
    // setSubmittingForm(false);
    // history.push(MFA_VERIFY);
  };

  const handleLoginButton = async () => {
    setLoginError(undefined);
    setSubmittingForm(true);

    if (newPasswordRequired && cognitoUser && formValues.newPassword) {
      changePassword();
      return;
    }
    try {
      if (formValues.account.indexOf(' ') !== -1) {
        setLoginError('Error: Account contains a space.');
        setSubmittingForm(false);
        return;
      }
      const userName = `${formValues.account}@${accountDomain}`;
      const signInResponse = await Auth.signIn(userName, formValues.password);

      handleSignIn(signInResponse, handleNewPassword, setSubmittingForm);
    } catch (error) {
      const message =
        error && error.message
          ? `Error: ${error.message}`
          : 'Error: Unable to sign in';
      setLoginError(message);
      setSubmittingForm(false);
      console.error('login form error', error);
    }
  };

  const submitLoginForm = (event: React.FormEvent) => {
    event.preventDefault();
    handleLoginButton();
  };

  useEffect(() => {
    if (settings && Amplify) {
      Amplify.configure({
        Auth: {
          userPoolId: settings.cognito,
          userPoolWebClientId: settings.cognito_client,
          region: settings.region,
        },
      });
    }
  }, [settings, Amplify]);

  useEffect(() => {
    if (accessToken && history) {
      history.push(
        roles.includes(USER_ROLES.grundfos_data_analyst)
          ? DSFE_ASSET_LIST
          : FAULT_LIST
      );
    }
  }, [accessToken, history, roles]);

  useEffect(() => {
    if (!dispatch) {
      return;
    }
    const fetch = async () => {
      try {
        setConfigError(undefined);
        const { data } = await api.get('/configuration');
        dispatch(appSettingsSlice.actions.setSettings(data));
        setLoadingConfig(false);
      } catch (error) {
        setConfigError('Error: Unable to load tenant configuration');
        console.error('settings error', error);
        setLoadingConfig(false);
      }
    };
    fetch();
    // eslint-disable-next-line
  }, [dispatch]);

  // useEffect(() => {
  //   try {
  //     const parsedSubdomain = window.location.hostname
  //       .split('.')
  //       .slice(0, -2)
  //       .join('.');
  //     if (process.env.NODE_ENV === 'development') {
  //       setSubdomain(settings?.tenant_id);
  //     } else {
  //       setSubdomain(parsedSubdomain);
  //     }
  //   } catch (error) {
  //     console.error('subdomain error', error);
  //   }
  // }, [settings?.tenant_id]);

  return (
    <Box
      sx={
        settings?.settings?.background_image
          ? {
              minWidth: '100vw',
              minHeight: '100vh',
              background: '#2d2f36',
            }
          : { minWidth: '100vw', minHeight: '100vh', background: '#2d2f36' }
      }
    >
      {settings?.settings?.background_image && !loadingConfig ? (
        <Box
          sx={{
            width: '100vw',
            height: '100vh',
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 0,
            opacity: 0.2,
            backgroundColor: '#2d2f36',
            backgroundSize: 'cover',
            backgroundImage: `url(${settings?.settings?.background_image})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
        ></Box>
      ) : null}
      {!settings?.settings?.background_image && !loadingConfig ? (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: '100%',
            right: '100%',
            width: '100%',
            height: '100%',
            overflow: 'hidden',
          }}
        >
          <BackgroundIcon />
        </Box>
      ) : null}
      <Box sx={{ position: 'relative', pb: '3rem' }}>
        {loadingConfig ? (
          <Box sx={{ py: '58px' }}>
            <Box sx={{}}>
              <Skeleton
                animation="wave"
                width="100%"
                height="64px"
                sx={{
                  maxWidth: '139px',
                  background: '#e3e3e3',
                  m: 'auto',
                  transform: 'none',
                }}
              />
            </Box>
          </Box>
        ) : (
          <HeaderPublic logoUrl={settings?.settings?.logo} />
        )}
        <Box
          sx={{
            background: '#fff',
            p: isMobile ? '1rem 1rem' : '3rem',
            borderRadius: '1rem',
            color: '#000',
            maxWidth: '35rem',
            margin: isMobile ? '1rem' : 'auto',
          }}
        >
          {loadingConfig ? (
            <Skeleton
              animation="wave"
              width="100%"
              height="313px"
              sx={{ transform: 'none' }}
            />
          ) : (
            <>
              <Box
                sx={{
                  textAlign: 'center',
                  mb: '1rem',
                  fontSize: isMobile ? '1rem' : '2rem',
                  fontWeight: 'bold',
                }}
              >
                {t('loginPage.signin')}
              </Box>
              <Box sx={{ textAlign: 'center', mb: '2rem' }}>
                {t('loginPage.subtitle')}
              </Box>
              {loginError || configError ? (
                <Box sx={{ mb: '2rem' }}>
                  <Alert severity="error">{loginError || configError}</Alert>
                </Box>
              ) : null}
              <form onSubmit={submitLoginForm}>
                <Box sx={{ mb: '1rem' }}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    required
                    disabled={configError !== undefined}
                  >
                    <InputLabel htmlFor="account">Account</InputLabel>
                    <OutlinedInput
                      autoFocus
                      id="account"
                      label="Account"
                      value={formValues.account}
                      sx={{
                        '.MuiInputAdornment-root': {
                          whiteSpace: isMobile ? 'normal' : undefined,
                        },
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          @{accountDomain}
                        </InputAdornment>
                      }
                      onChange={handleChange('account')}
                      inputProps={{
                        'aria-label': 'account',
                      }}
                    />
                  </FormControl>
                </Box>
                <Box>
                  <FormControl
                    sx={{}}
                    variant="outlined"
                    fullWidth
                    required
                    disabled={configError !== undefined}
                  >
                    <InputLabel htmlFor="password">Password</InputLabel>
                    <OutlinedInput
                      id="password"
                      sx={{
                        '& input::-ms-reveal': {
                          display: 'none',
                        },
                        '& input::-ms-clear': {
                          display: 'none',
                        },
                      }}
                      label="Password"
                      value={formValues.password}
                      onChange={handleChange('password')}
                      type={formValues.showPassword ? 'text' : 'password'}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {formValues.showPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Box>
                {newPasswordRequired && (
                  <Box sx={{ mt: '1rem' }}>
                    <Box sx={{ mb: '1rem', textAlign: 'center' }}>
                      <Box>Your password needs to be changed.</Box>
                      <Box>Enter the new password below:</Box>
                    </Box>
                    <FormControl sx={{}} variant="outlined" fullWidth required>
                      <InputLabel htmlFor="password">New Password</InputLabel>
                      <OutlinedInput
                        id="newPassword"
                        label="New Password"
                        sx={{
                          '& input::-ms-reveal': {
                            display: 'none',
                          },
                          '& input::-ms-clear': {
                            display: 'none',
                          },
                        }}
                        value={formValues.newPassword}
                        onChange={handleChange('newPassword')}
                        type={formValues.showNewPassword ? 'text' : 'password'}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowNewPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {formValues.showNewPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </Box>
                )}
                <Box sx={{ mt: '1rem' }}>
                  <Button
                    type="submit"
                    disabled={
                      configError !== undefined ||
                      !formValues.account ||
                      !formValues.password ||
                      (newPasswordRequired && !formValues.newPassword) ||
                      submittingForm
                    }
                    variant="contained"
                    color="primary"
                    fullWidth
                    size="large"
                    sx={{ height: '56px' }}
                    startIcon={
                      submittingForm ? (
                        <CircularProgress color="inherit" size="30px" />
                      ) : undefined
                    }
                    endIcon={<LoginIcon />}
                  >
                    {submittingForm ? (
                      'Submitting...'
                    ) : (
                      <span>
                        {newPasswordRequired
                          ? t('loginPage.changePassword')
                          : t('loginPage.signin')}
                      </span>
                    )}
                  </Button>
                </Box>
              </form>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};
