import {
  MARK_PRODUCT_AS_FINISHED,
  PRODUCT_IDENTIFIED,
  QR_CODE_GENERATION_SPECIFIC,
} from '@dsf/util-router';
import { Box, Button, Fade } from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Slide from '@material-ui/core/Slide';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import WarningIcon from '@material-ui/icons/Warning';

import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useSwipeable } from 'react-swipeable';
import { AssetItem } from '../types/models/asset-item';
import { ButtonDiv } from '../components';
import { useTranslation } from 'react-i18next';

const INNER_PADDING = '0.5rem';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: '5px',
      boxShadow: '0px 5px 10px rgba(0, 0, 0, .4)',
      marginBottom: '1.5rem',
      backgroundColor: '#fff',
    },
    title: {
      padding: INNER_PADDING,
      background: '#E0E6E9',
      fontWeight: 'bold',
      fontSize: '1.1rem',
    },
    faults: {
      color: '#59BBA0',
    },
    content: {
      padding: INNER_PADDING,
      overflow: 'hidden',
    },
    priorityCount: {
      fontWeight: 'bold',
    },
    highPriority: {
      color: 'var(--priority-high)',
    },
    mediumPriority: {
      color: 'var(--priority-medium)',
    },
    lowPriority: {
      color: 'var(--priority-low)',
    },
  })
);

type Props = {
  assetItem: AssetItem;
};

const ProductListItem: React.FC<Props> = ({
  assetItem: {
    assetLocation: productionSegment,
    assetNumber: serialNumber,
    assetVersion: productPartNumber,
    productDescription,
    faultItems,
    assetId,
  },
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const [showSwipeMenu, setShowSwipeMenu] = useState(false);

  const highPriorityFaults = faultItems.filter(
    (item) => item.priority === 'high'
  );
  const mediumPriorityFaults = faultItems.filter(
    (item) => item.priority === 'medium'
  );
  const lowPriorityFaults = faultItems.filter(
    (item) => item.priority === 'low'
  );

  const handlers = useSwipeable({
    onSwipedLeft: (eventData) => {
      setShowSwipeMenu(true);
    },
    onSwipedRight: (eventData) => {
      setShowSwipeMenu(false);
    },
    preventDefaultTouchmoveEvent: false,
    trackMouse: true,
  });

  const handleTitleClick = () => {
    const assetUrl = PRODUCT_IDENTIFIED.replace(':id', `${assetId}`);
    const assetSerialUrl = assetUrl.replace(
      ':serial',
      encodeURIComponent(serialNumber)
    );
    history.push(assetSerialUrl);
  };

  const handleMarkFinished = () => {
    const idUrl = MARK_PRODUCT_AS_FINISHED.replace(':id', `${assetId}`);
    const idSerialUrl = idUrl.replace(
      ':serial',
      encodeURIComponent(serialNumber)
    );
    history.push(idSerialUrl);
  };

  const handleCreateQrCode = () => {
    const idUrl = QR_CODE_GENERATION_SPECIFIC.replace(
      ':serialNumber',
      encodeURIComponent(serialNumber)
    );
    history.push(idUrl);
  };

  const handleItemClick = () => {
    handleTitleClick();
  };

  const getFormattedFaultCount = (length: number): string => {
    if (length && length === 1) {
      return `${faultItems.length} ${t('productOverviewPage.fault')}`;
    } else if (length) {
      return `${faultItems.length} ${t('productOverviewPage.faults')}`;
    }

    return '';
  };

  const renderItem = () => (
    <div className={classes.root}>
      <div className={classes.title}>
        <Box display="flex" justifyContent="space-between">
          <span>{serialNumber}</span>
          <div className={classes.faults}>
            {getFormattedFaultCount(faultItems.length)}
          </div>
        </Box>
      </div>
      <div className={classes.content} {...handlers}>
        <Slide direction="left" in={showSwipeMenu} mountOnEnter unmountOnExit>
          <Box display="flex" justifyContent="space-evenly">
            <Button
              variant="contained"
              color="primary"
              onClick={handleMarkFinished}
            >
              Mark Finished
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleCreateQrCode}
            >
              Create QR-Code
            </Button>
          </Box>
        </Slide>
        <Fade in={!showSwipeMenu} mountOnEnter unmountOnExit>
          <Box display="flex">
            <Box display="flex" flexDirection="column">
              <ListItemIcon className={classes.highPriority}>
                <WarningIcon />
                <span className={classes.priorityCount}>
                  {highPriorityFaults.length}
                </span>
              </ListItemIcon>
              <ListItemIcon className={classes.mediumPriority}>
                <WarningIcon />
                <span className={classes.priorityCount}>
                  {mediumPriorityFaults.length}
                </span>
              </ListItemIcon>
              <ListItemIcon className={classes.lowPriority}>
                <WarningIcon />
                <span className={classes.priorityCount}>
                  {lowPriorityFaults.length}
                </span>
              </ListItemIcon>
            </Box>
            <div>
              <div>{productDescription}</div>
              <div>{productPartNumber}</div>
              <div>{productionSegment}</div>
            </div>
          </Box>
        </Fade>
      </div>
    </div>
  );

  return showSwipeMenu ? (
    <div id={serialNumber}>{renderItem()}</div>
  ) : (
    <ButtonDiv id={serialNumber} onClick={handleItemClick}>
      {renderItem()}
    </ButtonDiv>
  );
};

export default ProductListItem;
