import { formatDataValue, useQueries } from '@dsf/util-tools';
import { Box } from '@mui/material';
import { orderBy, range } from 'lodash';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  XAxis,
  YAxis,
  Line,
  ComposedChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';

export type QHPoint = {
  q: number;
  h?: number;
};

// eslint-disable-next-line
const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <Box>
        <Box>Q: {label}</Box>
        <Box>H: {payload[0].value}</Box>
      </Box>
    );
  }

  return null;
};

type Props = {
  pumpOnline: boolean;
  qhData: QHPoint[];
};

export const QHCurrentCurve = memo(({ pumpOnline, qhData }: Props) => {
  const { isMobile } = useQueries();
  const { t } = useTranslation();

  const [rangeData, setRangeData] = useState<QHPoint[]>([]);

  // eslint-disable-next-line
  const CustomizedDot = (props: any) => {
    const { cx, cy, stroke, payload, value } = props;
    if (!cx || !cy) {
      return null;
    }

    return (
      <circle
        cx={cx}
        cy={cy}
        r={6}
        strokeWidth={0}
        fill={pumpOnline ? 'red' : 'grey'}
      />
    );
  };

  useEffect(() => {
    if (JSON.stringify(rangeData) !== JSON.stringify(qhData)) {
      let newData = [...qhData];
      newData = orderBy(newData, ['q'], ['asc']);
      setRangeData(newData);
    } else {
      setRangeData([]);
    }
    // eslint-disable-next-line
  }, [qhData]);

  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        sx={{
          position: 'absolute',
          left: '107px',
          top: '92px',
          right: '196px',
          height: '75%',
        }}
      >
        <img
          src="/images/pumpcurve-clean.png"
          alt=""
          style={{ height: '73%' }}
        />
      </Box>

      <ComposedChart
        data={rangeData}
        width={653}
        height={350}
        margin={{
          top: 40,
          right: 20,
          bottom: 20,
          left: 0,
        }}
      >
        <XAxis
          label={{
            value: t('assetDetail.qhLabel'),
            position: 'right',
            dy: 22,
            dx: -85,
          }}
          type="number"
          dataKey="q"
          domain={[0, 51]}
          max={51}
          tickCount={20}
          tickFormatter={(value) => {
            return formatDataValue(value, undefined, 0);
          }}
        />
        <YAxis
          label={{ value: 'H [m]', position: 'top', dx: 10, dy: -15 }}
          domain={[0, 50]}
          tickCount={11}
          max={50}
          tickFormatter={(value) => {
            return formatDataValue(value, undefined, 0);
          }}
        />
        <Tooltip content={<CustomTooltip />} isAnimationActive={false} />
        {/* <Area
            type="monotoneY"
            dataKey="h"
            stroke="#8884d8"
            fill="#D2DDE8"
            connectNulls
            isAnimationActive={false}
          /> */}

        <Line
          isAnimationActive={false}
          strokeWidth={0}
          // connectNulls
          // type="monotoneX"
          connectNulls={false}
          dataKey="h"
          stroke="#1F4A82"
          // tooltipType=''
          dot={<CustomizedDot />}
        />
        <CartesianGrid strokeDasharray="3 3" />
      </ComposedChart>
    </Box>
  );
});
