import React from 'react';
import { useQueries } from '@dsf/util-tools';
import { makeStyles, createStyles, Box } from '@material-ui/core';
import { Theme } from 'pretty-format';
import './ui-content-box.module.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: '1px solid rgba(45, 47, 54, 0.2)',
      background: '#fff',
      '& .content-box-icon path': {
        fill: '#707483',
      },
    },
  })
);

/* eslint-disable-next-line */
type Props = {
  title: JSX.Element;
  icon?: JSX.Element;
  postfix?: JSX.Element;
};

export const UiContentBox: React.FC<Props> = ({
  title,
  icon,
  postfix,
  children,
}) => {
  const { isMobile } = useQueries();
  const classes = useStyles();

  return (
    <Box
      position="relative"
      p="1rem"
      m="2rem 0 2rem 0"
      className={classes.root}
      borderRadius="10px"
      boxShadow="0px 0px 30px rgba(0,0,0,0.07)"
    >
      <Box
        display="flex"
        flexWrap={isMobile ? 'wrap' : undefined}
        alignItems={isMobile ? 'flex-start' : 'center'}
        justifyContent="space-between"
        borderBottom="1px solid rgba(45, 47, 54, 0.2)"
        p="0 1rem 1rem 1rem"
        m="0 -1rem 1rem -1rem"
      >
        <Box display="flex" alignItems="flex-start">
          <Box className="content-box-icon">{icon}</Box>
          <Box ml="1rem" flexGrow="1">
            {title}
          </Box>
        </Box>
        {isMobile ? (
          <Box sx={{ flexBasis: '100%', height: 0, mb: '1rem' }} />
        ) : null}
        {postfix}
      </Box>
      <div>{children}</div>
    </Box>
  );
};

export default UiContentBox;
