import moment from 'moment';

const DATE_TIME_SECONDS_FORMAT = 'YYYY-MM-DD HH:mm';
const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm';

const formatTime = (timestamp: string) => {
  return moment(timestamp).format(DATE_TIME_FORMAT);
};

const formatTimeSeconds = (timestamp: string) => {
  return moment(timestamp).format(DATE_TIME_SECONDS_FORMAT);
};

const formatters = {
  formatTime,
};

function useFormatters(): typeof formatters {
  return formatters;
}

export default useFormatters;
