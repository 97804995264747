import { makeStyles } from '@material-ui/core/styles';
import Typography, { TypographyProps } from '@material-ui/core/Typography';
import React from 'react';

const useStyles = makeStyles({
  title: {
    fontSize: '2.125rem',
    fontWeight: 'bold',
    margin: '1.5rem 0 1.5rem 0',
  },
});

const ScreenTitle: React.FC<TypographyProps> = ({
  children,
  className,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <Typography
      variant="h1"
      gutterBottom
      className={`${classes.title} ${className || ''}`}
      {...rest}
    >
      {children}
    </Typography>
  );
};

export default ScreenTitle;
