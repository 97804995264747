import { Alert, Box, Button } from '@mui/material';

type Props = {
  error?: string;
  actionLabel?: string;
  action?: () => void;
};

export const ErrorAlert: React.FC<Props> = ({
  error = 'Unknown error occurred.',
  actionLabel = 'Try Again',
  action = () => {
    window.location.reload();
  },
}) => {
  return (
    <Box>
      <Alert
        severity="error"
        action={
          <Button color="inherit" variant="text" size="small" onClick={action}>
            {actionLabel}
          </Button>
        }
      >
        {error}
      </Alert>
    </Box>
  );
};
