import { State } from '@dsf/data-access-store';
import { DSFE_ASSET_LIST, FAULT_LIST } from '@dsf/util-router';
import { USER_ROLES } from '@dsf/util-tools';

import { MenuIcon } from '@dsf/ui-assets';
import { useLocation } from 'react-router-dom';
import {
  AppBar as AppBarMat,
  Box,
  Button,
  createStyles,
  makeStyles,
  Theme,
  Toolbar,
} from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { DsfeLogo } from '@dsf/ui-assets';
import { NotificationBell, HeaderProfile } from '../components';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      justifyContent: 'space-between',
      [theme.breakpoints.up('sm')]: {
        '& > .MuiBox-root': {
          flexBasis: 0,
          flexGrow: 1,
        },
      },
    },
    menuButton: {
      alignSelf: 'stretch',
    },
  })
);

type Props = {
  handleOpenDrawer: () => void;
};

export const AppBar: React.FC<Props> = ({ handleOpenDrawer }) => {
  const classes = useStyles();
  const location = useLocation();

  const { tenantConfig: settings } = useSelector(
    (state: State) => state.appSettings
  );
  const { roles } = useSelector((state: State) => state.userTokens);

  const customLogo = settings?.settings?.logo;
  const isDsfe = location.pathname.indexOf('/dsfe') !== -1;

  return (
    <AppBarMat position="sticky">
      <Toolbar className={classes.toolbar} disableGutters>
        <Box>
          <Button
            id="appbar_menuButton"
            startIcon={<MenuIcon />}
            color="inherit"
            onClick={handleOpenDrawer}
            className={classes.menuButton}
          />
        </Box>
        <Box textAlign="center">
          <Link
            href="#"
            id="Header_Title_Link"
            color="inherit"
            to={
              roles.includes(USER_ROLES.grundfos_data_analyst)
                ? DSFE_ASSET_LIST
                : FAULT_LIST
            }
          >
            {customLogo && roles.includes(USER_ROLES.grundfos_data_analyst) ? (
              <Box
                component="span"
                sx={{
                  m: '-6px 0 0px 0',
                  borderRadius: '5px',
                  display: 'inline-flex',
                  position: 'relative',
                  top: '4px',
                }}
                style={{
                  filter: 'invert(1) grayscale(1) brightness(2)',
                }}
              >
                <img
                  alt="Custom Logo"
                  src={customLogo}
                  width="auto"
                  height="64px"
                />
              </Box>
            ) : isDsfe ? (
              <img alt="DSFE Logo" src={DsfeLogo} width={83} />
            ) : (
              <img alt="DSFQ Logo" src={'/logo/logo.png'} width={83} />
            )}
          </Link>
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Box
            style={
              roles.includes(USER_ROLES.grundfos_data_analyst)
                ? { pointerEvents: 'none', opacity: 0.2 }
                : undefined
            }
            sx={{
              mr: '1rem',
            }}
          >
            <NotificationBell />
          </Box>
          <HeaderProfile />
        </Box>
      </Toolbar>
    </AppBarMat>
  );
};
