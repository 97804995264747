import { ButtonDiv } from '../../../components';
import {
  Box,
  Collapse,
  createStyles,
  IconButton,
  makeStyles,
  Theme,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FaultItemListResponse } from '@dsf/util-types';

import clsx from 'clsx';
import React, { useState } from 'react';
import FaultItem from './fault-item';

type StyleProps = {
  expanded: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: '1px solid #d3d3d3',
      borderRadius: '0.6rem',
      backgroundColor: '#fff',
    },
    titleWrap: {
      borderBottom: (p: StyleProps) => (p.expanded ? '1px solid #d3d3d3' : 0),
    },
    title: {
      fontSize: '1.25rem',
      fontWeight: 'bold',
    },
    titleCounter: {
      fontSize: '0.8rem',
      fontWeight: 'bold',
      color: '#2D2F36',
      opacity: 0.54,
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    clickableTitle: {
      display: 'flex',
      flexGrow: 1,
      alignItems: 'center',
      minWidth: 0,
    },
  })
);

type Props = {
  product: FaultItemListResponse;
  index: number;
};

const ProductItem: React.FC<Props> = ({ product, index }) => {
  const [expanded, setExpanded] = useState<StyleProps['expanded']>(index < 3);
  const classes = useStyles({ expanded });

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const getFaultItems = () => {
    return product.faultItems.map((item, index) => {
      return (
        <FaultItem
          key={`${item.id}`}
          fault={item}
          lastChild={index === product.faultItems.length - 1}
        />
      );
    });
  };

  return (
    <Box mb="0.75rem" className={classes.root}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p="0.6rem"
        className={classes.titleWrap}
        id={`faultOverview_productItem_${product.assetNumber}`}
      >
        <Box
          paddingLeft="0.4rem"
          paddingRight="0.4rem"
          display="flex"
          flexGrow={1}
          overflow="hidden"
        >
          <ButtonDiv
            onClick={handleExpandClick}
            className={classes.clickableTitle}
            id="faultOverview_productItem_clickableExpand"
          >
            <Box
              className={classes.title}
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              minWidth={0}
              pr="0.25rem"
            >
              {product.assetVersion} {product.assetNumber}
            </Box>{' '}
            <span className={classes.titleCounter}>
              ({product.faultItems.length})
            </span>
          </ButtonDiv>
        </Box>
        <Box>
          <IconButton
            id="faultOverview_productItem_expandArrowButton"
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        </Box>
      </Box>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {getFaultItems()}
      </Collapse>
    </Box>
  );
};

export default ProductItem;
