import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DefaultState } from './types/defaultState';
import { extractRoleFromToken } from '@dsf/util-tools';
import { PERMISSIONS, PermissionType, UserRole } from './appSettingsSlice';

export interface UserTokensState extends DefaultState {
  accessToken: string;
  refreshToken: string;
  roles: UserRole[];
  permissions: PermissionType[];
}

const initialUserTokensState: UserTokensState = {
  accessToken: '',
  refreshToken: '',
  roles: [],
  persist: true,
  permissions: [],
};

export const userTokensSlice = createSlice({
  name: 'userTokens',
  initialState: initialUserTokensState,
  reducers: {
    setAccessToken: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload;
      state.roles = extractRoleFromToken(action.payload).map(
        (role) => role?.toLowerCase() as UserRole
      );
      const permissions = [];
      state.roles.forEach((role: UserRole) => {
        permissions.push('some permission from configuration file'); // TODO: jm - use allowed_gui_by_role from configuration
      });
    },
    setRefreshToken: (state, action: PayloadAction<string>) => {
      state.refreshToken = action.payload;
    },
  },
});
