import { useWrapApi } from '@dsf/data-access-api';
import { UiContentBox } from '@dsf/ui-content-box';
import AddBoxIcon from '@mui/icons-material/AddBox';
import {
  Box,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Button,
  CircularProgress,
  Alert,
  Grid,
} from '@mui/material';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { useState } from 'react';

type FormValues = {
  username: string; // "john.doe" ... @grundfos.digital-platform.io is added on backend
  email: string; // "john.doe@gmail.com"
  name: string; // "John"
  surname: string; // "Doe"
};

const INITIAL_STATE: FormValues = {
  username: '',
  email: '',
  name: '',
  surname: '',
};

export const AddUserForm = () => {
  const { api } = useWrapApi();

  const [submittingForm, setSubmittingForm] = useState(false);
  const [formError, setFormError] = useState<string>();
  const [formSuccess, setFormSuccess] = useState(false);

  const handleChange =
    (prop: keyof FormValues) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFormValues({ ...formValues, [prop]: event.target.value });
    };
  const [formValues, setFormValues] = useState<FormValues>(INITIAL_STATE);

  const handleSubmitButton = async () => {
    setFormError(undefined);
    setSubmittingForm(true);

    try {
      setFormSuccess(false);
      const response = await api.post('/user', formValues);
      console.warn('response', response);
      setFormSuccess(true);
      setTimeout(() => {
        setFormSuccess(false);
      }, 10000);
      setFormValues(INITIAL_STATE);
    } catch (error: any) {
      const message =
        error && error.message
          ? `Error: ${error.message}`
          : 'Error: Unable to sign in';
      setFormError(message);
      setSubmittingForm(false);
      console.error('form submit error', error);
    }
  };

  const submitForm = (event: React.FormEvent) => {
    event.preventDefault();
    handleSubmitButton();
  };

  return (
    <div>
      <UiContentBox
        icon={
          <Box>
            <GroupAddIcon />
          </Box>
        }
        title={
          <Box fontWeight="bold" fontSize="1.125rem">
            Add New User
          </Box>
        }
      >
        <form onSubmit={submitForm}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box>
                <FormControl size="small" variant="outlined" fullWidth required>
                  <InputLabel htmlFor="user_name">User Name</InputLabel>
                  <OutlinedInput
                    id="user_name"
                    label="User Name"
                    value={formValues.username}
                    onChange={handleChange('username')}
                    inputProps={{
                      'aria-label': 'user_name',
                    }}
                  />
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box>
                <FormControl size="small" variant="outlined" fullWidth required>
                  <InputLabel htmlFor="email">Email</InputLabel>
                  <OutlinedInput
                    id="email"
                    label="Email"
                    value={formValues.email}
                    onChange={handleChange('email')}
                    inputProps={{
                      'aria-label': 'email',
                    }}
                  />
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box>
                <FormControl size="small" variant="outlined" fullWidth required>
                  <InputLabel htmlFor="first_name">First Name</InputLabel>
                  <OutlinedInput
                    id="first_name"
                    label="First Name"
                    value={formValues.name}
                    onChange={handleChange('name')}
                    inputProps={{
                      'aria-label': 'first_name',
                    }}
                  />
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box>
                <FormControl size="small" variant="outlined" fullWidth required>
                  <InputLabel htmlFor="last_name">Last Name</InputLabel>
                  <OutlinedInput
                    id="last_name"
                    label="Last Name"
                    value={formValues.surname}
                    onChange={handleChange('surname')}
                    inputProps={{
                      'aria-label': 'last_name',
                    }}
                  />
                </FormControl>
              </Box>
            </Grid>
          </Grid>

          {formError ? (
            <Box sx={{ mb: '1rem', mt: '1rem' }}>
              <Alert severity="error">{formError}</Alert>
            </Box>
          ) : null}

          {formSuccess ? (
            <Box sx={{ mb: '1rem', mt: '1rem' }}>
              <Alert severity="success">User added successfully.</Alert>
            </Box>
          ) : null}

          <Box sx={{ mt: '1rem' }}>
            <Button
              size="large"
              type="submit"
              disabled={
                !formValues.email ||
                !formValues.name ||
                !formValues.surname ||
                !formValues.username ||
                submittingForm
              }
              variant="contained"
              color="primary"
              startIcon={
                submittingForm ? (
                  <CircularProgress color="inherit" size="25px" />
                ) : (
                  <AddBoxIcon />
                )
              }
            >
              {submittingForm ? 'Submitting...' : <>Create User</>}
            </Button>
          </Box>
        </form>
      </UiContentBox>
    </div>
  );
};
