import {
  Button,
  createStyles,
  makeStyles,
  Menu,
  MenuItem,
  Theme,
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  AVAILABLE_LANGS,
  getCurrentLanguage,
  setUserConfiguredLang,
} from '@dsf/util-translate';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    languageSwitchBtn: {
      color: '#fff',
      minWidth: 'none',
      margin: 15,
      [theme.breakpoints.up('sm')]: {
        margin: '15px 0',
      },
    },
  })
);

const LanguageSwitch: React.FC = () => {
  const classes = useStyles();
  const lang = getCurrentLanguage();
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState(null);

  // eslint-disable-next-line
  const handleToggle = (event: any) => {
    event.stopPropagation();
    if (anchorEl === null) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
    }
  };

  // eslint-disable-next-line
  const handleChange = (event: any, lng: string) => {
    event.stopPropagation();
    setUserConfiguredLang(lng);
    handleToggle(event);
  };

  return (
    <div>
      <Button
        className={classes.languageSwitchBtn}
        aria-controls="language-switch"
        aria-haspopup="true"
        onClick={handleToggle}
      >
        {lang}
      </Button>
      <Menu
        id="language-switch"
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleToggle}
        anchorEl={anchorEl}
      >
        {AVAILABLE_LANGS.map((lng) => {
          return (
            <MenuItem key={lng} onClick={(event) => handleChange(event, lng)}>
              {lng.toUpperCase()}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

export default LanguageSwitch;
