import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#eeeeee',
      padding: '2.5rem 1rem 1rem 1rem',
      margin: '-2.5rem -1rem -1rem -1rem',
    },
  })
);

const ScreenWrap: React.FC = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.root}>{children}</div>;
};

export default ScreenWrap;
