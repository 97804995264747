import { API, APIResponse, useApi } from '..';
import { TeamResponse } from '@dsf/util-types';
import { ENDPOINT_PRODUCTION_TEAM } from '..';

const BASE = ENDPOINT_PRODUCTION_TEAM;
export const getCausersPath = (): string => `${BASE}`;

export interface ProductionTeamAPI extends API {
  getCausers: () => APIResponse<TeamResponse[]>;
}

export const useProductionApi = (): ProductionTeamAPI => {
  const api = useApi();

  return {
    ...api,
    getCausers() {
      const cancelToken = api.prepareCancelToken('getCausers');
      return api.authRequest.get(getCausersPath(), { cancelToken });
    },
  };
};
