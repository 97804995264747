import moment from 'moment';

export const removeScientificNotation = (x: any): string => {
  if (Math.abs(x) < 1.0) {
    const e = parseInt(x.toString().split('e-')[1]);
    if (e) {
      x *= Math.pow(10, e - 1);
      x = '0.' + new Array(e).join('0') + x.toString().substring(2);
    }
  } else {
    let e = parseInt(x.toString().split('+')[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      x += new Array(e + 1).join('0');
    }
  }
  return x;
};

/** Utility function to create a K:V from a list of strings */
export const strEnum = <T extends string>(o: T[]): { [K in T]: K } =>
  o.reduce((res, key) => {
    res[key] = key;
    return res;
  }, Object.create(null));

export const roundNumber = (value: number | string, precision = 0) => {
  if (isNumber(value)) {
    if (value === 0) {
      return 0;
    }
    return removeScientificNotation(
      parseFloat((value as number).toFixed(precision))
    );
  }
  if (!value && value !== 0) {
    return value;
  }
  const multiplier = Math.pow(10, precision);
  return (
    Math.round((parseFloat(value as string) + Number.EPSILON) * multiplier) /
    multiplier
  );
};

export const containsUrl = (url: string) => {
  return new RegExp(
    '([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?'
  ).test(url);
};

export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm';

export const formatTime = (timestamp: string) => {
  return moment(timestamp).format(DATE_TIME_FORMAT);
};

const getUnit = (unit?: string) => {
  return unit ? ` ${unit}` : '';
};

// eslint-disable-next-line
export const isNumber = (n: any) => {
  return !isNaN(parseFloat(n)) && !isNaN(n - 0);
};

export const formatThousands = (n: string | number) => {
  const parts = n.toString().split('.');
  const numberPart = parts[0];
  const decimalPart = parts[1];
  const thousands = /\B(?=(\d{3})+(?!\d))/g;
  return (
    numberPart.replace(thousands, '.') + (decimalPart ? ',' + decimalPart : '')
  );
};

export const formatYearsValue = (
  value: any, // eslint-disable-line
  unit?: string
) => {
  if (value === 'Infinity') {
    return `∞${getUnit(unit)}`;
  } else if (value && isNumber(value)) {
    const floatValue = parseFloat(value);
    const roundedNumber = `${roundNumber(floatValue, floatValue < 99 ? 1 : 0)}`;
    const separatedNumber = formatThousands(roundedNumber);
    const unitNumber = `${separatedNumber}${getUnit(unit)}`;
    return unitNumber;
  } else if (value === '' || value === undefined || value === null) {
    return 'N/A';
  }
  return value;
};

export const formatDataValue = (
  value: any, // eslint-disable-line
  unit?: string,
  roundPrecision = 2
) => {
  if (value === 'Infinity') {
    return `∞${getUnit(unit)}`;
  } else if (value && isNumber(value)) {
    const roundedNumber = `${roundNumber(parseFloat(value), roundPrecision)}`;
    const separatedNumber = formatThousands(roundedNumber);
    const unitNumber = `${separatedNumber}${getUnit(unit)}`;
    return unitNumber;
  } else if (value === '' || value === undefined || value === null) {
    return 'N/A';
  }
  return value;
};

// eslint-disable-next-line
export const isEmptyObject = (obj: any) => Object.keys(obj).length === 0;
