import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DefaultState } from './types/defaultState';
import { TenantResponse } from '@dsf/util-types';

export const TENANT_ALPHA = 'alpha';

export interface TenantState extends DefaultState {
  tenant: TenantResponse | null;
}

const initialTenantState: TenantState = {
  tenant: null,
  persist: true,
};

export const tenantSlice = createSlice({
  name: 'tenant',
  initialState: initialTenantState,
  reducers: {
    setTenant: (state, action: PayloadAction<TenantResponse>) => {
      if (process.env.NODE_ENV === 'development') {
        //   override of appUri for local instance running against DEV API
        action.payload.appUri = 'localhost:3000';
      }

      state.tenant = action.payload;
    },
  },
});
