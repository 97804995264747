import {
  State,
  sessionTokenSlice,
  userTokensSlice,
} from '@dsf/data-access-store';
import { useUserApi } from '@dsf/data-access-api';
import { MFA_SETUP, MFA_VERIFY, TENANT_PROFILE } from '@dsf/util-router';
import { ChangeTemporaryPasswordRequest } from '@dsf/util-types';

import React, {
  RefObject,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosResponse } from 'axios';

import {
  handlePasswordUsingValidatorsChange,
  ValidatorValues,
} from '@dsf/util-tools';
import { ValidatorStatuses } from '../../../components';

import './ChangeTemporaryPasswordPage.scss';
import { withTenantLayout } from '@dsf/ui-layout';

const ChangeTemporaryPasswordPage: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const userApi = useUserApi();
  const dispatch = useDispatch();
  const { tenant } = useSelector((state: State) => state.tenant);
  const { email } = useSelector((state: State) => state.login);
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [newPassword, setNewPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const isMounted = useRef(false);
  const [showError, setShowError] = React.useState(false);
  const [statusMessage, setStatusMessage] = React.useState('');
  const [values, setValues] = React.useState<ValidatorValues>({
    showPassword: false,
    passwordCorrect: true,
    errorText: '',
    match_length: false,
    match_number: false,
    match_special: false,
    match_upper: false,
    match_lower: false,
  });
  const newPasswordInput = useRef(null) as RefObject<HTMLInputElement>;
  const { sessionToken } = useSelector((state: State) => state.sessionToken);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleChangeTemporaryPasswordAction = async (event: SyntheticEvent) => {
    event.preventDefault();
    setInProgress(true);
    const requestData: ChangeTemporaryPasswordRequest = {
      newPassword: newPassword as string,
      username: email as string,
      session: sessionToken,
    };

    try {
      const changeTemporaryPasswordResponse: AxiosResponse =
        await userApi.changeTemporaryPassword(requestData);
      if (changeTemporaryPasswordResponse.status === 200) {
        if (changeTemporaryPasswordResponse.data.accessToken) {
          dispatch(
            userTokensSlice.actions.setAccessToken(
              changeTemporaryPasswordResponse.data.accessToken
            )
          );
          history.push(TENANT_PROFILE);
        } else {
          dispatch(
            sessionTokenSlice.actions.setSessionToken(
              changeTemporaryPasswordResponse.data.payload.session
            )
          );

          if (
            changeTemporaryPasswordResponse.data.message ===
            'AUTH_MFA_SETUP_REQUIRED'
          ) {
            history.push(MFA_SETUP);
          } else if (
            changeTemporaryPasswordResponse.data.message ===
            'AUTH_MFA_TOTP_REQUIRED'
          ) {
            history.push(MFA_VERIFY);
          }
        }
      }
    } catch (e) {
      setStatusMessage(e.response.data.message);
      setShowError(true);
    }

    if (isMounted.current) {
      setInProgress(false);
    }
  };

  return (
    <div className="page-change-temporary-password">
      <Box mb={2}>
        <Typography variant="h4" align={'center'} component="h2">
          {t('changeTemporaryPasswordPage.title')}
        </Typography>
      </Box>

      <Box mb={2}>
        <Collapse in={showError}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setShowError(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            <div
              dangerouslySetInnerHTML={{
                __html: t(
                  'changeTemporaryPasswordPage.errors.' + statusMessage
                ),
              }}
            />
          </Alert>
        </Collapse>
      </Box>

      <form noValidate onSubmit={handleChangeTemporaryPasswordAction}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              inputRef={newPasswordInput}
              error={!values.passwordCorrect}
              helperText={t(values.errorText)}
              disabled={inProgress}
              variant="outlined"
              required
              fullWidth
              label={t('global.newPassword')}
              type={showPassword ? 'text' : 'password'}
              value={newPassword}
              onChange={(event) => {
                handlePasswordUsingValidatorsChange(
                  event,
                  setNewPassword,
                  setValues,
                  values
                );
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        setShowPassword((prev) => !prev);
                        setValues({
                          ...values,
                          showPassword: !values.showPassword,
                        });

                        setTimeout(() => {
                          if (newPasswordInput.current) {
                            newPasswordInput.current.setSelectionRange(
                              newPassword.length,
                              newPassword.length
                            );
                          }
                        }, 20);
                      }}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <ValidatorStatuses values={values} />
          </Grid>
        </Grid>
        <Box mt={2} mb={2}>
          <Button
            type="submit"
            className="btn"
            fullWidth
            variant="contained"
            color="primary"
            disabled={
              !!values.errorText || inProgress || !email || !newPassword
            }
            onClick={handleChangeTemporaryPasswordAction}
            key={`${
              !!values.errorText || inProgress || !email || !newPassword
            }`} // Fix for Safari
          >
            {inProgress ? (
              <CircularProgress className="progress" color="secondary" />
            ) : null}
            {t('global.continue')}
          </Button>
        </Box>
      </form>
    </div>
  );
};

export default withTenantLayout(ChangeTemporaryPasswordPage)({
  pageName: 'SCR-ChangeTemporaryPasswordPage',
});
