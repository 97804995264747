import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DefaultState } from './types/defaultState';
import { SnackbarMessage } from './types/snackbarMessage';

export interface PublicSnackbarMessagesState extends DefaultState {
  messages: SnackbarMessage[];
  open: boolean;
}

const initialPublicSnackbarMessagesState: PublicSnackbarMessagesState = {
  messages: [],
  persist: false,
  open: false,
};

export const publicSnackbarMessagesSlice = createSlice({
  name: 'publicSnackbarMessages',
  initialState: initialPublicSnackbarMessagesState,
  reducers: {
    pushMessage: (state, action: PayloadAction<SnackbarMessage>) => {
      action.payload.shown = false;
      state.open = true;
      state.messages.push(action.payload);
    },
    setOpen: (state, action: PayloadAction<boolean>) => {
      state.open = action.payload;
    },
  },
});
