import { API, APIResponse, useApi } from '..';
import { FaultType } from '@dsf/util-types';
import { ENDPOINT_FAULT_TYPE } from '..';

const BASE = ENDPOINT_FAULT_TYPE;
export const getFaultTypesPath = (faultyPartId: number): string =>
  `${BASE}?physicalAssetType=${faultyPartId}`;

export interface FaultTypesAPI extends API {
  getFaultTypes: (faultyPartId: number) => APIResponse<FaultType[]>;
}

export const useFaultTypeApi = (): FaultTypesAPI => {
  const api = useApi();

  return {
    ...api,
    getFaultTypes(faultyPartId) {
      const cancelToken = api.prepareCancelToken('getFaultTypes');
      return api.authRequest.get(getFaultTypesPath(faultyPartId), {
        cancelToken,
      });
    },
  };
};
