import { useTenantDeletionApi } from '@dsf/data-access-api';
import { FAULT_LIST } from '@dsf/util-router';
import { RequestInitDeleteTenant } from '@dsf/util-types';

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Snackbar, Typography } from '@material-ui/core';

import { Alert } from '@material-ui/lab';

import './DeleteTenantPage.scss';

import { determineDefaultLanguage } from '@dsf/util-translate';
import { Color } from '@material-ui/lab/Alert/Alert';
import { withTenantLayout } from '@dsf/ui-layout';

const DeleteTenantPage: React.FC = () => {
  const { t } = useTranslation();
  const tenantDeletionApi = useTenantDeletionApi();
  const history = useHistory();

  const [openMessage, setOpenMessage] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [severity, setSeverity] = useState<Color>('info');
  const [disabled, setDisabled] = useState<boolean>(false);

  const closeWarningHandle = (
    event?: React.SyntheticEvent,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenMessage(false);
    history.push(FAULT_LIST);
  };

  const postInitDeleteTenant = async (payload: RequestInitDeleteTenant) => {
    setDisabled(true);
    try {
      payload.lang = determineDefaultLanguage();
      const postNewTenantResponse =
        await tenantDeletionApi.postInitDeleteTenant(payload);
      if (postNewTenantResponse.status === 204) {
        setMessage('emailSent');
        setOpenMessage(true);
        setSeverity('info');
        return true;
      } else if (postNewTenantResponse.status === 401) {
        setMessage('insufficientPrivileges');
        setOpenMessage(true);
        setSeverity('error');
        return false;
      } else if (postNewTenantResponse.status === 500) {
        setMessage('apiSomethingWentWrong');
        setOpenMessage(true);
        setSeverity('error');
        return false;
      }
    } catch (error) {
      console.log(error);
      if (error.response.status === 401) {
        setMessage('insufficientPrivileges');
        setOpenMessage(true);
      } else if (error.response.status === 500) {
        setMessage('apiSomethingWentWrong');
        setOpenMessage(true);
      } else {
        console.log(error);
        setMessage('apiSomethingWentWrong');
        setOpenMessage(true);
      }
      return false;
    }
  };

  return (
    <div className="init-delete-tenant">
      <Grid item xs={12}>
        <Typography variant="h5" component="h1">
          {t('deleteTenantCluster.title1')}
        </Typography>
      </Grid>

      <p>{t('deleteTenantCluster.content1')}</p>
      <p>{t('deleteTenantCluster.content2')}</p>
      <p>{t('deleteTenantCluster.content3')}</p>
      <b>
        <p>{t('deleteTenantCluster.content4')}</p>
      </b>

      <Grid item xs={12}>
        <Button
          id="dont-delete-cluster-button"
          variant="contained"
          onClick={() => history.push(FAULT_LIST)}
          color="primary"
        >
          {t('deleteTenantCluster.no')}
        </Button>
        <Button
          id="init-cluster-delete-button"
          className={'float_right'}
          variant="contained"
          onClick={() => postInitDeleteTenant({})}
          disabled={disabled}
          key={`${disabled}`} // Fix for Safari
        >
          {t('deleteTenantCluster.yes')}
        </Button>
      </Grid>

      <Snackbar
        open={openMessage}
        autoHideDuration={5000}
        onClose={closeWarningHandle}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={closeWarningHandle} severity={severity}>
          {t('deleteTenantCluster.notificationMessages.' + message)}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default withTenantLayout(DeleteTenantPage)({
  pageName: 'SCR-Init-Delete-Tenant',
});
