import React, { useEffect, useState } from 'react';
import { useWrapApi } from '@dsf/data-access-api';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { UiContentBox } from '@dsf/ui-content-box';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import { ErrorAlert } from '@dsf/ui-components';
import CheckIcon from '@mui/icons-material/Check';
import ReceiptIcon from '@mui/icons-material/Receipt';
import './ui-location-list.module.scss';
import { Alert, Button, CircularProgress, TableHead } from '@mui/material';
import { useTranslation } from 'react-i18next';

type ShadowType = {
  name: string;
  billing_name: string;
  billing_email: string;
  billing_address: string;
  invoice_paid: boolean;
  invoice_pdf: string;
  invoice_issued_at: string;
  max_x: number;
  max_y: number;
};

export type LocationType = {
  thingName: string;
  thingTypeName: string;
  thingArn: string;
  attributes: { [key: string]: string };
  version: number;
  shadow: ShadowType;
};

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
        id="button_first-page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
        id="button_previous-page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
        id="button_next-page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
        id="button_last-page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

/* eslint-disable-next-line */
export interface UiLocationListProps {}

const CUSTOM_API_URL = `https://84qypb59df.execute-api.eu-central-1.amazonaws.com/data/locations`;

export function UiLocationList(props: UiLocationListProps) {
  const { api } = useWrapApi();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [locations, setLocations] = useState<LocationType[]>([]);
  const [locationsError, setLocationsError] = useState<undefined | string>();

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - locations.length) : 0;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    try {
      setLoading(true);
      api
        .get('/locations')
        .then(({ data }) => {
          setLocations(data);
          setLoading(false);
          setLocationsError(undefined);
        })
        .catch((error) => {
          console.error('locations error', error);
          setLocations([]);
          setLoading(false);
          setLocationsError(undefined);
        });
    } catch (error) {
      setLocations([]);
      setLoading(false);
      setLocationsError('Unable to load the locations.');
    }
  }, []);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" p="2rem">
        <CircularProgress />
      </Box>
    );
  }

  if (locationsError) {
    return (
      <Box
        sx={{
          bgcolor: '#fff',
          p: '1rem',
          borderRadius: '5px',
        }}
      >
        <ErrorAlert error={locationsError} />;
      </Box>
    );
  }

  return (
    <Box mt="2rem">
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 500 }}
          aria-label="custom pagination table"
          size="small"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell>{t('locationsPage.location')}</StyledTableCell>
              <StyledTableCell>
                {t('locationsPage.billingInfo')}
              </StyledTableCell>
              <StyledTableCell align="center">
                {t('locationsPage.actions')}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? locations.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : locations
            ).map((row) => (
              <TableRow key={row.thingName}>
                <StyledTableCell
                  scope="row"
                  style={{
                    verticalAlign: 'top',
                  }}
                >
                  {row.shadow.name}
                </StyledTableCell>
                <StyledTableCell scope="row">
                  <Box>{row.shadow.billing_name}</Box>
                  <Box>{row.shadow.billing_address}</Box>
                  <Box>{row.shadow.billing_email}</Box>
                </StyledTableCell>
                <StyledTableCell
                  scope="row"
                  style={{ width: 160, verticalAlign: 'top' }}
                  align="center"
                >
                  {!row.shadow.invoice_paid ? (
                    <Button
                      fullWidth
                      color="primary"
                      variant="contained"
                      size="small"
                      startIcon={<ReceiptIcon />}
                      href={`${CUSTOM_API_URL}/${row.thingName}/invoice`}
                      target="_blank"
                      id="button_invoice"
                    >
                      {t('locationsPage.invoice')}
                    </Button>
                  ) : (
                    <Button
                      fullWidth
                      color="success"
                      variant="contained"
                      size="small"
                      disabled
                      startIcon={<CheckIcon />}
                      id="button_all-OK"
                    >
                      {t('locationsPage.allOK')}
                    </Button>
                  )}
                </StyledTableCell>
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <StyledTableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                colSpan={3}
                count={locations.length}
                rowsPerPage={rowsPerPage}
                labelDisplayedRows={function defaultLabelDisplayedRows({
                  from,
                  to,
                  count,
                }) {
                  return `${from}–${to} ${t('global.of')} ${
                    count !== -1 ? count : `more than ${to}`
                  }`;
                }}
                labelRowsPerPage={t('global.rowsPerPage')}
                page={page}
                id="rows-per-page"
                SelectProps={{
                  inputProps: {
                    'aria-label': t('global.rowsPerPage'),
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default UiLocationList;
