import { useDispatch, useSelector } from 'react-redux';
import { strEnum } from '@dsf/util-tools';
import {
  State,
  sessionTokenSlice,
  userTokensSlice,
  featuresSlice,
  loginSlice,
  identitySlice,
} from '@dsf/data-access-store';

import { UserAPI } from '@dsf/data-access-api';
import { UserAccountResponse } from '@dsf/util-types';
import { UserRole } from '@dsf/data-access-store';
import { useHistory, useLocation } from 'react-router-dom';
import {
  DSFE_ASSET_LIST,
  FAULT_LIST,
  LOGOUT,
  MFA_SETUP,
  MFA_VERIFY,
} from '@dsf/util-router';

export const MFA_CODE_LENGTH = 6;

export const USER_ROLES = strEnum(['admin', 'grundfos_data_analyst']);

export const isLoggedIn = async (
  userApi: UserAPI,
  accessToken: string
): Promise<UserAccountResponse | boolean> => {
  if (!accessToken) {
    return false;
  }

  try {
    const response = await userApi.getUserProfile();
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    if (error.response?.status === 401) {
      return false;
    }
  }
  return false;
};

export const extractRoleFromToken = (token: string): UserRole[] => {
  const parts = token.split('.');
  if (parts[1]) {
    const decoded = JSON.parse(atob(parts[1]));
    return (decoded['cognito:groups'] || []) as UserRole[];
  }

  return [];
};

export const useLogin = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { redirectUrl } = useSelector((state: State) => state.identity);

  const navigateToMainPage = (jwtToken: string) => {
    dispatch(identitySlice.actions.setRedirectUrl(''));
    history.push(
      extractRoleFromToken(jwtToken).includes(USER_ROLES.grundfos_data_analyst)
        ? DSFE_ASSET_LIST
        : FAULT_LIST
    );
  };

  const loginAfterMFA = (jwtToken: string, refreshToken: string) => {
    dispatch(sessionTokenSlice.actions.setSessionToken(''));
    dispatch(userTokensSlice.actions.setAccessToken(jwtToken));
    if (
      extractRoleFromToken(jwtToken).includes(USER_ROLES.grundfos_data_analyst)
    ) {
      dispatch(featuresSlice.actions.activateFeature('DSF/E'));
      dispatch(featuresSlice.actions.deActivateFeature('DSF/Q'));
    }
    dispatch(userTokensSlice.actions.setRefreshToken(refreshToken));
    if (redirectUrl === LOGOUT) {
      navigateToMainPage(jwtToken);
    } else {
      if (redirectUrl) {
        dispatch(identitySlice.actions.setRedirectUrl(''));
        history.push(redirectUrl);
      } else {
        navigateToMainPage(jwtToken);
      }
    }
  };

  const handleSignIn = (
    response: any,
    handleNewPassword: (response: any) => void,
    setSubmittingForm: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    if (response.challengeName === 'SOFTWARE_TOKEN_MFA') {
      dispatch(loginSlice.actions.setLoginEmail(response));
      dispatch(sessionTokenSlice.actions.setSessionToken(response.Session));
      setSubmittingForm(false);
      history.push(MFA_VERIFY);
    } else if (response.challengeName === 'NEW_PASSWORD_REQUIRED') {
      handleNewPassword(response);
      setSubmittingForm(false);
    } else if (response.challengeName === 'MFA_SETUP') {
      dispatch(loginSlice.actions.setLoginEmail(response));
      dispatch(sessionTokenSlice.actions.setSessionToken(response.Session));
      setSubmittingForm(false);
      history.push(MFA_SETUP);
    } else if (
      response?.signInUserSession?.idToken?.jwtToken &&
      response?.signInUserSession?.refreshToken?.token
    ) {
      // Bypass MFA
      // MFA is not required and we handle the situation the same way
      // as if in the reponse case of a MFA Verify
      loginAfterMFA(
        response.signInUserSession.idToken.jwtToken,
        response.signInUserSession.refreshToken.token
      );
    }
  };

  return {
    loginAfterMFA,
    handleSignIn,
  };
};
