import React from 'react';
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';
import { useTranslation } from 'react-i18next';
import { withTenantLayout } from '@dsf/ui-layout';
import { useHistory } from 'react-router-dom';
import { LOGIN_PAGE } from '@dsf/util-router';

const HowToPage: React.FC = () => {
  const { t } = useTranslation();
  const downloadMattermostApple = '#';
  const downloadMattermostGoogle = '#';
  const imageDownloadMattermostApple = '/images/download-on-the-app-store.svg';
  const imageDownloadMattermostGoogle = '/images/get-it-on-google-play.svg';
  const history = useHistory();

  return (
    <Box sx={{ maxWidth: '721px', m: 'auto' }}>
      <div className="page-mfa-setup">
        <Box mb={2}>
          <Typography variant="h4" align={'center'} component="h1">
            {t('howToUseDsfPage.title')}
          </Typography>
        </Box>
        <Box
          sx={{ background: 'white', borderRadius: '10px', padding: '20px' }}
        >
          <Typography variant="h5" align={'left'} component="h2">
            Grundfos Digital Prototype
          </Typography>
          <p>
          The Grundfos Digital Prototype holds a Digital Twin for a Grundfos CRE 20-2 Pump. The digital platform allows access to the current data, reflecting the real world situation of the pump as well as to explore the recent history of it. It also warns the pump operators in case of malfunctions and risks for the pump. 
          </p>
          <p>
          To get the full demonstration experience, you need to setup the client to the messaging system Mattermost. 
          </p>
          <p>
          Mattermost is a professional open-source messaging system, similar to MS Teams or Slack. It is used by the digital platform to deliver real time messages to the user in case of warnings and alerts. 
          </p>
          <Typography variant="h5" align={'left'} component="h2">
            Setup Mattermost
          </Typography>
          <Typography
            variant="h6"
            align={'left'}
            component="h3"
            sx={{ marginTop: '20px' }}
          >
            <span>1. </span>
            Install Mattermost App
          </Typography>
          <Box>
            <p>
              Please install the Mattermost App from the Apple AppStore/Google Play to your device.
            </p>
            <a
              target={'_blank'}
              rel={'noreferrer'}
              href="https://apps.apple.com/de/app/mattermost/id1257222717"
            >
              <img
                className="image"
                alt="google authenticator"
                src={imageDownloadMattermostApple}
                height="40px"
                style={{
                  paddingRight: '5px',
                }}
              />
            </a>
            <a
              target={'_blank'}
              rel={'noreferrer'}
              href="https://play.google.com/store/apps/details?id=com.mattermost.rn"
            >
              <img
                className="image"
                alt="google authenticator"
                src={imageDownloadMattermostGoogle}
                height="40px"
              />
            </a>
          </Box>
          <Typography
            variant="h6"
            align={'left'}
            component="h3"
            sx={{ marginTop: '20px' }}
          >
            <span>2. </span>
            Login to Mattermost
          </Typography>
          <Box>
            <p>
            <ol>
              <li><p>Start the Mattermost app</p>
                <img className="image" 
                  alt="start mattermost"
                  src="images/howto/IMG_0102.PNG"
                  style={{ maxHeight: '100pt' }}
                   />
              </li>
              <li><p>Allow notifications from Mattermost</p>
              <img className="image" 
                  alt="allow notifications"
                  src="images/howto/IMG_0103.PNG"
                  style={{ maxHeight: '200pt' }}
                   /></li>
              <li><p>Set the server URL</p>
              <img className="image" 
                  alt="set server url"
                  src="images/howto/IMG_0104.PNG"
                  style={{ maxHeight: '200pt' }}
                   /></li>
              <li><p>Enter your username and password, which you received via e-mail</p>              <img className="image" 
                  alt="set username and password"
                  src="images/howto/IMG_0107.PNG"
                  style={{ maxHeight: '200pt' }}
                   />
              
              </li>
              </ol>
              </p>
            </Box>
            <Typography
            variant="h6"
            align={'left'}
            component="h3"
            sx={{ marginTop: '20px' }}
          >
            <span>3. </span>
            Select Mattermost Channel
          </Typography>
          <Box>
          <p>
<p>You are logged in to Mattermost now select the Mattermost channel that transports messages related to the pump:</p>
<p>
                 <img className="image" 
                  alt="set username and password"
                  src="images/howto/IMG_0108.PNG"
                  style={{ maxHeight: '200pt' }}
                   />
                   </p>
                   <p>
             <img className="image" 
                  alt="set username and password"
                  src="images/howto/IMG_0112.PNG"
                  style={{ maxHeight: '200pt' }}
                   /></p>


            </p>
            
          </Box>
          <Typography variant="h5" align={'left'} component="h2">
          If you need assistance
          </Typography>
          <p>
          If something goes wrong or you need further assistance please call
          <a href="tel:+4915158071139"> +49 151 5807 1139</a> or mail to <a href="mailto:mstulle@deloitte.de">mstulle@deloitte.de (Markus Stulle)</a>.
          </p>
        </Box>
      </div>
    </Box>
  );
};

export default withTenantLayout(HowToPage)({
  pageName: 'How-To-Use-DSF',
  publicPage: true,
});
