import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';

import { State } from '@dsf/data-access-store';
import { useFaultItemApi } from '@dsf/data-access-api';
import { FAULT_ITEM_CREATE } from '@dsf/util-router';
import {
  FaultItemListResponse,
  FaultItemStatus,
  FAULT_PRIORITY,
} from '@dsf/util-types';

import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { UserIcon, WarningIcon, WrenchIcon } from '@dsf/ui-assets';
import {
  ButtonDiv,
  Loader,
  ScreenTitle,
  ScreenWrap,
} from '../../../components';
import FloatingPlusIcon from '../../../components/FloatingPlusIcon/FloatingPlusIcon';
import { withTenantLayout } from '@dsf/ui-layout';

import { strEnum } from '@dsf/util-tools';

import { updateItemsToDisplay } from './fault-list-actions';
import {
  FaultListProvider,
  useFaultListDispatch,
  useFaultListState,
} from './fault-list-context';
import FilterBox from './filter-box';
import ProductItem from './product-item';
import SearchField from './search-field';

type StyleProps = {
  criticalFilterActive: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginBottom: '0.75rem',
    },
    lastUpdated: {
      color: 'rgba(0, 0, 0, 0.6)',
      fontSize: '0.75rem',
    },
    criticalFaultsTitle: {
      color: (p: StyleProps) =>
        p.criticalFilterActive ? 'var(--text-white)' : 'var(--critical-color)',
    },
    criticalIcon: {
      '& path': {
        fill: (p: StyleProps) =>
          p.criticalFilterActive
            ? 'var(--text-white)'
            : 'var(--critical-color)',
      },
    },
    subTitle: {
      fontSize: '1rem',
      fontWeight: 'bold',
      '& span': {
        color: '#2D2F36',
        fontSize: '0.875rem',
        opacity: 0.54,
      },
    },
  })
);

const FILTERS_ENUM = strEnum(['critical', 'open', 'assigned', 'search']);
type FilterType = keyof typeof FILTERS_ENUM;

const FaultListPage: React.FC = () => {
  const faultListDispatch = useFaultListDispatch();
  const { itemsToDisplay } = useFaultListState();

  const [listData, setListData] = useState<FaultItemListResponse[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const { profile } = useSelector((state: State) => state.identity);

  const [activeFilters, setActiveFilters] = useState<FilterType[]>([]);

  const [searchValue, setSearchValue] = useState('');

  const [criticalItemsCounter, setCriticalItemsCounter] = useState(0);
  const [openFaultCounter, setOpenFaultCounter] = useState(0);
  const [assignedCounter, setAssignedCounter] = useState(0);
  const [allFaultsCounter, setAllFaultsCounter] = useState(0);

  const classes = useStyles({
    criticalFilterActive: activeFilters.indexOf('critical') !== -1,
  });
  const { t } = useTranslation();

  const faultItemApi = useFaultItemApi();

  const resetFilterBoxes = () => {
    setActiveFilters([]);
    setSearchValue('');
    faultListDispatch(updateItemsToDisplay(listData));
  };

  const updateFilters = (filter: FilterType) => {
    setActiveFilters((prev) => {
      if (prev.indexOf(filter) === -1) {
        return [...prev, filter];
      }
      return prev.filter((item) => item !== filter);
    });
  };

  const handleCriticalFilter = () => {
    updateFilters(FILTERS_ENUM.critical);
  };

  const handleOpenFaultsFilter = () => {
    updateFilters(FILTERS_ENUM.open);
  };

  const handleAssignedFilter = () => {
    updateFilters(FILTERS_ENUM.assigned);
  };

  const getPageTitle = () => {
    if (
      activeFilters.indexOf(FILTERS_ENUM.critical) !== -1 &&
      activeFilters.indexOf(FILTERS_ENUM.open) !== -1 &&
      activeFilters.indexOf(FILTERS_ENUM.assigned) !== -1
    ) {
      return (
        <>{t('faultOverviewPage.allOpenHighPriorityFaultsAssignedToMe')}</>
      );
    } else if (
      activeFilters.indexOf(FILTERS_ENUM.critical) !== -1 &&
      activeFilters.indexOf(FILTERS_ENUM.open) !== -1
    ) {
      return <>{t('faultOverviewPage.allOpenHighPriorityFaults')}</>;
    } else if (
      activeFilters.indexOf(FILTERS_ENUM.critical) !== -1 &&
      activeFilters.indexOf(FILTERS_ENUM.assigned) !== -1
    ) {
      return <>{t('faultOverviewPage.allHighPriorityFaultsAssignedToMe')}</>;
    } else if (
      activeFilters.indexOf(FILTERS_ENUM.open) !== -1 &&
      activeFilters.indexOf(FILTERS_ENUM.assigned) !== -1
    ) {
      return <>{t('faultOverviewPage.allOpenFaultsAssignedToMe')}</>;
    } else if (activeFilters.indexOf(FILTERS_ENUM.critical) !== -1) {
      return <>{t('faultOverviewPage.criticalFaults')}</>;
    } else if (activeFilters.indexOf(FILTERS_ENUM.open) !== -1) {
      return <>{t('faultOverviewPage.openFaults')}</>;
    } else if (activeFilters.indexOf(FILTERS_ENUM.assigned) !== -1) {
      return <>{t('faultOverviewPage.assignedToMe')}</>;
    }
    return <>{t('faultOverviewPage.allFaults')}</>;
  };

  const handleFaultSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchValue(value);
    if (value) {
      setActiveFilters((prev) => [...prev, FILTERS_ENUM.search]);
    } else {
      setActiveFilters((prev) =>
        prev.filter((item) => item !== FILTERS_ENUM.search)
      );
    }
  };

  useEffect(() => {
    const fetchFaultList = async () => {
      setError(false);
      try {
        const faultItemListResponse = await faultItemApi.getList('1');
        setListData(faultItemListResponse.data);
        faultListDispatch(updateItemsToDisplay(faultItemListResponse.data));

        setLoading(false);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    };
    fetchFaultList();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let newItemsToDisplay = [...listData];
    activeFilters.forEach((item) => {
      if (item === FILTERS_ENUM.search) {
        const searchValueClean = searchValue.toLowerCase();
        const filteredItems = newItemsToDisplay.filter((item) => {
          const serialNumberClean = item.assetNumber.toLowerCase();
          return serialNumberClean.indexOf(searchValueClean) !== -1;
        });
        newItemsToDisplay = filteredItems;
      } else if (item === FILTERS_ENUM.critical) {
        const filteredItems = newItemsToDisplay.map((item) => {
          const faults = item.faultItems.filter((fault) => {
            return fault.priority === FAULT_PRIORITY.high;
          });
          return { ...item, faultItems: [...faults] };
        });
        newItemsToDisplay = filteredItems.filter(
          (item) => item.faultItems.length > 0
        );
      } else if (item === FILTERS_ENUM.assigned) {
        const filteredItems = newItemsToDisplay.map((item) => {
          const faults = item.faultItems.filter((fault) => {
            return profile && fault.assignee?.id === profile.id;
          });
          return { ...item, faultItems: [...faults] };
        });
        newItemsToDisplay = filteredItems.filter(
          (item) => item.faultItems.length > 0
        );
      } else if (item === FILTERS_ENUM.open) {
        const filteredItems = newItemsToDisplay.map((item) => {
          const faults = item.faultItems.filter((fault) => {
            return fault.status === FaultItemStatus.open;
          });
          return { ...item, faultItems: [...faults] };
        });
        newItemsToDisplay = filteredItems.filter(
          (item) => item.faultItems.length > 0
        );
      }
    });
    faultListDispatch(updateItemsToDisplay(newItemsToDisplay));
    // eslint-disable-next-line
  }, [activeFilters, listData]);

  useEffect(() => {
    let criticalCounter = 0;
    let openFaultsCounter = 0;
    let assignedCounter = 0;
    let allFaultsCounter = 0;
    itemsToDisplay.forEach((item) => {
      item.faultItems.forEach((fault) => {
        if (fault.priority === FAULT_PRIORITY.high) {
          criticalCounter++;
        }
        if (fault.status === FaultItemStatus.open) {
          openFaultsCounter++;
        }
        if (fault.assignee?.id === profile?.id) {
          assignedCounter++;
        }
        allFaultsCounter++;
      });
    });
    setCriticalItemsCounter(criticalCounter);
    setOpenFaultCounter(openFaultsCounter);
    setAssignedCounter(assignedCounter);
    setAllFaultsCounter(allFaultsCounter);
    // eslint-disable-next-line
  }, [itemsToDisplay]);

  const getProductItems = useMemo(() => {
    return itemsToDisplay.map((item, index) => {
      return (
        <ProductItem
          key={`${item.assetId}-${index}`}
          product={item}
          index={index}
        />
      );
    });
  }, [itemsToDisplay]);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return (
      <Box p="1rem" textAlign="center">
        {t('global.generalError')}
      </Box>
    );
  }

  return (
    <ScreenWrap>
      <Box display="flex" mb="2rem" mt="1.5rem">
        <Box flex="1" mr="0.5rem" maxWidth={350}>
          <SearchField onChange={handleFaultSearch} value={searchValue} />
        </Box>
        {/* TODO: Maybe */}
        {/* <Button variant="contained" color="primary" id="faultOverview_filterSearchFieldButton">
          {t('faultOverviewPage.filter')}
        </Button> */}
      </Box>
      <ScreenTitle id="faultOverviewPage_title" className={classes.title}>
        {t('faultOverviewPage.title')}
      </ScreenTitle>
      <div className={classes.lastUpdated}>
        {t('faultOverviewPage.lastUpdated')} {t('faultOverviewPage.justNow')}
      </div>
      <Box
        mt="1rem"
        display="flex"
        justifyContent="space-between"
        maxWidth={350}
      >
        <FilterBox
          onClick={handleCriticalFilter}
          title={() => (
            <div className={classes.criticalFaultsTitle}>
              {criticalItemsCounter}
            </div>
          )}
          description={() => <>{t('faultOverviewPage.criticalFaults')}</>}
          icon={() => <WarningIcon className={classes.criticalIcon} />}
          id="faultOverviewPage_filterBox_criticalFaults"
          active={activeFilters.indexOf(FILTERS_ENUM.critical) !== -1}
        />
        <FilterBox
          onClick={handleOpenFaultsFilter}
          title={() => <span>{openFaultCounter}</span>}
          description={() => <>{t('faultOverviewPage.openFaults')}</>}
          icon={() => <WrenchIcon />}
          id="faultOverviewPage_filterBox_openFaults"
          active={activeFilters.indexOf(FILTERS_ENUM.open) !== -1}
        />
        <FilterBox
          onClick={handleAssignedFilter}
          title={() => <span>{assignedCounter}</span>}
          description={() => (
            <Box mr="-0.75rem">{t('faultOverviewPage.assignedToMe')}</Box>
          )}
          icon={() => <UserIcon />}
          id="faultOverviewPage_filterBox_assignedToMe"
          active={activeFilters.indexOf(FILTERS_ENUM.assigned) !== -1}
        />
      </Box>
      <Box mt="2rem" mb="0.75rem" display="flex" justifyContent="space-between">
        <div className={classes.subTitle}>
          {getPageTitle()} <span>({allFaultsCounter})</span>
        </div>
        {activeFilters.length ? (
          <Box whiteSpace="nowrap">
            <ButtonDiv onClick={resetFilterBoxes} textLink>
              {t('faultOverviewPage.resetFilter')}
            </ButtonDiv>
          </Box>
        ) : null}
      </Box>
      <Box>{getProductItems}</Box>
      <FloatingPlusIcon
        newPage={FAULT_ITEM_CREATE.replace(':assetId?', '')}
        iconID="floating-icon-create-fault"
      />
    </ScreenWrap>
  );
};

const FaultListPageContainer: React.FC = () => (
  <FaultListProvider>
    <FaultListPage />
  </FaultListProvider>
);

export default withTenantLayout(FaultListPageContainer)({ pageName: 'SCR-35' });
