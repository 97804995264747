import { Box } from '@material-ui/core';
import { Alert, AlertColor, Button } from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';
import { AssetEventItem } from './asset-detail';

type Props = {
  data: AssetEventItem;
  onToggleExpandEvent: (eventId: string) => void;
};

const AssetEvent: React.FC<Props> = ({ data, onToggleExpandEvent }) => {
  return (
    <Alert
      severity={data.type as AlertColor}
      sx={{
        p: '3px 8px',
        borderRadius: 0,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          marginTop: '1px',
        }}
      >
        <Box
          sx={{
            mr: '0.7rem',
            minWidth: '102px',
            fontSize: '0.8rem',
          }}
        >
          {moment(data.timestamp).format('YYYY-MM-DD HH:mm')}
        </Box>
        <Box>
          <Button
            sx={{
              textTransform: 'none',
              textAlign: 'left',
              fontWeight: 'normal',
              lineHeight: '1.43',
              p: 0,
              color: 'inherit',
              display: 'inline',
            }}
            onClick={() => {
              onToggleExpandEvent(data.eventId);
            }}
          >
            {data.txt}{' '}
            {data.detail && (
              <Box
                component={'span'}
                display={'inline'}
                style={{
                  textTransform: 'uppercase',
                }}
              >
                [view details]
              </Box>
            )}
          </Button>
          {data.isExpanded && (
            <Box
              sx={{
                mt: '1rem',
              }}
            >
              {data.detail}
            </Box>
          )}
        </Box>
      </Box>
    </Alert>
  );
};

export default AssetEvent;
