import { TextField } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Autocomplete, AutocompleteProps } from '@material-ui/lab';

const LABEL_OFFSET = '42px';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    search: {
      position: 'relative',
      backgroundColor: '#fff',
    },
    searchIcon: {
      position: 'absolute',
      top: 'calc(50% - 12px)',
      right: 36,
      opacity: 0.2,
    },
    autocomplete: {
      '& .MuiAutocomplete-inputRoot': {
        paddingLeft: LABEL_OFFSET,
      },
      '& .MuiInputLabel-root:not(.MuiInputLabel-shrink)': {
        transform: `translate(${LABEL_OFFSET}, 20px) scale(1)`,
      },
    },
  })
);

export type SearchOption = {
  label: string;
};

const SearchField = <
  T extends SearchOption,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>({
  options,
  placeholder,
  ...props
}: Omit<
  AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
  'renderInput'
>) => {
  const classes = useStyles();

  const renderInput: AutocompleteProps<
    T,
    Multiple,
    DisableClearable,
    FreeSolo
  >['renderInput'] = (params) => (
    <TextField {...params} label={placeholder} variant="outlined" />
  );

  return (
    <div className={classes.search}>
      <Autocomplete
        options={options}
        getOptionLabel={(option) => option.label}
        className={classes.autocomplete}
        renderInput={renderInput}
        {...props}
      />
    </div>
  );
};

export default SearchField;
