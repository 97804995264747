import { useWrapApi } from '@dsf/data-access-api';
import { appSettingsSlice, State } from '@dsf/data-access-store';
import { Alert, Box, CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UiAssetWizard } from './ui-asset-wizard';

export const WizardContainer = () => {
  const { api } = useWrapApi();
  const dispatch = useDispatch();
  const { secrets } = useSelector((state: State) => state.appSettings);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchSecrets = async () => {
      try {
        // eslint-disable-next-line
        const data: any = await api.get('/secrets');
        if (data && data.data) {
          dispatch(appSettingsSlice.actions.setSecrets(data.data));
        }
        setLoading(false);
      } catch (error) {
        console.error('secrets error', error);
        setLoading(false);
        setError(true);
      }
    };
    fetchSecrets();
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return (
      <Box textAlign="center" p="3rem">
        <CircularProgress />
      </Box>
    );
  }

  if (!loading && error) {
    return (
      <Alert severity="error">Error while fetching the API secrets.</Alert>
    );
  }

  if (!loading && !error && secrets?.gmap_api_key) {
    return <UiAssetWizard secrets={secrets} />;
  }

  return null;
};
