import { hasFeature } from '@dsf/data-access-store';
import { DSFE_ASSET_DETAIL, DSFE_ASSET_LIST } from '@dsf/util-router';

import React from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { DecommissionedList } from '@dsf/feature-dsfe';

import { withTenantLayout } from '@dsf/ui-layout';

import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';

import { UiContentBox } from '@dsf/ui-content-box';
import { Box, Button } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const DecommissionedPageComponent = () => {
  const history = useHistory();
  const { t } = useTranslation();
  return hasFeature('DSF/E') ? (
    <Box maxWidth="721px" margin="0 auto">
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          color="primary"
          variant="outlined"
          id="button_back"
          onClick={() => {
            history.push(DSFE_ASSET_LIST);
          }}
          startIcon={<ArrowBackIcon />}
        >
          {t('assetDetail.back')}
        </Button>
      </Box>
      <UiContentBox
        icon={
          <Box>
            <PlaylistRemoveIcon />
          </Box>
        }
        title={
          <Box
            display="flex"
            fontWeight="bold"
            fontSize="1.125rem"
            alignItems="center"
            id="title_decomissioned-assets"
          >
            {t('assetManagement.decommissionedAssets')}
          </Box>
        }
      >
        <DecommissionedList />
      </UiContentBox>
    </Box>
  ) : (
    <div>Your DSF/E licence is not active.</div>
  );
};

export const DecommissionedPage = withTenantLayout(DecommissionedPageComponent)(
  { pageName: 'DECOMMISSIONED_ASSETS_PAGE' }
);
