import { State } from '@dsf/data-access-store';
import { useUserApi } from '@dsf/data-access-api';
import { LOGIN_PAGE } from '@dsf/util-router';
import { ResetPasswordRequest } from '@dsf/util-types';

import React, {
  RefObject,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import {
  handlePasswordUsingValidatorsChange,
  ValidatorValues,
} from '@dsf/util-tools';
import ValidatorStatuses from '../../../components/ValidatorStatuses';

import './ResetPasswordPage.scss';
import { useSelector } from 'react-redux';
import { withTenantLayout } from '@dsf/ui-layout';

const ResetPasswordPage: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { tenant } = useSelector((state: State) => state.tenant);
  const userApi = useUserApi();
  const { email } = useSelector((state: State) => state.login);
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [verificationCode, setVerificationCode] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const isMounted = useRef(false);
  const [showError, setShowError] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');
  const [values, setValues] = useState<ValidatorValues>({
    showPassword: false,
    passwordCorrect: true,
    errorText: '',
    match_length: false,
    match_number: false,
    match_special: false,
    match_upper: false,
    match_lower: false,
  });
  const newPasswordInput = useRef(null) as RefObject<HTMLInputElement>;
  const [showVerificationCodeInfo, setShowVerificationCodeInfo] =
    useState(true);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleResetPasswordAction = async (event: SyntheticEvent) => {
    event.preventDefault();
    setInProgress(true);
    const _email = `${email}@${tenant?.dnsMxDomain}`;
    const requestData: ResetPasswordRequest = {
      username: _email,
      newPassword: newPassword as string,
      code: verificationCode as string,
    };

    try {
      const resetPasswordResponse = await userApi.resetPassword(requestData);
      if (resetPasswordResponse.status === 200) {
        history.push(LOGIN_PAGE);
      }
    } catch (e) {
      setStatusMessage(e.response.data.message);
      setShowError(true);
    }

    if (isMounted.current) {
      setInProgress(false);
    }
  };

  return (
    <div className="page-reset-password">
      <Box mb={2}>
        <Typography variant="h4" align={'center'} component="h2">
          {t('resetPasswordPage.title')}
        </Typography>
      </Box>

      <Box mb={1}>
        <Collapse in={showError}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setShowError(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            <div
              dangerouslySetInnerHTML={{
                __html: t('resetPasswordPage.errors.' + statusMessage),
              }}
            />
          </Alert>
        </Collapse>
      </Box>

      <Box mb={2}>
        <Collapse in={showVerificationCodeInfo}>
          <Alert
            severity="info"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setShowVerificationCodeInfo(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            <div
              dangerouslySetInnerHTML={{
                __html: t('resetPasswordPage.infos.verificationCodeSent'),
              }}
            />
          </Alert>
        </Collapse>
      </Box>

      <form noValidate onSubmit={handleResetPasswordAction}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              id="ResetPassword_verificationCode_Input"
              variant="outlined"
              required
              fullWidth
              label={t('resetPasswordPage.verificationCode')}
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              id="ResetPassword_newPassword_Input"
              inputRef={newPasswordInput}
              error={!values.passwordCorrect}
              helperText={t(values.errorText)}
              disabled={inProgress}
              variant="outlined"
              required
              fullWidth
              label={t('global.newPassword')}
              type={showPassword ? 'text' : 'password'}
              value={newPassword}
              onChange={(event) => {
                handlePasswordUsingValidatorsChange(
                  event,
                  setNewPassword,
                  setValues,
                  values
                );
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        setShowPassword((prev) => !prev);
                        setValues({
                          ...values,
                          showPassword: !values.showPassword,
                        });

                        setTimeout(() => {
                          if (newPasswordInput.current) {
                            newPasswordInput.current.setSelectionRange(
                              newPassword.length,
                              newPassword.length
                            );
                          }
                        }, 20);
                      }}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <ValidatorStatuses values={values} />
          </Grid>
        </Grid>
        <Box mt={2} mb={2}>
          <Button
            id="ResetPassword_continue_Button"
            type="submit"
            fullWidth
            className="btn"
            variant="contained"
            disabled={
              !!values.errorText ||
              inProgress ||
              !email ||
              !newPassword ||
              !verificationCode
            }
            color="primary"
            onClick={handleResetPasswordAction}
            key={`${
              !!values.errorText ||
              inProgress ||
              !email ||
              !newPassword ||
              !verificationCode
            }`} // Fix for Safari
          >
            {inProgress ? (
              <CircularProgress className="progress" color="secondary" />
            ) : null}
            {t('global.continue')}
          </Button>
        </Box>
      </form>
    </div>
  );
};

export default withTenantLayout(ResetPasswordPage)({
  pageName: 'SCR-29',
});
