import React from 'react';

export interface Props {
  className?: string;
}

export const WarningIcon: React.FC<Props> = ({ className }: Props) => {
  return (
    <svg
      className={className}
      width="47"
      height="44"
      viewBox="0 0 47 44"
      fill="none"
      xmlns="http://www.w3.org/…svg"
    >
      <path
        d="M13.4401 0.25L0.884758 22L13.4401 43.75H38.5602L51.1156 22L38.5602 0.25H37.1631H13.4401ZM16.2344
      5.08333H35.7707L45.5365 22L35.7659 38.9167H16.2344L6.46386 22L16.2344 5.08333ZM23.5835 9.91667V24.4167H28.4168V9.91667H23.5835ZM23.5835
      29.25V34.0833H28.4168V29.25H23.5835Z"
        fill="#B00020"
      />
    </svg>
  );
};
