import React from 'react';

export interface ValidatorValues {
  showPassword: boolean;
  showOldPassword?: boolean;
  passwordCorrect: boolean;
  errorText: string;
  match_length: boolean;
  match_number: boolean;
  match_special: boolean;
  match_upper: boolean;
  match_lower: boolean;
}

export const handlePasswordUsingValidatorsChange = (
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  setPassword: React.Dispatch<React.SetStateAction<string>>,
  setValues: React.Dispatch<React.SetStateAction<ValidatorValues>>,
  values: ValidatorValues
): void => {
  const _password = event.target.value;
  setPassword(event.target.value);
  if (_password.length > 0) {
    if (
      _password.match(
        new RegExp(
          '^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[+@$!%*#?&_-]).{8,}$'
        )
      )
    ) {
      setValues({
        ...values,
        passwordCorrect: true,
        errorText: '',
        match_length: true,
        match_number: true,
        match_special: true,
        match_upper: true,
        match_lower: true,
      });
    } else {
      const _match_length = _password.length > 7;
      const _match_number = !!_password.match(new RegExp('^(?=.*\\d).{1,}$'));
      const _match_lower = !!_password.match(new RegExp('^(?=.*[a-z]).{1,}$'));
      const _match_upper = !!_password.match(new RegExp('^(?=.*[A-Z]).{1,}$'));
      const _match_special = !!_password.match(
        new RegExp('^(?=.*[+@$!%*#?&_-]).{1,}$')
      );

      setValues({
        ...values,
        passwordCorrect: false,
        errorText: 'global.requireStrongerPassword',
        match_length: _match_length,
        match_number: _match_number,
        match_special: _match_special,
        match_upper: _match_upper,
        match_lower: _match_lower,
      });
    }
  } else {
    setValues({
      ...values,
      passwordCorrect: false,
      errorText: 'global.requireStrongerPassword',
      match_length: false,
      match_number: false,
      match_special: false,
      match_upper: false,
      match_lower: false,
    });
  }
};
