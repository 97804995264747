import {
  ProductionSegment,
  UserProfile,
  UserProfileResponse,
  UserProfileUpdate,
  UserRole,
} from './user-profile';
import { strEnum } from '@dsf/util-tools';

export type { PhysicalAssetCreationRequest } from './physical-asset';
export type { Product } from './product';
export type {
  UserAccount,
  UserRole,
  UserAccountProductionSegment,
} from './user-profile';

export interface GeneralApiResponse {
  message?: string;
  code?: number;
  payload?: unknown;
}

export interface Post {
  userId: number;
  id: number;
  title: string;
  body: string;
}

export interface RequestAccountUnlockRequest {
  requestType: string;
  message: string;
  username: string;
}

export interface TenantResponse {
  dnsMxDomain: string;
  appUri: string;
  name: string;
  createDate: string;
  createUser: string;
  id: number;
  isReady: boolean;
  lastUpdatedDate: string;
  lastUpdatedUser: string;
}

export interface TenantAdminResponse {
  firstName: string;
  lastName: string;
}

export interface VatInfoResponse {
  valid: string;
  database: string;
  format_valid: string;
  query: string;
  country_code: string;
  vat_number: string;
  company_name: string;
  company_address: string;
}

export interface RequestPostTenant {
  vatNumber: string;
  tenantName: string;
  country: string;
  address: string;
  homepage: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  tacAccepted: boolean;
  defaultLang?: string;
}

export interface RequestInitDeleteTenant {
  lang?: string;
}

export interface RequestStartDeleteTenant {
  secret: string;
  lang?: string;
}

export interface RequestVerifyEmail {
  token: string;
}

export interface ResponseVerifyEmail {
  email: string;
}

export interface PostTenantResponse {
  vatNumber: string;
}

export interface LoginResponse extends GeneralApiResponse {
  payload: {
    session: string;
  };
  accessToken: string;
  refreshToken: string;
}

export interface RequestAccountLoginRequest {
  username: string;
  password: string;
}

export interface ChangeTemporaryPasswordRequest {
  username: string;
  newPassword: string;
  session: string;
}

export interface ChangeTemporaryPasswordResponse extends GeneralApiResponse {
  accessToken: string;
}

export interface MFASetupSecretResponse extends GeneralApiResponse {
  secret: string;
  session: string;
}

export interface MFASetupVerifyRequest {
  username: string;
  session: string;
  code: string;
}

export interface MFALoginVerifyRequest {
  username: string;
  session: string;
  code: string;
}

export interface RequestResetPasswordRequest {
  username: string;
}

export interface ResetPasswordRequest {
  username: string;
  code: string;
  newPassword: string;
}

export interface ChangeProfilePasswordRequest {
  oldPassword: string;
  newPassword: string;
  code: string;
}

export interface AccountCreateRequest {
  firstName: string;
  lastName: string;
  email: string;
}

export interface AdminCreateAccount {
  firstName: string;
  lastName: string;
  email: string;
  roleId: number | undefined;
}

export interface FaultItemPerson {
  id: number;
  firstName: string;
  lastName: string;
}

export const FAULT_PRIORITY = strEnum(['high', 'medium', 'low']);

export enum FaultItemStatus {
  open = 'open',
  notneeded = 'notneeded',
  ordered = 'ordered',
  delivered = 'delivered',
  closed = 'closed',
}

export interface FaultItem {
  assignee: FaultItemPerson;
  creationTime: string;
  updateTime: string;
  faultName: string;
  id: number;
  isExternalPartnerInformed: boolean;
  partName: string;
  pictureId: string;
  priority: string;
  sparePartDeliveryStatus: string;
  status: FaultItemStatus;
  typeOfFault: string;
}

export interface FaultItemListResponse {
  assetId: number;
  assetNumber: string;
  assetVersion: string;
  assetVersionDetailed: string;
  faultItems: FaultItem[];
  pictureId: string;
  productDescription: string;
  assetLocation: string;
  digitalIdentity: string;
  markedAsFinished: boolean;
}

export interface TeamResponse {
  teamId: number;
  name: string;
}

export type LocationType = {
  id: number;
  segmentName: string;
  siteName: string;
};

export type PhysicalAsset = {
  assetLocation: LocationType;
  assetPictureId: string;
  assetVersionDetailed: string;
  id: number;
  productDescription: string;
  productNumber: string;
  serialNumber: string;
  digitalIdentity: string;
};

export interface FaultItemDetailResponse {
  assetColour: string;
  assetLocation: string;
  assetNumber: string;
  assetPictureId: string;
  assetVersion: string;
  assetVersionDetailed: string;
  assignee: FaultItemPerson;
  faultName: string;
  faultPictureIds: string[];
  id: number;
  informedPersons: FaultItemPerson[];
  isExternalPartnerInformed: boolean;
  origin: string;
  partName: string;
  priority: string;
  reportLocation: string;
  reportTime: string;
  reporter: FaultItemPerson;
  status: FaultItemStatus;
  typOfFault: string;
}

export interface PostProfileImage {
  id: number;
  image: string;
}

export interface ProfileImageResponse {
  id: number;
  image: string;
}

export interface TagResponse {
  id: number;
  name: string;
}

export interface FaultItemCreateRequest {
  physicalAssetId: number; // id of the physical asset (must)
  reportLocationId: number; // id of the production stage (must)
  reporterId: number; // id of the reporter (must)
  faultyPartId: number; // Gear drive (must)
  faultTypeId: number; // Defect key (must)
  faultPictureIds?: string[]; // id of pictures (optional)
  category?: string; // mechanical, electrical (optional)
  status?: string; // open (optional)
  priority?: string; // open (optional)
  causerId?: number; // Cost code 2348 (optional)
  comment?: string; // reporters comment (optional)
}

export interface FaultItemPatchRequest {
  status?: FaultItemStatus;
  priority?: string;
}

export interface FaultyPart {
  id: number;
  name: string;
  pictureId?: string;
}

export interface FaultTypeAssetTypeTenantResponse {
  faultyPartId: number;
  faultyPartName: string;
  faultTypeId: number;
  faultTypeName: string;
  priority: string;
  faultCategory: string;
  faultyPartPictureId: string;
}

export interface FaultyPartImageArea {
  id: number;
  name: string;
  coords: string;
  shape: string;
}

export interface FaultType {
  id: number;
  name: string;
}

export interface UserAccountResponse {
  position: string;
  id: number;
  loginUserName: string;
  acceptedTermsAndCondition: boolean;
  dateAcceptedTermsAndConditions: string;
  versionAcceptedTermsAndConditions: string;
  mustSetNewPassword: boolean;
  isLocked: boolean;
  isConfirmed: boolean;
  isTenantAdmin: boolean;
  tenant: TenantResponse;
  lastLogin: string;
  invalidLoginAttempts: number;
  lastLogout: string;
  firstName: string;
  email: string;
  lastName: string;
  preferredUILanguage: string;
  userProfile: UserProfile;
  location?: ProductionSegment;
  role: UserRole;
  createDate: Date;
  createUser: string;
  lastUpdatedDate: Date;
  lastUpdatedUser: string;
}

export interface Point {
  x: number;
  y: number;
}

export interface OverlayLocation {
  id: number;
  points: Point[];
  physicalAssetTypeId: number;
  physicalAssetTypeName: string;
  physicalAssetTypePictureId: string;
}

export interface PictureDefinition {
  id: number;
  pictureId: number;
  width: number;
  height: number;
  overlays: OverlayLocation[];
}

export type { UserProfile, UserProfileUpdate };

export interface NotificationItemListResponse {
  category: string;
  createDate: string;
  data: {
    typeOfFault: string;
    assetNumber: string;
    faultyPart: string;
    assetVersion: string;
    category: string;
    priority: string;
    faultItemId: number;
  };
  id: number;
  unread: boolean;
}

export interface MailtoInterface {
  email: string;
  subject: string;
  body: string;
  children: string;
}

export interface TenantCreationProcessResponse {
  creationStatus: string;
  tenantName: string;
  valid: string;
  database: string;
  format_valid: string;
  query: string;
  country: string;
  vatNumber: string;
  address: string;
  homepage: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  tacAccepted: boolean;
  tenantAlias: string;
  startCreation: Date;
}

export interface CountDownProps {
  hours: number;
  minutes: number;
  seconds: number;
}

export interface Secret {
  secret: string;
}

export interface Token {
  t: string;
}

export interface User {
  id: number;
  firstname: string;
  lastname: string;
  email: string;
  locked?: boolean;
}

export interface SwipeProps {
  id: number;
  activeView: number;
  item: UserAccountResponse;
  onSwipe: (id: number) => void;
}

export interface AccountAdminOperationRequest {
  command: string;
  userAccountId: number;
}

export interface FloatingPlusIconInterface {
  newPage: string;
  iconID: string;
}

export interface ItemCommentRequest {
  comment: string;
  faultItemId: number;
}

export interface ItemCommentResponse {
  id: number;
  user?: UserProfileResponse;
  comment?: string;
  commentLanguage?: string;
  translatedComment?: string;
  translatedCommentLanguage?: string;
  timestamp?: string;
}
