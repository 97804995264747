import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { strEnum } from '@dsf/util-tools';
import { store } from '..';
import { DefaultState } from './types/defaultState';

export const FEATURES = strEnum(['DSF/Q', 'DSF/E']);
export type FeatureType = keyof typeof FEATURES;

export interface FeaturesState extends DefaultState {
  activeFeatures: FeatureType[];
}

const INITIAL_STATE: FeaturesState = {
  activeFeatures: [FEATURES['DSF/E']],
  persist: true,
};

export const featuresSlice = createSlice({
  name: 'features',
  initialState: INITIAL_STATE,
  reducers: {
    activateFeature: (state, action: PayloadAction<FeatureType>) => {
      state.activeFeatures = [...state.activeFeatures, action.payload];
    },
    deActivateFeature: (state, action: PayloadAction<FeatureType>) => {
      state.activeFeatures = [
        ...state.activeFeatures.filter((item) => item !== action.payload),
      ];
    },
  },
});

export const hasFeature = (feature: FeatureType) => {
  return store.getState().features.activeFeatures.indexOf(feature) !== -1;
};
