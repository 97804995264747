import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DefaultState } from './types/defaultState';
import { SnackbarMessage } from './types/snackbarMessage';

export interface PrivateSnackbarMessagesState extends DefaultState {
  messages: SnackbarMessage[];
  openSnack: boolean;
}

const initialPrivateSnackbarMessagesState: PrivateSnackbarMessagesState = {
  messages: [],
  persist: false,
  openSnack: false,
};

export const privateSnackbarMessagesSlice = createSlice({
  name: 'privateSnackbarMessages',
  initialState: initialPrivateSnackbarMessagesState,
  reducers: {
    pushMessage: (state, action: PayloadAction<SnackbarMessage>) => {
      action.payload.shown = false;
      state.openSnack = true;
      state.messages.push(action.payload);
    },
    setOpen: (state, action: PayloadAction<boolean>) => {
      state.openSnack = action.payload;
    },
    removeMessage: (state, action: PayloadAction<SnackbarMessage>) => {
      const message = state.messages.find((m) => m.key === action.payload.key);
      if (message) {
        const index = state.messages.indexOf(message);
        if (index !== -1) {
          state.messages.splice(index, 1);
        }
      }
    },
  },
});
