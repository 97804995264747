import React from 'react';

export interface Props {
  className?: string;
  style?: React.CSSProperties;
}

export const BackgroundIcon: React.FC<Props> = ({
  className,
  ...rest
}: Props) => {
  return (
    <svg
      className={className}
      width="850"
      height="1189"
      // viewBox="0" // removed due to console error
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M149.341 943.591L-100.381 1188.96V-269.852H850L454.756 574.532L246.355 709.378L149.341 943.591Z"
        fill="white"
        fillOpacity="0.03"
      />

      <path
        d="M137.05 910.328L-99.4886 1168.43L-175.837 -288.383L773.242 -338.122L422.732 525.79L221.673 671.359L137.05 910.328Z"
        fill="white"
        fillOpacity="0.03"
      />

      <path
        d="M122.105 880.828L-100.602 1150.95L-253.089 -299.864L692.086 -399.206L387.27 481.867L194.106 637.758L122.105 880.828Z"
        fill="white"
        fillOpacity="0.03"
      />

      <path
        d="M107.927 853.213L-100.337 1134.62L-328.544 -306.226L610.136 -454.898L351.849 440.92L167.108 606.707L107.927 853.213Z"
        fill="white"
        fillOpacity="0.03"
      />

      <path
        d="M92.554 825.601L-100.697 1117.52L-404 -309.405L525.613 -507L314.564 401.108L138.753 576.337L92.554 825.601Z"
        fill="white"
        fillOpacity="0.03"
      />
    </svg>
  );
};
