import { API, APIResponse, useApi, ENDPOINT_COMMENT } from '..';
import { ItemCommentRequest, ItemCommentResponse } from '@dsf/util-types';

const BASE = ENDPOINT_COMMENT;
export const postCapabilitiesPath = (): string => `${BASE}`;
export const translatePath = (id: number) => `${BASE}/${id}/translate`;

export interface CommentApi extends API {
  postComments: (data: ItemCommentRequest) => APIResponse<ItemCommentResponse>;
  translate: (id: number) => APIResponse<ItemCommentResponse>;
}

export const useCommentApi = (): CommentApi => {
  const api = useApi();

  return {
    ...api,
    postComments(data) {
      const cancelToken = api.prepareCancelToken('postCapabilities');
      return api.authRequest.post(postCapabilitiesPath(), data, {
        cancelToken,
      });
    },
    translate(id: number) {
      const cancelToken = api.prepareCancelToken(`translate-${id}`);
      return api.authRequest.get(translatePath(id), { cancelToken });
    },
  };
};
