import { State } from '@dsf/data-access-store';
import { useProfileImageApi } from '@dsf/data-access-api';
import { TENANT_PROFILE } from '@dsf/util-router';
import { extractRoleFromToken, USER_ROLES } from '@dsf/util-tools';
import { useQueries } from '@dsf/util-tools';
import {
  Avatar,
  Box,
  createStyles,
  IconButton,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { createImageFromBlob } from '@dsf/util-tools';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatarButton: {
      padding: '0 ',
    },
    avatarImage: {
      width: 35,
      height: 35,
    },
    userName: {
      fontSize: '0.875rem',
      fontWeight: 'bold',
    },
    userData: {
      color: '#7D7E82',
    },
  })
);

export const HeaderProfile: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const profileImageApi = useProfileImageApi();
  const { isDesktop } = useQueries();
  let isInitialized = false;
  const { accessToken } = useSelector((state: State) => state.userTokens);

  const { profile } = useSelector((state: State) => state.identity);

  const [avatar, setAvatar] = React.useState<string | undefined>(undefined);
  const [userRole, setUserRole] = React.useState<string[]>([]);
  const { roles } = useSelector((state: State) => state.userTokens);

  const handleManageProfile = () => {
    history.push(TENANT_PROFILE);
  };

  // OnLoad
  useEffect(() => {
    profileImageApi
      .getProfileImage()
      .then((value) => {
        createImageFromBlob(value.data).then((data) => {
          // eslint-disable-next-line
          setAvatar(data as any);
        });
      })
      .catch((err) => {
        console.error('profile image error', err);
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isInitialized && accessToken) {
      //const extractUserRole = extractRoleFromToken(accessToken);
      if (profile?.position) {
        if (profile?.position !== '') {
          setUserRole(profile.position);
        } else {
          // grundfos only for presentation
          setUserRole(['Grundfos']);
        }
      } else {
        // grundfos only for presentation
        setUserRole(['Grundfos']);
      }
    }

    // eslint-disable-next-line
    isInitialized = true;
  }, []);

  if (!profile) {
    return null;
  }

  return (
    <Box display="flex">
      <Box mr={isDesktop ? undefined : '1rem'}>
        <IconButton
          id="headerProfile_button"
          onClick={handleManageProfile}
          className={classes.avatarButton}
        >
          {avatar ? (
            <img className={classes.avatarImage} src={avatar} alt="Avatar" />
          ) : (
            <Avatar alt="Profile Picture" className={classes.avatarImage} />
          )}
        </IconButton>
      </Box>
      {isDesktop ? (
        <Box mr="2rem" ml="0.5rem">
          <div className={classes.userName}>
            {profile.firstName} {profile.lastName}
          </div>
          <div className={classes.userData}>{userRole}</div>
        </Box>
      ) : null}
    </Box>
  );
};
