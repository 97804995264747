import axios from 'axios';
import { Amplify, Auth } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';
import { State, userTokensSlice, identitySlice } from '@dsf/data-access-store';

import { useEffect, useState } from 'react';
import { LOGOUT } from '@dsf/util-router';
import { useHistory, useLocation } from 'react-router-dom';

export const AWS_IOT_SHADOW = 'DSF_SHADOW';

const CUSTOM_API_URL = `https://api-${window.location.hostname}`;

const API_PATH =
  process.env.NODE_ENV === 'development' ? '/dsfapi' : CUSTOM_API_URL;

export const useWrapApi = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const { accessToken } = useSelector((state: State) => state.userTokens);

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const { tenantConfig: settings } = useSelector(
    (state: State) => state.appSettings
  );

  useEffect(() => {
    if (settings) {
      Amplify.configure({
        Auth: {
          userPoolId: settings.cognito,
          userPoolWebClientId: settings.cognito_client,
          region: settings.region,
        },
      });
    }
  }, [settings]);

  useEffect(() => {
    setIsLoggedIn(!!accessToken);
  }, [accessToken]);

  const api = axios.create({
    baseURL: API_PATH,
    headers: accessToken
      ? { Authorization: `Bearer ${accessToken}` }
      : undefined,
  });

  api.interceptors.request.use(
    async (config) => {
      try {
        const session = await Auth.currentSession();
        const idTokenExpire = session.getIdToken().getExpiration();
        const refreshToken = session.getRefreshToken();
        const currentTimeSeconds = Math.round(+new Date() / 1000);
        console.log(
          'request interceptor, session',
          session,
          'idTokenExpire',
          idTokenExpire,
          'refreshToken',
          refreshToken,
          'currentTimeSeconds',
          currentTimeSeconds
        );

        if (idTokenExpire < currentTimeSeconds) {
          const currentAuthenticatedUser =
            await Auth.currentAuthenticatedUser();
          console.log(
            'request interceptor, currentAuthenticatedUser',
            currentAuthenticatedUser
          );

          currentAuthenticatedUser.refreshSession(
            refreshToken,
            // eslint-disable-next-line
            async (err: any, data: any) => {
              if (err) {
                console.log('request interceptor, refreshSession err,', err);
                if (location.pathname !== LOGOUT) {
                  dispatch(
                    identitySlice.actions.setRedirectUrl(
                      window.location.pathname
                    )
                  );
                }
                history.push(LOGOUT);
              } else {
                console.log('request interceptor, refreshSession data', data);
                config.headers.Authorization =
                  'Bearer ' + data.getIdToken().getJwtToken();
              }
            }
          );
        } else {
          console.log('request ok, token has not expired yet');
          config.headers.Authorization =
            'Bearer ' + session.getIdToken().getJwtToken();
        }
        dispatch(
          userTokensSlice.actions.setAccessToken(
            session.getIdToken().getJwtToken()
          )
        );
        dispatch(
          userTokensSlice.actions.setRefreshToken(
            session.getRefreshToken().getToken()
          )
        );
      } catch (error) {
        console.error(
          'interceptor refreshToken catch, no logged in user, error',
          error
        );
        if (error === 'No current user' && accessToken) {
          console.log('No current user => logging out');
          if (location.pathname !== LOGOUT) {
            dispatch(
              identitySlice.actions.setRedirectUrl(window.location.pathname)
            );
          }
          history.push(LOGOUT);
        }
        // No logged-in user: don't set auth header
        config.headers.Authorization = undefined;
      }
      return config;
    },
    (error) => {
      console.error('useWrapApi request interceptor, error', error);
      if (401 === error.response.status) {
        console.error('useWrapApi 401, error.response', error.response);
      }
      return Promise.reject(error);
    }
  );

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      console.error(
        'useWrapApi response interceptor, error',
        error,
        'stringify error',
        JSON.stringify(error)
      );
      if (error?.response?.status === 401) {
        console.error(
          'useWrapApi response interceptor 401, error.response',
          error.response
        );
      }
      // await refreshToken();
      return Promise.reject(error);
    }
  );

  return { api, Auth, Amplify, isLoggedIn };
};
