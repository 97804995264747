import { loginSlice, State } from '@dsf/data-access-store';
import { useUserApi } from '@dsf/data-access-api';
import { RESET_PASSWORD } from '@dsf/util-router';
import { RequestResetPasswordRequest } from '@dsf/util-types';

import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  FormControl,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';

import './RequestResetPasswordPage.scss';
import { useDispatch, useSelector } from 'react-redux';

import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import { withTenantLayout } from '@dsf/ui-layout';

const RequestResetPasswordPage: React.FC = () => {
  const { tenant } = useSelector((state: State) => state.tenant);
  const { t } = useTranslation();
  const history = useHistory();
  const userApi = useUserApi();
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [showError, setShowError] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');
  const isMounted = useRef(false);
  const dispatch = useDispatch();

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleRequestResetPasswordAction = async (event: SyntheticEvent) => {
    event.preventDefault();
    setInProgress(true);
    const _email = `${email}@${tenant?.dnsMxDomain}`;
    const requestData: RequestResetPasswordRequest = {
      username: _email as string,
    };

    try {
      const requestResetPasswordResponse = await userApi.requestResetPassword(
        requestData
      );
      if (requestResetPasswordResponse.status === 200) {
        dispatch(loginSlice.actions.setLoginEmail(email as string));
        history.push(RESET_PASSWORD);
      }
    } catch (e) {
      const knownErrors = ['AUTH_UNKNOWN_USER', 'AUTH_CANNOT_RESET_PASSWORD'];
      const errorMessage = knownErrors.includes(e.response?.data?.message)
        ? e.response?.data?.message
        : 'UNKNOWN';
      setStatusMessage(errorMessage);
      setShowError(true);
    }

    if (isMounted.current) {
      setInProgress(false);
    }
  };

  return (
    <div className="page-request-reset-password">
      <Box mb={2}>
        <Typography variant="h4" align={'center'} component="h2">
          {t('requestResetPasswordPage.title')}
        </Typography>
      </Box>

      <Box mb={1}>
        <Collapse in={showError}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setShowError(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {t('resetPasswordPage.errors.' + statusMessage)}
          </Alert>
        </Collapse>
      </Box>

      <form noValidate onSubmit={handleRequestResetPasswordAction}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl className="account">
              <TextField
                id="RequestResetPassword_email_Input"
                variant="outlined"
                required
                fullWidth
                label={t('global.account')}
                placeholder={t('global.accountPlaceholder')}
                value={email}
                type="email"
                onChange={(e) => setEmail(e.target.value)}
              />
              <Typography className="domain">@{tenant?.dnsMxDomain}</Typography>
            </FormControl>
          </Grid>
        </Grid>
        <Box mt={2} mb={2}>
          <Button
            id="RequestResetPassword_continue_Button"
            type="submit"
            fullWidth
            className="btn"
            variant="contained"
            disabled={inProgress || !email}
            color="primary"
            onClick={handleRequestResetPasswordAction}
            key={`${inProgress || !email}`} // Fix for Safari
          >
            {inProgress ? (
              <CircularProgress className="progress" color="secondary" />
            ) : null}
            {t('global.continue')}
          </Button>
        </Box>
      </form>
    </div>
  );
};

export default withTenantLayout(RequestResetPasswordPage)({
  pageName: 'SCR-29',
});
