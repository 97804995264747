import { API, APIResponse, useApi } from '..';
import { LocationType, UserAccountProductionSegment } from '@dsf/util-types';
import { ENDPOINT_LOCATIONS, ENDPOINT_USER_LOCATION } from '..';

const BASE = ENDPOINT_LOCATIONS;
const USER_MANAGEMENT_BASE = ENDPOINT_USER_LOCATION;
export const getLocationsPath = (): string => `${BASE}`;
export const getLocationByUserAccountPath = (id: number): string =>
  `${BASE}?userAccountId=${id}`;
export const getFinalLocation = (): string => `${BASE}?finalLocation=true`;
export const get = (id: number): string => `${BASE}?userAccountId=${id}`;
export const putLocationForUserAccountPath = (id: number): string =>
  `${USER_MANAGEMENT_BASE}`.replace(':accountId', id + '');

export interface LocationAPI extends API {
  getLocations: () => APIResponse<LocationType[]>;
  getLocationForUserAccount: (id: number) => APIResponse<LocationType>;
  putLocationForUserAccount: (
    id: number,
    productionSegmentId: number
  ) => APIResponse;
  getFinalLocation: () => APIResponse<LocationType[]>;
  searchLocationsForUserAccounts: (
    ids: number[]
  ) => APIResponse<UserAccountProductionSegment[]>;
}

export const useLocationApi = (): LocationAPI => {
  const api = useApi();

  return {
    ...api,
    getLocations() {
      const cancelToken = api.prepareCancelToken('getDetail');
      return api.authRequest.get(getLocationsPath(), { cancelToken });
    },
    getLocationForUserAccount(id: number) {
      const cancelToken = api.prepareCancelToken('getLocationForUserAccount');
      return api.authRequest.get(getLocationByUserAccountPath(id), {
        cancelToken,
      });
    },
    getFinalLocation() {
      const cancelToken = api.prepareCancelToken('getFinalLocation');
      return api.authRequest.get(getFinalLocation(), { cancelToken });
    },
    searchLocationsForUserAccounts(ids: number[]) {
      const cancelToken = api.prepareCancelToken(
        'searchLocationsForUserAccounts'
      );
      return api.authRequest.post(
        BASE,
        { userAccountIds: ids },
        { cancelToken }
      );
    },
    putLocationForUserAccount(id: number, productionSegmentId: number) {
      const cancelToken = api.prepareCancelToken('putLocationForUserAccount');
      return api.authRequest.put(
        putLocationForUserAccountPath(id),
        { productionSegmentId },
        { cancelToken }
      );
    },
  };
};
