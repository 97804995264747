import {
  State,
  sessionTokenSlice,
  userTokensSlice,
} from '@dsf/data-access-store';
import { useUserApi, useWrapApi } from '@dsf/data-access-api';
import { MFA_CODE_LENGTH } from '@dsf/util-tools';

import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import './MFASetupPage.scss';
import {
  Box,
  Button,
  Collapse,
  IconButton,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  TextField,
  Typography,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import AppleIcon from '@material-ui/icons/Apple';
import AndroidIcon from '@material-ui/icons/Android';
import QRCode from 'react-qr-code';
import { useDispatch, useSelector } from 'react-redux';
import { withTenantLayout } from '@dsf/ui-layout';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    expanded: {
      cursor: 'initial !important',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    detail: {
      display: 'block',
      textAlign: 'center',
    },
    button: {
      margin: '10px',
    },
  })
);

const MFASetupPage: React.FC = () => {
  const { Auth } = useWrapApi();
  const { t } = useTranslation();
  const history = useHistory();
  const userApi = useUserApi();
  const dispatch = useDispatch();

  const [inProgress, setInProgress] = useState<boolean>(false);
  const [verificationCode, setVerificationCode] = useState<string>();
  const [secret, setSecret] = useState<string>();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [showSecret, setShowSecret] = useState<boolean>(false);
  const isMounted = useRef(false);
  const [showError, setShowError] = React.useState(false);
  const [statusMessage, setStatusMessage] = React.useState('');
  const classes = useStyles();
  const { sessionToken } = useSelector((state: State) => state.sessionToken);
  const { cognitoUser } = useSelector((state: State) => state.login);
  const { email } = useSelector((state: State) => state.login);

  useEffect(() => {
    if (!cognitoUser || !Auth) {
      return;
    }
    const fetchTotp = async () => {
      try {
        const authorizationCode = await Auth.setupTOTP(cognitoUser);
        setSecret(authorizationCode);

        setLoaded(true);
      } catch (error) {
        console.error('setup totp error', error);
        setLoaded(true);
      }
    };
    fetchTotp();
  }, [cognitoUser, Auth]);

  const handleMFASetupAction = async (event: SyntheticEvent) => {
    event.preventDefault();
    await mfaSetup(`${verificationCode}`);
  };

  const mfaSetup = async (code: string) => {
    setInProgress(true);

    try {
      const verifyResponse = await Auth.verifyTotpToken(
        cognitoUser,
        code || 'null'
      );

      dispatch(sessionTokenSlice.actions.setSessionToken(''));
      dispatch(
        userTokensSlice.actions.setAccessToken(
          verifyResponse.getIdToken().getJwtToken()
        )
      );
      dispatch(
        userTokensSlice.actions.setRefreshToken(
          verifyResponse.getRefreshToken().getToken()
        )
      );

      setInProgress(false);
      history.push('/');
    } catch (error) {
      console.error('mfa verify error', error);
      setInProgress(false);
    }
  };

  const showSecretAsText = (event: SyntheticEvent): void => {
    event.preventDefault();
    setShowSecret(true);
  };

  // eslint-disable-next-line
  const mfaSetupOnChange = (e: any) => {
    setVerificationCode(e.target.value);
    if (e.target.value.length === MFA_CODE_LENGTH) {
      mfaSetup(`${e.target.value}`);
    }
  };

  return (
    <Box sx={{ maxWidth: '721px', m: 'auto' }}>
      <div className="page-mfa-setup">
        <Box mb={2}>
          <Typography variant="h4" align={'center'} component="h2">
            {t('mfaSetupPage.title')}
          </Typography>
        </Box>

        <Box mb={2}>
          <Collapse in={showError}>
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setShowError(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: t('mfaSetupPage.errors.' + statusMessage),
                }}
              />
            </Alert>
          </Collapse>
        </Box>

        <form noValidate onSubmit={handleMFASetupAction}>
          <Accordion expanded={true}>
            <AccordionSummary className={classes.expanded}>
              <Typography className={classes.heading}>
                {t('mfaSetupPage.instructions.installAuthenticatorTitle')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.detail}>
              <Typography>
                {t('mfaSetupPage.instructions.installAuthenticator')}
              </Typography>
              <Button
                className={classes.button}
                variant="contained"
                target={'_blank'}
                startIcon={<AppleIcon />}
                color="primary"
                href="https://apps.apple.com/us/app/google-authenticator/id388497605"
              >
                Apple
              </Button>
              <Button
                className={classes.button}
                variant="contained"
                target={'_blank'}
                startIcon={<AndroidIcon />}
                color="primary"
                href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
              >
                Android
              </Button>
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={true} className={classes.expanded}>
            <AccordionSummary className={classes.expanded}>
              <Typography className={classes.heading}>
                {t('mfaSetupPage.instructions.scanQrCodeTitle')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.detail}>
              <Typography>
                {t('mfaSetupPage.instructions.scanQrCode')}
              </Typography>
              <div hidden={!loaded}>
                <div className="qr-code">
                  <QRCode
                    value={`otpauth://totp/DSFQ:${email}?secret=${secret}`}
                  />
                  <Button
                    hidden={showSecret}
                    onClick={(e) => showSecretAsText(e)}
                  >
                    {t('mfaSetupPage.showSecret')}
                  </Button>
                  <div hidden={!showSecret}>
                    {t('mfaSetupPage.secret')}: <br />
                    <code>{secret}</code>
                  </div>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={true} className={classes.expanded}>
            <AccordionSummary className={classes.expanded}>
              <Typography className={classes.heading}>
                {t('mfaSetupPage.instructions.enterCodeTitle')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                variant="outlined"
                className={'code_input'}
                autoFocus={true}
                inputProps={{ maxLength: MFA_CODE_LENGTH }}
                required
                fullWidth
                label={t('global.verificationCode')}
                defaultValue={verificationCode}
                type="text"
                onChange={(e) => mfaSetupOnChange(e)}
              />
            </AccordionDetails>
          </Accordion>
        </form>
      </div>
    </Box>
  );
};

export default withTenantLayout(MFASetupPage)({
  pageName: 'SCR-MFA-Setup',
  publicPage: true,
});
