import { USER_ROLES } from '@dsf/util-tools';
import {
  Collapse,
  createStyles,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

export type MenuItemVisibility = 'admin';

export type MenuItemType = {
  label: string;
  icon?: React.FC;
  subItems?: MenuItemType[];
  onClick?: () => void;
  id?: string;
  visibility?: MenuItemVisibility;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nested: {
      paddingLeft: theme.spacing(8),
    },
    icon: {
      minWidth: 32,
    },
  })
);

type Props = {
  item: MenuItemType;
  nested?: boolean;
};

const MenuItem: React.FC<Props> = ({ item, nested }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const Icon = item.icon;
  const [open, setOpen] = React.useState(false);

  const handleParentClick = () => {
    if (item.subItems) {
      setOpen((prev) => !prev);
    }
    if (item.onClick) {
      item.onClick();
    }
  };
  return (
    <>
      <ListItem
        button
        key={item.label}
        onClick={handleParentClick}
        className={nested ? classes.nested : undefined}
        id={item.id}
      >
        {Icon ? (
          <ListItemIcon className={classes.icon}>
            <Icon />
          </ListItemIcon>
        ) : null}
        <ListItemText primary={t(item.label)} />
        {item.subItems ? (
          <span>{open ? <ExpandLess /> : <ExpandMore />}</span>
        ) : null}
      </ListItem>
      {item.subItems ? (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {item.subItems.map((subItem) => {
              return (
                <MenuItem key={subItem.label} item={subItem} nested={true} />
              );
            })}
          </List>
        </Collapse>
      ) : null}
    </>
  );
};

export default MenuItem;
