import './ui-line-chart.module.scss';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineProps,
} from 'recharts';
import { FunctionComponent } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .recharts-surface': {
        overflow: 'visible',
      },
    },
  })
);

/* eslint-disable-next-line */
export type UiLineChartProps = {
  data: any[];
  lines: LineProps[];
  xAxis: string;
  showLegend?: boolean;
  showDirections?: boolean;
};

export function UiLineChart(props: UiLineChartProps) {
  const classes = useStyles();
  const {
    data,
    lines,
    xAxis,
    showLegend = true,
    showDirections = false,
  } = props;

  const CustomizedDot: FunctionComponent<any> = (props: any) => {
    const { cx, cy, value } = props;

    const thetaPosition = props?.payload?.theta_position;

    return (
      <svg
        x={cx - 12}
        y={cy - 12}
        width="24"
        height="24"
        xmlns="http://www.w3.org/2000/svg"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="red"
        stroke="#ff0000"
        strokeWidth={'1px'}
      >
        <path
          d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"
          style={
            thetaPosition
              ? {
                  transformBox: 'fill-box',
                  transformOrigin: 'center',
                  transform: `rotate(${thetaPosition}rad)`,
                }
              : undefined
          }
        />
      </svg>
    );
  };

  return (
    <div className={classes.root}>
      <LineChart
        width={600}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 50,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey={xAxis}
          //   angle={45}
          tickCount={10}
          interval="preserveEnd"
          tick={(props) => {
            const { x, y, stroke, payload } = props;

            const payloadValue = payload?.value;
            let newValue = payloadValue;
            if (payloadValue) {
              const valueArr = payloadValue.split(' ');
              if (valueArr.length === 2) {
                newValue = (
                  <>
                    <tspan x="0" dy="1.2em">
                      {valueArr[0]}
                    </tspan>
                    <tspan x="0" dy="1.2em">
                      {valueArr[1]}
                    </tspan>
                  </>
                );
              }
            }

            return (
              <g transform={`translate(${x},${y})`}>
                <text
                  x={0}
                  y={0}
                  //   dy={16}
                  textAnchor="middle"
                  fill="#666"
                  //   transform="rotate(-35)"
                >
                  {newValue}
                </text>
              </g>
            );
          }}
        />
        <YAxis />
        <Tooltip />
        {showLegend ? <Legend wrapperStyle={{ bottom: '20px' }} /> : null}
        {lines.map((item: LineProps, index: number) => {
          return (
            <Line
              key={index}
              isAnimationActive={false}
              dot={showDirections ? <CustomizedDot /> : false}
              {
                ...(item as any) /** type is wrong? */
              }
            />
          );
        })}
      </LineChart>
    </div>
  );
}

export default UiLineChart;
