import React from 'react';

export interface Props {
  className?: string;
}

export const ClockCheckedIcon: React.FC<Props> = ({ className }: Props) => {
  return (
    <svg
      className={className}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 0.333008C10.053 0.333008 0.333328 10.0527 0.333328 21.9997C0.333328 33.9467 10.053 43.6663 22 43.6663C22.4875 43.6663 22.9642 43.6269 23.443 43.5944C22.819 42.2641 22.3859 40.8304 22.1693 39.3245H22.1566C22.0547 38.6204 22 37.9008 22 37.1663C22 28.79 28.7903 21.9997 37.1667 21.9997C37.9012 21.9997 38.6207 22.0565 39.3249 22.1605V22.1689C40.8307 22.3834 42.2666 22.8208 43.5947 23.4469C43.6272 22.9659 43.6667 22.4872 43.6667 21.9997C43.6667 10.0527 33.947 0.333008 22 0.333008ZM19.8333 8.99967H24.1667V22.8968L14.8652 32.1982L11.8014 29.1344L19.8333 21.1025V8.99967ZM37.1667 26.333C31.1845 26.333 26.3333 31.1842 26.3333 37.1663C26.3333 43.1485 31.1845 47.9997 37.1667 47.9997C43.1488 47.9997 48 43.1485 48 37.1663C48 31.1842 43.1488 26.333 37.1667 26.333ZM41.61 32.0755L44.64 35.1097L36.0833 43.6663L29.6934 37.2764L32.7233 34.2422L36.0833 37.598L41.61 32.0755Z"
        fill="#CCCCCC"
        fillOpacity="0.5"
      />
    </svg>
  );
};
