import { UiLocationList } from '@dsf/ui-location-list';
import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { withTenantLayout } from '@dsf/ui-layout';

const LocationsPage = () => {
  const { t } = useTranslation();
  return (
    <Box maxWidth="721px" margin="0 auto">
      <div>
        <h1>{t('locationsPage.title')}</h1>

        <UiLocationList />
      </div>
    </Box>
  );
};

export default withTenantLayout(LocationsPage)({ pageName: 'LocationsPage' });
