import { hasFeature } from '@dsf/data-access-store';

import { AssetDetail } from '@dsf/feature-dsfe';
import React from 'react';
import { withTenantLayout } from '@dsf/ui-layout';

const AssetDetailPage = () => {
  return hasFeature('DSF/E') ? (
    <AssetDetail />
  ) : (
    <div>Your DSF/E licence is not active.</div>
  );
};

export default withTenantLayout(AssetDetailPage)({
  pageName: 'DSFE-AssetDetailPage',
});
