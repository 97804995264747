import { API, APIResponse, useApi } from '..';
import {
  AccountCreateRequest,
  AccountAdminOperationRequest,
  ChangeProfilePasswordRequest,
  ChangeTemporaryPasswordRequest,
  ChangeTemporaryPasswordResponse,
  LoginResponse,
  MFALoginVerifyRequest,
  MFASetupSecretResponse,
  MFASetupVerifyRequest,
  RequestAccountLoginRequest,
  RequestAccountUnlockRequest,
  RequestResetPasswordRequest,
  ResetPasswordRequest,
  UserAccountResponse,
  UserProfile,
  UserProfileUpdate,
  UserAccount,
  AdminCreateAccount,
  RequestVerifyEmail,
  ResponseVerifyEmail,
} from '@dsf/util-types';
import { AxiosResponse } from 'axios';
import { ENDPOINT_USER, ENDPOINT_USER_ADMINISTRATION } from '..';
import {
  UserRoleUpdate,
  UpdateUserProfileLocation,
} from '../types/user-profile';
import { LocationType } from '@dsf/util-types';

const BASE = ENDPOINT_USER;
const BASE_ADMIN = ENDPOINT_USER_ADMINISTRATION;
export const getLoginPath = (): string => `${BASE}/login`;
export const getLogoutPath = (): string => `${BASE}/logout`;
export const getRegisterPath = (): string => `${BASE}/register`;
export const getRequestResetPasswordPath = (): string =>
  `${BASE}/lost-password`;
export const getResetPasswordPath = (): string =>
  `${BASE}/lost-password/reset-password`;
export const getRequestAccountUnlockPath = (): string =>
  `${BASE}/operation-request`;
export const getUserCreateRequestPath = (): string => `${BASE}`;
export const getUserProfilePath = (): string => `${BASE}/profile`;
export const getUserProfileImagePath = (id: number): string =>
  `${BASE}/management/${id}/image`;
export const getUserSearchPath = (): string => `${BASE}/management/search`;
export const getUserProfileLockPath = (user: string): string =>
  `${BASE}/profile/${user}/lock`;
export const getChangeTemporaryPasswordPath = (): string =>
  `${BASE}/change-temporary-password`;
export const getMFASetupPath = (): string => `${BASE}/mfa-setup`;
export const getLoginMFAVerify = (): string => `${BASE}/login/mfa`;
export const getChangeProfilePasswordPath = (): string =>
  `${BASE}/profile/change-password`;
export const getUserAccountDetail = (accountId: number): string =>
  `${BASE}/management/${accountId}`;
export const getAccountOperationPath = (): string => `${BASE_ADMIN}/operation`;
export const getAccountCreationPath = (): string => `${BASE_ADMIN}/create`;
export const getVerifyEmailPath = (): string => `${BASE}/verify-email`;
export const getUserProfileLocationPath = (): string =>
  `${BASE}/profile/location`;

export enum AccountOperation {
  LOCK = 'LOCK',
  UNLOCK = 'UNLOCK',
  SET_TEMPORARY_PASSWORD = 'SET_TEMPORARY_PASSWORD',
  CONFIRM_REGISTRATION = 'CONFIRM_REGISTRATION',
  DECLINE_REGISTRATION = 'DECLINE_REGISTRATION',
}

export interface UserAPI extends API {
  requestAccountUnlock: (
    data: RequestAccountUnlockRequest
  ) => APIResponse<void>;
  loginAccount: (
    data: RequestAccountLoginRequest
  ) => APIResponse<LoginResponse>;
  createAccount: (
    data: AccountCreateRequest
  ) => APIResponse<Record<string, never>>;
  requestResetPassword: (
    data: RequestResetPasswordRequest
  ) => APIResponse<Record<string, never>>;
  resetPassword: (
    data: ResetPasswordRequest
  ) => APIResponse<Record<string, never>>;
  getUserProfile: () => APIResponse<UserAccountResponse>;
  getUserProfileImage: (id: number) => Promise<AxiosResponse<Blob>>;
  postProfileImage: (id: number, data: Blob) => Promise<AxiosResponse>;
  getUserSearch: () => APIResponse<UserAccountResponse[]>;
  updateUserProfile: (data: UserProfileUpdate) => APIResponse<UserProfile>;
  changeTemporaryPassword: (
    data: ChangeTemporaryPasswordRequest
  ) => APIResponse<ChangeTemporaryPasswordResponse>;
  mfaSetupGetSecret: (session: string) => APIResponse<MFASetupSecretResponse>;
  mfaSetupVerifyCode: (
    data: MFASetupVerifyRequest
  ) => APIResponse<LoginResponse>;
  loginMfaVerify: (data: MFALoginVerifyRequest) => APIResponse<LoginResponse>;
  logout: () => APIResponse<void>;
  changeProfilePassword: (
    data: ChangeProfilePasswordRequest
  ) => APIResponse<void>;
  getAccount: (id: number) => APIResponse<UserAccount>;
  updateAccount: (id: number, data: UserProfileUpdate) => APIResponse<void>;
  updateUserRole: (id: number, data: UserRoleUpdate) => APIResponse<void>;
  // eslint-disable-next-line
  administrateAccount: (data: AccountAdminOperationRequest) => APIResponse<any>;
  adminCreateAccount: (
    data: AdminCreateAccount
  ) => APIResponse<Record<string, never>>;
  postVerifyEmail: (
    data: RequestVerifyEmail
  ) => APIResponse<ResponseVerifyEmail>;
  getUserProfileLocation: () => APIResponse<LocationType>;
  updateUserProfileLocation: (
    data: UpdateUserProfileLocation
  ) => APIResponse<void>;
}

export const useUserApi = (): UserAPI => {
  const api = useApi();

  return {
    ...api,
    requestAccountUnlock(data) {
      const cancelToken = api.prepareCancelToken('requestAccountUnlock');
      return api.request.post(getRequestAccountUnlockPath(), data, {
        cancelToken,
      });
    },
    loginAccount(data) {
      const cancelToken = api.prepareCancelToken('loginAccount');
      return api.request.post(getLoginPath(), data, { cancelToken });
    },
    createAccount(data) {
      const cancelToken = api.prepareCancelToken('createAccount');
      return api.request.post(getRegisterPath(), data, { cancelToken });
    },
    getUserProfile() {
      const cancelToken = api.prepareCancelToken('getUserProfile');
      return api.authRequest.get(getUserProfilePath(), { cancelToken });
    },
    getUserProfileImage(id) {
      const cancelToken = api.prepareCancelToken(`getUserProfileImage-${id}`);
      return api.authRequest.get(getUserProfileImagePath(id), {
        cancelToken,
        responseType: 'blob',
      });
    },
    postProfileImage(id: number, data: Blob) {
      const formData = new FormData();
      formData.append('file', data);
      const cancelToken = api.prepareCancelToken('postImage');
      return api.authRequest.post(getUserProfileImagePath(id), formData, {
        cancelToken,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },
    getUserSearch() {
      const cancelToken = api.prepareCancelToken('getUserSearch');
      return api.authRequest.get(getUserSearchPath(), { cancelToken });
    },
    updateUserProfile(data) {
      const cancelToken = api.prepareCancelToken('updateUserProfile');
      return api.authRequest.put(getUserProfilePath(), data, { cancelToken });
    },
    requestResetPassword(data) {
      const cancelToken = api.prepareCancelToken('requestResetPassword');
      return api.request.post(getRequestResetPasswordPath(), data, {
        cancelToken,
      });
    },
    resetPassword(data) {
      const cancelToken = api.prepareCancelToken('resetPassword');
      return api.request.post(getResetPasswordPath(), data, { cancelToken });
    },
    changeTemporaryPassword(data) {
      const cancelToken = api.prepareCancelToken('changeTemporaryPassword');
      return api.request.post(getChangeTemporaryPasswordPath(), data, {
        cancelToken,
      });
    },
    mfaSetupGetSecret(session) {
      const cancelToken = api.prepareCancelToken('mfaSetupGetSecret');
      return api.request.get(getMFASetupPath(), {
        params: { session },
        cancelToken,
      });
    },
    mfaSetupVerifyCode(data) {
      const cancelToken = api.prepareCancelToken('mfaSetupVerifyCode');
      return api.request.post(getMFASetupPath(), data, { cancelToken });
    },
    loginMfaVerify(data) {
      const cancelToken = api.prepareCancelToken('loginMfaVerify');
      return api.request.post(getLoginMFAVerify(), data, { cancelToken });
    },
    logout() {
      const cancelToken = api.prepareCancelToken('logout');
      return api.authRequest.post(getLogoutPath(), { cancelToken });
    },
    changeProfilePassword(data) {
      const cancelToken = api.prepareCancelToken('changeProfilePassword');
      return api.authRequest.post(getChangeProfilePasswordPath(), data, {
        cancelToken,
      });
    },
    getAccount(id: number) {
      const cancelToken = api.prepareCancelToken('getAccount');
      return api.authRequest.get(getUserAccountDetail(id), { cancelToken });
    },
    updateAccount(id: number, data: UserProfileUpdate) {
      const cancelToken = api.prepareCancelToken('updateAccount');
      return api.authRequest.put(getUserAccountDetail(id), data, {
        cancelToken,
      });
    },
    updateUserRole(id: number, data: UserRoleUpdate) {
      const cancelToken = api.prepareCancelToken('updateUserRole');
      return api.authRequest.put(getUserAccountDetail(id) + '/role', data, {
        cancelToken,
      });
    },
    administrateAccount(data) {
      const cancelToken = api.prepareCancelToken('administrateAccount');
      return api.authRequest.post(getAccountOperationPath(), data, {
        cancelToken,
      });
    },
    adminCreateAccount(data) {
      const cancelToken = api.prepareCancelToken('adminCreateAccount');
      return api.authRequest.post(getAccountCreationPath(), data, {
        cancelToken,
      });
    },
    postVerifyEmail(data) {
      const cancelToken = api.prepareCancelToken('postVerifyEmail');
      return api.request.post(getVerifyEmailPath(), data, { cancelToken });
    },
    getUserProfileLocation() {
      const cancelToken = api.prepareCancelToken('getUserProfileLocation');
      return api.authRequest.get(getUserProfileLocationPath(), { cancelToken });
    },
    updateUserProfileLocation(data: UpdateUserProfileLocation) {
      const cancelToken = api.prepareCancelToken('updateUserProfileLocation');
      return api.authRequest.put(getUserProfileLocationPath(), data, {
        cancelToken,
      });
    },
  };
};
