export const LANDING = '/';
export const LOGIN = '/login-default';
export const LOGIN_PAGE = '/login';
export const HALL_MAP = '/hall-map';
export const NEWS = '/news';
export const LOGOUT = '/logout';
export const CHANGE_TEMPORARY_PASSWORD = '/change-temporary-password';
export const REQUEST_RESET_PASSWORD = '/request-reset-password';
export const RESET_PASSWORD = '/reset-password';
export const SIGNUP = '/self-registration';
export const TENANT = '/tenant';
export const TENANT_REQUEST_CHANGE_PASSWORD = '/request-change-password';
export const TENANT_CHANGE_PASSWORD = '/change-password';
export const TENANT_PROFILE = '/profile';
export const UNLOCK_ACCOUNT_REQUEST = '/unlock-account/request';
export const UNLOCK_ACCOUNT_SUCCESS = '/unlock-account/success';
export const FAULT_LIST = '/faults';
export const MFA_SETUP = '/mfa-setup';
export const MFA_VERIFY = '/mfa-verify';
export const SERVICES = '/services';
export const PRICE = '/price';
export const ABOUT = '/about';
export const NEW_TENANT_SUMMARY = '/new-tenant/:secret';
export const NEW_TENANT_PROCESS = '/new-tenant/:secret/processing';
export const NEW_TENANT = '/new-tenant';
export const TAC = '/terms-and-conditions';
export const SUPPORT = '/support';
export const VERIFY_EMAIL = '/verify-email';
export const HOW_TO_USE_DSF = '/how-to-use-my-account';

export const TENANT_NON_SPECIFIC_ROUTES = [
  LOGIN,
  SERVICES,
  ABOUT,
  NEW_TENANT,
  SIGNUP,
  PRICE,
  TAC,
  SUPPORT,
];
