import { Avatar } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

export interface Props {
  profileImage?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatarPlaceholder: {
      fontSize: '8px',
    },
    image: {
      maxWidth: '45px',
    },
  })
);

const ProfileImage = ({ profileImage }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const profileImageItem = () => {
    if (profileImage) {
      return (
        <img
          className={clsx('avatar__image', classes.image)}
          src={profileImage}
          alt="Avatar"
        />
      );
    } else {
      return (
        <div className={classes.avatarPlaceholder}>
          {t('UserOverviewPage.avatar.noImage')}
        </div>
      );
    }
  };

  return (
    <Avatar className="avatar" sizes="">
      {profileImageItem()}
    </Avatar>
  );
};

export default ProfileImage;
