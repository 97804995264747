import { makeStyles, createStyles, Box } from '@material-ui/core';

import { Theme } from 'pretty-format';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: '1px solid rgba(45, 47, 54, 0.2)',
      background: '#fff',
      '& path': {
        fill: '#707483',
      },
    },
  })
);

type Props = {
  title: JSX.Element;
  icon?: JSX.Element;
};

const ContentBox: React.FC<Props> = ({ title, icon, children }) => {
  const classes = useStyles();

  return (
    <Box
      p="1rem"
      m="2rem 0 2rem 0"
      className={classes.root}
      borderRadius="10px"
    >
      <Box
        display="flex"
        alignItems="center"
        borderBottom="1px solid rgba(45, 47, 54, 0.2)"
        p="0 1rem 1rem 1rem"
        m="0 -1rem 1rem -1rem"
      >
        <Box>{icon}</Box>
        <Box ml="1rem" flexGrow="1">
          {title}
        </Box>
      </Box>
      {children}
    </Box>
  );
};

export default ContentBox;
