import React from 'react';
import Typography, { TypographyProps } from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = (props: TypographyProps) =>
  makeStyles({
    root: {
      fontSize: '1rem',
      marginBottom: props.gutterBottom ? '1rem' : 0,
    },
  });

const ScreenText: React.FC<TypographyProps> = (props) => {
  const { children, gutterBottom = true, variant = 'body1', ...rest } = props;

  const classes = useStyles({ ...props, gutterBottom, variant })();

  return (
    <Typography
      variant={variant}
      gutterBottom={gutterBottom}
      className={classes.root}
      {...rest}
    >
      {children}
    </Typography>
  );
};

export default ScreenText;
