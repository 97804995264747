import { API, APIResponse, useApi, ENDPOINT_FAULT_PART } from '..';
import { FaultyPart } from '@dsf/util-types';

const BASE = ENDPOINT_FAULT_PART;
export const getFaultPartsPath = (physicalAssetId: number) =>
  `${BASE}?physicalAssetId=${physicalAssetId}`;

export interface FaultPartsAPI extends API {
  getFaultParts: (physicalAssetId: number) => APIResponse<FaultyPart[]>;
}

export const useFaultPartsApi = (): FaultPartsAPI => {
  const api = useApi();

  return {
    ...api,
    getFaultParts(physicalAssetId) {
      const cancelToken = api.prepareCancelToken('getFaultParts');
      return api.authRequest.get(getFaultPartsPath(physicalAssetId), {
        cancelToken,
      });
    },
  };
};
