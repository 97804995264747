import { privateSnackbarMessagesSlice } from '@dsf/data-access-store';
import React from 'react';
import { QrReader, OnResultFunction } from 'react-qr-reader';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles } from '@material-ui/core';

export const VALUE_PREFIXES = {
  LOCATION: 'DSF-LOC-',
  PHYSICAL_ASSET: 'DSF-PA-',
};
export const VALUE_PATTERNS = {
  LOCATION: new RegExp(`^${VALUE_PREFIXES.LOCATION}([0-9]{1,})$`, 'g'),
  PHYSICAL_ASSET: new RegExp(
    `^${VALUE_PREFIXES.PHYSICAL_ASSET}([0-9]{1,})$`,
    'g'
  ),
};

type Props = {
  successCallback: (value: string) => void;
  // eslint-disable-next-line
  errorCallback?: (error: any) => void;
  valuePattern?: RegExp;
};

const useStyles = makeStyles(() =>
  createStyles({
    qrScanner: {
      width: '100%',
      '& section': {
        borderRadius: '16px',
      },

      '& section div': {
        boxShadow: 'unset !important',
        border: 'unset !important',
      },

      '@media (min-width:600px)': {
        width: '60%',
        maxWidth: 800,
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
  })
);

const QrScanner: React.FC<Props> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const readerPeriod = 500; // ms

  const sendNotification = (message: string): void => {
    dispatch(
      privateSnackbarMessagesSlice.actions.pushMessage({
        message: message,
        key: new Date().getTime(),
      })
    );
  };

  const makeSuggestion = (value: string): void => {
    const suggestion = Object.entries(VALUE_PATTERNS).find((e) =>
      value.match(e[1])
    );

    if (suggestion) {
      // Known pattern, we can provide suggestion
      const suggestionMessage =
        `${t('qrScanner.suggestions.beforeType')} ` +
        t(`qrScanner.suggestions.types.${suggestion[0]}`) +
        `${t('qrScanner.suggestions.afterType')}`;
      sendNotification(suggestionMessage);
    } else {
      // Unknown value
      sendNotification('qrScanner.unknownValue');
    }
  };

  const checkValuePattern = (value: string, pattern: RegExp): boolean => {
    if (value.match(pattern)) {
      // Value matches expected pattern
      return true;
    } else {
      // Value is not matched expected pattern
      makeSuggestion(value);
      return false;
    }
  };

  // eslint-disable-next-line
  const handleScan: OnResultFunction = (result, error): void => {
    // No value read
    if (!result || error) {
      return;
    }

    if (error && !result) {
      sendNotification('qrScanner.unknownError');
      console.error('Error during QR scanning', error);
      if (props?.errorCallback) {
        props.errorCallback('qrScanner.unknownError');
      }
    }

    // Scanner has set pattern for expected value
    if (props?.valuePattern) {
      if (!checkValuePattern(`${result.getText()}`, props.valuePattern)) {
        return;
      }
    }

    // Run callback
    props.successCallback(`${result.getText()}`);
  };

  return (
    <QrReader
      className={`qr-reader ${classes.qrScanner}`}
      scanDelay={readerPeriod}
      onResult={handleScan}
      constraints={{ facingMode: 'environment' }}
    />
  );
};

export default QrScanner;
