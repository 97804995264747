import LoginIcon from '@mui/icons-material/Login';
import LockIcon from '@mui/icons-material/Lock';
import {
  identitySlice,
  sessionTokenSlice,
  State,
  userTokensSlice,
} from '@dsf/data-access-store';
import { LOGIN_PAGE, LOGOUT } from '@dsf/util-router';

import axios from 'axios';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

// import ws from '../../../services/websocket';
import { Box, Button } from '@mui/material';
import { useQueries } from '@dsf/util-tools';
import TouchRipple from '@mui/material/ButtonBase/TouchRipple';
import { withTenantLayout } from '@dsf/ui-layout';
import { useWrapApi } from '@dsf/data-access-api';

const LogoutPage: React.FC = () => {
  const { Auth } = useWrapApi();
  const { isMobile } = useQueries();
  const { t } = useTranslation();
  const { redirectUrl } = useSelector((state: State) => state.identity);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!dispatch || !Auth) {
      return;
    }
    const logout = async () => {
      try {
        await Auth.signOut({ global: true });
        // ws.stop();
        dispatch(identitySlice.actions.clear());
        dispatch(sessionTokenSlice.actions.setSessionToken(''));
        dispatch(userTokensSlice.actions.setAccessToken(''));
        dispatch(userTokensSlice.actions.setRefreshToken(''));
        if (redirectUrl === LOGOUT) {
          dispatch(identitySlice.actions.setRedirectUrl(''));
        }
        axios.defaults.headers.common['Authorization'] = '';
      } catch (error) {
        console.error('logout error', error);
      }
    };
    logout();
  }, [dispatch, Auth, redirectUrl]);

  return (
    <Box
      sx={{
        position: 'relative',
        textAlign: 'center',
        p: isMobile ? '2rem' : '4rem 8rem',
        m: '2rem auto',
        bgcolor: '#fff',
        border: '1px solid #bbb',
        borderRadius: '10px',
        boxShadow: '0px 0px 20px #ccc',
        overflow: 'hidden',
        maxWidth: '721px',
      }}
    >
      <LockIcon
        sx={{
          position: 'absolute',
          top: '50px',
          left: '50px',
          opacity: 0.1,
          transform: 'scale(8) rotate(25deg)',
        }}
      />

      <Box
        sx={{ fontSize: isMobile ? '1rem' : '1.5rem', fontWeight: 'bold' }}
        id="successfullyLogout"
      >
        {t('logoutPage.successfullyLogout')}
      </Box>
      <Box sx={{ mt: '2rem' }} id="successfullyLogoutDescription">
        {t('logoutPage.successfullyLogoutDescription')}
      </Box>

      <Box sx={{ mt: '2rem' }}>
        <Button
          variant="contained"
          endIcon={<LoginIcon />}
          onClick={() => history.push(LOGIN_PAGE)}
          id="Logout_return_Link"
        >
          {t('logoutPage.return')}
        </Button>
      </Box>
    </Box>
  );
};

export default withTenantLayout(LogoutPage)({
  pageName: 'SCR-Logout',
  publicPage: true,
});
