import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UserAccountResponse } from '@dsf/util-types';
import { DefaultState } from './types/defaultState';

export interface IdentityState extends DefaultState {
  profile: UserAccountResponse | null;
  loadingProfile: boolean;
  redirectUrl: string;
}

const initialIdentityState: IdentityState = {
  profile: null,
  loadingProfile: false,
  persist: true,
  redirectUrl: '',
};

export const identitySlice = createSlice({
  name: 'identity',
  initialState: initialIdentityState,
  reducers: {
    setLoadingProfile: (
      state,
      { payload: loadingProfile }: PayloadAction<boolean>
    ) => {
      state.loadingProfile = loadingProfile;
    },
    setProfile: (
      state,
      { payload: userProfile }: PayloadAction<UserAccountResponse>
    ) => {
      state.profile = userProfile;
      state.loadingProfile = false;
    },
    userNotFound: (state) => {
      state.loadingProfile = false;
    },
    setRedirectUrl: (
      state,
      { payload: redirectUrl }: PayloadAction<string>
    ) => {
      state.redirectUrl = redirectUrl;
    },
    clear: (state) => {
      state.loadingProfile = false;
      state.profile = null;
    },
  },
});
