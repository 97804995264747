import React, { useEffect } from 'react';

import { hasFeature } from '@dsf/data-access-store';
import { FeatureDsfe } from '@dsf/feature-dsfe';
import { withTenantLayout } from '@dsf/ui-layout';

const DsfePage = () => {
  return hasFeature('DSF/E') ? (
    <FeatureDsfe />
  ) : (
    <div>Your DSF/E licence is not active.</div>
  );
};

export default withTenantLayout(DsfePage)({ pageName: 'DSFE' });
