import InputAdornment from '@material-ui/core/InputAdornment';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: '#fff',
    },
  })
);

type Props = {
  onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  value?: string;
};

const SearchField: React.FC<Props> = ({ onChange, value }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <TextField
      id="faultOverview_searchField"
      placeholder={t('faultOverviewPage.searchForFaultOrAsset')}
      variant="outlined"
      className={classes.root}
      onChange={onChange}
      value={value}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchField;
