import React from 'react';

export interface Props {
  className?: string;
}

export const AlertIcon: React.FC<Props> = ({ className }: Props) => {
  return (
    <svg
      className={className}
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.66895 0.5L0.339523 8L4.66895 15.5H13.3311L17.6605 8L13.3311 0.5H12.8493H4.66895ZM5.63249 2.16667H12.3691L15.7367
       8L12.3675 13.8333H5.63249L2.26335 8L5.63249 2.16667ZM8.16667 3.83333V8.83333H9.83334V3.83333H8.16667ZM8.16667
       10.5V12.1667H9.83334V10.5H8.16667Z"
        fill="black"
      />
    </svg>
  );
};
