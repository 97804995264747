import * as React from 'react';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, BoxProps, ListItemIcon, Typography } from '@mui/material';

const ITEM_HEIGHT = 48;

type Option = {
  label: string;
  icon?: JSX.Element;
  onClick?: () => void;
  id?: string;
};

type Props = IconButtonProps & {
  options: Option[];
};

export const ThreeDots: React.FC<Props> = ({ options, ...rest }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        {...rest}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '100%',
            maxWidth: '200px',
          },
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.label}
            onClick={() => {
              handleClose();
              if (option.onClick) {
                option.onClick();
              }
            }}
            id={option.id}
            sx={{ py: '0.75rem' }}
          >
            {option.icon ? <ListItemIcon>{option.icon}</ListItemIcon> : null}
            <Typography variant="inherit" noWrap>
              {option.label}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
