import { Box, Grid, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { blue, grey } from '@material-ui/core/colors';
import { useTranslation } from 'react-i18next';
import TranslateIcon from '@material-ui/icons/Translate';
import { ButtonDiv, Loader } from '../../../../components';
import { useSelector } from 'react-redux';
import { State } from '@dsf/data-access-store';
import ProfileImage from '../../../../components/user-profile-image/ProfileImage';

export interface Props {
  isFirst: boolean;
  title: string;
  comments: Comment[];
  profileImages: { id: number; image?: string }[];
  translateComment: (id: number) => void;
  showTranslation: (id: number) => void;
}

export interface Comment {
  id: number;
  userId: number;
  title: string;
  text: string;
  textLanguage: string;
  translatedText: string;
  translatedTextLanguage: string;
  isTranslated: boolean;
  isTranslating: boolean;
  showTranslation: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    discussionGroup: {
      paddingTop: theme.spacing(1),
    },
    groupTitle: {
      fontSize: '0.8rem',
      color: grey[700],
    },
    whiteComment: {
      background: 'white',
    },
    blueComment: {
      background: blue[400],
      color: 'white',
    },
    firstComment: {
      borderTopLeftRadius: '10px',
      borderTopRightRadius: '10px',
    },
    lastComment: {
      borderBottomRightRadius: '10px',
    },
    comment: {
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    commentHeader: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      borderBottom: '1px solid rgba(45, 47, 54, 0.2)',
    },
    commentHeaderIcon: {
      width: theme.spacing(4),
      height: theme.spacing(2),
      color: grey[700],
      '& > .white': {
        color: 'white',
      },
    },
    commentHeaderTitle: {
      fontSize: '0.8rem',
      color: grey[700],
      fontWeight: 400,
    },
    commentTitle: {
      padding: theme.spacing(2),
      fontSize: '0.6rem',
      color: grey[600],
      fontWeight: 400,
    },
    commentText: {
      paddingTop: 0,
      paddingLeft: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    white: {
      color: 'white',
    },
  })
);

const DiscussionCommentGroup = ({
  title,
  comments,
  isFirst,
  profileImages,
  translateComment,
  showTranslation,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { profile } = useSelector((state: State) => state.identity);

  const discussionComments = (
    comment: Comment,
    isFirstComment: boolean,
    isLastComment: boolean
  ) => {
    const showTranslationHeader = () => {
      return comment.textLanguage !== comment.translatedTextLanguage;
    };

    const isMyComment = (): boolean => {
      return comment.userId === profile?.id;
    };

    const showTranslatedText = () => {
      return comment.showTranslation;
    };

    const onHeaderClickHandler = () => {
      showTranslation(comment.id);
      if (!comment.isTranslated) {
        translateComment(comment.id);
      }
    };

    const commentHeader = () => {
      return showTranslatedText()
        ? `${t('faultItemDiscussion.translation.showOriginal')} (${t(
            'language.' + comment.textLanguage
          )})`
        : `${t('faultItemDiscussion.translation.showTranslatedText')} (${t(
            'language.' + comment.translatedTextLanguage
          )})`;
    };

    const commentText = () => {
      if (showTranslatedText()) {
        if (comment.isTranslating) {
          return <Loader color="inherit" />;
        } else {
          return comment.translatedText;
        }
      } else {
        return comment.text;
      }
    };

    return (
      <Box key={comment.id} display="flex" flexDirection="row">
        <Box display="flex" alignItems="flex-end">
          <ProfileImage
            profileImage={
              profileImages.find((v) => v.id === comment.userId)?.image
            }
          />
        </Box>
        <Grid container>
          <Grid
            item
            xs={12}
            className={clsx(
              classes.comment,
              isMyComment() ? classes.whiteComment : classes.blueComment,
              isFirstComment ? classes.firstComment : '',
              isLastComment ? classes.lastComment : ''
            )}
          >
            {showTranslationHeader() && (
              <Box className={classes.commentHeader} display="flex">
                <div
                  className={clsx(
                    classes.commentHeaderIcon,
                    !isMyComment() ? classes.white : ''
                  )}
                >
                  <TranslateIcon />
                </div>
                <ButtonDiv onClick={onHeaderClickHandler}>
                  <Typography
                    className={clsx(
                      classes.commentHeaderTitle,
                      !isMyComment() ? classes.white : ''
                    )}
                  >
                    {commentHeader()}
                  </Typography>
                </ButtonDiv>
              </Box>
            )}

            <Typography
              className={clsx(
                classes.commentTitle,
                !isMyComment() ? classes.white : ''
              )}
            >
              {comment.title}
            </Typography>
            <Typography
              className={clsx(
                classes.commentText,
                !isMyComment() ? classes.white : ''
              )}
            >
              {commentText()}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const createCommentElements = (comments: Comment[]) => {
    return comments.map((m) =>
      discussionComments(
        m,
        comments.indexOf(m) === 0,
        comments.indexOf(m) === comments.length - 1
      )
    );
  };

  return (
    <div className={clsx(classes.discussionGroup)}>
      <Box display="flex" justifyContent="center">
        <Typography className={classes.groupTitle}>{title}</Typography>
      </Box>
      {createCommentElements(comments)}
    </div>
  );
};

export default DiscussionCommentGroup;
