import { useLocationApi } from '@dsf/data-access-api';
import SearchField, { SearchOption } from '../components/search-field';
import { Box } from '@material-ui/core';
import InfoLabel from '../pages/private/CreateFaultItemPage/components/InfoLabel';
import { LocationType } from '@dsf/util-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import QrScanner, {
  VALUE_PATTERNS,
  VALUE_PREFIXES,
} from '../components/qr-scanner';
import OrBlock from '../components/or-block';

export type LocationOption = SearchOption & LocationType;

type Props = {
  onChange?: (value: LocationOption) => void;
};

const LocationScanner: React.FC<Props> = ({ onChange }) => {
  const { t } = useTranslation();
  const locationApi = useLocationApi();

  const [locations, setLocations] = useState<LocationType[]>([]);
  const [searchOptions, setSearchOptions] = useState<LocationOption[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<LocationType>();

  useEffect(() => {
    const getLocations = async () => {
      try {
        const response = await locationApi.getLocations();
        setLocations(response.data);
        setSearchOptions(
          response.data.map((item) => ({ label: item.segmentName, ...item }))
        );
      } catch (error) {
        console.log(error);
      }
    };
    getLocations();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Box mb="1rem">
        <InfoLabel text={t('createFaultItem.location.infoLabel')} />
        <QrScanner
          successCallback={(value: string) => {
            const locId = value.replace(VALUE_PREFIXES.LOCATION, '');
            const scannedLocation = locations.find(
              (location) => `${location.id}` === locId
            );
            if (scannedLocation) {
              setSelectedLocation(scannedLocation);
            } else {
              console.log('locationNotFound');
            }
          }}
          valuePattern={VALUE_PATTERNS.LOCATION}
        />
      </Box>

      <OrBlock />

      <Box mb="1rem">
        <SearchField
          placeholder={t('createFaultItem.location.select')}
          options={searchOptions}
          onChange={(
            event: React.ChangeEvent<unknown>,
            newValue:
              | string
              | LocationOption
              | (string | LocationOption)[]
              | null
          ) => {
            const option = newValue as LocationOption;
            setSelectedLocation(option);
            if (onChange) {
              onChange(newValue as LocationOption);
            }
          }}
        />
      </Box>
    </>
  );
};

export default LocationScanner;
