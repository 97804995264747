import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DefaultState } from './types/defaultState';

export interface RequestChangePasswordState extends DefaultState {
  oldPassword: string;
  password: string;
}

const initialRequestChangePasswordState: RequestChangePasswordState = {
  oldPassword: '',
  password: '',
  persist: false,
};

export const requestChangePasswordSlice = createSlice({
  name: 'requestChangePassword',
  initialState: initialRequestChangePasswordState,
  reducers: {
    setOldPassword: (state, action: PayloadAction<string>) => {
      state.oldPassword = action.payload;
    },
    setPassword: (state, action: PayloadAction<string>) => {
      state.password = action.payload;
    },
  },
});
