import {
  Button,
  ButtonProps,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'inline',
      padding: 0,
      verticalAlign: 'inherit',
      textTransform: 'none',
      lineHeight: 'inherit',
      letterSpacing: 'normal',
      textDecoration: 'underline',
      borderRadius: 0,
      color: '#5589EF',
      fontSize: 'inherit',
      fontWeight: 'normal',
      '&:hover': {
        background: 'transparent',
      },
    },
  })
);

const TextButton: React.FC<ButtonProps> = ({ children, ...rest }) => {
  const classes = useStyles();
  return (
    <Button className={classes.root} {...rest}>
      {children}
    </Button>
  );
};

export default TextButton;
