import { privateSnackbarMessagesSlice } from '@dsf/data-access-store';
import { usePhysicalAssetApi, useUserApi } from '@dsf/data-access-api';
import { PRODUCT_IDENTIFIED } from '@dsf/util-router';
import { FaultItemListResponse } from '@dsf/util-types';

import { Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import OrBlock from '../components/or-block';
import QrScanner, {
  VALUE_PATTERNS,
  VALUE_PREFIXES,
} from '../components/qr-scanner';
import SearchField, { SearchOption } from '../components/search-field';

type Option = SearchOption & FaultItemListResponse;

type Props = {
  id?: string;
};

const ProductScanner: React.FC<Props> = ({ id }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const physicalAssetAPI = usePhysicalAssetApi();
  const userApi = useUserApi();

  const [loaded, setLoaded] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [searchOptions, setSearchOptions] = useState<Option[]>([]);
  const [physicalAsset, setPhysicalAsset] = useState<FaultItemListResponse>();

  useEffect(() => {
    const getFaultItemList = async () => {
      setLoaded(false);
      try {
        const response = await physicalAssetAPI.getAll();
        setSearchOptions(
          response.data.map((item) => ({ label: item.assetNumber, ...item }))
        );
      } catch (e) {
        setError(true);
      }
      setLoaded(true);
    };
    getFaultItemList();
    // eslint-disable-next-line
  }, []);

  const sendNotification = (message: string): void => {
    dispatch(
      privateSnackbarMessagesSlice.actions.pushMessage({
        message: message,
        key: new Date().getTime(),
      })
    );
  };

  const getCurrentLocation = async (option: Option) => {
    try {
      const getLocationResponse = await userApi.getUserProfileLocation();
      const physicalAssetLocationId = getLocationResponse.data.id;
      const storeCheckedInLocation = async () => {
        if (option) {
          try {
            await physicalAssetAPI.updatePhysicalAsset(
              // eslint-disable-next-line
              option!.assetId,
              // eslint-disable-next-line
              option!.markedAsFinished,
              // eslint-disable-next-line
              physicalAssetLocationId!
            );
          } catch (error) {
            sendNotification('productionSegmentCheckIn.errors.general');
            console.error(error);
          }
        }
      };
      await storeCheckedInLocation();
      setLoaded(true);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Box mb="1rem">
        <QrScanner
          successCallback={async (value: string) => {
            const assetId = value.replace(VALUE_PREFIXES.PHYSICAL_ASSET, '');
            const scannedAsset = searchOptions.find(
              (item) => `${item.assetId}` === assetId
            );
            if (scannedAsset) {
              setPhysicalAsset(scannedAsset);
              await getCurrentLocation(scannedAsset);
              const assetUrl = PRODUCT_IDENTIFIED.replace(
                ':id',
                `${scannedAsset.assetId}`
              );
              const assetSerialUrl = assetUrl.replace(
                ':serial',
                scannedAsset.assetNumber
              );
              history.push(assetSerialUrl);
            }
          }}
          valuePattern={VALUE_PATTERNS.PHYSICAL_ASSET}
        />
      </Box>

      <OrBlock />

      <Box mb="1rem">
        <SearchField
          id={id}
          placeholder={t('identifyProductPage.searchLabel')}
          options={searchOptions}
          onChange={async (
            event: React.ChangeEvent<unknown>,
            newValue: string | Option | (string | Option)[] | null
          ) => {
            const option = newValue as Option;
            setPhysicalAsset(option);
            await getCurrentLocation(option);
            const assetUrl = PRODUCT_IDENTIFIED.replace(
              ':id',
              `${option.assetId}`
            );
            const assetSerialUrl = assetUrl.replace(
              ':serial',
              option.assetNumber
            );
            history.push(assetSerialUrl);
          }}
        />
      </Box>
    </>
  );
};

export default ProductScanner;
