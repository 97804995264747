import {
  createFaultItemSlice,
  privateSnackbarMessagesSlice,
} from '@dsf/data-access-store';
import { useFaultItemApi, usePhysicalAssetApi } from '@dsf/data-access-api';
import { FAULT_ITEM_CREATE, MARK_PRODUCT_AS_FINISHED } from '@dsf/util-router';

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { Skeleton } from '@material-ui/lab';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  FaultAccordionList,
  ScreenText,
  ScreenTitle,
  TextLabel,
} from '../../../components';
import LocationScanner, {
  LocationOption,
} from '../../../components/location-scanner';
import { withTenantLayout } from '@dsf/ui-layout';
import { FaultItem, FaultItemListResponse } from '@dsf/util-types';

type ParamsType = {
  id: string;
  tenant: string;
  serial: string;
};

const ProductIdentifiedPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const history = useHistory();
  const faultItemApi = useFaultItemApi();
  const physicalAssetApi = usePhysicalAssetApi();
  const { id, serial } = useParams<ParamsType>();
  const locationRef = useRef<HTMLDivElement>(null);

  const [loaded, setLoaded] = useState<boolean>(false);
  const [notFound, setNotFound] = useState<boolean>(false);
  const [physicalAsset, setPhysicalAsset] = useState<FaultItemListResponse>();
  const { setAsset, setKeepAsset, setFaultItem } = createFaultItemSlice.actions;
  const [editLocation, setEditLocation] = useState(false);
  const [assetSegmentName, setAssetSegmentName] = useState<string>();
  const [assetPicture, setAssetPicture] = useState<string>();
  const [faultItems, setFaultItems] = useState<FaultItem[]>([]);

  const sendNotification = (message: string): void => {
    dispatch(
      privateSnackbarMessagesSlice.actions.pushMessage({
        message: message,
        key: new Date().getTime(),
      })
    );
  };

  const handleLocationChange = async (locationOption: LocationOption) => {
    setEditLocation(false);
    try {
      const updateLocationResponse = await physicalAssetApi.updatePhysicalAsset(
        // eslint-disable-next-line
        physicalAsset!.assetId,
        // eslint-disable-next-line
        physicalAsset!.markedAsFinished,
        locationOption.id
      );
      setAssetSegmentName(locationOption.segmentName);
    } catch (error) {
      sendNotification('productionSegmentCheckIn.errors.general');
      console.error(error);
    }
  };

  const handleNewFaultItem = (physicalAsset?: FaultItemListResponse) => {
    const idUrl = FAULT_ITEM_CREATE.replace(
      ':assetId?',
      `${physicalAsset?.assetId}`
    );
    history.push(idUrl);
    setAsset(physicalAsset);
    setKeepAsset(true);
    dispatch(setAsset(physicalAsset));
    dispatch(setKeepAsset(true));
  };

  const handleMarkAsFinished = () => {
    const idUrl = MARK_PRODUCT_AS_FINISHED.replace(':id', id);
    const idSerialUrl = idUrl.replace(':serial', serial);
    history.push(idSerialUrl);
  };

  useEffect(() => {
    let mounted = true;

    const getPhysicalAsset = async () => {
      setLoaded(false);
      try {
        const detailResponse = await physicalAssetApi.getAll(
          parseInt(id),
          serial
        );
        if (!mounted) {
          return;
        }
        setFaultItems(detailResponse.data[0].faultItems);
        setPhysicalAsset(detailResponse.data[0]);
        setFaultItem(detailResponse.data[0]);
        setAssetSegmentName(detailResponse.data[0].assetLocation);
        setLoaded(true);
      } catch (error) {
        if (!mounted) {
          return;
        }
        setNotFound(true);
        console.log(error);
      }
      console.log(physicalAsset, assetSegmentName);
      if (!mounted) {
        return;
      }
    };
    getPhysicalAsset();
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    const getAssetPicture = async () => {
      const assetPictureId = physicalAsset?.pictureId;
      if (!assetPictureId || assetPicture) {
        return;
      }

      try {
        const assetPictureResponse = (
          await faultItemApi.getImageBase64(assetPictureId)
        ).data;
        setAssetPicture(`data:image/png;base64,${assetPictureResponse}`);
      } catch (error) {
        console.log(error);
      }
    };
    getAssetPicture();
    // eslint-disable-next-line
  }, [physicalAsset?.pictureId]);

  useEffect(() => {
    if (locationRef && locationRef.current && editLocation) {
      locationRef.current.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }
  }, [editLocation]);

  if (!loaded && !notFound) {
    return (
      <div className="loader">
        <CircularProgress color="secondary" />
        <Typography variant="body1">{t('global.loading')}</Typography>
      </div>
    );
  }

  if (loaded && notFound) {
    return <ScreenText>{t('faultSItemDetailPage.notFound')}</ScreenText>;
  }

  return (
    <>
      <ScreenTitle id="productIdentifiedPage_title">
        {t('productIdentifiedPage.headline')}
      </ScreenTitle>
      <ScreenText gutterBottom>{t('productIdentifiedPage.intro')}</ScreenText>
      <ScreenText gutterBottom id="productIdentifiedPage_assetNumber">
        <strong>{physicalAsset?.assetNumber}</strong>
      </ScreenText>

      {assetPicture ? (
        <div>
          <img
            alt="Asset"
            src={assetPicture}
            id="productIdentifiedPage_assetPicture"
          />
        </div>
      ) : (
        <Box maxWidth={400}>
          <Skeleton variant="rect" height={200} />
        </Box>
      )}
      <Box mb="1rem">
        <TextLabel
          content={physicalAsset?.assetVersion || ''}
          label={t('faultItemDetailPage.info.version')}
        />
        <TextLabel
          content={physicalAsset?.productDescription || ''}
          label={t('faultItemDetailPage.info.colourCode')}
        />
      </Box>

      {assetSegmentName === undefined ? (
        <Box maxWidth={400}>
          <Skeleton variant="rect" height={200} />
        </Box>
      ) : (
        <div ref={locationRef}>
          <Box display="flex" alignItems="center" mb="1rem">
            <TextLabel
              content={assetSegmentName || ''}
              label={t('faultItemDetailPage.info.lastLocation')}
            />
            <IconButton
              aria-label="edit-location"
              onClick={() => {
                setEditLocation((previousState) => !previousState);
              }}
              id="productIdentifiedPage_editLocationButton"
            >
              <EditIcon />
            </IconButton>
          </Box>
        </div>
      )}
      {editLocation && (
        <Box mb="1rem">
          <LocationScanner onChange={handleLocationChange} />
        </Box>
      )}
      <Box mb="1rem">
        <FaultAccordionList faultItems={faultItems} />
      </Box>
      <Box mb="1rem">
        <Grid container justify="space-between" spacing={2}>
          <Grid item xs={6}>
            <Button
              variant="outlined"
              size="small"
              fullWidth
              onClick={() => {
                handleNewFaultItem(physicalAsset);
              }}
              id="productIdentifiedPage_createFaultItemButton"
            >
              {t('faultItemDetailPage.buttons.createNewFaultItem.firstRow')}
              <br />
              {t('faultItemDetailPage.buttons.createNewFaultItem.secondRow')}
            </Button>
          </Grid>
          <Grid item xs={6} hidden={physicalAsset?.markedAsFinished}>
            <Button
              variant="contained"
              size="small"
              fullWidth
              onClick={handleMarkAsFinished}
              id="productIdentifiedPage_markAsFinishedButton"
            >
              {t('faultItemDetailPage.buttons.markAsFinished.firstRow')}
              <br />
              {t('faultItemDetailPage.buttons.markAsFinished.secondRow')}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default withTenantLayout(ProductIdentifiedPage)({ pageName: 'SCR-102' });
