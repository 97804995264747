import { usePhysicalAssetApi } from '@dsf/data-access-api';
import { FAULT_LIST, SERIAL_NUMBER_NEW } from '@dsf/util-router';
import { FaultItemListResponse } from '@dsf/util-types';

import {
  ProductListItem,
  ScreenText,
  ScreenTitle,
  SearchField,
  TextButton,
} from '../../../components';
import { SearchOption } from '../../../components/search-field';
import {
  Box,
  CircularProgress,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import FloatingPlusIcon from '../../../components/FloatingPlusIcon/FloatingPlusIcon';
import { withTenantLayout } from '@dsf/ui-layout';
import KpiItem from '../../../pages/private/product-overview-page/kpi-item';
import { ClockCheckedIcon } from '@dsf/ui-assets';
import { ApprovalIcon } from '@dsf/ui-assets';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    secondRow: {
      fontSize: '1.125rem',
      marginTop: -12,
    },
    kpiBox: {
      maxWidth: 350,
      marginTop: '1rem',
      marginBottom: '1rem',
    },
    searchBox: {
      maxWidth: 350,
    },
  })
);

const ProductOverviewPage: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const physicalAssetApi = usePhysicalAssetApi();

  const [loaded, setLoaded] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [items, setItems] = useState<FaultItemListResponse[]>([]);
  const [itemsToDisplay, setItemsToDisplay] = useState<FaultItemListResponse[]>(
    []
  );
  const [searchOptions, setSearchOptions] = useState<SearchOption[]>([]);

  useEffect(() => {
    const getFaultItemList = async () => {
      setLoaded(false);
      try {
        const response = await physicalAssetApi.getAllFinished();
        setItems(response.data);
        setItemsToDisplay(response.data);
        setSearchOptions(
          response.data.map(({ assetNumber }) => ({ label: assetNumber }))
        );
      } catch (e) {
        setError(true);
      }
      setLoaded(true);
    };
    getFaultItemList();
    // eslint-disable-next-line
  }, []);

  const handleFaultySwitch = () => {
    history.push(FAULT_LIST);
  };

  if (!loaded && !error) {
    return (
      <div className="loader">
        <CircularProgress color="secondary" />
        <Typography variant="body1">{t('global.loading')}</Typography>
      </div>
    );
  }

  if (loaded && error) {
    return (
      <div>
        <Typography variant="body1">{t('global.errorOccurred')}</Typography>
      </div>
    );
  }

  return (
    <>
      <ScreenTitle id="productOverviewPage_title">
        {t('productOverviewPage.headline')}
      </ScreenTitle>
      <ScreenText>{t('productOverviewPage.intro')}</ScreenText>
      <ScreenText>
        <TextButton id="Faulty_switch_button" onClick={handleFaultySwitch}>
          {t('productOverviewPage.faultyProductsToggle')}
        </TextButton>
        , {t('productOverviewPage.faultyProductsText')}
      </ScreenText>
      <Box
        className={classes.kpiBox}
        display="flex"
        justifyContent="space-between"
      >
        <KpiItem
          value={() => <div>{'82%'}</div>}
          description={() => (
            <>{t('productOverviewPage.kpi.firstTimeQuality')}</>
          )}
          icon={() => <ApprovalIcon />}
          id="productOverviewPage_KPI_firstTimeQuality"
        />
        <KpiItem
          value={() => (
            <>
              <div>{'9h'}</div>
              <div className={classes.secondRow}>{'14m'}</div>
            </>
          )}
          description={() => (
            <>{t('productOverviewPage.kpi.actualRunThroughTime')}</>
          )}
          icon={() => <ClockCheckedIcon />}
          id="productOverviewPage_KPI_actualRunThroughTime"
        />
      </Box>

      <Box mb="1rem" className={classes.searchBox}>
        <SearchField
          id="productOverviewPage_serialNumberSearch"
          placeholder={t('productOverviewPage.searchForSerialNumber')}
          options={searchOptions}
          onChange={(
            event: React.ChangeEvent<unknown>,
            newValue: string | SearchOption | (string | SearchOption)[] | null
          ) => {
            const option = newValue as SearchOption;
            if (option) {
              setItemsToDisplay(
                items.filter(
                  (item) =>
                    item.assetNumber
                      .toLowerCase()
                      .indexOf(option.label.toLowerCase()) !== -1
                )
              );
            } else {
              setItemsToDisplay(items);
            }
          }}
        />
      </Box>

      {itemsToDisplay.map((item) => {
        return <ProductListItem assetItem={item} key={item.assetId} />;
      })}
      <FloatingPlusIcon
        newPage={SERIAL_NUMBER_NEW}
        iconID="floating-icon-create-product"
      />
    </>
  );
};

export default withTenantLayout(ProductOverviewPage)({ pageName: 'SRC-88' });
