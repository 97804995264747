import React from 'react';

export interface Props {
  className?: string;
}

export const ProfileIcon: React.FC<Props> = ({ className }: Props) => {
  return (
    <svg
      className={className}
      width="18"
      height="21"
      viewBox="0 0 18 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 0H2C0.895 0 0 0.895 0 2V16C0 17.105 0.895 18 2 18H6L9 21L12 18H16C17.105 18 18 17.105 18 16V2C18 0.895 17.105 0 16 0ZM9 3C10.7 3 12 4.3 12 6C12 7.7 10.7 9 9 9C7.3 9 6 7.7 6 6C6 4.3 7.3 3 9 3ZM15 15H3C3 15 3 14.415 3 14C3 12.429 5.722 11 9 11C12.278 11 15 12.429 15 14C15 14.415 15 15 15 15Z"
        fill="black"
      />
    </svg>
  );
};
