import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  Canceler,
  CancelToken,
} from 'axios';
import { get, set } from 'lodash';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { store, State } from '@dsf/data-access-store';
import { userTokensSlice } from '@dsf/data-access-store';
import { ENDPOINT_API } from '..';
import { determineDefaultLanguage } from '@dsf/util-translate';
import { useHistory } from 'react-router-dom';
import { LOGOUT, UNLOCK_ACCOUNT_REQUEST } from '@dsf/util-router';

const BASE = ENDPOINT_API;

const CUSTOM_API_URL = `https://api-${window.location.hostname}`;

const API_PATH =
  process.env.NODE_ENV === 'development' ? '/dsfapi' : CUSTOM_API_URL;

export const refreshTokenPath = (): string => BASE;

export interface APICancelerType {
  [key: string]: Canceler;
}

export type APIResponse<T = unknown> = Promise<AxiosResponse<T>>;

export interface API {
  readonly request: AxiosInstance;
  readonly authRequest: AxiosInstance;

  isCancel(value: string): boolean;
  prepareCancelToken(name: string): CancelToken;
  cancelToken(name: string): void;
  cancelAllRequests(): void;
}

export const useApi = (): API => {
  const [cancelTokens, setCancelTokens] = useState<APICancelerType>({});
  const { accessToken } = useSelector((state: State) => state.userTokens);
  const history = useHistory();

  const getAxiosInstance = (config?: AxiosRequestConfig): AxiosInstance => {
    return axios.create(config);
  };

  const interceptAuthenticatedRequest = async (
    requestConfig: AxiosRequestConfig
  ): Promise<AxiosRequestConfig> => {
    if (!accessToken) return requestConfig;

    requestConfig.headers = {
      ...requestConfig.headers,
      Authorization: `Bearer ${accessToken}`,
    };
    return requestConfig;
  };

  const tenantContextInterceptor = async (
    requestConfig: AxiosRequestConfig
  ): Promise<AxiosRequestConfig> => {
    requestConfig.headers = {
      ...requestConfig.headers,
      'X-TENANT-CONTEXT':
        process.env.NODE_ENV === 'development'
          ? 'YWxwaGEuZHNmcS5kZXY='
          : btoa(window.location.hostname),
    };

    return requestConfig;
  };

  const userLanguageInterceptor = async (
    requestConfig: AxiosRequestConfig
  ): Promise<AxiosRequestConfig> => {
    requestConfig.headers = {
      ...requestConfig.headers,
      'X-LANG': determineDefaultLanguage(),
    };
    return requestConfig;
  };

  const interceptAuthenticatedErrorResponse = async (
    // eslint-disable-next-line
    error: any
    // eslint-disable-next-line
  ): Promise<any> => {
    const status = get(error, 'response.status');
    const data = get(error, 'response.data');
    const requestConfig = get(error, 'config');

    // locked account
    if (status === 400 && (data || '').includes('AUTH_ACCOUNT_IS_LOCKED')) {
      history.push(UNLOCK_ACCOUNT_REQUEST);
    } else if (status === 401) {
      try {
        const refreshTokenResponse = (await refreshAuthToken()).data;
        store.dispatch(
          userTokensSlice.actions.setAccessToken(
            refreshTokenResponse.accessToken
          )
        );

        const authorizationHeader = `Bearer ${refreshTokenResponse.accessToken}`;
        axios.defaults.headers.common['Authorization'] = authorizationHeader;
        set(requestConfig, 'headers.Authorization', authorizationHeader);

        return getAxiosInstance()(requestConfig);
      } catch (e) {
        // logout

        const { tenant } = store.getState().tenant;
        if (tenant) {
          history.push(LOGOUT);
        } else {
          store.dispatch(userTokensSlice.actions.setAccessToken(''));
          store.dispatch(userTokensSlice.actions.setRefreshToken(''));
          window.location.href = '/';
        }

        throw error;
      }
    } else {
      throw error;
    }
  };

  const refreshAuthToken = () => {
    const refreshToken = store.getState().userTokens.refreshToken;
    const username = store.getState().identity.profile?.loginUserName;

    return api.request.post(refreshTokenPath(), { refreshToken, username });
  };

  const setCancelToken = (name: string, token: Canceler): void => {
    cancelTokens[name] = token;
    setCancelTokens(cancelTokens);
  };

  const api: API = {
    get request(): AxiosInstance {
      const axiosInstance = getAxiosInstance({
        baseURL: API_PATH,
      });
      axiosInstance.interceptors.request.use(
        tenantContextInterceptor,
        (error: AxiosError) => {
          return Promise.reject(error);
        }
      );
      axiosInstance.interceptors.request.use(userLanguageInterceptor);
      return axiosInstance;
    },
    get authRequest(): AxiosInstance {
      const axiosInstance = getAxiosInstance({
        baseURL: API_PATH,
      });

      axiosInstance.interceptors.request.use(
        interceptAuthenticatedRequest,
        (error: AxiosError) => {
          return Promise.reject(error);
        }
      );
      axiosInstance.interceptors.request.use(
        tenantContextInterceptor,
        (error: AxiosError) => {
          return Promise.reject(error);
        }
      );
      axiosInstance.interceptors.request.use(
        userLanguageInterceptor,
        (error: AxiosError) => {
          return Promise.reject(error);
        }
      );

      axiosInstance.interceptors.response.use(
        (response) => Promise.resolve(response),
        interceptAuthenticatedErrorResponse
      );

      return axiosInstance;
    },
    prepareCancelToken(name: string): CancelToken {
      api.cancelToken(name);
      return new axios.CancelToken((token) => {
        setCancelToken(name, token);
      });
    },
    isCancel(value: string): boolean {
      return axios.isCancel(value);
    },
    cancelToken(name: string): void {
      if (typeof cancelTokens[name] === 'function') {
        cancelTokens[name]();
      }
    },
    cancelAllRequests(): void {
      Object.keys(cancelTokens).map(api.cancelToken);
    },
  };

  return api;
};
