import { privateSnackbarMessagesSlice, State } from '@dsf/data-access-store';
import { useFaultItemApi } from '@dsf/data-access-api';
import { FAULT_ITEM_DETAIL } from '@dsf/util-router';
import {
  FaultItem as FaultItemType,
  FaultItemListResponse,
  FaultItemStatus,
} from '@dsf/util-types';

import { AlertIcon } from '@dsf/ui-assets';
import { ElectricalIcon } from '@dsf/ui-assets';
import { WrenchSmallIcon } from '@dsf/ui-assets';
import { ButtonDiv } from '../../../components';
import useFormatters from '../../../hooks/formatter';
import {
  Box,
  Button,
  createStyles,
  Fade,
  IconButton,
  makeStyles,
  Slide,
  Theme,
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import RemoveShoppingCartIcon from '@material-ui/icons/RemoveShoppingCart';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { getPageContext } from '@dsf/util-tools';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import { updateItemsToDisplay } from './fault-list-actions';
import { useFaultListDispatch, useFaultListState } from './fault-list-context';

type StyleProps = {
  lastChild: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderBottom: (p: StyleProps) => (p.lastChild ? 0 : '1px solid #d3d3d3'),
    },
    content: {
      overflow: 'hidden',
    },
    highPriorityFault: {
      backgroundColor: '#F7E6E9',
    },
    times: {
      fontSize: '0.6rem',
      color: '#2D2F36',
      opacity: 0.54,
      marginBottom: '0.5rem',
    },
    iconWrap: {
      width: '1.1rem',
      minWidth: '1.2rem',
    },
    priorityIconHigh: {
      '& path': {
        fill: '#b00020',
      },
    },
    priorityIconLow: {
      '& path': {
        fill: '#ffb800',
      },
    },
    deliveredStatus: {
      color: '#00b894',
    },
  })
);

type Props = StyleProps & {
  fault: FaultItemType;
};

const FaultItem: React.FC<Props> = ({ fault, lastChild }) => {
  const classes = useStyles({ lastChild });
  type ClassesType = keyof typeof classes;
  const [showSwipeMenu, setShowSwipeMenu] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();
  const { profile } = useSelector((state: State) => state.identity);
  const assigningItemsMap: Map<string, boolean> = useMemo(() => new Map(), []);
  const faultItemApi = useFaultItemApi();
  const faultListDispatch = useFaultListDispatch();
  const { itemsToDisplay } = useFaultListState();
  const { dispatch, translate } = getPageContext();
  const { formatTime } = useFormatters();

  const showSnack = (message: string) => {
    dispatch(
      privateSnackbarMessagesSlice.actions.pushMessage({
        message: translate(message),
        key: new Date().getTime(),
      })
    );
  };

  const handleMoreClick = () => {
    setShowSwipeMenu((prev) => !prev);
  };

  const handlers = useSwipeable({
    onSwipedLeft: (eventData) => {
      setShowSwipeMenu(true);
    },
    onSwipedRight: (eventData) => {
      setShowSwipeMenu(false);
    },
    preventDefaultTouchmoveEvent: false,
    trackMouse: true,
  });

  const getPartIcon = () => {
    if (fault.typeOfFault === 'mechanical') {
      return <WrenchSmallIcon />;
    } else if (fault.typeOfFault === 'electrical') {
      return <ElectricalIcon />;
    }
  };

  const getFaultIcon = () => {
    let className = '';
    if (fault.priority === 'high') {
      className = 'High';
    } else if (fault.priority === 'medium') {
      className = 'Medium';
    } else if (fault.priority === 'low') {
      className = 'Low';
    }
    const attr = `priorityIcon${className}` as ClassesType;
    return <AlertIcon className={className ? classes[attr] : ''} />;
  };

  const getStatusIcon = () => {
    const status = fault.status;
    const getIcon = (status: FaultItemStatus) => {
      switch (status) {
        case FaultItemStatus.delivered:
          return <ShoppingCartIcon />;
        case FaultItemStatus.ordered:
          return <ShoppingCartIcon />;
        case FaultItemStatus.notneeded:
          return <RemoveShoppingCartIcon />;
        default:
          return null;
      }
    };

    const className =
      status === FaultItemStatus.delivered ? classes.deliveredStatus : '';
    return <Box className={`${className}`}>{getIcon(status)}</Box>;
  };

  const handleFaultClick = () => {
    history.push(FAULT_ITEM_DETAIL.replace(':id', `${fault.id}`));
  };

  const replaceFaultItem = (
    id: string,
    faultItem: FaultItemType,
    list: FaultItemListResponse[]
  ): FaultItemListResponse[] => {
    list.forEach((listItem) => {
      const item = listItem.faultItems.find((i) => String(i.id) === id);
      if (item) {
        const itemIndex = listItem.faultItems.indexOf(item);
        listItem.faultItems[itemIndex] = faultItem;
      }
    });

    return list;
  };

  const assignFaultItemToMe = async () => {
    const id = `${fault.id}`;
    try {
      if (profile?.id && !assigningItemsMap.has(id)) {
        assigningItemsMap.set(id, true);
        const res = await faultItemApi.changeAssignee(id, profile?.id);

        // replace in items
        const faultItemRes = await faultItemApi.getFaultItem(id);
        // const itemsReplace = replaceFaultItem(id, faultItemRes.data, [...(itemsToDisplay || [])]);
        const displayItemsReplace = replaceFaultItem(id, faultItemRes.data, [
          ...(itemsToDisplay || []),
        ]);
        faultListDispatch(updateItemsToDisplay(displayItemsReplace));

        showSnack('faultListPage.success.assignFaultItemToMe');
      }
    } catch (e) {
      showSnack('faultListPage.error.assignFaultItemToMe');
    } finally {
      //remove from map
      assigningItemsMap.delete(id);
    }
  };

  const isAssignedToMe = (assigneeId: number) => {
    return !!assigneeId && assigneeId === profile?.id;
  };

  const handleAssignToMe = () => {
    assignFaultItemToMe();
  };
  const handleNotifyIndustries = () => {
    // TODO: Implement in future
  };

  return (
    <Box
      p="0.5rem 1rem"
      className={`${classes.root} ${
        fault.priority === 'high' ? classes.highPriorityFault : ''
      }`}
    >
      <ButtonDiv onClick={handleFaultClick} id={`faultItem_${fault.id}`}>
        <div className={classes.content} {...handlers}>
          <Slide direction="left" in={showSwipeMenu} mountOnEnter unmountOnExit>
            <Box display="flex" justifyContent="space-evenly" gridGap="10px">
              <Button
                size="small"
                variant="contained"
                color="secondary"
                onClick={handleAssignToMe}
                disabled={isAssignedToMe(fault?.assignee?.id)}
              >
                {t('faultListPage.assignToMe')}
              </Button>
              <Button
                size="small"
                variant="contained"
                color="secondary"
                onClick={handleNotifyIndustries}
              >
                Notify beta industries
              </Button>
              <Box display="flex" alignItems="center">
                <IconButton size="small" onClick={handleMoreClick}>
                  <MoreVert />
                </IconButton>
              </Box>
            </Box>
          </Slide>
          {!showSwipeMenu ? (
            <Fade in={!showSwipeMenu} mountOnEnter unmountOnExit>
              <Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  className={classes.times}
                >
                  <div>
                    {t('faultListPage.faultLastUpdate')}:{' '}
                    {formatTime(fault.updateTime)}
                  </div>
                  <div>{formatTime(fault.creationTime)}</div>
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box>
                    <Box display="flex" alignItems="center">
                      <Box mr="0.5rem" className={classes.iconWrap}>
                        {getPartIcon()}
                      </Box>
                      <div>{fault.partName}</div>
                    </Box>
                    <Box display="flex">
                      <Box mr="0.5rem" className={classes.iconWrap}>
                        {getFaultIcon()}
                      </Box>
                      <div>{fault.faultName}</div>
                    </Box>
                  </Box>

                  <Box display="flex">
                    <Box>{getStatusIcon()}</Box>
                    <Box ml="0.5rem" alignItems="center">
                      {fault.status !== FaultItemStatus.closed ? (
                        <Box>
                          <IconButton size="small" onClick={handleMoreClick}>
                            <MoreVert />
                          </IconButton>
                        </Box>
                      ) : (
                        <Box className={classes.deliveredStatus}>
                          <DoneOutlineIcon />
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Fade>
          ) : null}
        </div>
      </ButtonDiv>
    </Box>
  );
};

export default FaultItem;
