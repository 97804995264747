import { State, privateSnackbarMessagesSlice } from '@dsf/data-access-store';
import { useProfileImageApi } from '@dsf/data-access-api';
import {
  PRODUCTION_SEGMENT_CHECK_IN,
  TENANT_REQUEST_CHANGE_PASSWORD,
} from '@dsf/util-router';

import {
  Checkbox,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Snackbar,
  Typography,
} from '@material-ui/core';
import { Button, Box, Paper } from '@mui/material';
import { UiContentBox } from '@dsf/ui-content-box';
import PersonIcon from '@mui/icons-material/Person';
import KeyIcon from '@mui/icons-material/Key';
import Backdrop from '@material-ui/core/Backdrop';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import { Alert } from '@material-ui/lab';
import React, { useEffect, useState, useCallback } from 'react';
import Avatar from 'react-avatar-edit';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { withTenantLayout } from '@dsf/ui-layout';
import { createImageFromBlob, dataURItoBlob } from '@dsf/util-tools';
import './EditProfilePage.scss';
import { useWrapApi } from '@dsf/data-access-api';

export const PROFILE_FILE_SIZE_LIMIT = 4194304; // 4 MB

const EditProfilePage: React.FC = () => {
  const { api } = useWrapApi();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profileImageApi = useProfileImageApi();
  const history = useHistory();
  const { tenant } = useSelector((state: State) => state.tenant);
  const [onLoadDone, setOnLoadDone] = React.useState<boolean>(false);
  const [preview, setPreview] = React.useState<string | undefined>('');
  const [src, setSrc] = React.useState<string | undefined>('');
  const [imagePlaceholderText, setImagePlaceholderText] =
    React.useState<string>('profilePage.avatar.loading');
  const [userProfileImage, setUserProfileImage] = React.useState<
    string | undefined
  >(undefined);

  const [openModalAvatar, setOpenModalAvatar] = React.useState(false);
  const [openModalCapabilities, setOpenModalCapabilities] =
    React.useState(false);
  const [openWarning, setOpenWarning] = useState<boolean>(false);
  const [snackbarWarningText, setSnackbarWarningText] = useState<string>('');
  const [openInfo, setOpenInfo] = useState<boolean>(false);

  const [capChecked, setCapChecked] = useState<string[]>([]);
  const [selectedCapabilities, setSelectedCapabilities] = useState<string[]>([
    'cap_2',
    'cap_3',
  ]);
  const [availableCapabilities, setAvailableCapabilities] = useState<string[]>([
    'cap_1',
    'cap_4',
    'cap_5',
  ]);

  // OnLoad
  // eslint-disable-next-line
  useEffect(() => {
    if (onLoadDone) {
      return;
    }
    profileImageApi
      .getProfileImage()
      .then((value) => {
        createImageFromBlob(value.data).then((data) => {
          // eslint-disable-next-line
          setUserProfileImage(data as any);
        });
      })
      .catch((err) => {
        setImagePlaceholderText('profilePage.avatar.noImage');
        return;
      });
    setOnLoadDone(true);
  });

  function intersection(a: string[], b: string[]) {
    return a.filter((value) => b.indexOf(value) !== -1);
  }

  const capCheckedAvailable = intersection(capChecked, availableCapabilities);
  const capCheckedSelected = intersection(capChecked, selectedCapabilities);

  const publishSuccessSnackbar = (message: string) => {
    dispatch(
      privateSnackbarMessagesSlice.actions.pushMessage({
        message: message,
        key: new Date().getTime(),
      })
    );
  };

  const publishWarningSnackbar = (message: string) => {
    setSnackbarWarningText(message);
    setOpenWarning(true);
  };

  const onSaveAvatarClick = async () => {
    if (!preview) {
      publishWarningSnackbar('profilePage.avatar.noFiles');
      return;
    }

    setOpenModalAvatar(false);
    // eslint-disable-next-line
    setUserProfileImage(preview as any);
    const imageBlob = dataURItoBlob(preview as string);
    profileImageApi
      .postProfileImage(imageBlob)
      .then(() => {
        publishSuccessSnackbar('profilePage.avatar.saved');
        window.location.reload();
        setPreview('');
      })
      .catch((reason) => {
        publishWarningSnackbar('profilePage.avatar.failed');
      });
  };

  const handleOpenModalAvatar = () => {
    setOpenModalAvatar(true);
  };

  const handleCloseModalAvatar = () => {
    setOpenModalAvatar(false);
  };

  const handleOpenModalCapabilities = () => {
    setOpenModalCapabilities(true);
  };

  const handleCloseModalCapabilities = () => {
    setOpenModalCapabilities(false);
  };

  const deleteImage = useCallback(() => {
    try {
      api
        .delete('/user/profile/image')
        .then(({ data }) => {
          window.location.reload();
        })
        .catch((error) => {
          console.error('delete image error', error);
        });
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line
  }, []);

  const handleToggle = (value: string) => () => {
    const currentIndex = capChecked.indexOf(value);
    const newChecked = [...capChecked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCapChecked(newChecked);
  };

  function not(a: string[], b: string[]) {
    return a.filter((value) => b.indexOf(value) === -1);
  }

  const handleAllRight = () => {
    setSelectedCapabilities(selectedCapabilities.concat(availableCapabilities));
    setAvailableCapabilities([]);
  };

  const handleCheckedRight = () => {
    setSelectedCapabilities(selectedCapabilities.concat(capCheckedAvailable));
    setAvailableCapabilities(not(availableCapabilities, capCheckedAvailable));
    setCapChecked(not(capChecked, capCheckedAvailable));
  };

  const handleCheckedLeft = () => {
    setAvailableCapabilities(availableCapabilities.concat(capCheckedSelected));
    setSelectedCapabilities(not(selectedCapabilities, capCheckedSelected));
    setCapChecked(not(capChecked, capCheckedSelected));
  };

  const handleAllLeft = () => {
    setAvailableCapabilities(
      availableCapabilities.concat(selectedCapabilities)
    );
    setSelectedCapabilities([]);
  };

  const onClose = () => {
    setPreview(undefined);
  };

  const onCrop = (preview: string) => {
    setPreview(preview);
  };

  // eslint-disable-next-line
  const onBeforeFileLoad = (elem: any) => {
    if (elem.target.files && elem.target.files.length === 0) {
      publishWarningSnackbar('profilePage.avatar.noFiles');
      elem.target.value = '';
    }
    if (elem.target.files[0].size > PROFILE_FILE_SIZE_LIMIT) {
      publishWarningSnackbar('profilePage.avatar.tooLarge');
      elem.target.value = '';
    }
  };

  const closeWarning = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenWarning(false);
  };

  const closeInfo = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenInfo(false);
  };

  const customList = (items: string[]) => (
    <Paper>
      <List dense component="div" role="list">
        {items.map((value: string) => {
          const labelId = `transfer-list-item-${value}-label`;

          return (
            <ListItem
              key={value}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={capChecked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Paper>
  );

  return (
    <Box maxWidth="721px" margin="0 auto">
      <div>
        <UiContentBox
          icon={
            <Box>
              <PersonIcon />
            </Box>
          }
          title={
            <Box
              display="flex"
              fontWeight="bold"
              fontSize="1.125rem"
              alignItems="center"
              id="title_profile-page"
            >
              {t('profilePage.headline')}
            </Box>
          }
        >
          <Grid container>
            <Grid item xs={12} className={'avatar'}>
              {!userProfileImage && (
                <div
                  className="avatar__loader"
                  id="profilePage_userProfileImagePlaceholder"
                >
                  {t(imagePlaceholderText)}
                </div>
              )}
              {userProfileImage && (
                // eslint-disable-next-line
                <img
                  className="avatar__image"
                  src={userProfileImage}
                  id="profilePage_userProfileImage"
                />
              )}
            </Grid>
            <Grid item xs={12} className={'button-row button-row-first'}>
              <Button
                type="submit"
                fullWidth
                color="inherit"
                variant="outlined"
                startIcon={<InsertPhotoIcon htmlColor="#000" />}
                id="profilePage_changePhotoButton"
                onClick={handleOpenModalAvatar}
              >
                {t('profilePage.CHANGE_MY_PHOTO')}
              </Button>
            </Grid>
            {userProfileImage && (
              <Grid item xs={12} className={'button-row'}>
                <Button
                  type="submit"
                  fullWidth
                  color="inherit"
                  variant="outlined"
                  startIcon={<DeleteForeverIcon htmlColor="#000" />}
                  id="profilePage_deletePhotoButton"
                  onClick={deleteImage}
                >
                  {t('profilePage.DELETE_MY_PHOTO')}
                </Button>
              </Grid>
            )}
            <Grid item xs={12} className={'button-row'}>
              <Button
                type="submit"
                fullWidth
                disabled
                color="inherit"
                variant="outlined"
                id="profilePage_changePasswordButton"
                startIcon={<KeyIcon htmlColor="inherit" />}
                onClick={() => history.push(TENANT_REQUEST_CHANGE_PASSWORD)}
              >
                {t('profilePage.CHANGE_MY_PASSWORD')}
              </Button>
            </Grid>
          </Grid>

          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={openModalAvatar}
            className={'modal'}
            onClose={handleCloseModalAvatar}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={openModalAvatar}>
              <div className={'modal_content modal_avatar'}>
                {preview && (
                  <>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {t('profilePage.avatar.preview')}
                    </Typography>
                    <img
                      className={'image_preview'}
                      src={preview}
                      alt="Preview"
                    />
                  </>
                )}
                <Avatar
                  width={390}
                  height={180}
                  onCrop={onCrop}
                  onClose={onClose}
                  onBeforeFileLoad={onBeforeFileLoad}
                  src={src}
                  label={t('profilePage.avatar.choose')}
                />
                <Button
                  className={'confirm_button'}
                  disabled={!preview}
                  variant="contained"
                  color="primary"
                  onClick={onSaveAvatarClick}
                  key={`${!preview}`} // Fix for Safari
                >
                  {t('profilePage.avatar.confirm')}
                </Button>
              </div>
            </Fade>
          </Modal>

          <Modal
            open={openModalCapabilities}
            className={'modal'}
            onClose={handleCloseModalCapabilities}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={openModalCapabilities}>
              <div className={'modal_content'}>
                <h3>Capabilities</h3>
                <h4>Available - Selected</h4>

                <Grid
                  container
                  spacing={2}
                  justify="center"
                  alignItems="center"
                >
                  <Grid item>
                    <Grid container direction="column" alignItems="center">
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={handleAllRight}
                        disabled={availableCapabilities.length === 0}
                        aria-label="move all right"
                        key={`${availableCapabilities.length === 0}`} // Fix for Safari
                      >
                        ≫
                      </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={handleCheckedRight}
                        disabled={capCheckedAvailable.length === 0}
                        aria-label="move selected right"
                        key={`${availableCapabilities.length === 0}`} // Fix for Safari
                      >
                        &gt;
                      </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={handleCheckedLeft}
                        disabled={capCheckedSelected.length === 0}
                        aria-label="move selected left"
                        key={`${availableCapabilities.length === 0}`} // Fix for Safari
                      >
                        &lt;
                      </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={handleAllLeft}
                        disabled={selectedCapabilities.length === 0}
                        aria-label="move all left"
                        key={`${availableCapabilities.length === 0}`} // Fix for Safari
                      >
                        ≪
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item>{customList(selectedCapabilities)}</Grid>
                </Grid>

                <Button
                  className={'confirm_button'}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    console.log('TODO: api capabilities');
                    publishSuccessSnackbar('profilePage.capabilities.saved');
                    setOpenInfo(true);
                  }}
                >
                  {t('profilePage.capabilities.confirm')}
                </Button>
              </div>
            </Fade>
          </Modal>

          {/*Snack bars*/}
          {/* TODO unify snackbar presentation */}
          <Snackbar
            open={openWarning}
            autoHideDuration={5000}
            onClose={closeWarning}
            anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
          >
            <Alert onClose={closeWarning} severity="error">
              {t(snackbarWarningText)}
            </Alert>
          </Snackbar>
        </UiContentBox>
      </div>
    </Box>
  );
};

export default withTenantLayout(EditProfilePage)({ pageName: 'SCR-24' });
