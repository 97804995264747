import React, { useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider, { SliderProps, SliderTypeMap } from '@material-ui/core/Slider';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { useTheme } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 300,
      width: '100%',
      paddingTop: '2rem',
      '& .MuiSlider-root.Mui-disabled .MuiSlider-valueLabel': {
        left: 'calc(-50% - 8px)',
      },
    },
    margin: {
      height: theme.spacing(3),
    },
  })
);

type UiSliderProps = SliderProps & {
  units?: string;
  sliderColor?: string;
};

export const UiSlider: React.FC<UiSliderProps> = ({
  units = '',
  sliderColor,
  value,
  ...rest
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const valuetext = (value: number) => {
    return `${value}${units}`;
  };

  const generateMarks = () => {
    let marks = [];
    for (let index = 0; index <= 100; index = index + 20) {
      marks.push({
        value: index,
        label: `${index}${units}`,
      });
    }

    return marks;
  };
  const marks = generateMarks();

  if (!value && value !== 0) {
    return <span>N/A</span>;
  }

  return (
    <div className={classes.root}>
      <Slider
        getAriaValueText={valuetext}
        aria-labelledby="discrete-slider-always"
        step={1}
        marks={marks}
        valueLabelDisplay="on"
        min={0}
        max={100}
        valueLabelFormat={(value) => {
          return `${value}${units}`;
        }}
        style={{
          color: rest.disabled ? 'inherit' : sliderColor || '#2196f3',
        }}
        value={value}
        {...rest}
      />
    </div>
  );
};
