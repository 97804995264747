import { appTheme } from '@dsf/ui-assets';
import { CssBaseline, makeStyles, MuiThemeProvider } from '@material-ui/core';
import React, { Suspense } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { Provider, useDispatch } from 'react-redux';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import './app.scss';
import { store, identitySlice } from '@dsf/data-access-store';
import { LOGOUT } from '@dsf/util-router';
import { RootWrapper } from './root-wrapper';
import { Box, CircularProgress } from '@mui/material';
import { useWrapApi } from '@dsf/data-access-api';

const MINUTES_OF_INACTIVITY = 30;

const useStyles = makeStyles((theme) => ({
  '@global': {
    '.bg-primary-color': {
      backgroundColor: 'var(--primary-color)',
    },
    '.MuiButton-root': {
      borderRadius: 4,
    },
    '.MuiOutlinedInput-root': {
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: '2px solid var(--secondary-color)',
      },
    },
    '.MuiFormLabel-root': {
      '&.Mui-focused': {
        color: 'var(--secondary-color)',
      },
    },
    '.MuiLink-button': {
      fontSize: 14,
      fontWeight: 500,
      textAlign: 'center',
      textTransform: 'uppercase',
      alignItems: 'center',
      padding: '19px 19px',
      border: '1px solid var(--primary-color)',
      borderRadius: 4,
      boxSizing: 'border-box',
      margin: 0,
      '&:hover': {
        textDecoration: 'none',
      },
      '&.MuiTypography-colorPrimary': {
        color: 'var(--primary-color)',
        backgroundColor: 'var(--white-default)',
      },
      '&.MuiTypography-colorSecondary': {
        color: 'var(--white-default)',
        backgroundColor: 'var(--primary-color)',
      },
    },
    '.reset-password-link-container': {
      paddingBottom: 24,
      paddingTop: 24,
      '& > .reset-password-link': {
        color: 'secondary',
        cursor: 'pointer',
      },
    },
  },
}));

const Fallback = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <CircularProgress />
    </Box>
  );
};

const IdleContainer = () => {
  const { isLoggedIn } = useWrapApi();
  const dispatch = useDispatch();
  const location = useLocation();

  const onIdle = () => {
    if (isLoggedIn) {
      console.log('idle --> logout');
      if (location.pathname !== LOGOUT) {
        dispatch(
          identitySlice.actions.setRedirectUrl(window.location.pathname)
        );
        window.location.href = LOGOUT;
      }
    }
  };

  const onAction = () => {
    reset();
  };

  const { reset } = useIdleTimer({
    onIdle,
    onAction,
    timeout: 1000 * 60 * MINUTES_OF_INACTIVITY,
  });
  return (
    <Suspense fallback={<Fallback />}>
      <RootWrapper />
    </Suspense>
  );
};

export const App: React.FC = () => {
  useStyles();

  return (
    <Provider store={store}>
      <Router>
        <MuiThemeProvider theme={appTheme}>
          <CssBaseline />
          <IdleContainer />
        </MuiThemeProvider>
      </Router>
    </Provider>
  );
};
