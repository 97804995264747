import React from 'react';

export interface Props {
  className?: string;
}

export const PlusIcon: React.FC<Props> = ({ className }: Props) => {
  return (
    <svg
      className={className}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8 0V8H0V10H8V18H10V10H18V8H10V0H8Z" fill="white" />
    </svg>
  );
};
