export const USER_OVERVIEW = '/user-overview';
export const FAULT_ITEM_DETAIL = '/faults/:id/detail';
export const FAULT_ITEM_CREATE = '/faults/create/:assetId?';
export const SERIAL_NUMBER_NEW = '/serial/create';
export const PRODUCT_OVERVIEW = '/product-overview';
export const QR_CODE_GENERATION = '/qrcode';
export const QR_CODE_GENERATION_SPECIFIC = '/qrcode/:serialNumber';
export const DELETE_TENANT = '/delete-tenant';
export const DELETE_TENANT_START = '/delete-tenant-2/:secret';
export const DELETE_TENANT_IN_PROGRESS = '/delete-tenant-3';

export const ADMIN = '/admin';
export const ADMIN_USERS = '/admin/users';
export const ADMIN_USERS_DETAIL = '/user-overview/:id';
export const ADMIN_CREATE_USER = '/create-user';

export const IDENTIFY_PRODUCT = '/identify-product';
export const PRODUCT_IDENTIFIED = '/products/:id/:serial/identified';
export const MARK_PRODUCT_AS_FINISHED = '/products/:id/:serial/finish';

export const PRODUCTION_SEGMENT_CHECK_IN = '/production-segment/check-in';

export const KPI_DASHBOARD_PAGE = '/kpi-dashboard';
export const FEATURES_PAGE = '/features';

export const DSFE_ASSET_LIST = '/dsfe';
export const DECOMMISSIONED_ASSETS = '/dsfe/decommissioned';
export const LOCATIONS = '/locations';
export const FIELD_OVERVIEW = '/dsfe/field-overview';
export const DSFE_ASSET_DETAIL = '/dsfe/asset-detail/:assetId';
export const DSFE_PUMP_DETAIL = '/dsfe/pump-detail/:assetId';
export const DSFE_ADD_ASSET = '/dsfe/add-asset/';

export const USER_LIST_PAGE = '/user-list';
export const CHAT_PAGE = '/chat';
export const CONVERSATION_PAGE = '/conversation/:conversationId';
export const CREATE_CONVERSATION_PAGE = '/create-conversation';
