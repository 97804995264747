import importedI18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import { Language } from '@material-ui/icons';

const LANG_KEY = 'lang';
export const AVAILABLE_LANGS = ['en', 'de', 'zh', 'da', 'pt'];

export type Language = 'en' | 'de' | 'zh' | 'da' | 'pt';

export const determineDefaultLanguage = (): string => {
  const browserLang = navigator.language;
  const userLang = localStorage.getItem(LANG_KEY);

  const getLanguage = (): Language => {
    if (userLang && AVAILABLE_LANGS.indexOf(userLang) !== -1) {
      return userLang as Language;
    }

    if (browserLang.startsWith('de')) {
      return 'de';
    } else if (browserLang.startsWith('zh')) {
      return 'zh';
    } else if (browserLang.startsWith('da')) {
      return 'da';
    } else if (browserLang.startsWith('pt')) {
      return 'pt';
    } else {
      return 'en';
    }
  };

  const defaultLanguage = getLanguage();
  document.documentElement.lang = defaultLanguage;

  return defaultLanguage;
};

export const getUserConfiguredLang = () => {
  return localStorage.getItem(LANG_KEY);
};

export const isStringValidLangCode = (langCode: string) => {
  if (AVAILABLE_LANGS.includes(langCode.toLowerCase())) {
    return true;
  }
  return false;
};

export const setUserConfiguredLang = async (lang: string) => {
  if (isStringValidLangCode(lang)) {
    await importedI18n.use(initReactI18next).init({ lng: lang.toLowerCase() });
    localStorage.setItem('lang', lang.toLowerCase());
  } else {
    throw new Error(
      'Wrong language code format or language does not exists yet.'
    );
  }
};

export const getCurrentLanguage = (): string => {
  return importedI18n.language || window.localStorage.i18nextLng || '';
};

importedI18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: determineDefaultLanguage(),
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false,
    },
  });

export const switchLanguage = async (lang: Language) => {
  await importedI18n.use(initReactI18next).changeLanguage(lang);
  localStorage.setItem('lang', lang);
  document.documentElement.lang = lang;
};

export const i18n = importedI18n;
