import { State } from '@dsf/data-access-store';
import ReplayIcon from '@mui/icons-material/Replay';
import { LOGIN, SUPPORT, LOGIN_PAGE } from '@dsf/util-router';
import { MFA_CODE_LENGTH, useLogin } from '@dsf/util-tools';

import React, { useEffect, useRef, useState } from 'react';
import { Collapse, Grid, IconButton, Link, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';

import './MFAVerifyPage.scss';
import { TwoFactorIcon } from '@dsf/ui-assets';
import { Box, Button } from '@mui/material';
import { withTenantLayout } from '@dsf/ui-layout';
import { useWrapApi } from '@dsf/data-access-api';

const INVALID_SESSION_MESSAGE =
  'Invalid session for the user, session is expired.';
const MISSING_SEND_MFA_CODE = 'user.sendMFACode is not a function';

enum DigitBoxColor {
  EMPTY,
  ACTIVE,
  FILLED,
}

const MFAVerifyPage: React.FC = () => {
  const { Auth } = useWrapApi();
  const { t } = useTranslation();
  const history = useHistory();
  const { loginAfterMFA } = useLogin();

  const [inProgress, setInProgress] = useState<boolean>(false);
  const [isInvalidSession, setIsInvalidSession] = useState(false);
  const [cognitoError, setCognitoError] = useState<string>();
  const [verificationCode, setVerificationCode] = useState<string>('');
  const isMounted = useRef(false);
  const [showError, setShowError] = React.useState(false);
  const [statusMessage, setStatusMessage] = React.useState('');
  const { cognitoUser } = useSelector((state: State) => state.login);
  const [boxColors, setBoxColors] = React.useState<DigitBoxColor[]>([
    DigitBoxColor.ACTIVE,
    ...Array(MFA_CODE_LENGTH - 1).fill(DigitBoxColor.EMPTY),
  ]);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  const mfaVerify = async (code: string) => {
    setCognitoError(undefined);
    setIsInvalidSession(false);
    setInProgress(true);
    try {
      const confirmSignInResponse = await Auth.confirmSignIn(
        cognitoUser,
        code,
        'SOFTWARE_TOKEN_MFA'
      );
      loginAfterMFA(
        confirmSignInResponse.signInUserSession.idToken.jwtToken,
        confirmSignInResponse.signInUserSession.refreshToken.token
      );
    } catch (error) {
      console.error('confirmSignIn error', error);
      const message =
        error && error.message
          ? `Error: ${error.message}`
          : 'Error: Unable verify the code';
      setCognitoError(message);
      setVerificationCode('');
      if (
        error &&
        (error.message === INVALID_SESSION_MESSAGE ||
          error.message === MISSING_SEND_MFA_CODE)
      ) {
        setCognitoError('Unable to verify the code, try signing in again.');
        setIsInvalidSession(true);
      }

      // setStatusMessage(e.response.data.message);
      // setShowError(true);

      // if (e.response.data.message === 'AUTH_INVALID_VERIFICATION_CODE') {
      //   setTimeout(() => {
      //     setVerificationCode('');
      //   }, 5000);
      // }
    }

    if (isMounted.current) {
      setInProgress(false);
    }
  };

  // eslint-disable-next-line
  const mfaOnChange = (e: any) => {
    setVerificationCode(e.target.value);
    updateDigitBoxesColor(e.target.value.length);
    if (e.target.value.length === MFA_CODE_LENGTH) {
      mfaVerify(e.target.value);
    }
  };

  const updateDigitBoxesColor = (inputLength: number) => {
    const updatedBoxes = Array(MFA_CODE_LENGTH).fill(DigitBoxColor.EMPTY);

    for (let i = 0; i < MFA_CODE_LENGTH; i++) {
      if (i === inputLength) {
        updatedBoxes[i] = DigitBoxColor.ACTIVE;
      } else if (i < inputLength) {
        updatedBoxes[i] = DigitBoxColor.FILLED;
      }
    }

    setBoxColors(updatedBoxes);
  };

  return (
    <Box textAlign="center">
      <div className="page-mfa-verify">
        <article>
          <Box
            className="icon-container"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <TwoFactorIcon className="icon" />
          </Box>
          <h1 className="title">{t('mfaVerifyPage.title')}</h1>
          <p className="subtitle">{t('mfaVerifyPage.subtitle')}</p>
        </article>

        <main>
          <Collapse in={showError}>
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setShowError(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: t('mfaVerifyPage.errors.' + statusMessage),
                }}
              />
            </Alert>
          </Collapse>

          <form
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {isInvalidSession ? null : (
                  <TextField
                    autoFocus={true}
                    className={'code_input'}
                    inputProps={{ maxLength: 6 }}
                    required
                    fullWidth
                    value={verificationCode}
                    type="text"
                    onChange={(e) => mfaOnChange(e)}
                  />
                )}
                {cognitoError ? (
                  <Box>
                    <Box sx={{ m: '1rem 0.2rem' }}>
                      <Alert severity="error">{cognitoError}</Alert>
                    </Box>
                    {isInvalidSession ? (
                      <Button
                        onClick={() => {
                          history.push(LOGIN_PAGE);
                        }}
                        variant="contained"
                        endIcon={<ReplayIcon />}
                        color="error"
                      >
                        Sign in again
                      </Button>
                    ) : null}
                  </Box>
                ) : null}
              </Grid>
            </Grid>
          </form>
        </main>
      </div>
    </Box>
  );
};

export default withTenantLayout(MFAVerifyPage)({
  pageName: 'SCR-MFA-Verify',
  publicPage: true,
});
