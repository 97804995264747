import { Box, CircularProgress } from '@material-ui/core';
import React from 'react';

export type Props = {
  color?: 'primary' | 'secondary' | 'inherit';
};

const Loader: React.FC<Props> = ({ color }: Props) => {
  return (
    <Box display="flex" justifyContent="center">
      <CircularProgress color={color} />
    </Box>
  );
};

Loader.defaultProps = {
  color: 'secondary',
};

export default Loader;
