import {
  Collapse,
  createStyles,
  Grid,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import React, { useState } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    itemHeader: {
      backgroundColor: '#b2bec3',
    },
  })
);

type Props = {
  title: string;
  items: React.FC[];
};

const AccordionList: React.FC<Props> = ({ title, items }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  return (
    <Grid item xs={12}>
      <ListItem
        button
        onClick={() => setOpen(!open)}
        className={classes.itemHeader}
      >
        <ListItemText primary={title} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding className="list_body">
          {items.map((ItemContent, index) => (
            <div
              style={{ borderBottom: '1px solid #b2bec3' }}
              key={`listItem-${index}`}
            >
              <ListItem button>
                <ItemContent />
              </ListItem>
            </div>
          ))}
        </List>
      </Collapse>
    </Grid>
  );
};

export default AccordionList;
