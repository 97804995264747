import { State, hasFeature } from '@dsf/data-access-store';
import { extractRoleFromToken } from '@dsf/util-tools';
import useWebSocket, { ReadyState } from 'react-use-websocket';

import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Button,
  Skeleton,
  Grid,
  TextField,
  List,
  ListItem,
  ListItemText,
  Divider,
  Fab,
  CircularProgress,
} from '@mui/material';
import { UiContentBox } from '@dsf/ui-content-box';
import ChatIcon from '@mui/icons-material/Chat';
import { useState } from 'react';
import { useWrapApi } from '@dsf/data-access-api';
import { useParams } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import SendIcon from '@material-ui/icons/Send';
import TranslateIcon from '@mui/icons-material/Translate';
import { getCurrentLanguage } from '@dsf/util-translate';
import Moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/da';
import 'moment/locale/pt';
import 'moment/locale/zh-cn';
import { useTranslation } from 'react-i18next';

export type ConversationParams = {
  conversationId?: string;
};

type FormValues = {
  text: string;
  origin_language?: string;
  type?: string;
};

type Message = {
  sort_key: string;
  text: string;
  created_at: string;
  conversation_id: string;
  created_by: string;
  translation: string;
};

type InitialAttributes = {
  type: string;
  id?: string;
};

const INITIAL_STATE: FormValues = {
  text: '',
};

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  chatSection: {
    width: '100%',
    height: '80vh',
  },
  headBG: {
    backgroundColor: '#e0e0e0',
  },
  borderRight500: {
    borderRight: '1px solid #e0e0e0',
  },
  messageArea: {
    height: '360px',
    overflowY: 'auto',
    display: 'flex',
    flexFlow: 'column-reverse',
  },
});

export const Messenger = (attributes: InitialAttributes) => {
  const { api } = useWrapApi();
  const { conversationId } = useParams<ConversationParams>();
  const [messages, setMessages] = useState<Message[] | undefined>([]);
  const [lastMessageIndex, setLastMessageIndex] = useState<any | undefined>();
  const [detail, setDetail] = useState<any | undefined>();
  const [loadingDetail, setLoadingDetail] = useState(true);
  const [loadingMessages, setLoadingMessages] = useState(true);
  const [sendingMessage, setSendingMessage] = useState(false);
  const [showOriginal, setShowOriginal] = useState(false);
  const classes = useStyles();

  const { t } = useTranslation();

  const { sendMessage, lastMessage, readyState } = useWebSocket(
    'wss://qtqd8gdz72.execute-api.eu-central-1.amazonaws.com/dev'
  );
  const [websocketMessages, setWebsocketMessages] = useState<any[]>([]);
  const [lastUpdatedMessages, setLastUpdatedMessages] = useState<any>();

  const handleChange =
    (prop: keyof FormValues) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFormValues({ ...formValues, [prop]: event.target.value });
    };
  const [formValues, setFormValues] = useState<FormValues>(INITIAL_STATE);

  const { profile } = useSelector((state: State) => state.identity);
  const lang = getCurrentLanguage();

  const { roles } = useSelector((state: State) => state.userTokens);

  const fetchDetailById = useCallback(() => {
    try {
      setLoadingDetail(true);
      api
        .get('/conversation/' + conversationId)
        .then(({ data }) => {
          setDetail(data);
          setLoadingDetail(false);
        })
        .catch((error) => {
          console.error('conversation detail error', error);
          setLoadingDetail(false);
        });
    } catch (error) {
      console.log(error);
      setLoadingDetail(false);
    }
  }, [conversationId]);

  const fetchDetailByAttribute = useCallback(() => {
    try {
      setLoadingDetail(true);
      api
        .get(
          '/conversation/attribute?attribute=' +
            attributes.type +
            '&val=' +
            attributes.id
        )
        .then(({ data }) => {
          setDetail(data);
          runWebsocket(data.conversation_id);
          fetchMessages(data.conversation_id, null, []);
          setLoadingDetail(false);
        })
        .catch((error) => {
          console.error('conversation detail error', error);
          setLoadingDetail(false);
        });
    } catch (error) {
      console.log(error);
      setLoadingDetail(false);
    }
  }, []);

  const fetchMessages = useCallback(
    (conversationId, lastIndex, messages: Message[]) => {
      try {
        let language = lang;
        setLoadingMessages(true);

        let last_message_parameter = '';

        if (lastIndex != null) {
          last_message_parameter = '&last_key=' + lastIndex.sort_key;
        }

        api
          .get(
            '/conversation/' +
              conversationId +
              '/message?target_language=' +
              language +
              last_message_parameter
          )
          .then(({ data }) => {
            let mess: Message[];
            if (messages.length == 0) {
              mess = data.messages;
            } else {
              let old_mess: Message[] = messages;
              let new_mess: Message[] = data.messages;
              mess = old_mess.concat(new_mess);
            }

            setMessages(mess);
            setLastMessageIndex(data.last_evaluated_item);
            setLoadingMessages(false);
            console.log(mess);
          })
          .catch((error) => {
            console.error('mesages detail error', error);
            setLoadingMessages(false);
          });
      } catch (error) {
        setLoadingMessages(false);
      }
    },
    []
  );

  const runWebsocket = useCallback(
    (conversation_id) => {
      if (conversation_id) {
        sendMessage(
          JSON.stringify({
            action: 'getConversationStream',
            message: {
              conversation_id: conversation_id,
              eventType: 'onNewMessage',
            },
          })
        );
      }
    },
    [readyState, conversationId, sendMessage]
  );

  useEffect(() => {
    if (attributes.type == 'chat') {
      fetchDetailById();
      fetchMessages(conversationId, null, []);
    } else if (attributes.type == 'field-overview') {
      fetchDetailByAttribute();
    }
    /*const interval = setInterval(() => fetchMessages(), 2000);
    return () => {
      clearInterval(interval);
    };*/
  }, [fetchMessages, fetchDetailById]);

  useEffect(() => {
    if (lastMessage && lastMessage.data) {
      let messageData = JSON.parse(lastMessage.data);
      if (messageData?.event == 'newMessage') messagePush(messageData?.message);

      setWebsocketMessages((prev) => [...prev, messageData]);
    }
  }, [lastMessage]);

  const sendMsg = async () => {
    setSendingMessage(true);
    if (attributes?.type == 'field-overview') {
      formValues.type = attributes.type;
      formValues.origin_language = lang;
    }
    try {
      const response = await api
        .post(
          '/conversation/' + detail?.conversation_id + '/message',
          formValues
        )
        .then((an) => {
          setSendingMessage(false);
          setFormValues(INITIAL_STATE);
        });
    } catch (error: any) {
      console.error('form submit error', error);
    }
  };

  function isSender(props: any) {
    if (profile?.loginUserName) {
      if (props.created_by === profile?.loginUserName) {
        return true;
      } else {
        return false;
      }
    }

    return true;
  }

  function getRecepients() {
    if (detail) {
      let header = '';
      for (const recepient of detail.recepients) {
        if (recepient.given_name && recepient.family_name) {
          header = recepient.given_name + ' ' + recepient.family_name;
        } else {
          header = recepient.username;
        }
      }

      return header;
    }

    return '';
  }

  async function messagePush(message: any) {
    if (message?.origin_language == lang) {
      pushMessagetoChat(message);
    } else {
      let response = await api
        .get(
          '/conversation/translate?origin=' +
            message?.origin_language +
            '&target=' +
            lang +
            '&message=' +
            message?.text
        )
        .then((an) => {
          message.translation = an.data.translation;
          pushMessagetoChat(message);
        });
    }
  }

  function pushMessagetoChat(message: any) {
    messages?.unshift(message);
    setMessages(messages);
  }

  function printMessage(val: any) {
    if (showOriginal == true) return val?.text;
    if (val.origin_language == lang) {
      return val?.text;
    } else {
      return val.translation;
    }
  }

  function removeTenant(str: string) {
    if (str) return str.split('@')[0];
    return str;
  }

  const onScroll = (e) => {
    if (e.target.scrollTop - 360 + e.target.scrollHeight < 1) {
      if (loadingMessages == false && lastMessageIndex !== null) {
        console.log('top');
        setLoadingMessages(true);
        fetchMessages(detail.conversation_id, lastMessageIndex, messages);
      }
    }
  };

  return (
    <Box mt="2rem">
      <UiContentBox
        icon={
          <Box>
            <ChatIcon />
          </Box>
        }
        title={
          loadingDetail ? (
            <Skeleton
              sx={{ transform: 'none' }}
              width="200px"
              height="25px"
              animation="wave"
              variant="text"
            />
          ) : (
            <Box
              display="flex"
              fontWeight="bold"
              fontSize="1.125rem"
              alignItems="center"
              id="title_equipment-overview"
            >
              {detail?.name ? detail.name : getRecepients()}
            </Box>
          )
        }
        postfix={
          <Box
            sx={{
              display: 'flex',
            }}
          >
            <Button
              variant="contained"
              id="button_show-original"
              onClick={() => {
                setShowOriginal(!showOriginal);
              }}
              startIcon={<TranslateIcon />}
              sx={{
                mr: '0.5rem',
              }}
            >
              {showOriginal
                ? t('fieldOverview.messenger.showTranslation')
                : t('fieldOverview.messenger.showOriginal')}
            </Button>
          </Box>
        }
      >
        <List className={classes.messageArea} onScroll={onScroll}>
          {messages?.map((val: any, key: any) => {
            val.show_original = false;

            return (
              <Box>
                <ListItem key={key}>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        textAlign: isSender(val) ? 'right' : 'left',
                      }}
                    >
                      <ListItemText
                        primary={
                          isSender(val)
                            ? val.text
                            : printMessage(val, val.show_original)
                        }
                        onClick={(e) => {
                          val.show_original = !val.show_original;
                        }}
                      ></ListItemText>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        textAlign: isSender(val) ? 'right' : 'left',
                      }}
                    >
                      <ListItemText
                        secondary={
                          Moment(val.created_at)
                            .utc(val.created_at)
                            .locale(lang)
                            .fromNow() +
                          ' | ' +
                          removeTenant(val.created_by)
                        }
                      ></ListItemText>
                    </Grid>
                  </Grid>
                </ListItem>
              </Box>
            );
          })}
          {loadingMessages && (
            <Box textAlign="center">
              <CircularProgress size={18} />
            </Box>
          )}
        </List>
        <Divider />
        <Grid container style={{ padding: '10px 0px 0px' }}>
          <Grid item xs={10} md={11}>
            <TextField
              label={t('fieldOverview.messenger.yourMessage')}
              value={formValues.text}
              placeholder="Type something"
              onChange={handleChange('text')}
              fullWidth
            />
          </Grid>
          <Grid item xs={2} md={1}>
            <Fab
              color="primary"
              aria-label="add"
              style={{
                fontSize: '12px',
                width: '45px',
                height: '45px',
                marginLeft: '10px',
                marginTop: '5px',
              }}
              onClick={(e) => sendMsg()}
            >
              {sendingMessage ? (
                <CircularProgress size={16} sx={{ color: 'white' }} />
              ) : (
                <SendIcon style={{ fontSize: '16px' }} />
              )}
            </Fab>
          </Grid>
        </Grid>
      </UiContentBox>
    </Box>
  );
};
