import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

export interface PageContext {
  translate: TFunction;
  // eslint-disable-next-line
  dispatch: (fn: any) => void;
}

export const getPageContext = (): PageContext => {
  // eslint-disable-next-line
  const { t } = useTranslation();
  // eslint-disable-next-line
  const dispatch = useDispatch();

  return {
    translate: t,
    dispatch: dispatch,
  };
};
